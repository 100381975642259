import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { OpenInNew } from '@material-ui/icons'
import styled from 'styled-components'
import { Tooltip } from '@material-ui/core'

const LinkText = styled.div`

`
const LinkAction = styled.div`
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const LinkWrapper = styled(RouterLink)`
  display: block;
  color: inherit;
  text-decoration: inherit;
  position: relative;
  &:hover ${LinkText} {
    mask-image: linear-gradient(90deg, rgba(0,0,0,1), rgba(0,0,0,0));
  }
  &:hover ${LinkAction} {
    opacity: 1;
  }
`

const LinkIcon = styled(OpenInNew)`
  color: ${props => props.theme.palette.secondary.main}
  width: 15px;
  height: 15px;
`

const Link = ({ value, title, link }) => {
  return (
    <LinkWrapper to={link} target="_blank">
      <LinkText>{ value }</LinkText>
      {
        link && <Tooltip title={`Open ${title ? `${title} ` : ''} details`}>
          <LinkAction><LinkIcon /></LinkAction>
        </Tooltip>
      }

    </LinkWrapper>
  )
}

export default Link
