import { observable, action, computed } from 'mobx'
import { Get, Put } from '../utils/request'
import hasError from './request-message'

class HostBlocklistStore {
  @observable loading = true
  @observable thinking = false
  @observable string = ''

  constructor() {
    this.load().then()
  }

  @action
  async load () {
    this.loading = true
    const { message, list } = await Get(`/api/admin/hosts-blocklists`)

    if (!hasError(!message, message, '')) {
      this.string = list
    }
    this.loading = false
  }

  @action
  async update (listItems) {
    this.thinking = true

    const listString = listItems.filter(i => !!i).join('\n')

    const { success, message, list } = await Put(`/api/admin/hosts-blocklists`, { list: listString })

    if (!hasError(success, message, 'Host blocklist successfully updated')) {
      this.string = list
    }
    this.thinking = false

    return { success }
  }

  @computed
  get list () {
    return this.string.split('\n')
  }
}

export default HostBlocklistStore
