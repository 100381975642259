import React from 'react'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import Campaign from './Campaign'
import TrackingCampaignStore from '../../stores/tracking-campaign'

@withRouter
@observer
class _Campaign extends React.Component {
  constructor(props) {
    super(props)
    const {
      match: {
        params: {
          id
        }
      }
    } = props
    this.store = new TrackingCampaignStore(id, 'campaign')
  }

  onCreate = async (details) =>  {
    const { errorCode, campaign } = await this.store.create(details)

    if (campaign && campaign.id) {
      this.props.history.push(`/tracking-campaigns/${campaign.id}`)
    }
  }

  onUpdate = async (details) => await this.store.update(details)

  onCancel = () => this.props.history.push(`/tracking-campaigns`)

  onToggleArchived = async () => await this.store.toggleArchived()

  // Touchpoints
  onUpdateTouchpoint = async (details) => {
    return await this.store.updateTouchpoint(details)
  }

  onRemoveTouchpoint = async (id) => {
    return await this.store.removeTouchpoint(id)
  }
  ///

  // Coupons
  onCreateCoupon = async (kind, details) => await this.store.createCoupon(kind, details)
  onUpdateCoupon = async (kind, details) => await this.store.updateCoupon(kind, details)
  onRemoveCoupon = async (kind, id) => await this.store.removeCoupon(kind, id)
  // Coupons

  render () {
    const {
      match: {
        params: {
          id
        }
      }
    } = this.props

    const {
      details,
      loading,
      thinking,
      rules,
      landings,
      statistics,
      touchpoints,
      coupons
    } = this.store

    const touchpointsProps = {
      thinking: touchpoints.thinking,
      items: touchpoints.touchpoints,
      columns: [
        {id: 'id', disablePadding: true, label: 'ID'},
        {id: 'name', disablePadding: true, label: 'Name'},
        {id: 'kind', disablePadding: true, label: 'Kind'},
        {id: 'revenueModel', disablePadding: true, label: 'Revenue type'},
        {id: 'revenue', disablePadding: true, label: 'Revenue'},
        {id: 'costModel', disablePadding: true, label: 'Payout type'},
        {id: 'cost', disablePadding: true, label: 'Payout'},
        {id: 'lookback', disablePadding: true, label: 'Lifetime'}
      ],
      onUpdate: this.onUpdateTouchpoint,
      onRemove: this.onRemoveTouchpoint,
    }
    const couponsProps = {
      ...coupons,
      onCreate: this.onCreateCoupon,
      onUpdate: this.onUpdateCoupon,
      onRemove: this.onRemoveCoupon,
    }

    return <Campaign
      id={id}
      details={details}
      loading={loading}
      thinking={thinking}
      rules={rules}
      landings={landings}
      stats={statistics}
      touchpoints={touchpointsProps}
      coupons={couponsProps}

      onCreate={this.onCreate}
      onUpdate={this.onUpdate}
      onCancel={this.onCancel}
      onToggleArchived={this.onToggleArchived}
    />
  }
}

export default _Campaign
