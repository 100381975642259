import React from 'react'
import {
  Card as MuiCard,
  CardHeader as MuiCardHeader,
  CardActions,
  CardContent,
  IconButton
} from '@material-ui/core'
import styled from 'styled-components'
import { Edit as IconEdit, AddCircleOutline as IconAdd } from '@material-ui/icons'
import { spacing } from '@material-ui/system'
import LoaderOverlay from './LoaderOverlay'

const Card = styled(MuiCard)(spacing)
const Add = styled(IconAdd)`
width: 20px;
height: 20px;
`

const Edit = styled(IconEdit)`
width: 20px;
height: 20px;
`

const CardHeader = styled(MuiCardHeader)`
height: 40px;
padding: 25px 15px 15px;

`

class PaperEditable extends React.Component {
  render () {
    const {
      actions: Actions,
      children,
      title,
      editable,
      edit,
      adding,
      thinking,
      dataCy,
      styles,
      onAdd,
      onEdit,
    } = this.props
    const heading = !!title || !!editable
    const showHeaderActions = (editable || adding) && !edit
    const onClick = () => {
      if (adding) {
        return onAdd()
      } else if (editable) {
        return onEdit()
      }
    }

    const Icon = () => {
      if (adding) {
        return <Add />
      } else if (editable) {
        return <Edit />
      }
      return null
    }

    return (
      <Card mb={3} style={{ position: 'relative', ...styles }}>
        { thinking && <LoaderOverlay /> }
        {!!heading && <CardHeader
          action={
            showHeaderActions &&
            <IconButton
              color="primary"
              data-cy={!!dataCy && dataCy}
              onClick={onClick}
            >
              <Icon />
            </IconButton>
          }
          title={ title }
        />}
        {/*<CardContent>*/}
        { children }
        {/*{ thinking && <LoaderOverlay /> }*/}
        {/*</CardContent>*/}
        { Actions && <Actions/> }
      </Card>
    )
  }
}

export default PaperEditable
