import React from 'react'
import { inject, observer } from 'mobx-react'
import { Redirect } from 'react-router-dom'


@inject(stores => ({
  authorized: stores.AuthorizationStore.authorized,
  checkAuthorization: stores.AuthorizationStore.checkAuthorization
}))
@observer
class PrivateRoute extends React.Component {
  render () {
    const {
      layout: Layout,
      component: Component,
      authorized,
      checkAuthorization,
      discovery
    } = this.props

    if (!authorized && !checkAuthorization) {

      return <Redirect to={{ pathname: '/signin' }} />
    }

    return (
      <Layout thinking={checkAuthorization} discovery={discovery}>
        <Component {...this.props} />
      </Layout>
    )
  }
}

export default PrivateRoute
