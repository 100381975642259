import React from 'react'
import { find, findIndex, isEqual } from 'lodash'
import Panel from './Panel'
import {
  Grid,
  Button as MuiButton,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography
} from '@material-ui/core'
import Autocomplete from './Autocomplete'
import List from './List'
import country from '../data/countries'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import {Warning as IconWarning} from '@material-ui/icons'
import orange from '@material-ui/core/colors/orange';


const ruleValues = {
  country,
  type: [
    {label: 'Desktop', value: 'desktop'},
    {label: 'Mobile', value: 'mobile'},
    {label: 'Tablet', value: 'tablet'},
    {label: 'Other', value: 'other'}
  ],
  os: [
    {label: 'Windows', value: 'Windows'},
    {label: 'Mac OS', value: 'Mac OS'},
    {label: 'Linux', value: 'Linux'},
    {label: 'iOS', value: 'iOS'},
    {label: 'Android', value: 'Android'},
    {label: 'BlackBerry', value: 'BlackBerry'},
    {label: 'Windows Phone', value: 'Windows Phone'},
    {label: 'Windows Mobile', value: 'Windows Mobile'},
    {label: 'Other', value: 'other'}
  ],
  browser: [
    {label: 'Chrome', value: 'Chrome'},
    {label: 'Safari', value: 'Safari'},
    {label: 'Edge', value: 'Edge'},
    {label: 'Internet Explorer', value: 'Internet Explorer'},
    {label: 'Opera', value: 'Opera'},
    {label: 'Firefox', value: 'Firefox'},
    {label: 'Other', value: 'other'}
  ]
}

const columns = [
  { id: 'n', disablePadding: true, label: '#' },
  { id: 'action', disablePadding: true, label: 'Action' },
  { id: 'country', disablePadding: true, label: 'Country' },
  { id: 'type', disablePadding: true, label: 'Device' },
  { id: 'os', disablePadding: true, label: 'OS' },
  { id: 'browser', disablePadding: true, label: 'Browser' }
]

const DialogActions = styled(MuiDialogActions)(spacing)
const Button = styled(MuiButton)(spacing)
const Warning = styled(IconWarning)`
  color: ${orange[700]};
  margin-right: 0.4em;
`
const WarningPane = styled(Paper)`
  background: ${props => props.theme.palette.grey[300]};
  display: flex;
  align-items: center;
  padding: 20px;
`


const normalizeRules = rules => {
  return rules.map((rule, index) => {
    let _rule = {}
    for (let key in ruleValues) {
      if (ruleValues.hasOwnProperty(key)) {
        _rule[key] = _rule[key] || []
        if (rule[key]) {
          rule[key].forEach(v => {
            _rule[key].push(find(ruleValues[key], ['value', v]))
          })
        }
      }
    }
    return { id: index + 1, ..._rule, opportunity: rule.opportunity }
  })
}

const denormalizeRules = rules => {
  return rules.map(({ country, type, os, browser, opportunity }) => ({
    country: country.map(({ value }) => value),
    type: type.map(({ value }) => value),
    os: os.map(({ value }) => value),
    browser: browser.map(({ value }) => value),
    opportunity
  }))
}

const tableRules = rules => {
  return rules.map((rule, index) => {
    let row = {}
    columns.forEach(c => {
      if (c.id === 'n') {
        return row['n'] = index + 1
      }
      if (c.id === 'action') {
        return row['action'] = 'Allow'
      }
      row[c.id] = (rule[c.id] || []).map(({ label }) => label).join(', ')
    })
    row.id = rule.id
    row.disabled = rule.opportunity
    return row
  })
}

export class RuleDialog extends React.Component {
  static defaultProps = {
    rule: {
      country: [],
      type: [],
      os: [],
      browser: []
    }
  }

  state = {
    country: [],
    type: [],
    os: [],
    browser: []
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...this.props.rule })
    }
  }

  onChange = key => (e, value) => {
    this.setState({ [key]: value })
  }

  onRemove = () => {
    const { id } = this.state
    return this.props.onRemoveRule(id)
  }

  onSave = () => {
    const {
      id,
      country,
      type,
      os,
      browser
    } = this.state

    return this.props.onSaveRule({ id: id || Math.random(), country, type, os, browser })
  }

  onCancel = () => {
    this.props.onCancelEditRule()
  }

  render () {
    const {
      open,
      campaign
    } = this.props

    const {
      id,
      country,
      type,
      os,
      browser
    } = this.state

    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        data-cy="addRuleModal"
        open={open}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          Define Rule
        </DialogTitle>
        <DialogContent>
          {
            campaign && <DialogContentText>
              <WarningPane><Warning />
                <Typography variant={"body2"}>By defining a new rule you will disable all current rules for this campaign</Typography></WarningPane>
            </DialogContentText>
          }

          <Autocomplete
            id="country"
            dataCy="countryRule"
            label="Country"
            placeholder="Select country"
            options={ruleValues.country}
            value={country}
            onChange={this.onChange('country')}
          />

          <Autocomplete
            id="device"
            label="Device"
            dataCy="deviceRule"
            placeholder="Select device"
            options={ruleValues.type}
            value={type}
            onChange={this.onChange('type')}
          />

          <Autocomplete
            id="os"
            label="OS"
            dataCy="osRule"
            placeholder="Select OS"
            options={ruleValues.os}
            value={os}
            onChange={this.onChange('os')}
          />

          <Autocomplete
            id="browser"
            label="Browser"
            dataCy="browserRule"
            placeholder="Select browser"
            options={ruleValues.browser}
            value={browser}
            onChange={this.onChange('browser')}
          />

        </DialogContent>
        <DialogActions px={6} py={4}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              {
                !!id && <Button onClick={this.onRemove} mr={3} variant="outlined">
                  Remove Rule
                </Button>
              }
            </Grid>
            <Grid xs={12} sm={6}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center">
              <Button onClick={this.onCancel} color="default" mr={2}>
                Cancel
              </Button>
              <Button onClick={this.onSave} variant="outlined" color="primary">
                Save Rule
              </Button>
            </Grid>
          </Grid>


        </DialogActions>
      </Dialog>
    )
  }
}

export default class Rules extends React.Component {
  constructor (props) {
    super(props)
    this.state = { open: false }
  }

  // componentDidUpdate (prevProps, prevState, snapshot) {
  //   if (isEqual(prevProps.rules, this.props.rules)) {
  //     this.setState({ rules: normalizeRules(this.props.rules) })
  //   }
  // }

  onAddRule = () => {
    this.setState({ open: true, rule: undefined })
  }

  onEditRule = (e, id) => {
    const rules = normalizeRules(this.props.rules)
    const rule = find(rules, ['id', id])
    if (rule.opportunity) return
    this.setState({ rule, open: true })
  }

  onSaveRule = (rule) => {
    const rules = normalizeRules(this.props.rules)
    const index = findIndex(rules, ['id', rule.id])

    if (index < 0) {
      rules.push(rule)
    } else {
      rules[index] = rule
    }

    this.props.onUpdate(denormalizeRules(rules))
    this.setState({ open: false })
  }

  onCancelEditRule = () => {
    this.setState({ open: false })
  }

  onRemoveRule = id => {
    const rules = normalizeRules(this.props.rules)
    const index = findIndex(rules, ['id', id])
    if (index >= 0) {
      rules.splice(index, 1)

      this.props.onUpdate(denormalizeRules(rules))

      this.setState({ open: false })
    }
  }

  render () {
    const {
      open,
      rule
    } = this.state

    const {
      campaign,
      thinking
    } = this.props
    const rules = normalizeRules(this.props.rules)

    return (
      <Panel
        title="Rules"
        dataCy="addRule"
        adding
        thinking={thinking}
        onAdd={this.onAddRule}
      >
        <List
          columns={columns}
          items={tableRules(rules)}
          onClickRow={this.onEditRule}
          emptyMessage={'No Rules are added.'}
          emptyActionText={'Add a Rule'}
          onEmptyAction={this.onAddRule}
          disablePagination/>
        <RuleDialog
          open={open}
          rule={rule}
          campaign={campaign}
          onSaveRule={this.onSaveRule}
          onRemoveRule={this.onRemoveRule}
          onCancelEditRule={this.onCancelEditRule}
        />
      </Panel>
    )
  }
}
