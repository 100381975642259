import React from 'react'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import Contact from './Contact'
import ContactStore from '../../stores/contact'

@withRouter
@observer
class _Contact extends React.Component {
  constructor(props) {
    super(props)
    const {
      match: {
        params: {
          id
        }
      }
    } = props

    this.store = new ContactStore(id)
  }

  onSave = async (details, publish) => {
    return await this.store.update(details, publish)
  }

  onRemove = async (id) => {
    const { success } = await this.store.remove(id)
    if (success) {
      this.props.history.push({ pathname: '/contacts' })
    }
    return { success }
  }

  render () {
    const { loading, thinking, details } = this.store

    return <Contact
      details={details}
      loading={loading}
      thinking={thinking}
      onSave={this.onSave}
      onRemove={this.onRemove}
    />
  }
}

export default _Contact
