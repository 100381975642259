import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import Campaign from './Campaign'
import CampaignStore from '../../stores/campaign'

@withRouter
@observer
class _Campaign extends React.Component {
  constructor(props) {
    super(props)
    const {
      match: {
        params: {
          id
        }
      }
    } = props
    this.store = new CampaignStore(id)
  }

  render () {
    const {
      details,
      loading,
      thinking
    } = this.store

    return <Campaign
      details={details}
      loading={loading}
      thinking={thinking}
    />
  }
}

export default _Campaign
