import React from 'react'
import { IconButton } from '@material-ui/core'
import { Delete as RemoveIcon } from '@material-ui/icons'

import ConfirmDialog from '../ConfirmDialog'
import List from '../List'
import Panel from '../Panel'

const columns = [
  { id: 'createdAt', disablePadding: true, label: 'Created at', nowrap: true },

  {
    id: 'name',
    label: 'Name',
    disablePadding: true,
    kind: 'link',
    link: {
      title: 'SaaS Ecosystem',
      pattern: `/saas-ecosystems/{id}`,
      keys: { id: 'id' },
    },
  },
  {
    id: 'channelsCount',
    disablePadding: true,
    label: 'Brands',
    numeric: true,
  },
  {
    id: 'affiliatePartnersCount',
    disablePadding: true,
    label: 'Creators',
    numeric: true,
  },
  {
    id: 'inPartnershipsCount',
    disablePadding: true,
    label: 'Partnerships',
    numeric: true,
  },
  { id: 'actions', numeric: 'right', kind: 'actions' },
]

const EcosystemMenu = ({ ecosystemId, onRemove }) => {
  return (
    <>
      <ConfirmDialog
        title={'Remove SaaS Ecosystem from Brand'}
        confirmation
        confirmationText={
          'Are you sure you want to remove this SaaS ecosystem from brand?'
        }
        action={() => onRemove(ecosystemId)}
        confirmButtonLabel={'Remove'}
        confirmButtonProps={{
          variant: 'contained',
          color: 'primary',
        }}
      >
        <IconButton size={'small'}>
          <RemoveIcon fontSize={'small'} />
        </IconButton>
      </ConfirmDialog>
    </>
  )
}

export default class Ecosystems extends React.Component {
  onRemove = async (id) => {
    return await this.props.onRemove(id)
  }

  onCancel = () => {
    this.setState({ open: false })
  }

  render() {
    const { items } = this.props

    return (
      <Panel title='SaaS Ecosystems'>
        <List
          disablePagination
          {...this.props}
          columns={columns}
          searchable={false}
          emptyMessage={'No ecosystems.'}
          items={items.map((item) => ({
            ...item,
            actions: () => (
              <EcosystemMenu
                ecosystemId={item.id}
                onRemove={(id) => this.onRemove(id)}
              />
            ),
          }))}
        />
      </Panel>
    )
  }
}
