import { gql } from '@apollo/client'

const typeDefs = gql`
  extend type SigninUserInput {
    email: String!
    password: String!
  }
  extend type SignupUserInput {
    email: String!
    password: String!
  }
  extend type User {
    id: ID!
  }
  extend type UserWithToken {
    token: String!
    user: User!
  }
  
  extend type InviteUserInput {
    brandId: String!
    email: String!
  }
  
  extend type CreateBrandInput {
    name: String!
    link: String
    logoUrl: String
    description: String
  }
  extend type UpdateBrandInput {
    id: String!
    name: String
    link: String
    logoUrl: String
    description: String
    onboardingStep: String
  }
  
  extend type CreateSearchInput {
    brandId: String!
    countryCode: String!
    domain: String!
  }
  extend type UpdateSearchInputPatch {
    competitorUrls: String
    description: String
    irrelevants: String
    keywords: String
    name: String
  }
  extend type UpdateSearchInput {
    id: String!
    patch: UpdateSearchInputPatch!
  }
  
  extend type ImportPartnersInput {
    brandId: String!
    links: [String!]!
  }
  extend type ChangeProspectsKindInput {
    kind: String!
    brandId: String!
    ids: [String!]!
  }
  extend type DeleteProspectsInput {
    brandId: String!
    ids: [String!]!
  }
  
  
  extend type CreateFoldersInput {
    brandId: String!
    kind: String!
    names: [String!]!
  }
  extend type CreateFolderInput {
    brandId: String!
    kind: String!
    names: String!
  }
  extend type UpdateFolderInputPatch {
    name: String
    status: String
  }
  extend type UpdateFolderInput {
    id: String!
    brandId: String!
    patch: UpdateFolderInputPatch!
  }
  extend type ReorderFoldersInput {
    brandId: String!
    kind: String!
    folderIds: [String!]!
  }
  
  extend type RequestContactsInput {
    brandId: String!
    channelIds: [String!]!
  }
`

export default typeDefs
