import Notification from './notification'

const hasError = (success, errorMessage, successMessage) => {
  if (!success) {
    Notification.show(errorMessage, 'error')
    return true
  } else if (successMessage) {
    Notification.show(successMessage, 'success')
  }

  return false
}

export default hasError
