import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import Ecosystem from './SaaSEcosystem'
import EcosystemStore from '../../stores/saas-ecosystem'

@withRouter
@observer
class _Ecosystem extends React.Component {
  constructor(props) {
    super(props)
    const {
      match: {
        params: { id },
      },
    } = props
    this.store = new EcosystemStore(id)
  }

  onRemove = async () => {
    const { success } = await this.store.remove()
    if (success) {
      this.props.history.push(`/saas-ecosystems`)
    }
  }

  render() {
    const {
      match: {
        params: { id },
      },
    } = this.props

    const { details, loaded, loading, thinking } = this.store

    return (
      <Ecosystem
        id={id}
        details={details}
        loaded={loaded}
        loading={loading}
        thinking={thinking}
        onRemove={this.onRemove}
      />
    )
  }
}

export default _Ecosystem
