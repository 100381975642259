import React from 'react'
import { Grid } from '@material-ui/core'
import DashboardPage from '../../layouts/DashboardPage'
import DetailsForm from '../../components/Details'
import LoaderOverlay from '../../components/LoaderOverlay'
import ConfirmDialog from '../../components/ConfirmDialog'
import { RejectButton } from '../../components/Buttons'

const config = [
  { key: 'id', name: 'ID', type: 'string' },
  { key: 'createdAt', name: 'Created At', type: 'string' },
  {
    key: 'name',
    name: 'Name',
    creatable: true,
    editable: true,
    type: 'string',
  },
  {
    key: 'brandName',
    name: 'Brand',
    type: 'brands',
    link: { title: 'Brand', pattern: `/brands/{id}`, keys: { id: 'brand' } },
  },
  {
    key: 'channelsCount',
    name: 'Brands',
    type: 'string',
  },
  {
    key: 'affiliatePartnersCount',
    name: 'Creators',
    type: 'string',
  },
  {
    key: 'inPartnershipsCount',
    name: 'Partnerships',
    type: 'string',
  },
]

export default class Ecosystem extends React.Component {
  state = { open: false, details: {} }

  componentDidUpdate(prevProps) {
    if (!prevProps.loaded && this.props.loaded) {
      const { details } = this.props
      this.setState({ details })
    }
  }

  render() {
    const { details } = this.state
    const { id, create, loaded, loading, thinking } = this.props

    return (
      <DashboardPage
        kind='details'
        title={create ? 'New ecosystem' : details.name || 'Ecosystem'}
        status={id}
        thinking={loading}
        noActions
        actions={() => (
          <ConfirmDialog
            title={'Remove Ecosystem'}
            confirmation
            confirmationText={'Are you sure you want to remove this ecosystem?'}
            action={this.props.onRemove}
            confirmButtonLabel={'Remove'}
            confirmButtonProps={{
              variant: 'contained',
              color: 'primary',
            }}
          >
            <RejectButton color='default' mr={2}>
              Remove
            </RejectButton>
          </ConfirmDialog>
        )}
      >
        {loading && <LoaderOverlay />}

        {!loading && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DetailsForm
                hideCreateButtons
                loaded={loaded}
                thinking={thinking || loading}
                config={config}
                details={details}
              />
            </Grid>
          </Grid>
        )}
      </DashboardPage>
    )
  }
}
