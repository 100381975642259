import React, {Component} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Card, CardContent, Typography} from '@material-ui/core'
import {formatCurrency} from '../utils/format'
import {Grid} from "@material-ui/core"
import Loader from './LoaderOverlay'

const style = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    minHeight: 117,
    // height: '100%',
    position: 'relative',
  },
  item: {
    color: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: 15,
    position: 'relative',
    borderRight: '1px solid #eee',
    '&:last-child': {
      borderRight: 'none'
    }
  },
  itemChart: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    borderRight: '1px solid #eee',
    '&:last-child': {
      borderRight: 'none'
    }
  },
  label: {
    // fontWeight: 300,
    // fontSize: '0.8rem',
    // opacity: 0.8,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
    zIndex: 3
  },
  value: {
    fontWeight: 300,
    fontSize: '2.1em',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  valueStrong: {
    // fontWeight: 500,
    // fontSize: '2.1em',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  header: {
    display: 'flex',
    marginBottom: -10,
    height: '100%'
  },
  body: {
    display: 'flex',
    position: 'relative',
    flex: 1,
    zIndex: 1
  },
  loader: {
    background: 'rgba(255, 255, 255, 1)',
    zIndex: 2
  }
})

class Widget extends Component {

  renderItem = ({label, value, currency, count, id}) => <Grid item xs={12/count} className={this.props.classes.item} key={id}>
    { this.props.thinking && ! this.props.statCharts && <Loader classes={{root: this.props.classes.loader}} /> }
    <Typography variant={"caption"} className={this.props.classes.label}>{label}</Typography>
      <Typography variant={"h3"} className={this.props.classes.valueStrong}>{currency ? formatCurrency(value) : value}</Typography>
    </Grid>

  renderChart = ({chart, count, id}) => <Grid item xs={12/count} className={this.props.classes.itemChart} key={id}>
        {chart}
      </Grid>

  render() {
    const {classes, statCols, statCharts, children, title, color} = this.props
    const sharedLoader = this.props.thinking && statCharts || this.props.thinking && statCharts === undefined
    return (
      <Card className={classes.root} elevation={1} style={{backgroundColor: color}}>
        <CardContent>
        { sharedLoader && <Loader classes={{root: classes.loader}} /> }
          {!!title && !children &&
          <Grid container className={classes.header}>
            <Grid item xs={12} className={this.props.classes.item}>
              <Typography variant={"caption"} className={this.props.classes.label}>{title}</Typography>
            </Grid>
          </Grid>
            }
        {!!statCols && <Grid container className={classes.header}>
            { statCols.map((item, id) => this.renderItem({...item, count: statCols.length, id:id})) }
          </Grid>}
          {!!statCharts && <Grid container className={classes.body}>
            { statCharts.map((item, id) => this.renderChart({...item, count: statCharts.length, id: id})) }
          </Grid>}
          {!!children && <Grid item xs={12} className={classes.item}>
            {!!title && <Grid container className={classes.header}>
              <Typography variant={"caption"} className={this.props.classes.label}>{title}</Typography>
              </Grid>}
              <Grid container className={classes.body}>
                { children }
              </Grid>
            </Grid>}
        </CardContent>
      </Card>
    )
  }
}

export default withStyles(style)(Widget)
