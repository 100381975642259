import React from 'react'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import styled, { css } from 'styled-components'
import { Button, CardActions, IconButton } from '@material-ui/core'
import { HighlightOff as RemoveIcon } from '@material-ui/icons'

import List from '../../components/List'
import Panel from '../../components/Panel'
import TextRows from '../../components/TextRows'
import ConfirmDialog from '../../components/ConfirmDialog'
import ChannelIcon from '../../components/Icon'

import { formatDate, formatNumber } from '../../utils/format'

import { ChannelsStore } from '../../stores/channels'

// Must be able to view Channel metrics in a Ecosystem details: link, kind, mainTitle, MOZ, SW Rank, SW Visits

const columns = [
  {
    id: 'domain', disablePadding: true, label: 'Link', kind: 'link',
    link: { title: 'Channel', pattern: `/channels/{id}`, keys: { id: 'id' } }
  },
  { id: 'kind', disablePadding: true, label: 'Kind' },
  { id: 'mozDomainAuthority', disablePadding: true, label: 'Domain Authority' },
  { id: 'swGlobalRank', disablePadding: true, label: 'Rank' },
  { id: 'swVisits', disablePadding: true, label: 'Visits' },
  { id: 'actions', disablePadding: true, kind: 'actions' }
]

const TextRowsWrapper = styled.div`
  //padding-bottom: 50px;
  height: ${props => props.kind === 'websites' ? '250px' : '100%'};
  margin: 10px;
  ${props => props.kind === 'websites' && css`
      padding-right: 15px;
      padding-left: 15px;
  `}
`

const TextRowsPanel = ({ kind, editable, edit, title, onEdit, onCancel, onUpdate, children }) => {
  const Actions = () => editable && edit ? (
    <CardActions style={{justifyContent: 'flex-end', borderTop: '1px #eee solid'}}>
      <Button variant="outlined" onClick={onCancel}>
        Cancel
      </Button>
      <Button onClick={onUpdate} variant="contained" color="primary">
        Import
      </Button>
    </CardActions>
  ) : null

  return (
    <Panel
      adding={!edit}
      onAdd={onEdit}
      // editable={editable}
      // edit={edit}
      title={title}
      // onEdit={onEdit}
      actions={Actions}
    >
      <TextRowsWrapper kind={kind}>
        {children}
      </TextRowsWrapper>
    </Panel>
  )
}

const ChannelMenu = ({ ecosystemId, channelId, onRemove }) => {

  return <>
    <ConfirmDialog
      title={'Remove Channel from Ecosystem'}
      confirmation
      confirmationText={'Are you sure you want to remove this channel from ecosystem?'}
      action={() => onRemove(ecosystemId, channelId)}
      confirmButtonLabel={'Remove'}
      confirmButtonProps={{
        variant: 'contained',
        color: 'primary'
      }}
    >
      <IconButton size={'small'}>
        <RemoveIcon fontSize={'small'}/>
      </IconButton>
    </ConfirmDialog>
  </>
}


@observer
class EcosystemChannels extends React.Component {
  constructor (props) {
    super(props)
    const { ecosystemId } = props
    this.store = new ChannelsStore({ ecosystemId })
    this.state = { edit: false }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.ecosystemId === 'create' && this.props.ecosystemId !== 'create') {
      this.store = new ChannelsStore({ ecosystem: this.props.ecosystemId })
    }
  }

  onAdd = () => console.log

  onEdit = () => this.setState({ edit: true })

  onUpdate = async () => {
    this.setState({ thinking: true })
    const { success } = await this.props.onUpdate()
    if (success) {
      await this.store.getList()
      this.setState({ edit: false })
    }
    this.setState({ thinking: false })
  }

  onCancel = () => this.setState({ edit: false })

  onRemoveChannel = async (ecosystemId, channelId) => {
    return await this.store.removeChannelFromEcosystem(ecosystemId, channelId)
  }

  onChangePage = (e, page) => this.store.onChangePage(page)

  onChangeRowsPerPage = ({ target: { value }}) => this.store.onChangeRowsPerPage(value)

  onChangeFilters = name => object => {
    let value = null
    const field = name === 'name' ? 'search' : name

    if (object.hasOwnProperty('target')) {
      value = object.target.value
    } else {
      value = object
    }
    this.store.onChangeFilter(field, value)
  }

  render () {
    const { ecosystemId, create, domains, domainsErrors } = this.props
    const { edit, thinking } = this.state
    const {
      listProps
    } = this.store
    const { items } = listProps

    const showTextFields = create || edit

    return (
      <TextRowsPanel
        kind={showTextFields ? 'websites' : null}
        editable={!create}
        edit={edit}
        title={showTextFields ? create ? 'Domains' : 'Import channels' : 'Ecosystem channels'}
        onEdit={this.onEdit}
        onUpdate={this.onUpdate}
        onCancel={this.onCancel}
      >
        <>
          {
            showTextFields &&  <TextRows
              thinking={thinking}
              disabled={!edit && !create}
              validation={'competitors'}
              helpText={'Type one domain per line'}
              // title={'Websites URLs'}
              placeholder={'e.g. example.com'}
              values={domains}
              errors={domainsErrors}
              onChange={this.props.onChange}
            />
          }

          {
            !showTextFields && <List
              searchable
              columns={columns}
              {...listProps}
              {...this.props}
              items={items.map(({ id, swVisits, ...rest }) => ({ id, ...rest, swVisits: formatNumber(swVisits), actions: () => <ChannelMenu ecosystemId={ecosystemId} channelId={id} onRemove={this.onRemoveChannel} /> }))}
              emptyMessage={'No Channels matching the given criteria.'}
              emptyActionText={'Add Channel'}
              onEmptyAction={this.onEdit}
              onChangePage={this.onChangePage}
              onChangeRowsPerPage={this.onChangeRowsPerPage}
              onChangeFilters={this.onChangeFilters}
            />
          }
        </>
      </TextRowsPanel>
    )
  }
}

export default EcosystemChannels
