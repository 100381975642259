import React from 'react'
import moment from 'moment'
import { TextField } from '@material-ui/core'
import { DateRange as MuiDateRangeIcon } from '@material-ui/icons'
import { DesktopDateRangePicker } from '@material-ui/pickers'
import styled from 'styled-components'
import config from '../config'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & p {
    display: none;
  }
`

export default class BasicDateRangePicker extends React.Component {
  static defaultProps = {
    disableFuture: true,
    startText: 'From date',
    endText: 'To date'
  }
  onChange = (dates) => {
    const parsedDates = dates.map(date => moment(date).format('YYYY-MM-DD'))
    const error = !!parsedDates.filter(d => d === 'Invalid date' )[0]
    if (!error) {
      this.props.onChange(parsedDates)
    }
  }

  render() {
    const {
      disableFuture,
      startText,
      endText
    } = this.props

    return (
      <Wrapper>
        <DesktopDateRangePicker
          inputFormat={config.DATE_FORMAT}
          disableFuture={disableFuture}
          calendars={2}
          data-cy="dataPicker"
          startText={startText}
          endText={endText}
          value={this.props.dates}
          onChange={this.onChange}
          TextFieldComponent={props => <TextField {...props} variant={'standard'}/>}
        />
        <MuiDateRangeIcon />
      </Wrapper>
    )
  }
}
