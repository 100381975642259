import React from 'react'
import { find, findIndex, isEqual } from 'lodash'
import {
  Grid,
  Button as MuiButton,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
  TextField,
  MenuItem
} from '@material-ui/core'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import Panel from './Panel'
import List from './List'
import DateRangePicker from './DateRangePicker'
import LoaderOverlay from './LoaderOverlay'
import config from '../config'
const DialogActions = styled(MuiDialogActions)(spacing)
const Button = styled(MuiButton)(spacing)

const commonColumns = [
  { id: 'code', disablePadding: true, label: 'Code' },
  { id: 'description', disablePadding: true, label: 'Description' },
  { id: 'terms', disablePadding: true, label: 'Terms' },
  { id: 'startsOn', disablePadding: true, label: 'Starts' },
  { id: 'expiresOn', disablePadding: true, label: 'Expires' },
  { id: 'status', disablePadding: true, label: 'Status', kind: 'status' }
]

const privateColumns = [
  { id: 'partnerName', disablePadding: true, label: 'Partner' },
  { id: 'channelName', disablePadding: true, label: 'Channel' },
  { id: 'kindName', disablePadding: true, label: 'Kind' },
]

const getColumns = kind => {
  if (kind === 'common') {
    return commonColumns
  }

  return [...commonColumns.slice(0, 5), ...privateColumns, ...commonColumns.slice(5)]
}

const EMPTY_COUPON = {
  id: null,
  code: null,
  description: null,
  terms: null,
  kind: null,
  startsOn: null,
  expiresOn: null,
  status: 'active'
}

export class CouponDialog extends React.Component {
  constructor (props) {
    super(props)
    this.state = {...(props.coupon || EMPTY_COUPON)}
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (!prevProps.open && this.props.open) {
      if (!this.props.coupon) {
        this.setState({...EMPTY_COUPON, message: null })
      } else {
        this.setState({...this.props.coupon, message: null})
      }
    }
  }

  onSave = async () => {
    this.setState({ thinking: true })
    const {
      id,
      terms,
      code,
      description,
      kind,
      startsOn,
      expiresOn,
      status
    } = this.state
    const { success, message } = await this.props.onSave({ id, terms, code, kind, description, startsOn, expiresOn, status })
    if (!success) {
      this.setState({ message })
    }
    this.setState({ thinking: false })
  }

  onCancel = () => {
    this.props.onCancel()
  }

  onRemove = async () => {
    this.setState({ thinking: true })
    const { id } = this.state
    await this.props.onRemove(id)
    this.setState({ thinking: false })
  }

  onChange = (name) => ({ target: { value }}) => {
    if (name === 'date') {
      const startsOn = value[0]
      const expiresOn = value[1]
      this.setState({ startsOn, expiresOn })
    } else {
      this.setState({ [name]: value })
    }
    if (name === 'code') {
      this.setState({ message: null })
    }
  }

  render () {
    const {
      open,
      dataCy
    } = this.props

    const {
      id,
      code,
      terms,
      kind,
      description,
      startsOn,
      expiresOn,
      status,
      message,
      thinking
    } = this.state

    return (
      <Dialog
        fullWidth
        data-cy={'modal' + dataCy}
        maxWidth="sm"
        open={open}
        aria-labelledby="max-width-dialog-title"
      >
        { thinking && <LoaderOverlay/> }
        <DialogTitle id="max-width-dialog-title">
          Coupon Details
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                error={message}
                helperText={message}
                fullWidth
                label="Code"
                data-cy={'code' + dataCy}
                m={2}
                value={code}
                onChange={this.onChange('code')}
              />
            </Grid>
            <Grid item xs={6}>
              <DateRangePicker
                disableFuture={false}
                dates={[startsOn, expiresOn]}
                onChange={dates => this.onChange('date')({ target: { value: dates } })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                data-cy={'description' + dataCy}
                m={2}
                value={description}
                onChange={this.onChange('description')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Terms"
                m={2}
                data-cy={'terms' + dataCy}
                multiline
                rows="4"
                rowsMax="4"
                value={terms}
                onChange={this.onChange('terms')}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                select
                fullWidth
                label="Kind"
                data-cy={'kind' + dataCy}
                m={2}
                value={kind}
                onChange={this.onChange('kind')}
              >
                {
                  config.COUPON_KINDS.map(({ value, label }) => <MenuItem data-cy={'kind'+ value + dataCy} key={value} value={value}>{label}</MenuItem>)
                }
              </TextField>
            </Grid>

            <Grid item xs={6}>
              <TextField
                select
                fullWidth
                label="Status"
                data-cy={'status' + dataCy}
                m={2}
                value={status}
                onChange={this.onChange('status')}
              >
                <MenuItem value={'active'}>Active</MenuItem>
                <MenuItem value={'paused'}>Paused</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions px={6} py={4}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              {
                !!id && <Button onClick={this.onRemove} mr={3} variant="outlined">
                  Archive Coupon
                </Button>
              }
            </Grid>
            <Grid xs={12} sm={6}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center">
              <Button onClick={this.onCancel} color="default" mr={2}>
                Cancel
              </Button>
              <Button onClick={this.onSave} variant="outlined" color="primary">
                Save Coupon
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }
}

export default class Coupons extends React.Component {
  constructor (props) {
    super(props)
    this.state = { open: false, coupon: null }
  }

  onAddCoupon = () => {
    this.setState({ open: true })
  }

  onEditCoupon = (e, id) => {
    if (!this.props.editable) {
      return false
    }
    this.setState({ open: true, coupon: find(this.props.coupons, ['id', id]) })
  }

  onSaveCoupon = async (details) => {
    const { id, ...otherDetails } = details
    let result = {}
    if (!id) {
      result = await this.props.onCreate(this.props.kind, otherDetails)
    } else {
      result = await this.props.onUpdate(this.props.kind, details)
    }

    const { success, message } = result
    this.setState({ open: !success })
    return { success, message }
  }

  onRemoveCoupon = async (id) => {
    const { success } = await this.props.onRemove(this.props.kind, id)
    this.setState({ open: !success, coupon: null })
    return { success }
  }

  onCancelEditCoupon = () => {
    this.setState({ open: false, coupon: null })
  }

  render () {
    const {
      open,
      coupon
    } = this.state

    const {
      kind,
      coupons,
      creatable,
      thinking
    } = this.props

    return (
      <Panel
        title={`${kind === 'common' ? 'Common' : 'Personal'} Coupons`}
        dataCy={`add${kind === 'common' ? 'Common' : 'Personal'}Coupons`}
        adding={creatable}
        thinking={thinking}
        onAdd={this.onAddCoupon}
      >
        <List
          columns={getColumns(kind)}
          items={coupons}
          onClickRow={this.onEditCoupon}
          emptyMessage={'No Coupons are added.'}
          emptyActionText={creatable ? 'Add a Coupon' : null}
          onEmptyAction={creatable ? this.onAddCoupon : null}
          disablePagination/>

        <CouponDialog
          open={open}
          dataCy={`${kind === 'common' ? 'Common' : 'Personal'}Coupons`}
          coupon={coupon}
          onSave={this.onSaveCoupon}
          onRemove={this.onRemoveCoupon}
          onCancel={this.onCancelEditCoupon}
        />
      </Panel>
    )
  }
}
