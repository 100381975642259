import React from 'react'
import { Button } from '@material-ui/core'

import List from '../../components/List'
import DashboardPage from '../../layouts/DashboardPage'
import ConfirmDialog from '../../components/ConfirmDialog'

const columns = [
  { id: 'createdAt', disablePadding: true, label: 'Created' },
  { id: 'name', disablePadding: true, label: 'Name' },
  { id: 'description', disablePadding: true, label: 'Description' },
  { id: 'channelsCount', disablePadding: true, label: 'Channels' }
]

export default class Ecosystems extends React.Component {
  state = { open: false }

  onOpen = () => this.setState({ open: true })

  onSave = async (details) => {
    return await this.props.onCreate(details)
  }

  onCancel = () => {
    this.setState({ open: false })
  }

  // update-ecosystems-request

  render () {
    return (
      <DashboardPage
        forceDefaultActions
        kind="list"
        title="Ecosystems"
        addLabel="Create Ecosystem"
        addLink="/ecosystems/create"
        actions={() => {
          return (
            <ConfirmDialog
              progress
              title={'Update All Ecosystems'}
              action={this.props.onUpdateEcosystems}
              confirmationButtonPrimary
              confirmButtonLabel={'Update'}
              confirmButtonProps={{
                variant: 'contained',
                color: 'primary'
              }}
              confirmationText={'Are you sure you want to update all ecosystems? It will take some time. Please, don’t run multiple updates simultaneously.'}
              confirmation
            >
              <Button
                variant="outlined"
                color="primary"
              >
                Update
              </Button>
            </ConfirmDialog>
          )}}
      >
        <List
          columns={columns}
          {...this.props}
          searchable
          emptyMessage={'No ecosystems matching the given criteria.'}
        />
      </DashboardPage>
    )
  }
}
