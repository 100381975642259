import { Delete, Get, Post, Put } from '../utils/request'
import { observable, action, computed, toJS } from 'mobx'
import { formatCurrency, formatDateTime } from '../utils/format'
import hasError from './request-message'
import config from '../config'

class SignatureStore {
  @observable id
  @observable createdAt
  @observable name
  @observable published
  @observable description

  @observable domains = []

  @observable loaded = false
  @observable loading = true
  @observable thinking = false

  constructor (id) {
    this.id = id
    if (id !== 'create') {
      this.load().then()
    } else {
      this.loading = false
    }
  }

  websitesToDetails (websites) {
    return websites.map(({ value }) => value).filter(value => Boolean(value))
  }

  fillModel (ecosystem) {
    for (let key in ecosystem) {
      if (ecosystem.hasOwnProperty(key)) {
        if (key === 'domains') {
          this.domains = ecosystem[key].filter(domain => Boolean(domain))
        } else {
         this[key] = ecosystem[key]
        }
      }
    }
  }

  @action
  async load (id) {
    this.id = id || this.id
    this.loaded = false
    this.loading = true
    const { errorCode, ecosystem, message } = await Get(`/api/admin/ecosystems/${this.id}`)

    if (!hasError(!errorCode, message, '')) {
      this.fillModel(ecosystem)
    }

    this.loaded = true

    setTimeout(() => {
      this.loading = false
    }, 500)

  }

  async create ({ domains, ...details }) {
    this.thinking = true
    const ecosystem = {
      ...details,
      domains: (domains || []).filter(value => Boolean(value))
    }
    const { success, message, ...rest } = await Post(`/api/admin/ecosystems`, { ecosystem })
    if (!hasError(success, message, 'Ecosystem successfully created')) {
      this.fillModel(rest.ecosystem)
    }
    this.thinking = false

    return { success, ...rest }
  }

  async update ({ domains, details }) {
    this.thinking = true

    const ecosystem = {
      ...(details && { ...details }),
      ...(domains && { domains: (domains || []).filter(value => Boolean(value)) })
    }

    const { success, message, ...rest } = await Put(`/api/admin/ecosystems/${this.id}`, { ecosystem })

    if (!hasError(success, message, 'Ecosystem successfully updated')) {
      this.fillModel(ecosystem)
    }
    this.thinking = false

    return { success, ...rest }
  }

  async remove () {
    return await Delete(`/api/admin/ecosystems/${this.id}`, {})
  }

  @computed
  get domainsList () {
    return toJS(this.domains) || []
  }

  @computed
  get details () {
    const {
      id, name, published, createdAt, description
    } = this
    return {
      id, name, published, createdAt, description
    }
  }
}

export default SignatureStore
