import React from 'react'
import Moment from 'moment'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import BrandSaaSEcosystems from './BrandSaaSEcosystems'
import BrandSaaSEcosystemsStore from '../../stores/brand-saas-ecosystems'

const itemsTime = (items = []) => {
  return items.map(({ createdAt, ready, published, ...rest }) => {
    return {
      ...rest,
      createdAt: Moment(createdAt).format(`YYYY-MM-DD`),
    }
  })
}

@withRouter
@observer
class _BrandSaaSEcosystems extends React.Component {
  constructor(props) {
    super(props)
    const { brandId } = this.props
    this.store = new BrandSaaSEcosystemsStore(brandId)
  }

  onChangeSort = (orderByNew) => (e) => {
    const { listProps } = this.store
    const { order, orderBy } = listProps
    let orderNew = ''
    if (orderBy === orderByNew) {
      if (order === 'desc') {
        orderNew = 'asc'
      } else {
        orderNew = 'desc'
      }
    } else {
      orderNew = 'desc'
    }

    this.store.onChangeSort(orderNew, orderByNew)
  }

  onChangePage = (e, page) => this.store.onChangePage(page)

  onChangeRowsPerPage = ({ target: { value } }) =>
    this.store.onChangeRowsPerPage(value)

  onAdd = async (ecosystemId) => {
    return await this.store.add(ecosystemId)
  }

  onRemove = async (ecosystemId) => {
    return await this.store.remove(ecosystemId)
  }

  render() {
    const { listProps } = this.store

    const { items } = listProps

    return (
      <BrandSaaSEcosystems
        {...this.props}
        {...listProps}
        items={itemsTime(items)}
        onAdd={this.onAdd}
        onRemove={this.onRemove}
        onChangeSort={this.onChangeSort}
        onChangePage={this.onChangePage}
        onChangeFilters={this.onChangeFilters}
        onChangeRowsPerPage={this.onChangeRowsPerPage}
        // onClickRow={this.onClickRow}
      />
    )
  }
}

export default _BrandSaaSEcosystems
