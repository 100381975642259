import React from 'react'
import { find, findIndex } from 'lodash'
import {
  Link,
  Grid,
  Divider as MuiDivider,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent,
  DialogTitle,
  TextField as MuiTextField,
  Button as MuiButton, InputAdornment,
  Tooltip,
  IconButton, TableBody, TableCell, Table, TableContainer, TableHead, TableRow
} from '@material-ui/core'
import { Launch, FilterNone as MuiIconCopy } from '@material-ui/icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import styled from 'styled-components'
import {spacing} from '@material-ui/system'

import config from '../config'
import Panel from './Panel'
import List from './List'

const Divider = styled(MuiDivider)(spacing)
const Button = styled(MuiButton)(spacing)
const LaunchIcon = styled(Launch)(spacing)
const DialogActions = styled(MuiDialogActions)(spacing)
const TextField = styled(MuiTextField)(spacing)
const IconCopy = styled(MuiIconCopy)`
  width: 20px;
  height: 20px;
  color: ${props => props.theme.palette.secondary.main}
`

const columns = [
  { id: 'n', disablePadding: true, label: '#' },
  { id: 'name', disablePadding: true, label: 'Name' },
  { id: 'link', disablePadding: true, label: 'URL', kind: 'actions' }
]

const trackingParameters = [
  'source',
  'clickSub',
  'clickSub2',
  'clickSub3',
  'clickSub4',
  'clickSub5',
  'extClickId',
  'url'
]

export class LandingPageDialog extends React.Component {
  state = { link: null, name: null, preview: null }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (!prevProps.open && this.props.open) {
      const { id, name, link, preview } = this.props.url
      this.setState({ id, name, link, preview })
    }
  }

  onChange = field => ({ target: { value } }) => {
    this.setState({ [field]: value })
  }

  onRemove = () => {
    const { id } = this.state
    this.props.onRemove(id)
  }

  onSave = () => {
    const {
      id,
      name,
      link,
      preview
    } = this.state
    this.props.onSave({ id, name, link, preview })
  }

  render () {
    const {
      open
    } = this.props

    const {
      id,
      link,
      name,
      preview
    } = this.state

    return (
      <Dialog
        fullWidth
        data-cy='addLandingPageModal'
        maxWidth="sm"
        open={open}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          Landing Pages URLs
        </DialogTitle>

        <DialogContent>
          <TextField
            fullWidth
            data-cy="nameLandingPage"
            label={'Name'}
            placeholder={'Name'}
            value={name}
            onChange={this.onChange('name')}
          />
          <TextField
            fullWidth
            data-cy="previewUrlLandingPage"
            label={'Preview URL'}
            placeholder={'Preview URL'}
            helperText={'Preview URL of the opportunity'}
            value={preview}
            onChange={this.onChange('preview')}
          />
          <TextField
            fullWidth
            data-cy="opportunityUrlLandingPage"
            label={'Opportunity URL'}
            placeholder={'Opportunity URL'}
            helperText={'The URL where traffic will be directed to.'}
            value={link}
            onChange={this.onChange('link')}
          />
        </DialogContent>

        <DialogActions px={7} py={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              {
                id && <Button
                  mr={2}
                  variant="outlined"
                  onClick={this.onRemove}
                >
                  Remove
                </Button>
              }
            </Grid>
            <Grid xs={12} sm={6}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
            >
              <Button
                color="default"
                mr={2}
                onClick={this.props.onCancel}
              >
                Cancel
              </Button>
              <Button
                onClick={this.onSave}
                variant="outlined"
                color="primary"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }
}

export class GenerateTrackingLinkDialog extends React.Component {
  state = { copied: false }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (!prevProps.open && this.props.open) {
      let newState = {}
      trackingParameters.forEach(p => newState[p] = null)
      this.setState(newState)
    }
  }

  onCopy = () => {
    this.setState({ copied: true }, () => {
      setTimeout(() => {
        this.setState({ copied: false })
      }, 2000)
    })
  }

  onChange = field => ({ target: { value } }) => {
    this.setState({ [field]: value })
  }

  get TrackingLink () {
    const { campaign, urlId } = this.props

    let url = `${config.GO_URL}/${campaign}`
    const queries = trackingParameters.map(p => {
      if (this.state[p]) {
        return `${p}=${encodeURIComponent(this.state[p]) /*p ==='url' ? encodeURIComponent(this.state[p]) : this.state[p]*/}`
      }
    }).filter(q => !!q).join('&')

    let queryString = urlId ? `?urlId=${urlId}` : ``
    queryString = queryString ? `${queryString}${queries ? `&${queries}` : ''}` : `${queries ? '?' : ''}${queries}`
    return `${url}${queryString}`
  }

  render () {
    const {
      open
    } = this.props
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          Generate Tracking Link
        </DialogTitle>

        <DialogContent>
          <CopyToClipboard text={this.TrackingLink}
                           onCopy={this.onCopy}>
            <Tooltip title={'Copied'} open={this.state.copied} placement={"top"}>
            <TextField value={this.TrackingLink}
                       multiline
                       fullWidth
                       variant={"outlined"}
                       disabled
                       InputProps={{
                         disableUnderline: true,
                         endAdornment: <InputAdornment position="end"><Tooltip arrow placement='top' title={'Copy'}><IconButton><IconCopy /></IconButton></Tooltip></InputAdornment>,
                       }}
                       mb={3}
            />
            </Tooltip>
          </CopyToClipboard>
          <TableContainer>
            <Table size={"small"} style={{width: '100%'}}>
              <TableHead>
                <TableRow>
                <TableCell>Parameter</TableCell>
                <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  trackingParameters.map((p, index) => (
                    <TableRow>
                      <TableCell style={{verticalAlign: 'middle'}}>
                        {p}
                      </TableCell>
                      <TableCell>
                          <TextField
                            size={"small"}
                            fullWidth
                            placeholder={'Enter value'}
                            value={this.state[p]}
                            onChange={this.onChange(p)}
                            mt={1}
                            InputProps={{
                              disableUnderline: true,
                            }}
                          />
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
          {/*<Grid container spacing={3}>*/}
          {/*  <Grid item xs={6}>*/}
          {/*    <TextField*/}
          {/*      fullWidth*/}
          {/*      disable*/}
          {/*      value={'Parameter'}*/}
          {/*      m={2}*/}
          {/*      InputProps={{*/}
          {/*        disableUnderline: true*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </Grid>*/}
          {/*  <Grid item xs={3}>*/}
          {/*    <TextField*/}
          {/*      fullWidth*/}
          {/*      disable*/}
          {/*      value={'Value'}*/}
          {/*      m={2}*/}
          {/*      InputProps={{*/}
          {/*        disableUnderline: true*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </Grid>*/}
          {/*</Grid>*/}

          {/*<Divider />*/}

        </DialogContent>

        <DialogActions px={7} py={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
            </Grid>
            <Grid xs={12} sm={6}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
            >
              <Button
                color="default"
                onClick={this.props.onClose}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }
}

export default class LandingPages extends React.Component {
  static defaultProps = {
    urls: [],
    onUpdate: console.log
  }

  state = { open: false, url: null, openGenerate: false, generateId: null }

  onAdd = () => {
    this.setState({ open: true, url: { name: null, link: null, preview: null } })
  }

  onEdit = (e, id) => {
    const { urls, campaign } = this.props
    if (!campaign) {
      const index = findIndex(urls, ['id', id])
      this.setState({ open: true, url: urls[index] })
    }
  }

  onCancel = () => {
    this.setState({ open: false, url: { name: null, link: null, preview: null } })
  }

  onRemove = async (id) => {
    const { urls } = this.props
    const index = findIndex(urls, ['id', id])
    urls.splice(index, 1)
    const { success } = await this.props.onUpdate({ urls })
    this.setState({ open: !success, url: { name: null, link: null, preview: null } })
  }

  onSave = async (url) => {
    const { urls } = this.props
    if (url.id) {
      const index = findIndex(urls, ['id', url.id])
      urls[index] = url
    } else {
      urls.push(url)
    }
    const { success } = await this.props.onUpdate({ urls })
    this.setState({ open: !success, url: { name: null, link: null, preview: null } })
  }

  onOpenGenerateTrackingLink = (id) => {
    this.setState({ openGenerate: true, generateId: id })
  }
  onCloseGenerateTrackingLink = () => {
    this.setState({ openGenerate: false, generateId: null })
  }

  render () {
    const {
      open,
      openGenerate,
      generateId,
      url
    } = this.state

    const {
      urls,
      campaign,
      thinking
    } = this.props

    const LandingActions = ({ link, index, id, ...other }) => (
      <React.Fragment>
        {
          other.campaign &&
          <Button
            onClick={this.onOpenGenerateTrackingLink.bind(this, index > 0 ? id : null)}
            variant="outlined"
            color="primary"
            size="small"
            mr={4}
          >
            Generate tracking link
          </Button>
        }
        <Button
          // onClick={() => { console.log(preview); window.open(preview, '_blank');}}
          href={link}
          target={'blank'}
          variant="outlined"
          color="default"
          size="small"
          endIcon={<LaunchIcon />}
        >
          Preview
        </Button>
      </React.Fragment>
    )

    return (
      <Panel
        dataCy="addLandingPages"
        title="Landing Pages"
        adding={!campaign}
        thinking={thinking}
        onAdd={this.onAdd}
      >
        <List
          columns={columns}
          items={urls.map((u, index) => ({
            ...u,
            n: index + 1,
            name: u.name,
            link: () => <LandingActions id={u.id} index={index} link={u.preview} campaign={campaign} />
          }))}
          onClickRow={this.onEdit}
          emptyMessage={'No Landing Pages are added.'}
          emptyActionText={'Add a Landing Page'}
          onEmptyAction={this.onAdd}
          disablePagination/>

        <GenerateTrackingLinkDialog
          open={openGenerate}
          urlId={generateId}
          campaign={campaign}
          onClose={this.onCloseGenerateTrackingLink}
        />

        <LandingPageDialog
          open={open}
          url={url}
          onSave={this.onSave}
          onRemove={this.onRemove}
          onCancel={this.onCancel}
        />
      </Panel>
    )
  }
}
