import { Get, Post, Put } from '../utils/request'
import moment from 'moment'
import { observable, action, computed, toJS } from 'mobx'
import hasError from './request-message'
import { formatDate } from '../utils/format'
import { dataToDetails, contactToPayload } from '../utils/contacts'

export default class EntityStore {
  @observable id
  @observable data = []
  @observable createdAt
  @observable updatedAt
  @observable status

  @observable loading = true
  @observable thinking = false

  constructor(id) {
    if (id) {
      this.id = id
      this.load().then()
    }
  }

  @action
  async load() {
    const { errorCode, entity, message } = await Get(
      `/api/admin/entities/${this.id}`
    )

    if (!hasError(!errorCode, message)) {
      for (let key in entity) {
        if (entity.hasOwnProperty(key)) {
          this[key] = entity[key]
        }
      }
    } else {
      return message
    }

    this.loading = false
  }

  @action
  async publish() {
    const { success } = await Put(`/api/admin/entities/${this.id}/publish`, {})

    if (success) {
      this.status = 'published'
    }

    return { success }
  }

  @action
  async create({ channel, ...details }, publish) {
    const payload = {
      data: contactToPayload(details),
    }

    const { success, message, entity } = await Post(`/api/admin/entities`, {
      entity: payload,
    })

    if (!hasError(success, message, 'Entity successfully created')) {
      for (let key in entity) {
        if (entity.hasOwnProperty(key)) {
          this[key] = entity[key]
        }
      }

      if (publish) {
        await this.publish()
      }
    }

    return { success, entity }
  }

  @action
  async update({ channel, ...details }, publish) {
    this.loading = true
    const payload = {
      data: contactToPayload(details),
    }

    const { success, message, entity } = await Put(
      `/api/admin/entities/${this.id}`,
      {
        entity: payload,
      }
    )

    if (!hasError(success, message, 'Entity successfully created')) {
      for (let key in entity) {
        if (entity.hasOwnProperty(key)) {
          this[key] = entity[key]
        }
      }
      if (publish) {
        await this.publish()
      }
    }

    this.loading = false

    return { success, entity }
  }

  @action
  async attach(channelId, entityId) {
    const { success } = await Put(
      `/api/admin/entities/${entityId}/attach-channel/${channelId}`,
      {}
    )

    if (success) {
      this.id = entityId
      this.load().then()
    }

    return { success }
  }

  @action
  async detach(channelId) {
    const { success } = await Put(
      `/api/admin/entities/detach-channel/${channelId}`,
      {}
    )

    if (success) {
      this.load().then()
    }

    return { success }
  }

  @computed
  get details() {
    const { id, data, createdAt, publishedAt, status } = this

    return {
      id,
      ...(createdAt && {
        createdAt: moment(createdAt).utc().format('dddd, D MMMM YYYY hh:mm'),
      }),
      ...(publishedAt && {
        publishedAt: moment(publishedAt)
          .utc()
          .format('dddd, D MMMM YYYY hh:mm'),
      }),
      ...dataToDetails(data),
      status,
    }
  }
}

export class EntityContactsStore {
  @observable id
  @observable contactList = []
  @observable loading = true
  @observable thinking = false

  constructor(id) {
    this.id = id
    this.load().then()
  }

  @action
  async load() {
    const { errorCode, contacts, message } = await Get(
      `/api/admin/contacts?filters[entityId]=${this.id}`
    )
    if (!hasError(!errorCode, message)) {
      this.contactList = contacts
    } else {
      return message
    }

    this.loading = false
  }

  async publish(contactId) {
    const { success, message } = await Put(
      `/api/admin/contacts/${contactId}/publish`,
      {}
    )

    if (!hasError(success, message, 'Contact successfully published')) {
      await this.load()
    }
    return { success }
  }

  @action
  async create(details, publish) {
    const { success, contact } = await Post(`/api/admin/contacts`, {
      contact: { data: contactToPayload(details) },
    })
    if (success) {
      if (publish) await this.publish(contact.id)
      await this.load()
    }
    return { success, contact }
  }

  @action
  async attach(contactId) {
    const { success } = await Put(
      `
      /api/admin/entities/${this.id}/attach-contact/${contactId}
      `,
      {}
    )

    if (success) {
      await this.load()
    }
    return { success }
  }

  @action
  async detach(contactId) {
    const { success } = await Put(
      `
      /api/admin/entities/${this.id}/detach-contact/${contactId}
      `,
      {}
    )

    if (success) {
      await this.load()
    }
    return { success }
  }

  @computed
  get contacts() {
    return toJS(this.contactList).map((contact) => {
      const { id, data, channel, createdAt, status } = contact

      return {
        id,
        channel: (channel || {}).id,
        channelName: (channel || {}).domain,
        createdAt: formatDate(createdAt),
        ...dataToDetails(data),
        status,
      }
    })
  }
}

export class EntityChannelsStore {
  @observable id
  @observable channelsList = []
  @observable loading = true
  @observable thinking = false

  constructor(id) {
    this.id = id
    this.load().then()
  }

  @action
  async load() {
    const { errorCode, channels, message } = await Get(
      `/api/admin/channels?filters[entityId]=${this.id}`
    )

    if (!hasError(!errorCode, message)) {
      this.channelsList = channels
    } else {
      return message
    }

    this.loading = false
  }

  @action
  async attach(channel) {
    const { success, message } = await Put(
      `/api/admin/entities/${this.id}/attach-channel/${channel.id}`,
      {}
    )

    if (
      !hasError(
        success,
        message,
        `Channel ${channel.label} successfully attached`
      )
    ) {
      this.load().then()
    }

    return { success }
  }

  @action
  async detach(channelId) {
    const { success, message } = await Put(
      `/api/admin/entities/detach-channel/${channelId}`,
      {}
    )

    if (
      !hasError(success, message, 'Channel successfully removed from entity')
    ) {
      this.channelsList = toJS(this.channelsList).filter(
        ({ id }) => id !== channelId
      )
    }

    return { success }
  }

  @computed
  get channels() {
    return toJS(this.channelsList)
  }
}
