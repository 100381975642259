import '../vendor/perfect-scrollbar.css'
import React from 'react'
import styled from 'styled-components'
import { withRouter, Link } from 'react-router-dom'
import {Power} from 'react-feather'

import {
  Avatar,
  Badge,
  Grid,
  Typography
} from '@material-ui/core'
import {rgba} from "polished";

const SidebarUser = styled.div`
  background-color: ${props =>
  props.theme.sidebar.footer.background} !important;
  padding: ${props => props.theme.spacing(2.75)}px
    ${props => props.theme.spacing(4)}px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const SidebarUserText = styled(Typography)`
  color: ${props => props.theme.sidebar.footer.color};
`;

const Logout = styled(Link)`
  color: ${props => rgba(props.theme.sidebar.color, 0.8)};
  font-size: .725rem;
  display: inline-flex;
  align-items: center;
  padding: 1px;
`;

const LogoutIcon = styled(Power)`
  color: ${props => rgba(props.theme.sidebar.color, 0.8)};
  width: 1em;
  height: 1em;  
  margin-right: .20rem;
`;

const StyledBadge = styled(Badge)`
  margin-right: ${props => props.theme.spacing(1)}px;

  span {
    background-color: ${props => props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${props => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`

const getUserFullName = user => {
  if (!user) {
    return ''
  }
  const {
    firstName,
    lastName,
    email
  } = user

  if (!firstName && !lastName) {
    return email
  }
  return `${firstName ? `${firstName} `: ''}${lastName || ''}`
}

class Sidebar extends React.Component {
  render() {
    const {
      user,
      onLogout
    } = this.props

    return (
      <SidebarUser>
        <Grid container spacing={2}>
          <Grid item>
            <Avatar alt={getUserFullName(user)} src=""/>
            {/*If social image is not provided the default avatar icon will be used*/}
          </Grid>
          <Grid item>
            <SidebarUserText variant="body2">
              {getUserFullName(user)}
            </SidebarUserText>
            <Logout onClick={onLogout}>
                <LogoutIcon/> Logout
            </Logout>
          </Grid>
        </Grid>
      </SidebarUser>
    );
  }
}

export default withRouter(Sidebar);
