import React from 'react'
import DashboardPage from '../../layouts/DashboardPage'
import DetailsForm from '../../components/Details'
import Touchpoints from '../../components/Touchpoints'
import LandingPages from '../../components/LandingPages'
import Rules from '../../components/Rules'
import StatisticsItem from '../../components/StatisticsItem'
import Coupons from '../../components/Coupons'
import { Button } from '@material-ui/core'

const config = [
  { key: 'id', name: 'ID', editable: false, creatable: false, type: 'string' },
  {
    key: 'partner', name: 'Partner', editable: false, creatable: true, type: 'partners',
    link: { title: 'Partner', pattern: `/partners/{id}`, keys: { id: 'partner' } }
  },
  { key: 'channelName', name: 'Partner Channel', editable: false, creatable: false, type: 'string' },
  {
    key: 'brand', name: 'Brand', editable: false, creatable: true, type: 'brands',
    link: { title: 'Brand', pattern: `/brands/{id}`, keys: { id: 'brand' } }
  },
  {
    key: 'brandChannelName', name: 'Brand Channel', editable: false, creatable: true, type: 'string'
  },
  {
    key: 'opportunity', name: 'Opportunity', editable: false, creatable: true, type: 'opportunities',
    link: { title: 'Opportunity', pattern: `/opportunities/{id}`, keys: { id: 'opportunity' } }
  },
  { key: 'status', name: 'Status', editable: true, creatable: true, type: 'select', kind: 'status',
    options: [
      { value: 'active', label: 'Active' },
      { value: 'verified', label: 'Verified' },
      { value: 'deleted', label: 'Deleted' },
    ]
  }
]

export default class Campaign extends React.Component {
  state = {
    edit: false
  }

  onCancel = e => {
    if (this.props.id === 'create') {
      return this.props.onCancel()
    }
  }

  onUpdateRules = rules => {
    this.props.onUpdate({ rules: rules.filter(rule => !rule.opportunity) })
  }

  render () {
    const {
      id,
      details,
      loading,
      thinking,
      rules,
      landings,
      stats,
      touchpoints,
      coupons,
      onCreate,
      onUpdate,
      onCancel
    } = this.props
    const create = id === 'create'

    return (
      <DashboardPage
        kind="details"
        title={"Tracking Campaign"}
        time
        status={id}
        noActions
        actions={() => <Button onClick={this.props.onToggleArchived} variant={"outlined"}> { details.status !== 'deleted' ? 'Archive' : 'Unarchive' } </Button>}
        thinking={loading}
      >
        { !create && <StatisticsItem {...stats} /> }

        <DetailsForm
          create={create}
          config={config}
          details={details}
          onCreate={onCreate}
          onUpdate={onUpdate}
          onCancel={onCancel}
        />

        { !create && <Touchpoints campaign {...touchpoints} /> }
        { !create && <LandingPages campaign={id} {...landings} /> }
        { !create && <Rules warning campaign {...rules} onUpdate={this.onUpdateRules} /> }

        { !create && <Coupons creatable editable kind={'private'} {...coupons} thinking={coupons.privateCouponsThinking} coupons={coupons.privateCoupons} /> }

      </DashboardPage>
    )
  }
}
