import { gql } from '@apollo/client'

export const SUCCESS_FIELDS = gql`
  fragment SUCCESS_FIELDS on Success {
    success
    errorMessage
  }
`

export const USER_FIELDS = gql`
  fragment USER_FIELDS on User {
    id
    email
    emailStatus
    firstName
    lastName
    role
    status
    createdAt
    hasPassword
  } 
`
