import React from 'react'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'

import Entity from './Entity'

import EntityStore from '../../stores/entity'

@observer
class _Entity extends React.Component {
  constructor(props) {
    super(props)
    const {
      match: {
        params: { id },
      },
    } = props

    this.store = new EntityStore(id)
  }

  onSave = async (details, publish) => {
    console.log(details, publish)
    return await this.store.update(details, publish)
  }

  render() {
    const {
      match: {
        params: { id },
      },
    } = this.props

    const { details, loading } = this.store

    return (
      <Entity
        id={id}
        loading={loading}
        details={details}
        onSave={this.onSave}
      />
    )
  }
}

export default _Entity
