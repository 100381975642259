import React from 'react'
import { find } from 'lodash'
import {
  Avatar,
  ListItem,
  List,

  Grid,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent,
  DialogTitle,
  Button as MuiButton,
  Divider as MuiDivider,
  TextField as MuiTextField,
  IconButton,
  InputAdornment
} from '@material-ui/core'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { Trash as IconDelete, PlusCircle as IconAdd } from 'react-feather'

import Panel from '../Panel'

const Button = styled(MuiButton)(spacing)
const DialogActions = styled(MuiDialogActions)(spacing)
const TextField = styled(MuiTextField)(spacing)
const Add = styled(IconAdd)`
width: 16px;
height: 16px;
color: ${props => props.theme.palette.primary.main};
`

const Delete = styled(IconDelete)`
width: 16px;
height: 16px;
color: ${props => props.theme.palette.grey[500]};
`

const initialPerson = {
  id: null,
  name: null,
  title: null,
  email: null,
  phone: null
}

const Name = styled.div`
margin-left: 8px;
`
const Position = styled.div`
color: ${props => props.theme.palette.grey[500]};
font-size: 12px;
margin-left: 1em;
`
const Email = styled.div`
margin-left: 28px;
`
const Phone = styled.div`
margin-left: 2em;
`

const MultiField = ({ placeholder, dataCy, values, onChange }) => {
  const onAdd = () => {
    onChange({ target: { value: [ ...values, { id: (new Date()).getTime()} ] }})
  }

  const onRemove = (index) => {
    if (values.length === 1) {
      onChange({ target: { value: [{ id: (new Date()).getTime()}] }})
    } else {
      if (index === 0) {
        onChange({ target: { value: [...values.splice(index + 1)] }})
      } else {
        onChange({ target: { value: [...values.splice(0, index), ...values.splice(index)] }})
      }
    }
  }

  const onChangeValue = index => ({ target: { value }}) => {
    values[index].value = value
    onChange({ target: { value: values }})
  }

  const count = values.length

  return values.map(({ id, value }, index) => (
    <TextField
      key={id}
      mb={2}
      fullWidth
      label={placeholder}
      placeholder={placeholder}
      value={value}
      data-cy={!!dataCy && dataCy+index}
      onChange={onChangeValue(index)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {
              count > 1 && <IconButton
                color="default"
                size={'small'}
                onClick={() => onRemove(index)}>
                <Delete />
              </IconButton>
            }
            {
              count - 1 === index &&
              <IconButton size={'small'} color="success" onClick={onAdd}>
                <Add />
              </IconButton>
            }
          </InputAdornment>
        )
      }}
    />
  ))
}

export class ContactPersonDialog extends React.Component {
  state = { ...initialPerson }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (!prevProps.open && this.props.open) {
      const { id, name, title, emails, phones } = this.props.person
      this.setState({ id, name, title, emails, phones })
    }
  }

  onChange = field => ({ target: { value } }) => {
    this.setState({ [field]: value })
  }

  onRemove = () => {
    const { id } = this.state
    this.props.onRemove(id)
  }

  onSave = () => {
    const { id, name, title, emails, phones } = this.state
    this.props.onSave({
      id, name, title,
      emails: (emails || []).map(({ value }) => value).filter(v => !!v),
      phones: (phones || []).map(({ value }) => value).filter(v => !!v)
    })
  }

  render () {
    const {
      open
    } = this.props

    const { id, name, title, emails, phones } = this.state

    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        data-cy="dialogContactPersons"
        open={open}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          Contact Person
        </DialogTitle>

        <DialogContent>
          <TextField
            mb={2}
            fullWidth
            data-cy="nameContactPersons"
            label={'Name'}
            placeholder={'Name'}
            value={name}
            onChange={this.onChange('name')}
          />
          <TextField
            mb={2}
            fullWidth
            label={'Position'}
            data-cy="positionContactPersons"
            placeholder={'e.g. Owner'}
            value={title}
            onChange={this.onChange('title')}
          />
          <MultiField
            values={emails || [{ id: (new Date()).getTime() }]}
            dataCy="emailContactPersons"
            placeholder={'Email'}
            onChange={this.onChange('emails')}
          />
          <MultiField
            values={phones || [{ id: (new Date()).getTime() }]}
            dataCy="phoneContactPersons"
            placeholder={'Phone'}
            onChange={this.onChange('phones')}
          />

        </DialogContent>

        <DialogActions px={7} py={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              {
                id && <Button
                  mr={2}
                  variant="outlined"
                  onClick={this.onRemove}
                >
                  Remove
                </Button>
              }
            </Grid>
            <Grid xs={12} sm={6}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
            >
              <Button
                color="default"
                mr={2}
                onClick={this.props.onCancel}
              >
                Cancel
              </Button>
              <Button
                onClick={this.onSave}
                variant="outlined"
                color="primary"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }
}

export default class ContactPersons extends React.Component {
  constructor (props) {
    super(props)
    this.state = { person: initialPerson, open: false }
  }

  onAddPerson = () => this.setState({ open: true, person: initialPerson })

  onEditPerson = (id) => {
    const person = find(this.props.persons, ['id', id])
    if (person) {
      this.setState({ open: true, person })
    }
  }

  onSavePerson = async (details) => {
    const { success } = details.id ? await this.props.onUpdate(details) : await this.props.onCreate(details)

    if (success) {
      this.setState({ open: false, person: initialPerson })
    }
  }

  onRemovePerson = async (id) => {
    const { success } = await this.props.onRemove(id)
    if (success) {
      this.setState({ open: false, person: initialPerson })
    }
  }

  onCancelEditPerson = () => this.setState({ open: false, person: initialPerson })


  render () {
    const {
      thinking,
      persons
    } = this.props

    return (
      <Panel
        title="Contact Persons"
        adding
        dataCy="addContactPersons"
        thinking={thinking}
        onAdd={this.onAddPerson}
      >
        <List>
          {
            persons.map((person, index) => (

              <ListItem data-cy={"person"+person.name} key={person.id} style={{ cursor: 'pointer' }} onClick={this.onEditPerson.bind(this, person.id)}>
                <Grid container>
                  <Grid item>
                    <Avatar style={{height: 20, width: 20}} alt={person.name} src=""  component={'div'} />
                  </Grid>
                  <Grid item style={{display: 'inline-flex', alignItems: 'center'}}>
                    <Name>{ person.name }</Name> <Position>{ person.title || 'Owner' }</Position>
                  </Grid>
                  <Grid item style={{display: 'inline-flex', alignItems: 'center'}}>
                    {
                      person.emails.length && <Email>
                        { person.emails.map(({ value }) => value).join(', ') }
                      </Email>
                    }
                    {
                      person.phones.length && <Phone>
                        { person.phones.map(({ value }) => value).join(', ') || 'xxx-xx-xx'}
                      </Phone>
                    }
                  </Grid>
                </Grid>
              </ListItem>
            ))
          }
        </List>
        <ContactPersonDialog
          open={this.state.open}
          person={this.state.person}
          onSave={this.onSavePerson}
          onRemove={this.onRemovePerson}
          onCancel={this.onCancelEditPerson}
        />
      </Panel>
    );
  }
}
