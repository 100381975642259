import React from 'react'
import {
  Button,
  MenuItem,
  TextField,
  Select,
  FormControl,
  Chip as MuiChip,
  Grid,
  Tooltip, DialogContent, CardActions, FormControlLabel, Switch,
} from '@material-ui/core'
import { OpenInNew } from '@material-ui/icons'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'


import Link from './Link'
import Panel from './Panel'
import { Form, FormField } from './Form'
import Autocomplete, { Asynchronous } from './Autocomplete'
import Preview from './Preview'
import Status from './Status'
import Logo from './Logo'
import Loader from './Loader'

const Chip = styled(MuiChip)(spacing)

const DetailsLink = ({ details, link, value }) => {
  const getValue = (_value) => {
    if ((typeof _value === "object" || typeof _value === 'function') && (_value !== null)) {
      return _value.id
    }
    return _value
  }

  const url = ({ pattern, keys }) => {
    let result = pattern
    for (let key in keys) {
      if (keys.hasOwnProperty(key)) {

        result = result.replace(`{${key}}`, getValue(details[keys[key]]))
      }
    }
    return result
  }

  return <Link link={url(link)} title={link.title} value={value} />
}

export default class Details extends React.Component {
  static defaultProps = {
    errors: {}
  }

  constructor (props) {
    super(props)
    const { config, details, create } = props
    let toState = {}
    config.forEach(({ key }) => toState[key] = details[key] || null )
    this.state = { edit: create, ...toState }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { config, details } = this.props

    if (prevProps.create && !this.props.create) {
      this.setState({ edit: false })
    }

    if (prevProps.thinking && !this.props.thinking) {
      let toState = {}
      config.forEach(({ key }) => toState[key] = details[key] || null )
      this.setState({ ...toState })
    }

    if (details['credits'] !== prevProps.details['credits']) {
      this.setState({ credits: details['credits'] })
    }
    if (!this.state.edit) {
      if (details['status'] !== prevState['status']) {
        this.setState({ status: details['status'] })
      }
    }
  }

  onChange = field => ({ target: { value } }) => {
    this.setState({ [field]: value })
    if (this.props.onChange) {
      this.props.onChange(field, value, fieldToReset => {
        this.setState({ [fieldToReset]: null })
      })
    }
  }

  onSubmit = async () => {
    const {
      config,
      create,
      onCreate,
      onUpdate
    } = this.props

    let details = {}

    config.forEach(c => {
      if ((create && c.creatable) || (!create && c.editable)) {
        details[c.key] = this.state[c.key]
      }
    })

    if (create) {
      return onCreate(details)
    }
    this.setState({ thinking: true })
    const { success } = await onUpdate(details)
    this.setState({ edit: !success, thinking: false })
    return success
  }

  onCancel = e => {
    if (this.props.create) {
      return this.props.onCancel()
    }
    return this.setState({ ...this.props.details, edit: false })
  }

  onEdit = () => {
    if (!this.props.onEdit) {
      return this.setState({ edit: true })
    }
    return this.props.onEdit()
  }

  render () {
    const { edit, thinking } = this.state
    const {
      loading,
      create,
      formName,
      config,
      details,
      errors,
      logo,
      notEditable,
      title,
      hideCreateButtons
    } = this.props

    const Actions = () => edit ? (
      <CardActions style={{justifyContent: 'flex-end', borderTop: '1px #eee solid'}}>
        <Button variant="outlined" onClick={this.onCancel}>
          Cancel
        </Button>
        <Button onClick={this.onSubmit} variant="contained" color="primary">
          { create  ? 'Create' : 'Save changes' }
        </Button>

      </CardActions>
    ) : null

    return (
      <Panel
        title={title || "Details"}
        editable={!notEditable}
        dataCy="brandEdit"
        edit={edit}
        onEdit={this.onEdit}
        actions={hideCreateButtons || loading ? null : Actions}
        thinking={thinking}
      >
        {
          loading && <Loader />
        }
        {
          !loading && (
            <Grid container>

              <Grid item xs={logo ? 8 : 12}>
                <Form
                  name={formName}
                  onSubmit={this.onSubmit}
                >

                  {
                    config.map(({ key, name, type, kind, options, editable, creatable, disabled, placeholder, dataCy, link, links, ...rest }) => {
                      if (type === 'image') { return null }
                      const hide = create && !creatable
                      const computeType = () => {
                        if (!edit) {
                          return "preview"
                        }
                        if (disabled && creatable) {
                          return "preview"
                        }
                        return type
                      }
                      const computeValue = (value) => {
                        if ((typeof value === "object" || typeof value === 'function') && (value !== null)) {
                          return value.label
                        }
                        return value
                      }
                      const computeDisable = () => {
                        if (create) {
                          return !creatable
                        } else if (edit) {
                          return !editable
                        }
                        return true
                      }
                      const disable = computeDisable()
                      const computedType = computeType()

                      if (disable && type === 'bool') {
                        return (
                          <FormField key={key} name={name}>
                            <FormControl  mb={3}>
                              { this.state[key] ? 'Active' : '-' }
                            </FormControl>
                          </FormField>
                        )
                      }

                      if (disable && kind === 'status') {
                        return <FormField key={key} name={name}>
                          <FormControl  mb={3}>
                            <Status value={this.state[key]}/>
                          </FormControl>
                        </FormField>
                      }

                      if (disable && kind === 'chips') {
                        return <FormField key={key} name={name}>
                          <FormControl style={{ display: 'block' }} mb={3}>
                            {
                              this.state[key].map(({ value, label }) => <Chip component={'span'} size="small" mr={1.2} my={0.5} key={value} label={label} />)
                            }
                          </FormControl>
                        </FormField>
                      }

                      // console.log(key, links, details, this.state[key])

                      return !hide && (
                        <FormField key={key} name={name}>
                          <FormControl fullWidth mb={3}>
                            {
                              computedType === 'preview' && <Preview data-cy={!!dataCy && dataCy}>
                                {
                                  !!link && <DetailsLink link={link} details={details} value={computeValue(this.state[key])}/>
                                }
                                {
                                  !!links && details[key].map(_details => {
                                    return <>
                                      <DetailsLink
                                        link={{ ...links, title: _details[links.titleKey] }}
                                        details={_details}
                                        value={_details[links.titleKey]}/>
                                      &nbsp;&nbsp;
                                    </>
                                  })

                                }
                                {
                                  !link && !links && computeValue(this.state[key])
                                }
                              </Preview>
                            }
                            {
                              computedType === 'bool' &&
                              <FormControlLabel
                                control={<Switch checked={this.state[key]} onChange={(e, value) =>
                                  this.onChange(key)({ target: { value }})} />}
                                label={placeholder}
                              />
                            }
                            {
                              computedType === 'string' &&
                              <TextField
                                id={key}
                                error={Boolean(errors[key])}
                                helperText={errors[key]}
                                placeholder={placeholder}
                                data-cy={!!dataCy && dataCy}
                                disabled={disable}
                                value={this.state[key]}
                                onChange={this.onChange(key)}
                                InputProps={{
                                  disableUnderline: disable
                                }}
                              />
                            }
                            {
                              computedType === 'text' &&
                              <TextField
                                id={key}
                                error={Boolean(errors[key])}
                                helperText={errors[key]}
                                disabled={disable}
                                placeholder={placeholder}
                                data-cy={!!dataCy && dataCy}
                                multiline
                                rows="4"
                                rowsMax="4"
                                value={this.state[key]}
                                onChange={this.onChange(key)}
                                m={2}
                                InputProps={{
                                  disableUnderline: disable
                                }}
                              />
                            }
                            {
                              computedType === 'brands' &&
                              <Asynchronous
                                id={key}
                                disabled={disable}
                                url="/api/admin/brands"
                                filter="filters[name]"
                                placeholder={placeholder}
                                dataCy={dataCy}
                                dataField="brands"
                                dataLabelField="name"
                                value={this.state[key]}
                                defaultValue={this.state[key]}
                                onChange={(e, a) => this.onChange('brand')({ target: { value: a } })}
                              />
                            }
                            {
                              computedType === 'partners' &&
                              <Asynchronous
                                id={key}
                                disabled={disable}
                                url="/api/admin/partners"
                                filter="filters[name]"
                                placeholder={placeholder}
                                dataCy={dataCy}
                                dataField="partners"
                                dataLabelField="name"
                                value={this.state[key]}
                                defaultValue={this.state[key]}
                                onChange={(e, a) => this.onChange('partner')({ target: { value: a } })}
                              />
                            }
                            {
                              computedType === 'opportunities' &&
                              <Asynchronous
                                id={key}
                                disabled={disable}
                                url="/api/admin/opportunities"
                                filter="filters[name]"
                                dataField="opportunities"
                                placeholder={placeholder}
                                dataCy={dataCy}
                                dataLabelField="name"
                                value={this.state[key]}
                                defaultValue={this.state[key]}
                                onChange={(e, a) => this.onChange('opportunity')({ target: { value: a } })}
                              />
                            }
                            {
                              computedType === 'channels' &&
                              <Asynchronous
                                id={key}
                                disabled={disable}
                                url="/api/admin/channels"
                                filter={'filters[name]'}
                                additionalFilter={rest.filter}
                                dataField="channels"
                                placeholder={placeholder}
                                dataCy={dataCy}
                                dataLabelField="name"
                                value={this.state[key]}
                                // defaultValue={this.state[key]}
                                onChange={(e, a) => this.onChange('channel')({ target: { value: a } })}
                              />
                            }
                            {
                              computedType === 'select' &&
                              <Select
                                id={key}
                                disabled={disable}
                                placeholder={placeholder}
                                data-cy={!!dataCy && dataCy}
                                value={this.state[key]}
                                onChange={this.onChange(key)}
                                inputProps={{placeholder: placeholder}}
                              >
                                { options.map(o => <MenuItem  data-cy={!!dataCy && dataCy+o.value}  key={o.value} value={o.value}>{o.label}</MenuItem>)}
                              </Select>
                            }
                            {
                              computedType === 'multiselect' &&
                              <Autocomplete
                                id={key}
                                placeholder={placeholder}
                                dataCy={dataCy}
                                options={options}
                                value={this.state[key]}
                                onChange={(e, a) => this.onChange(key)({ target: { value: a } })}
                              />
                            }
                          </FormControl>
                        </FormField>
                      )
                    })
                  }

                </Form>
              </Grid>

              {
                logo && <Grid item xs={4}>
                  <Logo
                    editable={!!edit}
                    name={details.id}
                    image={this.state['logoUrl']}
                    onChange={(url) => this.onChange('logoUrl')({ target: { value: url } })}
                  />
                </Grid>
              }

            </Grid>
          )
        }

      </Panel>
    )
  }
}

