const nonDataFields = [
  'id',
  'channel',
  'createdAt',
  'publishedAt',
  'validatedAt',
  'optedoutAt',
  'bouncedAt',
  'status',
  'sourceFirstName',
  'sourceLastName',
  'sourceEmail',
  'sourceCompanyEmail',
  'sourceLinkedin',
]

const kindSourceFields = {
  firstName: 'sourceFirstName',
  lastName: 'sourceLastName',
  email: 'sourceEmail',
  companyEmail: 'sourceCompanyEmail',
  linkedin: 'sourceLinkedin',
}

export const dataToDetails = (data = []) => {
  let result = {}
  data.forEach(({ kind, source, data: value }) => {
    result[kind] = value
    if (kindSourceFields.hasOwnProperty(kind)) {
      result[kindSourceFields[kind]] = source
    }
  })

  return result
}

export const contactToPayload = ({ ...contact }) => {
  let data = []

  for (let key in contact) {
    if (
      contact.hasOwnProperty(key) /*&& contact[key]*/ &&
      !nonDataFields.includes(key)
    ) {
      data.push({
        kind: key,
        data: contact[key] || null,
        ...(kindSourceFields.hasOwnProperty(key) && {
          source: contact[kindSourceFields[key]],
        }),
      })
    }
  }

  return data
}
