import { observable, action, computed } from 'mobx'

class Notification {
  @observable notifications

  constructor () {
    this.notifications = []
  }

  @action
  show (message, variant, timeout = 6000) {
    this.notifications = [...this.notifications, { message, variant, timeout, open: !this.notifications.length, id: (new Date()).getTime() }]
  }

  @action
  remove () {
    if (this.notifications.length) {
      this.notifications[0].open = false
      setTimeout(() => {
        this.notifications.shift()
        if (this.notifications.length) {
          this.notifications[0].open = true
        }
      }, 500)
    }
  }

  @computed
  get message () {
    const { message } = this.notifications[0] || {}
    return message || null
  }

  @computed
  get variant () {
    const { variant } = this.notifications[0] || {}
    return variant || 'success'
  }

  @computed
  get timeout () {
    const { timeout } = this.notifications[0] || {}
    return timeout || 100
  }

  @computed
  get open () {
    return this.notifications[0] ? this.notifications[0].open : false
  }
}

export default new Notification()
