import React from 'react'
import styled, {css} from 'styled-components'
import { Chip as MuiChip, Button } from '@material-ui/core'
import Autocomplete, { Asynchronous } from './Autocomplete'

const GroupWrapper = styled.div`
  padding: 5px;
  box-sizing: border-box;  
  //background: ${props => props.color};
  display: flex;
  ${props => props.variant === 'initial' && css`
      flex: 1 0 auto;
  `}
  ${props =>props.variant === 'set' && css`
      flex: 0;
  `}
`

const Chip = styled(MuiChip)`
  flex: 0;
  border: 0;
  background: #eee;
  box-sizing: border-box;
  ${props => props.variant === 'start' && css`
    border-radius: 3px 0 0 3px;  
  `}
  ${props => props.variant === 'middle' && css`
    border-radius: 0;
    margin: 0 1px;  
  `}
  ${props => props.variant === 'end' && css`
    border-radius: 0 3px 3px 0;  
  `}
`

const AutocompleteWrapper = styled.div`
  //background: #ff5bcf;
  flex: 1 0 auto;
  min-width: 6em;
  box-sizing: border-box;
`

const ChipWrapper = ({ children, value, onClick, onDelete, variant }) => {
  return !value
    ? <AutocompleteWrapper>{children}</AutocompleteWrapper>
    : <Chip label={value} onClick={onClick} onDelete={onDelete} variant={variant} />
}

const FilterGroup = ({
                       index,
                       placeholder,
                       keyOptions,
                       expressionOptions,
                       getValuesAsync,
                       groupBy,
                       filter,
                       ...other
}) => {
  const onChange = key => (e, value) => {
    other.onChange(index, key, value)
  }

  const onDelete = () => {
    other.onDelete(index)
  }

  const onEdit = () => {
    other.onEdit(index)
  }

  const fullWidth = index === 0 && !filter.key

  return <GroupWrapper fullWidth={fullWidth} variant={filter.key && filter.value ? 'set' : 'initial'}>
    <ChipWrapper value={filter.key && filter.key.label} onClick={onEdit} variant={'start'}>
      <Autocomplete
        fullWidth
        disableIcon
        focus
        disabled={!!filter.expression}
        placeholder={fullWidth && placeholder}
        options={keyOptions}
        value={filter.key}
        onChange={onChange('key')}
        onRemoveValue={onDelete}
      />
    </ChipWrapper>

    {
      filter.key && (
        <ChipWrapper value={filter.expression && filter.expression.label} onClick={onEdit} variant={'middle'}>
          <Autocomplete
            disableIcon
            fullWidth
            options={expressionOptions}
            value={filter.expression}
            onChange={onChange('expression')}
          />
        </ChipWrapper>
      )
    }
    {
      filter.key && filter.expression && (
        <ChipWrapper value={!filter.edit && filter.value && filter.value.label} onClick={onEdit} onDelete={onDelete} variant={'end'}>
          <Asynchronous
            disableIcon
            groupByField={'opportunity'}
            defaultValue={filter.value}
            onBlur={() => onChange('value')(null, filter.value)}
            onChange={onChange('value')}
            onGetData={getValuesAsync[filter.key.id]}
            groupBy={groupBy[filter.key.id]}
            onRemoveValue={onDelete}
          />
        </ChipWrapper>
      )
    }
  </GroupWrapper>
}

export default FilterGroup
