import React, { useState } from 'react'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { IconButton, Tooltip } from '@material-ui/core'
import {
  Button as MuiButton,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent,
  DialogTitle, Grid,
  Typography
} from '@material-ui/core'
import { FilterNone as CopyIcon } from '@material-ui/icons'
import List from '../../components/List'
import DashboardPage from '../../layouts/DashboardPage'
import { Asynchronous } from '../../components/Autocomplete'

const DialogActions = styled(MuiDialogActions)(spacing)
const Button = styled(MuiButton)(spacing)

const columns = [
  { id: 'researchStatus', disablePadding: true, label: 'Status', kind: 'status' },
  { id: 'createdAt', disablePadding: true, label: 'Started' },
  { id: 'id', disablePadding: true, label: 'ID' },
  { id: 'name', disablePadding: true, label: 'Name' },
  { id: 'keywordsCount', disablePadding: true, label: 'Keywords' },
  { id: 'competitorsCount', disablePadding: true, label: 'Competitors' },
  { id: 'countryCode', disablePadding: true, label: 'Country' },
  { id: 'actions', disablePadding: true, label: '', kind: 'actions' },
]

///api/admin/brands?page=1&perPage=25&filters%5Bname%5D=lifted&sort=-createdAt

const CopyToBrandDialog = ({ open, ...rest }) => {
  const [thinking, setThinking] = useState(false)
  const [brand, setBrand] = useState(null)
  const { keywordsChanged, competitorUrlsChanged, hostsBlocklistChanged } = rest.search || {}
  const hasChanges = keywordsChanged || competitorUrlsChanged || hostsBlocklistChanged

  const onChange = (value) => setBrand(value)

  const onCancel = () => {
    setBrand(null)
    rest.onClose()
  }

  const onCopy = async () => {
    setThinking(true)
    const { success } = await rest.onCopy(brand, rest.search)
    setThinking(false)
    if (success) {
      onCancel()
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      data-cy="channelDialog"
      open={open}
      onClose={onCancel}
      onBackdropClick={onCancel}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">
        Add Search to Brand
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              You are about to copy a Search to another Account with all keywords, competitors, and irrelevants associated with it.
            </Typography>
            {
              hasChanges && (
                <Typography variant="div" gutterBottom color={'error'}>
                  Search inputs have been updated since the last re-run. Leads might not be relevant to the new keywords, competitors, and irrelevants.
                </Typography>
              )
            }
          </Grid>
          <Grid item xs={12}>
            <Asynchronous
              fullWidth
              disabled={thinking}
              url={`/api/admin/brands`}
              filter="filters[name]"
              placeholder={`Choose brand`}
              dataField={`brands`}
              dataLabelField="name"
              value={brand}
              defaultValue={brand}
              onChange={(e, a) => onChange(a)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions px={6} py={4}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Button
              mr={2}
              color="default"
              disabled={thinking}
              onClick={onCancel}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}
                container
                direction="row"
                justify="flex-end"
                alignItems="center">

            <Button disabled={thinking} onClick={onCopy} variant="outlined" color="primary">
              Copy
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default class Searches extends React.Component {
  state = { search: null }

  onOpenCopy = (search) => {
    this.setState({ search })
  }

  onCloseCopy = () => {
    this.setState({ search: null })
  }

  render () {
    const { items } = this.props
    return (
      <DashboardPage
        kind="list"
        title="Searches"
        noActions
      >
        <List
          columns={columns}
          {...this.props}
          items={items.map(item => ({ ...item, actions: () => (
              <Tooltip title={'Copy search to another brand'}>
                <IconButton disabled={item.researchStatus !== 'researched'} size={'small'} onClick={() => this.onOpenCopy(item)}>
                  <CopyIcon fontSize={'small'} />
                </IconButton>
              </Tooltip>
            ) }))}
          searchable
          emptyMessage={'No Searches matching for this Brand.'}
        />

        <CopyToBrandDialog
          open={Boolean(this.state.search)}
          search={this.state.search}
          onCopy={this.props.onCopy}
          onClose={this.onCloseCopy}
        />
      </DashboardPage>
    )
  }
}
