import { Get, Put } from '../utils/request'
import { observable, action, computed } from 'mobx'
import { formatCurrency } from '../utils/format'
import hasError from './request-message'

class ConversionStore {
  @observable id
  @observable amount
  @observable brand
  @observable brandName
  @observable campaign
  @observable channelKind
  @observable channelName
  @observable cid
  @observable clickAt
  @observable cost
  @observable createdAt
  @observable couponCode
  @observable journey
  @observable opportunity
  @observable opportunityName
  @observable partner
  @observable partnerName
  @observable partnersCost
  @observable profit
  @observable revenue
  @observable status
  @observable tid
  @observable touchpointName
  @observable userId

  @observable loading = true
  @observable thinking = false

  constructor (id) {
    this.id = id
    this.load().then()
  }

  @action
  async load () {
    const { errorCode, conversion, /*balance,*/ message } = await Get(`/api/admin/conversions/${this.id}`)

    if (!hasError(!errorCode, message)) {
      for (let key in conversion) {
        if (conversion.hasOwnProperty(key)) {
          this[key] = conversion[key]
        }
      }
    } else {
      return message
    }



    this.loading = false
  }

  @action
  async update (details, notification) {
    const { conversion, success, message } = await Put(`/api/admin/conversions/${this.id}`, { conversion: details })

    if (!hasError(success, message, `Event ${details.status ? 'status ': ''}successfully updated`))
    for (let key in conversion) {
      if (conversion.hasOwnProperty(key)) {
        this[key] = conversion[key]
      }
    }

    return { success }
  }

  @computed
  get details () {
    const {
      id,
      status,
      brand,
      brandName,
      opportunity,
      opportunityName,
      touchpointName,
      partner,
      partnerName,
      channelName,
      cost: payout,
      revenue,
      amount,
      couponCode
    } = this
    return {
      id,
      status,
      brand,
      brandName,
      opportunity,
      opportunityName,
      touchpointName,
      partner,
      partnerName,
      channelName,
      payout: formatCurrency( (payout || 0) / 100),
      revenue: formatCurrency( (revenue || 0) / 100),
      amount: formatCurrency( (amount || 0) / 100),
      couponCode
    }
  }
}

export default ConversionStore
