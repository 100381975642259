export const decisionMakers = {
  'Affiliate': [
    'Head of Affiliates',
    'Head of Affiliate Marketing',
    'Head of Affiliate Partnerships',
    'Head of Affiliate & Partnerships',
    'Affiliate Manager',
    'Affiliate Marketing Manager',
    'Affiliate Partnerships Manager',
    'Affiliate & Partnerships Manager'
  ],
  'Partnerships': [
    'Head of Partnerships',
    'Head of Strategic Partnerships',
    'Head of Marketing Partnerships',
    'Partnership Marketing Manager',
    'Partnerships Manager',
    'Strategic Partnerships Manager',
    'Partnerships Director',
    'Strategic Partnerships Director',
    'VP, Partnerships',
    'Digital Partnerships Manager',
  ],
  'Marketing': [
    'Head of Marketing',
    'CMO',
    'Director of Marketing',
    'Marketing Director',
    'Marketing Manager',
    'Marketing Executive (too junior? Unless we have a referral incentive)'
  ],
  'Growth': [
    'Head of Growth',
    'Growth Manager',
    'Growth Lead'
  ],
  'Performance': [
    'Head of Performance',
    'Performance Manager',
    'Performance Lead',
  ],
  'Business Development': [
    'Business Development Manager',
    'BD Manager',
    'Head of Business Development',
    'Product Manager',
    'Entrepreneur',
    'Co-founder',
    'Founder',
    'Other',
  ]
}
