import React from 'react'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import Users from './Users'
import UsersStore from '../../stores/users'
import { formatDate } from '../../utils/format'

const itemsTime = (items = []) => {
  return items.map(({ createdAt, brands, ...other}) => {
    return { ...other, createdAt: formatDate(createdAt), ...(brands && brands.length && { brandName: brands[0].name, brand: brands[0].id }) }
  })
}

@withRouter
@observer
class _Users extends React.Component {
  constructor(props) {
    super(props)
    this.store = new UsersStore()
  }

  onChangeSort = orderByNew => e => {
    const { listProps } = this.store
    const { order, orderBy } = listProps
    let orderNew = ''
    if (orderBy === orderByNew) {
      if (order === 'desc') {
        orderNew = 'asc'
      } else {
        orderNew = 'desc'
      }
    } else {
      orderNew = 'desc'
    }

    this.store.onChangeSort(orderNew, orderByNew)
  }

  onChangePage = (e, page) => this.store.onChangePage(page)

  onChangeRowsPerPage = ({ target: { value }}) => this.store.onChangeRowsPerPage(value)

  onChangeFilters = name => object => {
    let value = null
    if (object.hasOwnProperty('target')) {
      value = object.target.value
    } else {
      value = object
    }
    this.store.onChangeFilter(name === 'name' ? 'search' : name, value)
  }

  onClickRow = (e, id) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(`/users/${id}`, '_blank')
    } else {
      this.props.history.push(`/users/${id}`)
    }
  }

  render () {
    const {
      listProps
    } = this.store

    const {
      items
    } = listProps

    return <Users
      {...this.props}
      {...listProps}
      items={itemsTime(items)}
      onUpdateList={() => this.store.getList()}
      onChangeSearch={this.onChangeSearch}
      onChangeSort={this.onChangeSort}
      onChangeFilters={this.onChangeFilters}
      onChangePage={this.onChangePage}
      onChangeRowsPerPage={this.onChangeRowsPerPage}
      onClickRow={this.onClickRow}
    />
  }
}

export default _Users
