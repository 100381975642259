import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import Channel from './Channel'
import { ChannelStore } from '../../stores/channels'

@withRouter
@observer
class _Channel extends React.Component {
  constructor(props) {
    super(props)
    const {
      match: {
        params: { id },
      },
    } = props
    this.store = new ChannelStore(id)
  }

  onUpdate = async (details) => {
    return this.store.update(details)
  }

  onAddToBlocklist = async () => {
    return this.store.toBlocklist()
  }

  onAddChannelToEcosystem = async (ecosystem, channelId) =>
    await this.store.addChannelFromEcosystem(ecosystem, channelId)
  onRemoveChannelFromEcosystem = async (ecosystemId, channelId) =>
    await this.store.removeChannelFromEcosystem(ecosystemId, channelId)

  render() {
    const {
      match: {
        params: { id },
      },
    } = this.props

    const {
      details,
      ecosystems,
      loading,
      thinking,
      hasPartnerships,
      channelDirection,
    } = this.store

    return (
      <Channel
        id={id}
        details={details}
        ecosystems={ecosystems}
        loading={loading}
        thinking={thinking}
        hasPartnerships={hasPartnerships}
        channelDirection={channelDirection}
        onLoad={() => this.store.load()}
        onUpdate={this.onUpdate}
        onAddToBlocklist={this.onAddToBlocklist}
        onAddChannelToEcosystem={this.onAddChannelToEcosystem}
        onRemoveChannelFromEcosystem={this.onRemoveChannelFromEcosystem}
      />
    )
  }
}

export default _Channel
