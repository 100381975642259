import { action, computed, observable, toJS } from 'mobx'
import param from 'can-param'
import { find } from 'lodash'
import List from './list'
import { Delete, Get, Post, Put } from '../utils/request'
import hasError from './request-message'
import config from '../config'

const multipleValues = {
  categories: config.CLASSIFICATIONS,
  businessModels: config.BUSINESS_MODELS,
  employeeCount: config.EMPLOYEE_COUNT,
  targetDecisionMaker: config.DECISION_MAKERS,
  age: config.AGE,
  gender: config.GENDER,
  income: config.INCOME,
  region: config.COUNTRIES
}

const convertToStrings = (data = []) => data.map(({ value }) => value)

const convertToOptions = (data = [], options) => data ? data.map(value => find(options, ['value', value])) : []

const toStringValues = (channel) => {
  let result = {}

  for (let key in multipleValues) {
    if (channel.hasOwnProperty(key)) {
      result[key] = (channel[key] || []).map(({ value }) => value)
    }
  }

  return {...channel, ...result}
}

const toMultipleValues = (channel) => {
  let result = {}

  for (let key in multipleValues) {
    if (multipleValues.hasOwnProperty(key) && channel[key] && channel[key].length) {
      result[key] = convertToOptions(channel[key], multipleValues[key])
    }
  }

  return { ...channel, ...result }
}

class DiscoveryChannelsStore {
  url = '/api/admin/channels'
  kind
  model
  modelId
  filters
  @observable channelsLoading = true
  @observable channelsThinking = true
  @observable similarThinking = true

  @observable _channels = []
  @observable _similar = {}

  constructor(model, modelId, kind) {
    this.model = model
    this.modelId = modelId
    this.kind = kind

    this.url = `/api/admin/${!kind ? '' : `${kind}/`}channels`

    this.loadAll().then()
  }

  @action
  async loadAll () {
    await this.loadChannels()
    await this.loadSimilar()
  }

  @action
  async loadChannels () {
    this.channelsThinking = true
    const queries = param({ filters: { [this.model]: this.modelId /*, notStatus: 'deleted' */ } })
    const { errorCode, channels } = await Get(`${this.url}?${queries}`)

    if (!errorCode) {
      this._channels = channels
    }

    this.channelsThinking = false
  }

  @action
  async loadSimilar () {
    this.similarThinking = true
    const queries = param({ filters: { [this.model]: this.modelId, notStatus: 'deleted' } })
    const result = await Get(`${this.url}/similar?${queries}`)

    if (!hasError(!result.errorCode, result.message, null) ) {
      this._similar = result
    }

    this.similarThinking = false
  }

  @action
  async create ({ channel, verified, ...details }) {
    const { message, success, channel: channelResult } = await Post(this.url, { channel: {
        ...toStringValues(details),
        [this.model]: this.modelId,
        ...(!this.kind && { verified }),
        ...(!!channel && { similar: true, channel })
    }})

    if (!!channel) {
      this.loadSimilar()
    } else {
      this.loadChannels()
    }

    return { success: !hasError(success, message, `Channel successfully created`), channel: channelResult }
  }

  @action
  async update ({ id, similar, verified, ...details }) {
    const { message, success } = await Put(`${this.url}/${id}`, {
      channel: {
        ...toStringValues(details),
        ...(!this.kind && { verified })
      }
    })

    if (similar) {
      this.loadSimilar()
    } else {
      this.loadChannels()
      this.loadSimilar()
    }
    return { success: !hasError(success, message, `Channel successfully updated`) }
  }

  @action
  async remove (id, similar) {
    const { success, message } = await Delete(`${this.url}/${id}`, {})
    if (similar) {
      this.loadSimilar()
    } else {
      this.loadChannels()
    }
    return { success: !hasError(success, message, `Channel successfully archived`) }
  }

  @action
  async removeSimilar (channelId, similarId) {
    const { success, message } = await Delete(`${this.url}/${channelId}/similar/${similarId}`, {})
    if (success) {
      this.loadSimilar()
    }
    return { success: !hasError(success, message, `Channel successfully removed`) }
  }

  @action
  async toggleArchive (channel, similar) {
    const { id, status } = channel
    const toArchive = status !== 'deleted'
    let result = {}
    if (toArchive) {
      result = await Delete(`${this.url}/${id}`, {})
    } else {
      result = await Put(`${this.url}/${id}`, { channel: { status: 'active' }})
    }
    const { success, message } = result
    if (similar) {
      this.loadSimilar()
    } else {
      this.loadChannels()
    }
    return { success: !hasError(success, message, `Channel successfully ${toArchive ? 'archived' : 'unarchived'}`) }
  }

  @computed
  get channels () {
    return toJS(this._channels).map(toMultipleValues).map(({ name, link, id, discoveredFacebookCategories, ...rest}) => ({
      id,
      link,
      name,
      ...rest,
      ...(!name && { name: link || id }),
      discoveredFacebookCategories: (discoveredFacebookCategories || []).map(v => ({ label: v, value: v }))
    }))
  }

  @computed
  get similar () {
    let channels = []
    const data = toJS(this._similar)
    for (let id in data) {
      if (data.hasOwnProperty(id)) {
        const { channelName, channelKind, similar } = data[id]
        channels = [
          ...channels,
          { parent: true, id, name: channelName, kind: channelKind },
          ...(similar.map(toMultipleValues)).map(c => ({...c, parentChannel: id}))
        ]
      }
    }
    return channels
  }
}

export class ChannelsStore extends List {
  url = '/api/admin/channels'

  constructor(filters = {}) {
    super()
    this.filters = filters
    this.order = 'desc'
    this.orderBy = 'createdAt'
    this.itemsField= 'channels'
    this.getList().then()
  }

  @action
  async create ({ channel, verified, ...details }) {
    const { message, success, channel: channelResult } = await Post(this.url, { channel: {
        ...toStringValues(details),
        [this.model]: this.modelId,
        ...(!this.kind && { verified }),
        ...(!!channel && { similar: true, channel })
      }})

    this.getList().then()

    return { success: !hasError(success, message, `Channel successfully created`), channel: channelResult }
  }

  @action
  async addChannelFromEcosystem (ecosystemId, channelId) {
    const { success, message } = await Put(` /api/admin/ecosystems/${ecosystemId}/attach-channel/${channelId}`, {})

    if (!hasError(success, message, 'Channel successfully added to ecosystem')) {
      this.getList().then()
    }
    return { success }
  }

  @action
  async removeChannelFromEcosystem (ecosystemId, channelId) {
    const { success, message } = await Delete(` /api/admin/ecosystems/${ecosystemId}/detach-channel/${channelId}`, {})

    if (!hasError(success, message, 'Channel successfully removed from ecosystem')) {
      this.items = toJS(this.items).filter(({ id }) => id !== channelId)
    }
    return { success }
  }
}

export class ChannelStore {
  @observable id

  @observable model = {}

  @observable loading = true
  @observable thinking = false

  constructor (id) {
    if (id !== 'create') {
      this.id = id
      this.load().then()
    } else {
      this.loading = false
    }
  }

  @action
  async load () {
    const { errorCode, channel, message } = await Get(`/api/admin/channels/${this.id}`)

    if (hasError(!errorCode, message)) {
      return message
    } else {
      for (let key in channel) {
        if (channel.hasOwnProperty(key)) {
          this.model[key] = channel[key]
        }
      }
    }

    await this.loadEcosystems()

    this.loading = false
  }

  @action
  async loadEcosystems () {
    const { errorCode, ecosystems, message } = await Get(`/api/admin/channels/${this.id}/ecosystems`)
    if (!hasError(!errorCode, message)) {
      this.model = { ...toJS(this.model), ecosystems }
    }
  }

  @action
  async toBlocklist () {
    const { success, message, channel } = await Put(`/api/admin/channels/${this.id}/add-to-blocklist`, {})

    if (hasError(success, message, 'Channel successfully added to Blocklist')) {
      return message
    } else {
      for (let key in channel) {
        if (channel.hasOwnProperty(key)) {
          this.model[key] = channel[key]
        }
      }
    }
  }

  @action
  async update ({ id, similar, verified, partner, brand, ...details }) {
    const { message, success } = await Put(`/api/admin/channels/${id}`, {
      channel: {
        ...toStringValues(details)
      }
    })

    return { success: !hasError(success, message, `Channel successfully updated`) }
  }

  @action
  async addChannelFromEcosystem (ecosystem, channelId) {
    const { id } = ecosystem

    const { success, message } = await Put(` /api/admin/ecosystems/${id}/attach-channel/${channelId}`, {})

    if (!hasError(success, message, 'Channel successfully added to ecosystem')) {
      const model = toJS(this.model)
      this.model = { ...model, ecosystems: [{ id, name: ecosystem.label }, ...model.ecosystems]}
    }

    return { success }
  }

  @action
  async removeChannelFromEcosystem (ecosystemId, channelId) {
    const { success, message } = await Delete(` /api/admin/ecosystems/${ecosystemId}/detach-channel/${channelId}`, {})

    if (!hasError(success, message, 'Channel successfully removed from ecosystem')) {
      const model = toJS(this.model)
      this.model = { ...model, ecosystems: model.ecosystems.filter((ecosystem) => ecosystemId !== ecosystem.id) }
    }
    return { success }
  }

  @computed
  get hasPartnerships () {
    return !!this.model.campaignsExitsCount
  }

  @computed
  get channelDirection () {
    const { direction } = this.model
    return direction ? `${direction}Channel` : null
  }

  @computed
  get ecosystems () {
    return toJS(this.model).ecosystems || []
  }

  @computed
  get details () {
    const { id, domain, name, brand, brandName, partner, partnerName, discoveredFacebookCategories, ...rest } = toMultipleValues(toJS(this.model))

    return {
      id,
      link: domain,
      ...rest,
      name,
      // ...(!name && { name: link }),

      brand: { id: brand, label: brandName },
      partner: { id: partner, label: partnerName },
      discoveredFacebookCategories: (discoveredFacebookCategories || []).map(v => ({ label: v, value: v }))
    }
  }
}

export default DiscoveryChannelsStore
