import React, { useState } from 'react'
import { ApolloProvider, ApolloClient, InMemoryCache, useQuery, gql } from '@apollo/client'
import styled from 'styled-components'
import { TextField, CircularProgress } from '@material-ui/core'
import MuiAutocomplete from '@material-ui/lab/Autocomplete'
import Config from '../config'

const GQL_LINK = Config.GQL_LINK

const client = new ApolloClient({
  uri: GQL_LINK,
  cache: new InMemoryCache({}),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
})

const StyledOption = styled.li`
    cursor: pointer;
    display: flex;
    width: 100%;
    outline: 0;
    box-sizing: border-box;
    align-items: center;
    padding: 6px 16px;
    justify-content: space-between;
`

const edgesToOptions = (data, dataField) => {
  if (!data || !data[dataField]) return []

  const { [dataField]: { edges } } = data

  return edges.map(({ node: { channelId, domain } }) => ({ id: channelId, label: domain }))
}

export const RequestWrapper = ({ request, variables, dataField, children }) => {
  const { data, loading, error } = useQuery(request, {
    ...(variables && { variables })
  })

  return children({ data: edgesToOptions(data, dataField), loading, error: error || {} });
}

const CHANNELS = gql`
  query channelsConnection (
      $search: String
    ) {
      channelsConnection(
        take: 50
        search: $search
      ) {
        edges {
          node {
            channelId
            domain
          }
        }
      }
    }
`

let typingTimeout = null

export default function ChannelsAutocomplete (props) {
  const [search, setSearch] = useState(false)
  const { id, name, value, error, disabled, placeholder, onChange } = props

  const onSearch = ({ target: { value } }) => {
    clearTimeout(typingTimeout)
    typingTimeout = setTimeout(() => setSearch(value), 1000)
  }

  return (
    <ApolloProvider client={client}>
      <RequestWrapper request={CHANNELS} dataField={'channelsConnection'} variables={{
        ...(search && { search })
      }}>
        {
          ({ data: options, loading }) => {
            return <MuiAutocomplete
              id={id}
              name={name}
              onChange={onChange}
              getOptionLabel={({ n, label}) => `${n ? `${n} - ` : ``}${label}`}
              renderOption={(option) => <StyledOption>
                <div>{option.label}</div>
                <div style={{ color: 'slategrey' }}>{`${option.n ? `ID ${option.n}` : ''}`}</div>
              </StyledOption>}
              options={options}
              ChipProps={{size: "small"}}
              loading={loading}
              disabled={disabled}
              value={value}
              // defaultValue={defaultValue}
              renderInput={params => (
                <TextField
                  {...params}
                  // label="Asynchronous"
                  fullWidth
                  error={!!error}
                  helperText={error}
                  variant="standard"
                  placeholder={placeholder}
                  onChange={onSearch}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          }
        }
      </RequestWrapper>

    </ApolloProvider>
  )
}
