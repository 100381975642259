import Moment from 'moment'
import { extendMoment } from 'moment-range'

const moment = extendMoment(Moment);

export const rangeDays = (days, fromDate) => {
  const range = moment.range(moment(fromDate).subtract(days - 1, 'day'), moment(fromDate))
  const daysArray = Array.from(range.by('day'))
  return daysArray.map(d => ({ date: moment(d).format('YYYY-MM-DD'), dayOfWeek: moment(d).format('ddd') }))
}
