import React from 'react'
import { observer } from 'mobx-react'
import { Delete as RemoveIcon } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'
import Panel from '../Panel'
import List from '../List'
import AttachChannelModal from './AttachChannelModal'
import ConfirmDialog from '../ConfirmDialog'

import { EntityChannelsStore } from '../../stores/entity'

const columns = [
  { id: 'status', disablePadding: true, label: 'Status', kind: 'status' },
  { id: 'domain', disablePadding: true, label: 'Link' },
  { id: 'createdAt', disablePadding: true, label: 'Created' },
  { id: 'kind', disablePadding: true, label: 'Kind' },
  { id: 'swGlobalRank', disablePadding: true, label: 'SimilarWeb Rank' },
  { id: 'mozDomainAuthority', disablePadding: true, label: 'Domain Authority' },
  { id: 'partnershipsOutCount', disablePadding: true, label: 'Partnerships' },
  { id: 'actions', disablePadding: true, label: '', kind: 'actions' },
]

const RemoveChannel = ({ channelId, onRemove }) => {
  return (
    <>
      <ConfirmDialog
        title={'Remove Channel from Entity'}
        confirmation
        confirmationText={'Detach channel from the entity?'}
        action={() => onRemove(channelId)}
        confirmButtonLabel={'Remove'}
        confirmButtonProps={{
          variant: 'contained',
          color: 'primary',
        }}
      >
        <IconButton size={'small'}>
          <RemoveIcon fontSize={'small'} />
        </IconButton>
      </ConfirmDialog>
    </>
  )
}

@observer
class EntityChannels extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      edit: false,
    }
    this.state = { open: false }
    this.store = new EntityChannelsStore(props.channelId)
  }

  onAttach = async (channel) => {
    return await this.store.attach(channel)
  }

  onRemove = async (id) => {
    return await this.store.detach(id)
  }

  onClickRow = (e, rowId) => {
    window.open(`/channels/${rowId}`, '_blank')
  }

  render() {
    const { channels } = this.store

    return (
      <Panel
        title={'Channels'}
        adding
        onAdd={() => this.setState({ open: true })}
      >
        <List
          disablePagination
          columns={columns}
          items={channels.map((item) => ({
            ...item,
            actions: () => (
              <RemoveChannel
                channelId={item.id}
                onRemove={(id) => this.onRemove(id)}
              />
            ),
          }))}
          emptyMessage={'No channels matching the given criteria.'}
          emptyActionText={'Add Channel to Entity'}
          onEmptyAction={() => this.setState({ open: true })}
          onClickRow={this.onClickRow}
        />

        <AttachChannelModal
          open={this.state.open}
          onAttach={this.onAttach}
          onClose={() => this.setState({ open: false })}
        />
      </Panel>
    )
  }
}

export default EntityChannels
