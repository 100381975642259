import React from 'react'
import List from '../../components/List'
import DashboardPage from '../../layouts/DashboardPage'
import { ContactContactDialog } from '../ContactRequest/Contacts'

const columns = [
  { id: 'createdAt', disablePadding: true, label: 'Created' },
  { id: 'status', disablePadding: true, label: 'Status', kind: 'status' },
  { id: 'firstName', disablePadding: true, label: 'First name' },
  { id: 'lastName', disablePadding: true, label: 'Last Name' },
  { id: 'jobTitle', disablePadding: true, label: 'Job title' },
  { id: 'email', disablePadding: true, label: 'Email' },
]

const initialContact = {
  id: null,
  firstName: null,
  lastName: null,
  jobTitle: null,
  company: null,
  role: null,
  email: null,
  companyEmail: null,
  phones: null,
  linkedin: null,
  twitter: null,
  instagram: null,
}

export default class Contacts extends React.Component {
  state = { thinking: false, openAdd: false }

  onOpenAdd = () => this.setState({ openAdd: true })
  onCloseAdd = () => this.setState({ openAdd: false })

  onCreate = async (details, publish) => {
    const { success } = await this.props.onCreate({ publish, ...details })

    if (success) {
      this.onCloseAdd()
    }
  }

  render() {
    const { openAdd } = this.state

    return (
      <DashboardPage
        kind='list'
        title='Contacts'
        addLabel='Add Contact'
        addClick={this.onOpenAdd}
      >
        <List
          columns={columns}
          {...this.props}
          searchable
          emptyMessage={'No contacts matching the given criteria.'}
        />

        {openAdd && (
          <ContactContactDialog
            variant={'create'}
            open={openAdd}
            contact={initialContact}
            onClose={this.onCloseAdd}
            onCancel={this.onCloseAdd}
            onSave={this.onCreate}
          />
        )}
      </DashboardPage>
    )
  }
}
