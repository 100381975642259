import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import Signature from './Signature'
import SignatureStore from '../../stores/signature'

@withRouter
@observer
class _Signature extends React.Component {
  constructor(props) {
    super(props)
    const {
      match: {
        params: {
          id
        }
      }
    } = props
    this.store = new SignatureStore(id)
  }

  onUpdate = async (details) => await this.store.update(details)

  onCreate = async (details) => {
    const { success, message, discoverySignature } = await this.store.create(details)
    if (success) {
      this.props.history.push(`/signatures/${discoverySignature.id}`)
      this.store = new SignatureStore(discoverySignature.id)
    }
    return { success, message }
  }

  onClickRow = (e, id) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(`/signatures/${id}`, '_blank')
    } else {
      this.props.history.push(`/signatures/${id}`)
    }
  }

  onRemove = async () => {
    const { success } = await this.store.remove()
    if (success) {
      this.props.history.push(`/signatures`)
    }
  }

  render () {
    const {
      match: {
        params: {
          id
        }
      }
    } = this.props

    const {
      details,
      signaturesList,
      websitesList,
      loaded,
      loading,
      thinking
    } = this.store

    return <Signature
      id={id}
      create={id === 'create'}
      details={details}
      signatures={signaturesList}
      websites={websitesList}
      loaded={loaded}
      loading={loading}
      thinking={thinking}
      onCreate={this.onCreate}
      onUpdate={this.onUpdate}
      onRemove={this.onRemove}
    />
  }
}

export default _Signature
