import List from './list'

export default class SimilarWebQuotasStore extends List {
  url = '/api/admin/similarweb-quotas'

  constructor() {
    super()
    this.itemsField= 'similarwebQuotas'
    this.perPage = 25
    this.getList().then()
  }
}
