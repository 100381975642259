import List from './list'
import { action } from 'mobx'
import { Delete, Post } from '../utils/request'
import hasError from './request-message'

export default class BrandEcosystemsStore extends List {
  brandId
  url = '/api/admin/brand-ecosystems'

  constructor(brandId) {
    super()
    this.brandId = brandId
    this.url = `${this.url}/${this.brandId}`
    this.order = 'desc'
    this.orderBy = 'createdAt'
    this.itemsField= 'ecosystems'
    this.perPage = 25
    this.getList().then()
  }

  @action
  async add (ecosystemId) {
    const { success, message } = await Post(`/api/admin/brand-ecosystems/${this.brandId}/${ecosystemId}`, {  })
    if (!hasError(success, message, 'Signatures successfully added')) {
      this.getList().then()
    }

    return { success }
  }

  @action
  async remove (ecosystemId) {
    const { success, message } = await Delete(`/api/admin/brand-ecosystems/${this.brandId}/${ecosystemId}`, {})
    if (!hasError(success, message, 'Signatures successfully removed from brand')) {
      this.getList().then()
    }

    return { success }
  }
}

//Test deploy
