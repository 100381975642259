import { Get, Delete, Post } from '../utils/request'
import { observable, action, computed, toJS } from 'mobx'
import GqlClient from './gql-client'
import { CURRENT, SIGN_IN } from '../gql/user'
// import Notification from './notification'

const providers = ['facebook', 'google', 'twitter']

const toSignin = () => {
  // window.location.replace('/signin')
}

class AuthorizationStore {
  @observable checkAuthorization = true
  @observable authorized = false
  @observable thinking = false
  @observable _user = {}


  constructor() {
    this.current().then()
  }

  @action
  async signin (details) {
    this.thinking = true

    GqlClient.removeToken()

    const { success, ...rest } = await GqlClient.mutation({ mutation: SIGN_IN, variables: { SigninUserInput: details } })

    if (success) {
      const { user, token } = rest.data['signinUser']

      GqlClient.updateToken(token)
      this._user = user
    } else {
      GqlClient.removeToken()
    }

    this.authorized = success

    this.checkAuthorization = false

    this.thinking = false

    return { error: !this.authorized, message: 'message' }
  }

  @action
  async current () {
    const { success, ...rest } = await GqlClient.query({ query: CURRENT })

    if (success) {
      this._user = rest.data['me']
    } else {
      GqlClient.removeToken()
    }

    this.authorized = success
    this.checkAuthorization = false

    return this.authorized
  }

  @action
  async logout () {
    this.thinking = true

    GqlClient.removeToken()

    this._user = {}
    this.authorized = false
    toSignin()
    this.thinking = false
  }

  @computed
  get user () {
    return toJS(this._user)
  }
}

export default new AuthorizationStore()
