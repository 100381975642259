import React, { useState } from 'react'
import { spacing } from '@material-ui/system'
import {
  Button as MuiButton,
  Chip,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import styled from 'styled-components'
import {
  HighlightOff as RemoveIcon,
  PostAddOutlined as MuiTableIcon,
} from '@material-ui/icons'

import DashboardPage from '../../layouts/DashboardPage'
import ConfirmDialog from '../../components/ConfirmDialog'
import ChannelDetails from '../../components/ChannelDetails'
import { RejectButton } from '../../components/Buttons'

import ChannelPartnerships from './Partnerships'
import Panel from '../../components/Panel'
import List from '../../components/List'
import { Asynchronous } from '../../components/Autocomplete'
import ChannelContacts from '../../components/Channels/ChannelContacts'
import ChannelEntity from '../../components/Channels/ChannelEntity'

const columns = [
  {
    id: 'name',
    disablePadding: true,
    label: 'Name',
    kind: 'link',
    link: {
      title: 'Ecosystem',
      pattern: `/ecosystems/{id}`,
      keys: { id: 'id' },
    },
  },
  { id: 'actions', numeric: 'right', kind: 'actions' },
]

const DialogActions = styled(MuiDialogActions)(spacing)

const Button = styled(MuiButton)(spacing)

const EcosystemsDialog = ({ open, channelId, ...rest }) => {
  const [thinking, setThinking] = useState(false)
  const [ecosystem, setEcosystem] = useState(null)

  const onChange = (value) => setEcosystem(value)

  const onCancel = () => {
    setEcosystem(null)
    rest.onClose()
  }

  const onAdd = async () => {
    setThinking(true)

    const { success } = await rest.onAdd(ecosystem, channelId)

    setThinking(false)

    if (success) {
      rest.onClose()
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      data-cy='channelDialog'
      open={open}
      onClose={onCancel}
      onBackdropClick={onCancel}
      aria-labelledby='max-width-dialog-title'
    >
      <DialogTitle id='max-width-dialog-title'>
        Add Channel to Ecosystem
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Asynchronous
              fullWidth
              disabled={thinking}
              url={`/api/admin/ecosystems`}
              filter='filters[name]'
              placeholder={`Choose ecosystem`}
              dataField={`ecosystems`}
              dataLabelField='name'
              value={ecosystem}
              defaultValue={ecosystem}
              onChange={(e, a) => onChange(a)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions px={6} py={4}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Button
              mr={2}
              color='default'
              disabled={thinking}
              onClick={onCancel}
            >
              Cancel
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            container
            direction='row'
            justify='flex-end'
            alignItems='center'
          >
            <Button
              disabled={thinking}
              onClick={onAdd}
              variant='outlined'
              color='primary'
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

const EcosystemMenu = ({ ecosystemId, channelId, onRemove }) => {
  return (
    <>
      <ConfirmDialog
        title={'Remove Channel from Ecosystem'}
        confirmation
        confirmationText={
          'Are you sure you want to remove this channel from ecosystem?'
        }
        action={() => onRemove(ecosystemId, channelId)}
        confirmButtonLabel={'Remove'}
        confirmButtonProps={{
          variant: 'contained',
          color: 'primary',
        }}
      >
        <IconButton size={'small'}>
          <RemoveIcon fontSize={'small'} />
        </IconButton>
      </ConfirmDialog>
    </>
  )
}

const EcosystemList = ({ ecosystems, channelId, ...rest }) => {
  const [open, setOpen] = useState(null)
  const onOpenAddToEcosystem = () => {
    setOpen(true)
  }

  const onRemoveChannelFromEcosystem = async (ecosystemId) => {
    return await rest.onRemoveChannelFromEcosystem(ecosystemId, channelId)
  }
  const onAddChannelToEcosystem = async (ecosystem) => {
    return await rest.onAddChannelToEcosystem(ecosystem, channelId)
  }

  return (
    <Panel title={'Ecosystems'} adding onAdd={onOpenAddToEcosystem}>
      <div style={{ margin: 10 }}>
        <List
          disablePagination
          columns={columns}
          items={ecosystems.map((item) => ({
            ...item,
            actions: () => (
              <EcosystemMenu
                ecosystemId={item.id}
                channelId={channelId}
                onRemove={(id) => onRemoveChannelFromEcosystem(id, channelId)}
              />
            ),
          }))}
          emptyMessage={'No Ecosystems matching the given criteria.'}
          emptyActionText={'Add Channel to Ecosystem'}
          onEmptyAction={onOpenAddToEcosystem}
        />
      </div>
      <EcosystemsDialog
        open={open}
        channelId={channelId}
        onAdd={onAddChannelToEcosystem}
        onClose={() => setOpen(false)}
      />
    </Panel>
  )
}

export default class Channel extends React.Component {
  state = {
    edit: false,
  }

  onCancel = (e) => {
    if (this.props.id === 'create') {
      return this.props.onCancel()
    }
  }

  render() {
    const { id, details, ecosystems, loading, channelDirection, onUpdate } =
      this.props

    const Actions = () => {
      if (details.status !== 'blocklist') {
        return (
          <ConfirmDialog
            title={'Add Channel to Host Blocklist URLs'}
            confirmation
            confirmationText={
              'Adding this channel to blocklist will delete all Pages and Redirects. Already discovered Partnerships will be preserved.'
            }
            action={this.props.onAddToBlocklist}
            confirnButtonLabel={'Add'}
            confirnButtonProps={{
              variant: 'contained',
              color: 'primary',
            }}
          >
            <Button variant='contained' color='primary'>
              {' '}
              Add to blocklist{' '}
            </Button>
          </ConfirmDialog>
        )
      }
      return (
        <RejectButton color='default' mr={2} disabled>
          Blocked
        </RejectButton>
      )
    }

    return (
      <DashboardPage
        kind='details'
        title={'Channel'}
        status={id}
        noActions
        actions={Actions}
        thinking={loading}
      >
        <ChannelDetails
          client
          channel={details.id}
          channels={[details]}
          onUpdate={onUpdate}
        />

        {!!id && !loading && (
          <EcosystemList
            channelId={id}
            ecosystems={ecosystems}
            onAddChannelToEcosystem={this.props.onAddChannelToEcosystem}
            onRemoveChannelFromEcosystem={
              this.props.onRemoveChannelFromEcosystem
            }
          />
        )}
        {!!id && !loading && (
          <ChannelEntity
            channelId={id}
            entityId={details.entityId}
            onCreate={this.props.onLoad}
          />
        )}
        {!!id && (
          <ChannelContacts
            channelId={id}
            entityId={details.entityId}
            onCreate={this.props.onLoad}
          />
        )}
        {!!id && (
          <ChannelPartnerships
            channelId={id}
            link={details.link}
            direction={channelDirection}
          />
        )}
      </DashboardPage>
    )
  }
}
