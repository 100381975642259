import { Get } from '../utils/request'
import { observable, action, computed, toJS } from 'mobx'
import { formatDateTime } from '../utils/format'

class CampaignStore {
  @observable id
  @observable createdAt
  @observable adex
  @observable partnerId
  @observable count
  @observable inChannel
  @observable inChannelLink
  @observable outChannel
  @observable outChannelLink

  @observable loading = true
  @observable thinking = false

  constructor (id) {
    this.id = id
    this.load().then()
  }

  @action
  async load () {
    const { errorCode, campaign, /*balance,*/ message } = await Get(`/api/admin/campaigns/${this.id}`)

    if (errorCode) {
      return message
    }

    for (let key in campaign) {
      if (campaign.hasOwnProperty(key)) {
        this[key] = campaign[key]
      }
    }

    this.loading = false
  }

  @computed
  get details () {
    const { id, createdAt, adexes, partnerId, count, inChannel, inChannelLink, outChannel, outChannelLink } = this
    return {
      id, createdAt: formatDateTime(createdAt), adex: (adexes || []).map(({ name }) => name).join(', '), partnerId, count,
      inChannel: { id: inChannel, label: inChannelLink },
      outChannel: { id: outChannel, label: outChannelLink },
    }
  }
}

export default CampaignStore
