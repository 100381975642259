import React from 'react'
import Moment from 'moment'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import Ecosystems from './Ecosystems'
import EcosystemsStore from '../../stores/ecosystems'

const itemsTime = (items = []) => {
  return items.map(({ createdAt, ready, published, ...rest}) => {
    return {
      ...rest,
      createdAt: Moment(createdAt).format(`YYYY-MM-DD`)
    }
  })
}

@withRouter
@observer
class _Ecosystems extends React.Component {
  constructor(props) {
    super(props)
    this.store = new EcosystemsStore()
    this.state = { filters: [] }
  }

  onUpdateEcosystems = () => this.store.updateEcosystems()

  onChangeSort = orderByNew => e => {
    const { listProps } = this.store
    const { order, orderBy } = listProps
    let orderNew = ''
    if (orderBy === orderByNew) {
      if (order === 'desc') {
        orderNew = 'asc'
      } else {
        orderNew = 'desc'
      }
    } else {
      orderNew = 'desc'
    }

    this.store.onChangeSort(orderNew, orderByNew)
  }

  onChangePage = (e, page) => this.store.onChangePage(page)

  onChangeRowsPerPage = ({ target: { value }}) => this.store.onChangeRowsPerPage(value)

  onClickRow = (e, id) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(`/ecosystems/${id}`, '_blank')
    } else {
      this.props.history.push(`/ecosystems/${id}`)
    }
  }

  onChangeFilters = name => object => {
    let value = null
    if (object.hasOwnProperty('target')) {
      value = object.target.value
    } else {
      value = object
    }
    this.store.onChangeFilter(name, value)
  }

  render () {
    const {
      listProps
    } = this.store

    const {
      items
    } = listProps

    return <Ecosystems
      {...this.props}
      {...listProps}
      items={itemsTime(items)}
      onChangeSort={this.onChangeSort}
      onChangePage={this.onChangePage}
      onChangeFilters={this.onChangeFilters}
      onChangeRowsPerPage={this.onChangeRowsPerPage}
      onClickRow={this.onClickRow}
      onUpdateEcosystems={this.onUpdateEcosystems}
    />
  }
}

export default _Ecosystems
