import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import styled, { withTheme } from 'styled-components'
import {
  Grid,
  Hidden,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar, Avatar,
  Tabs, Tab
} from '@material-ui/core'
import { Menu as MenuIcon } from '@material-ui/icons'

const AppBar = styled(MuiAppBar)`
  background: ${props => props.theme.header.background};
  color: ${props => props.theme.header.color};
  box-shadow: ${props => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

@withRouter
class UserMenu extends Component {
  state = {
    anchorMenu: null
  };

  toggleMenu = event => {
    this.setState({ anchorMenu: event.currentTarget });
  };

  closeMenu = () => {
    this.setState({ anchorMenu: null });
  };

  onProfileClick = () => {
    const { user, history } = this.props
    if (user && user.id) {
      history.push({ pathname: `/users/${user.id}` })
    }
    this.closeMenu()
  }

  get getUserName () {
    const { user } = this.props
    if (user) {
      const fullName = [user.firstName, user.lastName].filter(p => !!p).join(' ')
      return !fullName ? user.email : fullName
    }
    return null
  }

  render() {
    const { anchorMenu } = this.state;
    const { onLogout } = this.props;
    const open = Boolean(anchorMenu);

    return (
      <React.Fragment>
        <IconButton
          aria-owns={open ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={this.toggleMenu}
          color="inherit"
        >
          <Avatar alt={'Breezy User'} src=""/>
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorMenu}
          open={open}
          onClose={this.closeMenu}
        >

          <MenuItem disabled>
            {this.getUserName}
          </MenuItem>
          <MenuItem divider />
          <MenuItem onClick={this.onProfileClick}>
            Profile
          </MenuItem>
          <MenuItem onClick={onLogout}>
            Sign out
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }
}

const Header = ({ onDrawerToggle, user, discovery, onLogout, onChangeApp }) => (
  <React.Fragment>
    <AppBar position="sticky" elevation={0}>
      <Toolbar>
        <Grid container alignItems="center">
          <Hidden mdUp>
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          </Hidden>
          <Grid item xs>
            {/*<Tabs value={discovery ? 1 : 0} onChange={onChangeApp} aria-label="Header menu">*/}
            {/*  <Tab label="TRACKING" data-cy="tab-tracking"/>*/}
            {/*  <Tab label="DISCOVERY" data-cy="tab-discovery" />*/}
            {/*</Tabs>*/}
          </Grid>
          <Grid item>
            <UserMenu
              user={user}
              onLogout={onLogout}
            />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  </React.Fragment>
)

export default withTheme(Header)
