import { observable, action, computed, toJS } from 'mobx'
import { Delete, Get, Post, Put } from '../utils/request'
import hasError from './request-message'

export default class ContactPersons {
  model = null
  modelId = null
  url = null
  @observable items = []

  @observable thinking = true

  constructor (model, modelId, discovery) {
    this.model = model
    this.modelId = modelId
    this.url = `/api/admin/${discovery ? 'discovery/' : ''}contact-persons`
    this.load().then()
  }

  @action
  async load () {
    this.thinking = true

    const { errorCode, contactPersons, message } = await Get(`${this.url}?filters[notStatus]=deleted&filters[${this.model}]=${this.modelId}`)

    if (!hasError(!errorCode, message)) {
      this.items = contactPersons
    }

    this.thinking = false
  }

  @action
  async create (details) {
    this.thinking = true
    const { success, message } = await Post(`${this.url}`, {
      contactPerson: {...details, [this.model]: this.modelId }
    })
    this.load()
    this.thinking = false
    hasError(success, message, `Contact successfully created`)
    return { success }
  }

  @action
  async update ({ id, ...details }) {
    this.thinking = true
    const { success, message } = await Put(`${this.url}/${id}`, {
      contactPerson: { ...details }
    })
    this.load()
    this.thinking = false
    hasError(success, message, `Contact successfully updated`)
    return { success }
  }

  @action
  async remove (id) {
    this.thinking = true
    const { success, message } = await Delete(`${this.url}/${id}`, {})
    this.load()
    this.thinking = false
    hasError(success, message, `Contact successfully removed`)
    return { success }
  }

  @computed
  get persons () {
    return toJS(this.items).map(({ emails, phones, ...other}) => {
      return {
        ...other,
        phones: (phones.length ? phones : ['']).map(p => ({ id: Math.random(), value: p })), //...(phones && { phone: [0] }),
        emails: (emails.length ? emails : ['']).map(p => ({ id: Math.random(), value: p })) //...(emails && { email: emails[0] }),
      }
    })
  }
}
