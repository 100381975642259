import React from 'react'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import moment from 'moment'

import SaaSEcosystems from './SaaSEcosystems'
import SaaSEcosystemsStore from '../../stores/saas-ecosystems'

@withRouter
@observer
class _SaaSEcosystems extends React.Component {
  constructor(props) {
    super(props)
    this.store = new SaaSEcosystemsStore()
  }

  onChangeSort = (orderByNew) => (e) => {
    const { listProps } = this.store
    const { order, orderBy } = listProps
    let orderNew = ''
    if (orderBy === orderByNew) {
      if (order === 'desc') {
        orderNew = 'asc'
      } else {
        orderNew = 'desc'
      }
    } else {
      orderNew = 'desc'
    }

    this.store.onChangeSort(orderNew, orderByNew)
  }

  onChangePage = (e, page) => this.store.onChangePage(page)

  onChangeRowsPerPage = ({ target: { value } }) =>
    this.store.onChangeRowsPerPage(value)

  onClickRow = (e, id) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(`/saas-ecosystems/${id}`, '_blank')
    } else {
      this.props.history.push(`/saas-ecosystems/${id}`)
    }
  }

  onChangeFilters = (name) => (object) => {
    let value = null
    if (object.hasOwnProperty('target')) {
      value = object.target.value
    } else {
      value = object
    }
    this.store.onChangeFilter(name, value)
  }

  render() {
    const { listProps } = this.store

    return (
      <SaaSEcosystems
        {...listProps}
        items={listProps.items.map((i) => ({
          ...i,
          brand: i.brand.id,
          brandName: i.brand.name,
          createdAt: moment(i.createdAt).format(`YYYY-MM-DD`),
        }))}
        onChangeSort={this.onChangeSort}
        onChangePage={this.onChangePage}
        onChangeFilters={this.onChangeFilters}
        onChangeRowsPerPage={this.onChangeRowsPerPage}
        onClickRow={this.onClickRow}
      />
    )
  }
}

export default _SaaSEcosystems
