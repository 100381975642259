import React from 'react'
import styled, { css } from 'styled-components'
import is from 'is_js'
import { Button, CardActions, Grid } from '@material-ui/core'
import DashboardPage from '../../layouts/DashboardPage'
import DetailsForm from '../../components/Details'
import Panel from '../../components/Panel'
import TextRows from '../../components/TextRows'
import LoaderOverlay from '../../components/LoaderOverlay'
import ConfirmDialog from '../../components/ConfirmDialog'
import { RejectButton } from '../../components/Buttons'

import EcosystemChannels from './EcosystemChannels'

const config = [
  { key: 'id', name: 'ID', type: 'string' },
  { key: 'createdAt', name: 'Created At', type: 'string' },
  { key: 'name', name: 'Name', creatable: true, editable: true, type: 'string' },
  { key: 'published', name: 'Published', editable: true, creatable: true, type: 'bool' },
  { key: 'description', name: 'Description', editable: true, creatable: true, type: 'text' },
]

export default class Ecosystem extends React.Component {
  state = { open: false, domainsErrors: [], details: {}, domains: [] }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if ((!prevProps.loaded && this.props.loaded) || (prevProps.create && !this.props.create)) {
      const { details, domains } = this.props
      this.setState({ details, domains })
    }
  }

  onOpen = () => this.setState({ open: true })

  onEdit = (kind) => () => {
    this.setState({ [`${kind}Edit`]: true })
  }

  onDetailsChange = (kind) => (field, value) => {
    const { details } = this.state
    return this.setState({ details: {...details, [field]: value }})
  }

  onChange = kind => (value, index) => {
    this.setState({
      [kind]: value,
      ...(kind === 'domains' && { domainsErrors: index })
    })
  }

  onUpdate = (kind) => async () => {
    let error = false

    if (!error) {
      const { success } = await this.props.onUpdate({ [kind]: this.state[kind] })
      this.setState({ [`${kind}Edit`]: false, ...(kind === 'domains' && { domains: [] }) })
      return { success }
    }

    return { success: false }
  }

  onCreate = async () => {
    const { details, domains } = this.state
    await this.props.onCreate({ ...details, domains })
    this.setState({ domains: [] })
  }

  onCancel = (kind) => () => {
    this.setState({ [kind]: this.props[kind], [`${kind}Edit`]: false })
  }

  render () {
    const {
      details,
      domains,
      domainsErrors
    } = this.state
    const {
      id,
      create,
      loaded,
      loading,
      thinking
    } = this.props

    return (
      <DashboardPage
        kind="details"
        title={create ? "New ecosystem" : details.name || 'Ecosystem'}
        status={id}
        thinking={loading}
        noActions
        actions={() => {
          if (create) {
            return <Button color={'primary'} variant={'contained'} onClick={this.onCreate}>Create</Button>
          }

          return (
            <ConfirmDialog
              title={'Remove Ecosystem'}
              confirmation
              confirmationText={'Are you sure you want to remove this ecosystem?'}
              action={this.props.onRemove}
              confirmButtonLabel={'Remove'}
              confirmButtonProps={{
                variant: 'contained',
                color: 'primary'
              }}
            >
              <RejectButton color="default" mr={2}>Remove</RejectButton>
            </ConfirmDialog>
          )
        }}
      >

        { loading && <LoaderOverlay /> }

        {
          !loading && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DetailsForm
                  hideCreateButtons={create}
                  create={create}
                  loaded={loaded}
                  thinking={thinking || loading}
                  config={config}
                  details={details}
                  onChange={this.onDetailsChange('details')}
                  onUpdate={this.onUpdate('details')}
                />
              </Grid>

              <Grid item xs={12}>
                <EcosystemChannels
                  ecosystemId={id}
                  create={create}
                  domains={domains}
                  domainsErrors={domainsErrors}
                  onChange={this.onChange('domains')}
                  onUpdate={this.onUpdate('domains')}
                />
              </Grid>
            </Grid>
          )
        }
      </DashboardPage>
    )
  }
}
