import React from 'react'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import {
  Grid
} from '@material-ui/core'

import PartnerCampaigns from './PartnerCampaigns'
import Client from '../Client'
import PartnerStore from '../../stores/client'
import ChannelsStore from '../../stores/channels'
import config from '../../config'

const detailsConfig = [
  { key: 'n', name: 'ID', editable: false, creatable: false, type: 'string' },
  { key: 'name', name: 'Name', editable: true, creatable: true, type: 'string', dataCy:'brandName' },
  { key: 'description', name: 'Description', editable: true, creatable: true, type: 'string', dataCy:'brandDescription'  },
  { key: 'categories', name: 'Classification', dataCy:'brandCategories', editable: true, creatable: true, type: 'multiselect', kind: 'chips', options: config.CLASSIFICATIONS },
  { key: 'status', name: 'Status', editable: true, creatable: true, type: 'select', kind: 'status', dataCy:'brandStatus',
    options: [
      { value: 'active', label: 'Active' },
      { value: 'verified', label: 'Verified' },
      { value: 'deleted', label: 'Deleted' },
    ]
  },
  { key: 'logoUrl', editable: true, creatable: true, type: 'image'}
]

@withRouter
@observer
class Partner extends React.Component {
  constructor(props) {
    super(props)
    const { match: { params: { id } } } = props
    this.onLoadStores(id)
  }

  onLoadStores = id => {
    this.store = new PartnerStore(id, 'partner', false)
    this.channelsStore = {}
    if (id !== 'create') {
      this.channelsStore = new ChannelsStore('partner', id)
    }
  }

  render () {
    const { match: { params: { id, channel } } } = this.props
    const { details, loading } = this.store

    const channelsProps = {
      channels: this.channelsStore.channels,
      thinking: this.channelsStore.channelsThinking
    }

    const similarProps = {
      channels: this.channelsStore.similar,
      thinking: this.channelsStore.similarThinking
    }

    return (
      <Client
        loading={loading}
        discovery={false}
        id={id}
        channel={channel}
        client={'partner'}
        clientStore={this.store}
        details={details}
        detailsConfig={detailsConfig}

        channelsStore={this.channelsStore}
        channelsProps={channelsProps}
        similarProps={similarProps}

        onUpdateStores={this.onLoadStores}
      >
        <Grid item xs={12}>
          <PartnerCampaigns clientId={id} />
        </Grid>
      </Client>
    )
  }
}

export default Partner
