import { Delete, Get, Post, Put } from '../utils/request'
import { observable, action, computed, toJS } from 'mobx'
import moment from 'moment'

import hasError from './request-message'
import { dataToDetails, contactToPayload } from '../utils/contacts'

class ContactStore {
  @observable id
  @observable channel = {}
  @observable data = []
  @observable role
  @observable status
  @observable createdAt
  @observable publishedAt
  @observable optedoutAt
  @observable validatedAt
  @observable bouncedAt

  @observable loading = true
  @observable thinking = false

  constructor(id) {
    this.id = id
    this.load().then()
  }

  @action
  async load() {
    this.loading = true
    const { errorCode, contact, message } = await Get(
      `/api/admin/contacts/${this.id}`
    )

    if (!hasError(!errorCode, message, null)) {
      for (let key in contact) {
        if (contact.hasOwnProperty(key)) {
          this[key] = contact[key]
        }
      }
    }

    this.loading = false
  }

  @action
  async publish(contactId) {
    const { success, message } = await Put(
      `/api/admin/contacts/${contactId}/publish`,
      {}
    )

    if (!hasError(success, message, 'Contact successfully published')) {
      await this.load()
    }
    return { success }
  }

  @action
  async remove(id) {
    const { success, message } = await Delete(
      `/api/admin/contacts/${this.id}`,
      {}
    )
    hasError(success, message, 'Contact successfully removed')
    return { success }
  }

  @action
  async update({ id, ...details }, publish) {
    const { channel, ...contactDetails } = details

    const payload = {
      data: contactToPayload(contactDetails),
    }

    const { success, message, contact } = await Put(
      `/api/admin/contacts/${id}`,
      { contact: payload }
    )

    if (!hasError(success, message, 'Contact successfully updated')) {
      for (let key in contact) {
        if (contact.hasOwnProperty(key)) {
          this[key] = contact[key]
        }
      }

      if (publish) {
        return await this.publish(contact.id)
      }

      await this.load()
    }

    return { success }
  }

  @computed
  get details() {
    const {
      id,
      data,
      channel,
      createdAt,
      publishedAt,
      optedoutAt,
      validatedAt,
      bouncedAt,
      status,
    } = this

    return {
      id,
      channelId: toJS(channel).id,
      channelName: toJS(channel).domain,
      channel: { label: toJS(channel).domain, ...toJS(channel) },
      ...(createdAt && {
        createdAt: moment(createdAt).utc().format('dddd, D MMMM YYYY hh:mm'),
      }),
      ...(publishedAt && {
        publishedAt: moment(publishedAt)
          .utc()
          .format('dddd, D MMMM YYYY hh:mm'),
      }),
      ...(optedoutAt && {
        optedoutAt: moment(optedoutAt).utc().format('dddd, D MMMM YYYY hh:mm'),
      }),
      ...(validatedAt && {
        validatedAt: moment(validatedAt)
          .utc()
          .format('dddd, D MMMM YYYY hh:mm'),
      }),

      ...(bouncedAt && {
        bouncedAt: moment(bouncedAt).utc().format('dddd, D MMMM YYYY hh:mm'),
      }),
      ...dataToDetails(data),
      status,
    }
  }
}

export default ContactStore
