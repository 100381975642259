import React from 'react'
import {
  Button,
  MenuItem,
  Select,
  Chip as MuiChip,
  Grid, TextField,
  Table as MuiTable, TableBody, CardActions, Switch, FormControlLabel
} from '@material-ui/core'
import { CheckCircleOutline } from '@material-ui/icons'
import { spacing } from '@material-ui/system'
import styled from 'styled-components'
import is from 'is_js'
import { find } from 'lodash'

import Link from './Link'
import Panel from './Panel'
import { Form, FormField } from './Form'
import Autocomplete from './Autocomplete'
import configGlobal from '../config'
import LoaderOverlay from './LoaderOverlay'
import Icon from './Icon'

const LogoContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`
const Table = styled(MuiTable)`
td {
  border-color: rgba(0,0,0,0.08);  
}
tr:last-child > td{
 border-color: transparent;
}
`
const Chip = styled(MuiChip)(spacing)

const channelConfig = [
  { key: 'id' },
  { key: 'verified' },
  { key: 'name' },

  { key: 'swGlobalRank' },
  { key: 'mozDomainAuthority' },
  { key: 'externalPagesToRootDomain' },
  { key: 'rootDomainsToRootDomain' },

  // { key: 'countries' },

  { key: 'partnershipsOutCount' },

  { key: 'link' },
  { key: 'kind' },
  // {
  //   key: 'brand', name: 'Brand', editable: false, creatable: true, type: 'brands',
  //   link: { title: 'Brand', pattern: `/brands/{id}`, keys: { id: 'brand' } }
  // },
  // {
  //   key: 'partner', name: 'Partner', editable: false, creatable: true, type: 'partners',
  //   link: { title: 'Partner', pattern: `/partners/{id}`, keys: { id: 'partner' } }
  // },
  { key: 'type1Classification' },
  { key: 'categories' },
  { key: 'businessModels' },
  { key: 'employeeCount' },
  { key: 'targetDecisionMaker' },
  { key: 'age' },
  { key: 'gender' },
  { key: 'income' },
  { key: 'region' }
]

const PreviewWrapper = ({ children, preview, kind, value }) => {
  const computeValue = (v) => {
    if ((typeof v === "object" || typeof v === 'function') && (v !== null)) {
      return v.label
    }
    return v
  }

  return (
    <React.Fragment>
      {
        !preview && children
      }
      {
        preview && kind === 'icon' && (value ? <CheckCircleOutline /> : '-')
      }

      {
        preview && kind === 'number' &&  (typeof value !== 'undefined' ? value :  '–')
      }

      {
        preview && kind === 'string' && ((!!value && value.length > 0 && value) || '–')
      }
      {
        preview && kind === 'chips' && (((!!value && value.length > 0 && value.map((item) => <Chip component={'span'} size="small" mr={1.5} my={0.5} key={item.value} label={item.label} />))) || '–')
      }
    </React.Fragment>
  )
}

const BusinessModel = ({ title,
                         model,
                         preview,
                         client,
                         // B2B fields
                         employeeCount,
                         targetDecisionMaker,
                         // B2C fields
                         age,
                         gender,
                         income,
                         region,

                         onChange,
                       }) => {
  return (
    <FormField name={title} padding={'none'}>
      <Table size={'small'} padding={'none'}>
        <TableBody>
          {
            model === 'b2b' && [
              <FormField name={'Employee Count'} >
                <PreviewWrapper
                  preview={preview}
                  kind={'chips'}
                  value={employeeCount /*? [ find(configGlobal.EMPLOYEE_COUNT, ['value', employeeCount])] : []*/}
                >
                  <Autocomplete
                    placeholder={'Employee Count'}
                    options={configGlobal.EMPLOYEE_COUNT}
                    value={employeeCount}
                    onChange={(e, a) => onChange('employeeCount')({ target: { value: a } })}
                  />
                  {/*<Select*/}
                  {/*  placeholder={'Employee Count'}*/}
                  {/*  value={employeeCount}*/}
                  {/*  onChange={onChange('employeeCount')}*/}
                  {/*  inputProps={{placeholder: 'Employee Count'}}*/}
                  {/*>*/}
                  {/*  { configGlobal.EMPLOYEE_COUNT.map(o => <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>)}*/}
                  {/*</Select>*/}
                </PreviewWrapper>
              </FormField>,
              <FormField name={'Decision makers'}>
                <PreviewWrapper
                  preview={preview}
                  kind={'chips'}
                  value={targetDecisionMaker}
                >
                  <Autocomplete
                    placeholder={'Decision makers'}
                    options={configGlobal.DECISION_MAKERS}
                    value={targetDecisionMaker}
                    onChange={(e, a) => onChange('targetDecisionMaker')({ target: { value: a } })}
                  />
                </PreviewWrapper>
              </FormField>
            ]
          }

          {
            model === 'b2c' && [
              <FormField name={'Age'}>
                <PreviewWrapper
                  preview={preview}
                  kind={'chips'}
                  value={age}
                >
                  <Autocomplete
                    placeholder={'Age'}
                    options={configGlobal.AGE}
                    value={age}
                    onChange={(e, a) => onChange('age')({ target: { value: a } })}
                  />
                </PreviewWrapper>
              </FormField>,
              <FormField name={'Gender'}>
                <PreviewWrapper
                  preview={preview}
                  kind={'chips'}
                  value={gender}
                >
                  <Autocomplete
                    placeholder={'Gender'}
                    options={configGlobal.GENDER}
                    value={gender}
                    onChange={(e, a) => onChange('gender')({ target: { value: a } })}
                  />
                </PreviewWrapper>
              </FormField>,
              <FormField name={'Income'}>
                <PreviewWrapper
                  preview={preview}
                  kind={'chips'}
                  value={income}
                >
                  <Autocomplete
                    placeholder={'Income'}
                    options={configGlobal.INCOME}
                    value={income}
                    onChange={(e, a) => onChange('income')({ target: { value: a } })}
                  />
                </PreviewWrapper>
              </FormField>,
              <FormField name={'Region'}>
                <PreviewWrapper
                  preview={preview}
                  kind={'chips'}
                  value={region}
                >
                  <Autocomplete
                    placeholder={'Region'}
                    options={configGlobal.COUNTRIES}
                    value={region}
                    onChange={(e, a) => onChange('region')({ target: { value: a } })}
                  />
                </PreviewWrapper>
              </FormField>
            ]
          }
        </TableBody>
      </Table>
    </FormField>
  )
}


const DetailsLink = ({ details, link, value }) => {
  const getValue = (_value) => {
    if ((typeof _value === "object" || typeof _value === 'function') && (_value !== null)) {
      return _value.id
    }
    return _value
  }

  const url = ({ pattern, keys }) => {
    let result = pattern
    for (let key in keys) {
      if (keys.hasOwnProperty(key)) {

        result = result.replace(`{id}`, value.id)
      }
    }
    return result
  }

  return <Link link={url(link)} title={link.title} value={value.label} />
}

export default class ChannelDetails extends React.Component {
  static defaultProps = {
    employeeCount: [],
    targetDecisionMaker: [],
    age: [],
    gender: [],
    income: [],
    region: []
  }

  constructor (props) {
    super(props)
    const { channel, create } = props
    let toState = {}
    channelConfig.forEach(({ key }) => toState[key] = channel[key] || null )
    this.state = { edit: false, ...channel, errors: {} }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    // if (!this.state.edit) {
    //   let toUpdate = {}
    //   let needUpdateState = false
    //   const { config, details } = this.props
    //   config.forEach(({ key }) => {
    //     if (details[key] !== prevState[key]) {
    //       toUpdate[key] = details[key] || null
    //       needUpdateState = true
    //     }
    //   })
    //   //
    //   // console.log(needUpdateState, this.props.create)
    //   //
    //   // if (needUpdateState && !this.props.create) {
    //   //   this.setState(toUpdate)
    //   // }
    // }
  }

  resetError = (key) => {
    const { errors } = this.state
    this.setState({ errors: {...errors, [key]: null } })
  }

  onChange = field => ({ target: { value, checked } }) => {
    if (field === 'verified') {
      this.setState({ [field]: checked })
    } else if (field === 'kind' && this.disableLink(value)) {
      this.setState({ [field]: value, link: '' })
    } else if (field === 'age') {
      const allAges = find(value, ['label', 'All ages'])
      const allAgesState = find(this.state['age'], ['label', 'All ages'])
      if (!allAgesState && allAges) {
        this.setState({ [field]: [allAges] })
      } else {
        this.setState({ [field]: value.filter(({ label }) => label !== 'All ages') })
      }
    } else {
      this.setState({ [field]: value })
    }
    this.resetError(field)
  }

  onHasErrors = () => {
    let hasErrors = false
    let errors = {}

    const { name, kind, link, weight } = this.state
    if (!name) {
      hasErrors = true
      errors['name'] = 'Name is required'
    }
    if (!kind) {
      hasErrors = true
      errors['kind'] = 'Channel kind is required'
    }
    if (weight && (weight < 0 || weight > 1000)) {
      errors['weight'] = 'Weight must be between 0 and 1000'
      hasErrors = true
    }
    if (!this.disableLink()) {
      if (!link) {
        errors['link'] = 'Link is required for selected channel kind'
        hasErrors = true
      } else
      if (!is.url(link)) {
        hasErrors = true
        errors['link'] = 'Please enter correct url'
      }
    }

    this.setState({ errors })

    return hasErrors
  }

  disableLink = (_kind) => {
    const kind = _kind || this.state.kind
    if (!kind) {
      return true
    }
    const kindOption = find(configGlobal.CHANNELS, ['value', kind])

    return kindOption && !kindOption.hasLink
  }

  onSubmit = async () => {
    const {
      onUpdate
    } = this.props

    if (!this.onHasErrors()) {
      let details = {}

      channelConfig.forEach(c => {
        details[c.key] = this.state[c.key]
      })

      this.setState({ thinking: true })
      const { success } = await onUpdate(details)
      this.setState({ thinking: false })
      return success
    }
  }

  onCancel = e => {
    if (this.props.onCancel()) {
      this.props.onCancel()
    }
    return this.setState({ ...this.props.channel, edit: false })
  }

  onEdit = () => this.setState({ edit: true })

  onRemove = async () => {
    const { id } = this.state
    const { onRemove } = this.props
    this.setState({ thinking: true })
    const { success } = await onRemove(id)
    this.setState({ thinking: false, edit: !success })
  }

  render () {
    const {
      errors,
      thinking
    } = this.state

    const {
      edit,
      create,
      client,
      similar,
      discovery,
      formName,
      logo,
    } = this.props


    const Actions = () => edit ? (
      <CardActions style={{justifyContent: 'space-between', borderTop: '1px #eee solid'}}>
        <div>
          {
            similar && (
              <Button variant="outlined" onClick={this.onRemove}>
                Remove
              </Button>
            )
          }
        </div>
        <div>
          <Button variant="outlined" onClick={this.onCancel}>
            Cancel
          </Button>
          &nbsp;
          <Button onClick={this.onSubmit} variant="contained" color="primary">
            { 'Save changes' }
          </Button>
        </div>
      </CardActions>
    ) : null

    return (
      <Panel
        edit={edit}
        actions={Actions}
        notitle
      >
        { thinking && <LoaderOverlay /> }
        <Grid container>

          <Grid item xs={logo ? 8 : 12}>
            <Form
              name={formName}
              onSubmit={this.onSubmit}
            >

              <FormField name={'Icon'}>
                <Icon item={{ icon: this.props.channel.icon }} />
              </FormField>
              {
                !discovery && (
                  <FormField name={'Verified'}>
                    <PreviewWrapper
                      preview={!edit}
                      kind={'icon'}
                      value={this.state['verified']}
                    >
                      <FormControlLabel
                        control={<Switch checked={this.state['verified']} onChange={this.onChange('verified')} />}
                        label="Verified"
                      />
                    </PreviewWrapper>
                  </FormField>
                )
              }

              <FormField name={'Kind'}>
                <PreviewWrapper
                  preview={!edit}
                  kind={'chips'}
                  value={this.state['kind'] ? [ find(configGlobal.CHANNELS, ['value', this.state['kind']])] : []}
                >
                  <TextField
                    error={errors.kind}
                    helperText={errors.kind}
                    select
                    fullWidth
                    data-cy="channelKindEdit"
                    label="Kind"
                    m={2}
                    value={this.state['kind']}
                    onChange={this.onChange('kind')}
                  >
                    {
                      configGlobal.CHANNELS.map(({ label, value }) => <MenuItem key={value} value={value}>{label}</MenuItem>)
                    }
                  </TextField>
                </PreviewWrapper>
              </FormField>

              <FormField name={'Name'}>
                <PreviewWrapper
                  preview={!edit}
                  kind={'string'}
                  value={this.state['name']}
                >
                  <TextField
                    error={errors.name}
                    helperText={errors.name}
                    fullWidth
                    data-cy="nameChannelEdit"
                    label="Name"
                    m={2}
                    value={this.state['name']}
                    onChange={this.onChange('name')}
                  />
                </PreviewWrapper>
              </FormField>

              <FormField name={'SimilarWeb Rank'}>
                <PreviewWrapper
                  preview
                  kind={'number'}
                  value={this.state['swGlobalRank']}
                >
                </PreviewWrapper>
              </FormField>
              <FormField name={'Domain Authority'}>
                <PreviewWrapper
                  preview
                  kind={'number'}
                  value={this.state['mozDomainAuthority']}
                >
                </PreviewWrapper>
              </FormField>
              <FormField name={'External Page Links'}>
                <PreviewWrapper
                  preview
                  kind={'number'}
                  value={this.state['externalPagesToRootDomain']}
                >
                </PreviewWrapper>
              </FormField>
              <FormField name={'External Site Links'}>
                <PreviewWrapper
                  preview
                  kind={'number'}
                  value={this.state['rootDomainsToRootDomain']}
                >
                </PreviewWrapper>
              </FormField>
              <FormField name={'Affiliate Pages'}>
                <PreviewWrapper
                  preview
                  kind={'number'}
                  value={this.state['partnershipsOutCount']}
                >
                </PreviewWrapper>
              </FormField>

              {/*<FormField name={'Weight'}>*/}
              {/*  <PreviewWrapper*/}
              {/*    preview={!edit}*/}
              {/*    kind={'number'}*/}
              {/*    value={this.state['weight']}*/}
              {/*  >*/}
              {/*    <TextField*/}
              {/*      type="number"*/}
              {/*      error={errors.weight}*/}
              {/*      helperText={errors.weight}*/}
              {/*      fullWidth*/}
              {/*      data-cy="weightChannelEdit"*/}
              {/*      label="Weight"*/}
              {/*      m={2}*/}
              {/*      InputProps={{ inputProps: { min: 0, max: 1000 } }}*/}
              {/*      value={this.state['weight']}*/}
              {/*      onChange={this.onChange('weight')}*/}
              {/*    />*/}
              {/*  </PreviewWrapper>*/}
              {/*</FormField>*/}

              <FormField name={'Level 1 Classification'}>
                <PreviewWrapper
                  preview={!edit}
                  kind={'chips'}
                  value={this.state['type1Classification'] ? [ find(configGlobal.LEVEL_1_CLASSIFICATIONS, ['value', this.state['type1Classification']])] : []}
                >
                  <TextField
                    select
                    fullWidth
                    label="Level 1 Classification"
                    m={2}
                    value={this.state['type1Classification']}
                    onChange={this.onChange('type1Classification')}
                  >
                    <MenuItem value={null}>-</MenuItem>
                    {
                      configGlobal.LEVEL_1_CLASSIFICATIONS.map(({ label, value }) => <MenuItem key={value} value={value}>{label}</MenuItem>)
                    }
                  </TextField>
                </PreviewWrapper>
              </FormField>

              {/*<FormField name={'Level 1 Classification'}>*/}
              {/*  <PreviewWrapper*/}
              {/*    preview={!edit}*/}
              {/*    kind={'chips'}*/}
              {/*    value={this.state['type1Classification']}*/}
              {/*  >*/}
              {/*    <Autocomplete*/}
              {/*      placeholder={'Level 1 classification'}*/}
              {/*      options={configGlobal.LEVEL_1_CLASSIFICATIONS}*/}
              {/*      value={this.state['type1Classification']}*/}
              {/*      onChange={(e, a) => this.onChange('type1Classification')({ target: { value: a } })}*/}
              {/*    />*/}
              {/*  </PreviewWrapper>*/}
              {/*</FormField>*/}

              <FormField name={'Classification'}>
                <PreviewWrapper
                  preview={!edit}
                  kind={'chips'}
                  value={this.state['categories']}
                >
                  <Autocomplete
                    placeholder={'Classification'}
                    options={configGlobal.CLASSIFICATIONS}
                    value={this.state['categories']}
                    onChange={(e, a) => this.onChange('categories')({ target: { value: a } })}
                  />
                </PreviewWrapper>
              </FormField>

              <FormField name={'Facebook Classification'}>
                <PreviewWrapper
                  preview
                  kind={'chips'}
                  value={this.props.channel.discoveredFacebookCategories}
                >
                  {/*<Autocomplete*/}
                  {/*  placeholder={'Classification'}*/}
                  {/*  options={configGlobal.CLASSIFICATIONS}*/}
                  {/*  value={this.state['categories']}*/}
                  {/*  onChange={(e, a) => this.onChange('categories')({ target: { value: a } })}*/}
                  {/*/>*/}
                </PreviewWrapper>
              </FormField>

              <FormField name={'Link'}>
                <PreviewWrapper
                  preview={!edit}
                  kind={'string'}
                  value={this.state['link']}
                >

                  <TextField
                    error={errors.link}
                    helperText={errors.link}
                    disabled={this.disableLink()}
                    fullWidth
                    data-cy="linkChannelEdit"
                    label="Link"
                    m={2}
                    value={this.state['link']}
                    onChange={this.onChange('link')}
                  />
                </PreviewWrapper>
              </FormField>

              {
                this.state['brand'] && this.state['brand'].id && (
                  <FormField name={'Brand'}>
                    <PreviewWrapper
                      preview={false}
                    >
                      <DetailsLink
                        details={this.state['brand']}
                        link={{ title: 'Brand', pattern: `/brands/{id}`, keys: { id: 'brand' } }}
                        value={this.state['brand']}
                      />
                    </PreviewWrapper>
                  </FormField>
                )
              }

              {
                this.state['partner'] && this.state['partner'].id && (
                  <FormField name={'Partner'}>
                    <PreviewWrapper
                      preview={false}
                    >
                      <DetailsLink
                        details={this.state['partner']}
                        link={{ title: 'Partner', pattern: `/partner/{id}`, keys: { id: 'partner' } }}
                        value={this.state['partner']}
                      />
                    </PreviewWrapper>
                  </FormField>
                )
              }



              {/*{*/}
              {/*  !this.props.channel.parentChannel && <>*/}
              {/*    <FormField name={'Business Models'}>*/}
              {/*      <PreviewWrapper*/}
              {/*        preview={!edit}*/}
              {/*        kind={'chips'}*/}
              {/*        value={this.state['businessModels']}*/}
              {/*      >*/}
              {/*        <Autocomplete*/}
              {/*          placeholder={'Business Models'}*/}
              {/*          options={configGlobal.BUSINESS_MODELS}*/}
              {/*          value={this.state['businessModels']}*/}
              {/*          onChange={(e, a) => this.onChange('businessModels')({ target: { value: a } })}*/}
              {/*        />*/}
              {/*      </PreviewWrapper>*/}
              {/*    </FormField>*/}

              {/*    {*/}
              {/*      this.state['businessModels'] && this.state['businessModels'].map(model =>*/}
              {/*        ['b2b', 'b2c'].indexOf(model.value) > -1 &&*/}
              {/*        <BusinessModel*/}
              {/*          client={client}*/}
              {/*          model={model.value}*/}
              {/*          title={`${client === 'brand' ? 'Customer' : 'Audience'} ${model.label}`}*/}
              {/*          preview={!edit}*/}
              {/*          {...this.state}*/}
              {/*          onChange={this.onChange} />*/}
              {/*      )*/}
              {/*    }*/}
              {/*  </>*/}
              {/*}*/}


            </Form>
          </Grid>

          {
            logo && <Grid item xs={4}>
              <LogoContainer>
                <img src={'https://via.placeholder.com/150'} height={150} width={150}/>
              </LogoContainer>
            </Grid>
          }

        </Grid>

      </Panel>
    )
  }
}

