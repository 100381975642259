import React from 'react'
import DashboardPage from '../../layouts/DashboardPage'
import DetailsForm from '../../components/Details'

const config = [
  { key: 'id', name: 'ID', type: 'string' },
  { key: 'createdAt', name: 'Signed up', type: 'string' },

  {
    key: 'outChannel', name: 'Partner', type: 'string',
    link: { title: 'Partner Channel', pattern: `/channels/{id}`, keys: { id: 'outChannel' } }
  },
  {
    key: 'inChannel', name: 'Brand', type: 'string',
    link: { title: 'Brand Channel', pattern: `/channels/{id}`, keys: { id: 'inChannel' } }
  },

  { key: 'adex', name: 'Adex', type: 'string' },
  { key: 'partnerId', name: 'Partner ID', type: 'string' },
  { key: 'count', name: 'Count', type: 'string' }
]

export default class Campaign extends React.Component {
  render () {
    const {
      details,
      loading
    } = this.props

    return (
      <DashboardPage
        kind="details"
        title={"Campaign"}
        noActions
        thinking={loading}
      >
        <DetailsForm
          notEditable
          config={config}
          details={details}
        />
      </DashboardPage>
    )
  }
}
