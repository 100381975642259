import AccessControl from 'accesscontrol'
import administrator from './administrator'
import discoveryAdministrator from './discoveryAdministrator'

const grantsObject = {
  administrator,
  discoveryAdministrator
}

export default new AccessControl(grantsObject)
