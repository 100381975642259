import React from 'react'
import List from '../../components/List'
import DashboardPage from '../../layouts/DashboardPage'

const columns = [
  { id: 'createdAt', disablePadding: true, label: 'Created' },

  {
    id: 'outChannelLink', disablePadding: true, label: 'Partner', kind: 'link',
    link: { title: 'Partner Channel', pattern: `/channels/{id}`, keys: { id: 'outChannelId' } }
  },
  {
    id: 'inChannelLink', disablePadding: true, label: 'Brand', kind: 'link',
    link: { title: 'Brand Channel', pattern: `/channels/{id}`, keys: { id: 'inChannelId' } }
  },

  { id: 'adexes', disablePadding: true, label: 'Adex' },
  { id: 'count', disablePadding: true, label: 'Count' }
]

export default class Campaigns extends React.Component {
  render () {
    return (
      <DashboardPage
        kind="list"
        title="Campaigns"
        noActions
      >
        <List
          columns={columns}
          {...this.props}
          emptyMessage={'No Partnerships matching the given criteria.'}
          searchable
        />
      </DashboardPage>
    )
  }
}
