import React from 'react'
import {
  Grid,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent,
  DialogTitle,
  Button as MuiButton,
  TextField as MuiTextField,
  CircularProgress,
} from '@material-ui/core'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import MuiAutocomplete from '@material-ui/lab/Autocomplete'
import { Get } from '../../utils/request'
import { dataToDetails } from '../../utils/contacts'

const Button = styled(MuiButton)(spacing)
const DialogActions = styled(MuiDialogActions)(spacing)
const TextField = styled(MuiTextField)(spacing)

const StyledOption = styled.li`
  cursor: pointer;
  display: flex;
  width: 100%;
  outline: 0;
  box-sizing: border-box;
  align-items: center;
  padding: 6px 16px;
  justify-content: space-between;
`

export default function EntityExistingModal(props) {
  const { open, onClose, onAdd } = props
  const [entity, setEntity] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [options, setOptions] = React.useState([])

  React.useEffect(() => {
    onSearch({ target: { value: '' } })
  }, [])

  const onSearch = async ({ target: { value } }) => {
    setLoading(true)
    const { entities } = await Get(
      `/api/admin/entities?filters[search]=${value}`
    )
    setOptions(
      entities.map((entity) => {
        const { id, data } = entity
        const { companyEmail, company } = dataToDetails(data)

        return {
          id,
          label: companyEmail ? `${companyEmail} - ${company}` : company,
        }
      })
    )
    setLoading(false)
  }

  const onChange = (e, value) => setEntity(value)

  const onCancel = () => {
    setEntity(null)
    onClose()
  }

  const onAddEntity = () => {
    props.onAttach(entity)
    setEntity(null)
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby='form-dialog-title'
      maxWidth='sm'
      fullWidth
    >
      <DialogTitle id='form-dialog-title'>Add Entity</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MuiAutocomplete
              onChange={onChange}
              getOptionLabel={({ n, label }) => `${n ? `${n} - ` : ``}${label}`}
              renderOption={(option) => (
                <StyledOption>
                  <div>{option.label}</div>
                  <div style={{ color: 'slategrey' }}>{`${
                    option.n ? `ID ${option.n}` : ''
                  }`}</div>
                </StyledOption>
              )}
              options={options}
              ChipProps={{ size: 'small' }}
              loading={loading}
              value={entity}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant='standard'
                  placeholder={'Select existing entity'}
                  onChange={onSearch}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color='inherit' size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color='primary'>
          Cancel
        </Button>
        <Button onClick={onAddEntity} color='primary'>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}
