import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import is from 'is_js'
import { Button, CardActions, Grid, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { Warning } from '@material-ui/icons'
import DashboardPage from '../../layouts/DashboardPage'
import DetailsForm from '../../components/Details'
import Panel from '../../components/Panel'
import TextRows from '../../components/TextRows'
import LoaderOverlay from '../../components/LoaderOverlay'
import MultiField from '../../components/MultiField'
import ConfirmDialog from '../../components/ConfirmDialog'
import { RejectButton } from '../../components/Buttons'

const config = [
  { key: 'id', name: 'ID', type: 'string' },
  { key: 'name', name: 'Name', creatable: true, editable: true, type: 'string' },
  { key: 'affiliateProgramSignupURL', name: 'Affiliate program\'s signup URL', creatable: true, editable: true, type: 'string' },
  { key: 'partnerId', name: 'Partner ID', creatable: true, editable: true, type: 'string' },
  { key: 'campaignId', name: 'Campaign ID', creatable: true, editable: true, type: 'string' },
  { key: 'ready', name: 'Ready', creatable: true, editable: true, type: 'bool' },
  { key: 'published', name: 'Published', type: 'bool' },
  { key: 'kind', name: 'Kind', editable: true, creatable: true, type: 'select',
    options: [
      { value: 'adex', label: 'Adex' },
      { value: 'direct', label: 'Direct' },
      { value: 'indie', label: 'Indie' },
      { value: 'saas', label: 'SaaS' },
      { value: 'tracker', label: 'Tracker' },
    ]
  },
  { key: 'description', name: 'Description', editable: true, creatable: true, type: 'text' },
]

const DETAILS_REQUIRED_FIELDS = ['name']

const TextRowsWrapper = styled.div`
  //padding-bottom: 50px;
  height: ${props => props.kind === 'websites' ? '250px' : '500px'};
  margin: 5px 10px 20px 10px;
  ${props => props.kind === 'websites' && css`
    padding-right: 15px;
    padding-left: 15px;
  `}
`

const TextRowsPanel = ({ kind, editable, edit, title, errors, onEdit, onCancel, onUpdate, children }) => {
  const Actions = () => editable && edit ? (
    <CardActions style={{justifyContent: 'flex-end', borderTop: '1px #eee solid'}}>
      <Button variant="outlined" onClick={onCancel}>
        Cancel
      </Button>
      <Button disabled={errors && errors.length} onClick={onUpdate} variant="contained" color="primary">
        Save
      </Button>
    </CardActions>
  ) : null

  return (
    <Panel
      editable={editable}
      edit={edit}
      title={title}
      onEdit={onEdit}
      actions={Actions}
    >
      <TextRowsWrapper kind={kind}>
        {children}
      </TextRowsWrapper>
    </Panel>
  )
}

const validateWebsites = (websites = []) => {
  let hasErrors = false
  let errors = {}

  if (!websites.length || (websites.length === 1 && !websites[0].value)) {
    errors['0'] = 'Please enter URL'
    return errors
  }

  websites.forEach((website, index) => {
    const { value } = website
    if (!is.url(value)) {
      hasErrors = true
      errors[index] = 'Please enter correct url'
    }
  })

  return hasErrors ? errors : null
}

export default class Signature extends React.Component {
  state = { open: false, detailsErrors: {}, signatureErrors: [], signatureApiErrors: [], websitesErrors: {}, details: { kind: 'adex' }, signatures: [], websites: [] }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (!prevProps.loaded && this.props.loaded) {
      const { details, signatures, websites } = this.props
      this.setState({ details, signatures, websites })
    }
  }

  onOpen = () => this.setState({ open: true })

  onEdit = (kind) => () => {
    this.setState({ [`${kind}Edit`]: true })
  }

  onDetailsChange = (kind) => (field, value) => {
    const { details, detailsErrors } = this.state
    delete detailsErrors[field]
    return this.setState({ details: {...details, [field]: value }, detailsErrors})
  }

  onChange = kind => (value, index) => {
    if (kind === 'websites') {
      let { websitesErrors } = this.state
      delete websitesErrors[index]
      this.setState({ websitesErrors })
    } else if (kind === 'signatures') {
      const signatureErrors = index
      this.setState({ signatureErrors })
    }
    this.setState({ [kind]: value })
  }

  onValidateDetails = () => {
    const { name, partnerId, kind } = this.state.details
    let detailsErrors = {}
    let valid = true

    if (!name) {
      detailsErrors['name'] = 'Signature name is required'
      valid = false
    }
    if (kind === 'direct' && !partnerId) {
      detailsErrors['partnerId'] = 'Partner ID is required for Ditect signature'
      valid = false
    }
    this.setState({ detailsErrors })
    return valid
  }

  onUpdate = (kind) => async () => {
    let error = false
    let websitesErrors = null

    if (!this.onValidateDetails()) {
      return { success: false }
    }

    if (kind === 'websites') {
      websitesErrors = validateWebsites(this.state[kind])
      error = Boolean(websitesErrors)
    }

    if (!error) {
      const { success, message } = await this.props.onUpdate({ [kind]: this.state[kind] })
      this.setState({
        [`${kind}Edit`]: !success,
        ...(message && { signatureApiErrors: message })
      })

      if (success) {
        this.setState({ signatureApiErrors: [] })
      }

      return { success }
    }

    this.setState({ websitesErrors })

    return { success: false }
  }

  onCreate = async () => {
    if (!this.onValidateDetails()) {
      return { success: false }
    }

    const { details, signatures, websites } = this.state
    const { create } = this.props
    const websitesErrors = create ? false : validateWebsites(websites)
    if (!websitesErrors) {
      const { success, message } = await this.props.onCreate({ ...details, signatures, websites })
      if (!success && message) {
        this.setState({ signatureApiErrors: message })
      } else {
        this.setState({ signatureApiErrors: [] })
      }
    }
    this.setState({ websitesErrors })
  }

  onCancel = (kind) => () => {
    this.setState({ [kind]: this.props[kind], [`${kind}Edit`]: false })
  }

  render () {
    const {
      details,
      detailsErrors,
      signatures,
      signaturesEdit,
      websites,
      websitesEdit,
      websitesErrors,
      signatureErrors,
      signatureApiErrors
    } = this.state
    const {
      id,
      create,
      loading,
      thinking
    } = this.props

    const signatureApiErrorsFiltered = signatureApiErrors.filter(({ domain }) => signatures.includes(domain))

    const combineSignatureErrors = () => {
      let errors = [...signatureErrors]
      signatureApiErrors.map(({ domain }) => {
        const index = signatures.indexOf(domain)
        if (index >= 0 && errors.indexOf(index) < 0) {
          errors.push(index)
        }
      })
      return errors
    }

    return (
      <DashboardPage
        kind="details"
        title={create ? "New signature" : details.name || 'Signature'}
        status={id}
        thinking={loading}
        noActions
        actions={() => {
          if (create) {
            return <Button color={'primary'} variant={'contained'} onClick={this.onCreate}>Create</Button>
          }

          return (
            <ConfirmDialog
              title={'Delete Signature'}
              confirmation
              confirmationText={'Are you sure you want to delete this signature? It will remain in the database but will be hidden in from the list.'}
              action={this.props.onRemove}
              confirmButtonLabel={'Delete'}
              confirmButtonProps={{
                variant: 'contained',
                color: 'primary'
              }}
            >
              <RejectButton color="default" mr={2}>Delete</RejectButton>
            </ConfirmDialog>
          )
        }}
      >

        { loading && <LoaderOverlay /> }

        {
          !loading && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DetailsForm
                  hideCreateButtons={create}
                  create={create}
                  thinking={thinking || loading}
                  config={config}
                  details={details}
                  errors={detailsErrors}
                  onChange={this.onDetailsChange('details')}
                  onUpdate={this.onUpdate('details')}
                />
              </Grid>

              <Grid item xs={12}>
                <TextRowsPanel
                  kind={'websites'}
                  editable={!create}
                  edit={websitesEdit && !create}
                  title={'Websites URLs'}
                  onUpdate={this.onUpdate('websites')}
                  onCancel={this.onCancel('websites')}
                  onEdit={this.onEdit('websites')}
                >
                  <MultiField
                    kind={'websites'}
                    values={websites.length ? websites : [{ id: (new Date()).getTime() }]}
                    disabled={create ? false : !websitesEdit}
                    errors={websitesErrors}
                    placeholder={'e.g. https://breezy.io'}
                    onChange={this.onChange('websites')}
                  />
                </TextRowsPanel>
              </Grid>

              <Grid item xs={12}>
                <TextRowsPanel
                  editable={!create}
                  edit={signaturesEdit}
                  title={'Signatures'}
                  errors={signatureErrors}
                  onUpdate={this.onUpdate('signatures')}
                  onCancel={this.onCancel('signatures')}
                  onEdit={this.onEdit('signatures')}
                >

                    <Grid container spacing={1} style={{ height: '100%' }}>
                      <Grid item xs={signatureApiErrorsFiltered.length ? 7 : 12}>
                        <TextRowsWrapper>
                        <TextRows
                          thinking={thinking}
                          disabled={!signaturesEdit && !create}
                          validation={'competitors'}
                          helpText={'Type one signature per line'}
                          // title={'Signatures'}
                          placeholder={'e.g. signature'}
                          values={signatures}
                          errors={combineSignatureErrors()}
                          onChange={this.onChange('signatures')}
                        />
                        </TextRowsWrapper>
                      </Grid>
                      {
                        Boolean(signatureApiErrorsFiltered.length) && (
                          <Grid item xs={5}>
                            <List>
                              {
                                signatureApiErrorsFiltered.map(({ domain, signatureName, signatureId }) => (
                                  <ListItem component={Link} target={'_blank'} to={{ pathname: `/signatures/${signatureId}`}}>
                                    <ListItemIcon>
                                      <Warning fontSize={'small'} color={'error'}/>
                                    </ListItemIcon>
                                    <strong>{domain}</strong>&nbsp; already exist in &nbsp;<strong>{signatureName}</strong>
                                  </ListItem>
                                ))
                              }
                            </List>
                          </Grid>
                        )
                      }
                    </Grid>



                </TextRowsPanel>
              </Grid>
            </Grid>
          )
        }
      </DashboardPage>
    )
  }
}
