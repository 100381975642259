import React from 'react'
import styled from 'styled-components'
import PlaceholderIcon from '@material-ui/icons/Language'
import MuiImage from 'material-ui-image'
import cleanUrl from '../utils/cleanUrl'
import config from '../config'

const imageKitUrl = config.IMAGEKIT_URL
const retina = window.devicePixelRatio > 1 // TODO: Optimize this by moving to the app level
const imageWidth = 16

const Thumbnail = styled.div`
  width: ${imageWidth}px;
  height: auto;
  margin-right: 0.5em;
`
const Loader = styled(PlaceholderIcon)`
  width: 16px;
  height: 16px; 
  color: ${props => props.theme.palette.grey[300]}
`
function thumbnail(URL) {
  const imagePath = cleanUrl(URL, true)
  return imagePath ? `${imageKitUrl}/${imagePath}?tr=w-${retina ? imageWidth * 2 : imageWidth}` : ''
}

const isImageLoaded = src => {
  const img = new Image()
  img.src = src
  const complete = img.complete
  img.src = ''
  return complete
}

const Icon = ({item}) => {
  const src = thumbnail(item.icon)
  const isLoaded = isImageLoaded(src)

  return (
    <Thumbnail>
      <MuiImage
        src={src}
        loading={!isLoaded && <Loader/>}
        errorIcon={<Loader/>}
        disableTransition={isLoaded}
      />
    </Thumbnail>
  )
}


export default Icon
