import React from 'react'
import { observer } from 'mobx-react'
import BrandUsers from './BrandUsers'
import BrandUsersStore from '../../stores/brand-users'


@observer
class _BrandUsers extends React.Component {
  constructor (props) {
    super(props)
    const { brandId } = props
    this.store = new BrandUsersStore(brandId)
  }

  onInvite = details => this.store.invite(details)
  onRemove = id => this.store.remove(id)

  onChangeRole = (userId, accessId, role) => this.store.updateRole(userId, accessId, role)

  render () {
    const {
      users,
      thinking
    } = this.store

    return <BrandUsers
      users={users}
      brandId={this.props.brandId}
      thinking={thinking}
      onInvite={this.onInvite}
      onRemove={this.onRemove}
      onChangeRole={this.onChangeRole}
    />
  }
}

export default _BrandUsers
