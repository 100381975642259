import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import styled from "styled-components"

const Container = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
`

const LoaderOverlay = props => {
  return <Container><CircularProgress size={20} color={'secondary'} /></Container>
}

export default LoaderOverlay
