import React from 'react'
import {
  Button,
  TableRow as MuiTableRow,
  TableCell, TableCell as MuiTableCell
} from '@material-ui/core'
import styled from 'styled-components'
import List from '../../components/List'
import { RejectButton } from '../../components/Buttons'
import DashboardPage from '../../layouts/DashboardPage'
import ConfirmDialog from '../../components/ConfirmDialog'


const TableTotal = styled(MuiTableRow)`
  cursor: default;
  background-color: ${props => props.theme.table.footer.background};
  color: ${props => props.theme.table.footer.color};
  & > td {
  color: inherit;
  }
`

// brand: "CeEIsGE_h"
// brandName: "Lifted TEST"
// createdAt: "2021-05-27T14:35:45.678Z"
// data: {listName: "test", number: 2}
// id: "60afae416911163a2950ff02"
// kind: "leadsAddedToList"
// user: "h8zu5UZz_"
// userName: "Something New"

const columns = [
  { id: 'createdAt', disablePadding: true, label: 'Created at', nowrap:true },
  { id: 'kind', disablePadding: true, label: 'Kind' },
  {
    id: 'brandName', disablePadding: true, label: 'Brand', kind: 'link',
    link: { title: 'Brand', pattern: `/brands/{id}`, keys: { id: 'brand' } }
  },
  {
    id: 'userName', disablePadding: true, label: 'User', kind: 'link',
    link: { title: 'User', pattern: `/users/{id}`, keys: { id: 'user' } }
  }
]

const EventsAction = (props) => {
  const { selected, onClick } = props

  return <React.Fragment>
    <ConfirmDialog
      title={`Reject reason for ${selected.length} item${selected.length > 1 ? 's': ''}`}
      action={(reason) => onClick(selected, 'rejected', reason)}
      confirnButtonLabel={'Reject'}
      confirnButtonProps={{
        variant: 'contained',
        color: 'primary'
      }}
    >
      <RejectButton
        variant="contained"
        color="primary"
      >
        Reject
      </RejectButton>
    </ConfirmDialog>
    <span>&nbsp;</span>
    <Button
      variant="contained"
      color="secondary"
      onClick={() => onClick(selected, 'approved')}
    >
      Approve
    </Button>
  </React.Fragment>
}



export default class Events extends React.Component {
  render () {
    const {
      filteredSearchProps,
      importLink,
      onBulkAction,
      onChangeFilters,
      totalEvents
    } = this.props

    const TotalRow = () => (
      <TableTotal>
        <MuiTableCell colSpan={8}>Total</MuiTableCell>
        <MuiTableCell align='right'>{totalEvents.cost}</MuiTableCell>
        <MuiTableCell align='right'>{totalEvents.revenue}</MuiTableCell>
        <MuiTableCell align='right'>{totalEvents.amount}</MuiTableCell>
        <MuiTableCell align='right'>{totalEvents.profit}</MuiTableCell>
        <MuiTableCell />
      </TableTotal>
    )

    return (
      <DashboardPage
        kind="list"
        title="Events"
        // addTarget={'_new'}
        // addLink={importLink}
        // addLabel="Export to CSV"
        noActions
      >
        <List
          {...this.props}
          columns={columns}
          searchable
          // bulkOperations
          filteredSearchProps={filteredSearchProps}
          emptyMessage={'No events matching the given criteria.'}
          // toolbarActions={EventsAction}
          onChangeFilters={onChangeFilters}
          // onToolbarActionClick={onBulkAction}
          // totalRow={TotalRow}
        />
      </DashboardPage>
    )
  }
}
