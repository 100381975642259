import { Delete, Get, Post, Put } from '../utils/request'
import { observable, action, computed, toJS } from 'mobx'
import { formatDate } from '../utils/format'
import hasError from './request-message'

const providers = ['facebook', 'google', 'twitter']

const Notification = { show: console.log }

class UserStore {
  @observable id
  @observable email
  @observable firstName
  @observable lastName
  @observable fullName
  @observable role
  @observable brands = []
  @observable status
  @observable emailStatus
  @observable createdAt

  @observable loading = true
  @observable thinking = false

  constructor (id) {
    if (id !== 'invite') {
      this.id = id
      this.load().then()
    }
  }

  @action
  async load () {
    const { errorCode, user, /*balance,*/ message } = await Get(`/api/admin/users/${this.id}`)

    if (errorCode) {
      return message
    }

    for (let key in user) {
      if (user.hasOwnProperty(key)) {
        this[key] = user[key]
      }
    }

    this.loading = false
  }

  @action
  async update (details, notification) {
    const { errorCode, message, user, success } = await Put(`/api/admin/users/${this.id}`, { user: details })

    if (errorCode) {
      return message
    }

    for (let key in user) {
      if (user.hasOwnProperty(key)) {
        this[key] = user[key]
      }
    }
    return { success }
  }

  //deploy to test

  @action
  async setPassword (details) {
    const { errorCode, message, success } = await Put(`/api/admin/users/${this.id}/change-password`, details)
    hasError(!errorCode, message, `User password successfully updated`)

    return { error: errorCode, success, message }
  }

  @action
  async getToken () {
    const { token, message, success } = await Get(`/api/admin/users/${this.id}/token`)
    hasError(success, message, ``)
    return { token }
  }

  @computed
  get details () {
    const { id, email, firstName, lastName, fullName, role, brands, createdAt, status, emailStatus } = this
    return { id, email, firstName, lastName, fullName, role, brands: toJS(brands), createdAt: formatDate(createdAt), status, emailStatus }
  }
}

export default UserStore
