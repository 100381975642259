import { action, computed, observable, toJS } from 'mobx'
import { Delete, Get, Post, Put } from '../utils/request'
import hasError from './request-message'
import { formatDate } from '../utils/format'
import { dataToDetails, contactToPayload } from '../utils/contacts'

export class ChannelContactsStore {
  @observable id
  @observable contactList = []
  @observable loading = true
  @observable thinking = false
  constructor(id) {
    this.id = id
    this.load().then()
  }
  @action
  async load() {
    const { errorCode, contacts, message } = await Get(
      `/api/admin/contacts?filters[channelId]=${this.id}`
    )
    if (!hasError(!errorCode, message)) {
      this.contactList = contacts
    }
    this.loading = false
  }

  @action
  async create(details, publish) {
    const { success, contact } = await Post(`/api/admin/contacts`, {
      contact: { data: contactToPayload(details) },
    })
    if (success) {
      if (publish) await this.publish(contact.id)
      await this.load()
    }
    return { success, contact }
  }

  async publish(contactId) {
    const { success, message } = await Put(
      `/api/admin/contacts/${contactId}/publish`,
      {}
    )

    if (!hasError(success, message, 'Contact successfully published')) {
      await this.load()
    }
    return { success }
  }

  @action
  async attachToChannel(channelId, contactId) {
    const { success } = await Put(
      `
      /api/admin/contacts/${contactId}/attach-channel/${channelId}
      `,
      {}
    )

    if (success) {
      await this.load()
    }
    return { success }
  }

  @action
  async attachToEntity(entityId, contactId) {
    const { success } = await Put(
      `
      /api/admin/entities/${entityId}/attach-contact/${contactId}
      `,
      {}
    )

    if (success) {
      await this.load()
    }
    return { success }
  }

  @action
  async detachFromChannel(contactId) {
    const { success } = await Put(
      `
      /api/admin/contacts/${contactId}/detach-channel/${this.id}
      `,
      {}
    )

    if (success) {
      await this.load()
    }
    return { success }
  }

  @computed
  get contacts() {
    return toJS(this.contactList).map((contact) => {
      const { id, data, channel, createdAt, status } = contact

      return {
        id,
        channel: (channel || {}).id,
        channelName: (channel || {}).domain,
        createdAt: formatDate(createdAt),
        ...dataToDetails(data),
        status,
      }
    })
  }
}

export class ChannelEntitiesStore {
  @observable id
  @observable entityList = []
  @observable loading = true
  @observable thinking = false
  constructor(id) {
    this.id = id
    this.load().then()
  }
  @action
  async load() {
    const { errorCode, entities, message } = await Get(
      `/api/admin/entities?filters[channelId]=${this.id}`
    )
    if (!hasError(!errorCode, message)) {
      this.entityList = entities
    }
    this.loading = false
  }

  @computed
  get entities() {
    return toJS(this.entityList).map((entity) => {
      const { id, data, channel, createdAt, status } = entity

      return {
        id,
        channel: (channel || {}).id,
        channelName: (channel || {}).domain,
        createdAt: formatDate(createdAt),
        ...dataToDetails(data),
        status,
      }
    })
  }
}
