import React from 'react'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import List from '../../components/List'
import Panel from '../../components/Panel'
import { PartnerTrackingCampaignsStore } from '../../stores/tracking-campaigns'

@withRouter
@observer
class PartnerCampaigns extends React.Component {
  constructor (props) {
    super(props)
    const {clientId} = props

    this.onLoadStores(clientId)
  }

  onLoadStores = id => {
    this.store = new PartnerTrackingCampaignsStore(id)
  }

  // Campaigns
  onChangeSort = orderByNew => e => {
    const { listProps } = this.store
    const { order, orderBy } = listProps
    let orderNew = ''
    if (orderBy === orderByNew) {
      if (order === 'desc') {
        orderNew = 'asc'
      } else {
        orderNew = 'desc'
      }
    } else {
      orderNew = 'desc'
    }

    this.store.onChangeSort(orderNew, orderByNew)
  }

  onChangePage = (e, page) => this.store.onChangePage(page)

  onChangeRowsPerPage = ({ target: { value }}) => this.store.onChangeRowsPerPage(value)

  onChangeFilters = name => object => {
    let value = null
    if (object.hasOwnProperty('target')) {
      value = object.target.value
    } else {
      value = object
    }
    this.store.onChangeFilter(name, value)
  }

  onAddCampaign = () => this.props.history.push(`/tracking-campaigns/create`)
  onClickRow = (e, id) => this.props.history.push(`/tracking-campaigns/${id}`)

  render () {
    const {
      listProps
    } = this.store

    const campaignsListProps = {
      ...listProps,
      columns: [
        { id: 'n', disablePadding: true, label: 'ID' },
        {
          id: 'opportunityName', disablePadding: true, label: 'Opportunity', kind: 'link',
          link: { title: 'Opportunity', pattern: `/opportunities/{id}`, keys: { id: 'opportunity' } }
        },
        {
          id: 'brandName', disablePadding: true, label: 'Brand', kind: 'link',
          link: { title: 'Brand', pattern: `/brands/{id}`, keys: { id: 'brand' } }
        },
        { id: 'status', disablePadding: true, label: 'Status', kind: 'status' }
      ],
      onChangeSort: this.onChangeSort,
      onChangeFilters: this.onChangeFilters,
      onChangePage: this.onChangePage,
      onChangeRowsPerPage: this.onChangeRowsPerPage,
      onClickRow: this.onClickRow,
      onAddCampaign: this.onAddCampaign
    }

    return (
      <Panel
        title="Campaigns"
      >
        <List
          searchable
          {...campaignsListProps} />
      </Panel>
    )
  }
}

export default PartnerCampaigns
