import React from 'react'
import { find, findIndex } from 'lodash'
import TableCellLink from './TableCellLink'
import is from 'is_js'
import {
  Collapse,
  Paper,
  Grid,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Switch,
  FormControlLabel,
  Chip,
  TextField,
  IconButton,
  InputAdornment,
  MenuItem,
  Button as MuiButton,
  Typography,
  Table as MuiTable,
  TableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell, Tooltip
} from '@material-ui/core'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import {
  PostAddOutlined as MuiTableIcon,
  ChevronRight as MuiChevronRight,
  KeyboardArrowDown as MuiChevronDown,
  CheckCircleOutline
} from '@material-ui/icons'
import Panel from './Panel'
import Autocomplete, { Asynchronous } from './Autocomplete'
import config from '../config'
import { Edit as IconEdit, Delete as IconDelete } from '@material-ui/icons'
import {ChevronsRight as MuiChevronsRight} from 'react-feather'
import ChannelKind from './ChannelKind'
import ChannelContent from './ChannelContent'

const Edit = styled(IconEdit)`
width: 16px;
height: 16px;
`
const Delete = styled(IconDelete)`
width: 16px;
height: 16px;
`

const Button = styled(MuiButton)(spacing)
const DialogActions = styled(MuiDialogActions)(spacing)

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${props => props.theme.spacing(12)}px);
`

const TableRow = styled(MuiTableRow)`
  cursor: ${props => !props.disabled && 'pointer'};
`

const TableEmpty = styled.div`
  padding: 2em 2em 4em 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 5em;
  flex-direction: column;
`

const TableIcon = styled(MuiTableIcon)`
  width: 2.5em;
  height: 2.5em;
  display: block;
  margin-bottom: 0.5em;
  color: ${props => props.theme.palette.grey[500]}
`

const ChevronRight = styled(MuiChevronRight)`
    color: rgba(0, 0, 0, 0.87);
    width: 0.8em;
    height: 0.8em;
`
const VerifiedIcon = styled(CheckCircleOutline)`
    color: rgba(0, 0, 0, 0.87);
    width: 1.2em;
    padding-left: 10px;
`

const ChevronsRight = styled(MuiChevronsRight)`
    color: rgba(0, 0, 0, 0.87);
    width: 1.3em;
    height: 1.3em;
`
const ChevronDown = styled(MuiChevronDown)`
    color: rgba(0, 0, 0, 0.87);
    width: 0.8em;
    height: 0.8em;
`
const CollapseContent = styled.div`
  display: flex;
  flex-direction: column;
  //align-items: center;
  //padding: 23px;
`

const kinds = config.CHANNELS

const emptyChannel = { id: null, name: null, kind: 'website', link: null, verified: false, businessModels: [], errors: {} }

export class ChannelDialog extends React.Component {
  constructor (props) {
    super(props)
    this.state = emptyChannel
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (!prevProps.open && this.props.open) {
      if (!this.props.channel) {
        this.setState({ ...emptyChannel, errors: {} })
      } else {
        this.setState({ ...this.props.channel, errors: {} })
      }
    }
  }

  onSave = () => {
    let hasErrors = false
    let errors = {}

    const { id, name, kind, link, businessModels, ownerKind, owner, verified, channel } = this.state

    // if (this.props.list && !ownerKind) {
    //   hasErrors = true
    //   errors['ownerKind'] = 'Owner kind is required'
    // }
    // if (this.props.list && ownerKind && (!owner || !owner.id)) {
    //   hasErrors = true
    //   errors['owner'] = 'Channel owner is required'
    // }

    if (!name && kind !== 'website') {
      hasErrors = true
      errors['name'] = 'Name is required'
    }
    if (!kind) {
      hasErrors = true
      errors['kind'] = 'Channel kind is required'
    }
    if (!this.disableLink()) {
      if (!link) {
        errors['link'] = 'Link is required for selected channel kind'
        hasErrors = true
      } else
      if (!is.url(link)) {
        hasErrors = true
        errors['link'] = 'Please enter correct url'
      }
    }
    if (!hasErrors) {
      this.props.onSave({
        id, name, kind, link, businessModels, verified,
        ...((kind === 'website' && !name) && { name: link }),
        ...(channel && { channel }),
        ...((this.props.list && !!owner) && { [ownerKind]: owner.id })
      })
    } else {
      this.setState({ errors })
    }
  }

  onRemove = () => this.props.onRemove(this.state.id)

  onChange = field => ({ target: { value, checked } }) => {
    if (field === 'verified') {
      this.setState({ [field]: checked })
    } else if (field === 'kind' && this.disableLink(value)) {
      this.setState({ [field]: value, link: '' })
    } else if (field === 'ownerKind') {
      this.setState({ [field]: value, owner: null })
    } else {
      this.setState({ [field]: value })
    }
    this.resetError(field)
  }

  resetError = (key) => {
    const { errors } = this.state
    this.setState({ errors: {...errors, [key]: null } })
  }

  disableLink = (_kind) => {
    const kind = _kind || this.state.kind
    if (!kind) {
      return true
    }
    const kindOption = find(config.CHANNELS, ['value', kind])

    return kindOption && !kindOption.hasLink
  }

  render () {
    const {
      open,
      discovery
    } = this.props

    const { id, name, kind, link, businessModels, verified, owner, ownerKind, errors } = this.state

    const businessModelsFilter = (values = [], models = []) =>
      models.filter(model => findIndex(values, ['value', model.value]) < 0)

    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        data-cy="channelDialog"
        open={open}
        onClose={this.props.onCancel}
        onBackdropClick={this.props.onCancel}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          Channel
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={3}>
            {
              !discovery && (
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Channel verification</FormLabel>
                    <FormControlLabel
                      control={<Switch checked={verified} onChange={this.onChange('verified')} />}
                      label="Verified"
                    />
                  </FormControl>
                </Grid>
              )
            }


            <Grid item xs={4}>
              <TextField
                error={errors.kind}
                helperText={errors.kind}
                select
                fullWidth
                label="Kind"
                data-cy="channelKind"
                m={2}
                value={kind}
                onChange={this.onChange('kind')}
              >
                {
                  kinds.map(({ label, value }) => <MenuItem data-cy={"kind"+value}  key={value} value={value}>{label}</MenuItem>)
                }
              </TextField>
            </Grid>
            <Grid item xs={8}>
              <TextField
                error={errors.link}
                helperText={errors.link}
                disabled={this.disableLink()}
                fullWidth
                data-cy="channelLink"
                label="Link"
                m={2}
                value={link}
                onChange={this.onChange('link')}
              />

            </Grid>

            <Grid item xs={12}>
              <TextField
                error={errors.name}
                helperText={errors.name}
                fullWidth
                data-cy="channelName"
                label="Name"
                m={2}
                value={name}
                onChange={this.onChange('name')}
              />
            </Grid>

            {
              this.props.list && (
                <Grid item xs={12}>
                  <TextField
                    error={errors.ownerKind}
                    helperText={errors.ownerKind}
                    select
                    fullWidth
                    label="Brand/Partner"
                    m={2}
                    value={ownerKind}
                    onChange={this.onChange('ownerKind')}
                  >
                    <MenuItem value={'brand'}>{'Brand'}</MenuItem>
                    <MenuItem value={'partner'}>{'Partner'}</MenuItem>
                  </TextField>
                </Grid>
              )
            }
            {
              this.props.list && ownerKind && (
                <Grid item xs={12}>
                  <Asynchronous
                    error={errors.owner}
                    url={`/api/admin/${ownerKind}s`}
                    filter="filters[name]"
                    placeholder={`Choose channel owner`}
                    dataField={`${ownerKind}s`}
                    dataLabelField="name"
                    value={owner}
                    defaultValue={owner}
                    onChange={(e, a) => this.onChange('owner')({ target: { value: a } })}
                  />
                </Grid>
              )
            }

            <Grid item xs={12}>
              <Autocomplete
                placeholder={'Business Models'}
                options={businessModelsFilter(businessModels, config.BUSINESS_MODELS)}
                value={businessModels}
                onChange={(e, a) => this.onChange('businessModels')({ target: { value: a } })}
              />
            </Grid>
          </Grid>

        </DialogContent>

        <DialogActions px={6} py={4}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              {
                id && <Button onClick={this.onRemove} variant="outlined">
                  Remove Channel
                </Button>
              }
            </Grid>
            <Grid item xs={12} sm={6}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center">
              <Button onClick={this.props.onCancel} color="default" mr={2}>
                Cancel
              </Button>
              <Button onClick={this.onSave} variant="outlined" color="primary">
                Save Channel
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }
}

const ChevronIcon = ({ expanded }) => expanded ? <ChevronDown/> : <ChevronRight/>

const ChannelLinkRow = ({ item, type, discovery, parentId }) => {
  const url = `/${discovery ? 'discovery/' : ''}${type === 'partner' ? 'partners' : 'brands'}/${parentId}/${item.id}`
  return (
    <TableRow
      disabled={item.disabled}
      hover={!item.disabled}
      tabIndex={-1}
      key={item.id}
    >
      <TableCellLink colSpan={2} to={url}>
        <ChannelKind icon={item.icon} name={item.name} url={item.link} kind={item.kind} />

        { item.verified && <Tooltip arrow placement='right' title={'Verified'}><VerifiedIcon /></Tooltip>}
      </TableCellLink>
      <TableCellLink to={url} align="right" style={{width: 100, whiteSpace: 'nowrap'}}>
        <ChevronsRight/>
      </TableCellLink>
    </TableRow>
  )
}

const ChannelRow = ({ item, columns, similar, expanded, edited, client, discovery, onEdit, onSave, onRemove, onCancel, onAddSimilar, onExpand, type }) => item.parent ? (
  <TableRow
    disabled={item.disabled}
    tabIndex={-1}
    key={item.id}
  >
    <MuiTableCell
      colSpan={columns.length}
    >
      <Typography style={{fontWeight: 'bold', display: "flex", alignItems: 'center'}}  variant={"caption"} gutterBottom>
        Based on&nbsp;&nbsp;<ChannelKind kind={item.kind} url={item.link} name={item.name} />
      </Typography>
    </MuiTableCell>
  </TableRow>
) : [
  <TableRow
    disabled={item.disabled}
    hover={!item.disabled}
    tabIndex={-1}
    key={item.id}
  >
    <MuiTableCell onClick={() => onExpand(item.id)} align="left" style={{width: 10}}> <ChevronIcon show expanded={expanded} /></MuiTableCell>
    <MuiTableCell onClick={() => onExpand(item.id)}>
      <ChannelKind name={item.name} url={item.link} kind={item.kind} />
      { item.verified && <Tooltip arrow placement='right' title={'Verified'}><VerifiedIcon /></Tooltip>}
    </MuiTableCell>
    <MuiTableCell align="right" style={{width: 100, whiteSpace: 'nowrap'}}>
      { !similar && <Button variant="outlined" size="small" color="secondary" onClick={() => onAddSimilar(item.id)}>
        {type !== 'partner' ? 'Add Competitor' : 'Add Similar'}
      </Button> }&nbsp;&nbsp;&nbsp;&nbsp;
      <IconButton color="primary" onClick={() => onEdit(item.id)}>
        <Edit />
      </IconButton>
    </MuiTableCell>
  </TableRow>,
  <TableRow>
    <MuiTableCell
      style={{ paddingBottom: 0, paddingTop: 0, cursor: 'default' }}
      colSpan={columns.length}
    >
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
      >
        <CollapseContent>
          <ChannelContent
            client={client}
            similar={similar}
            discovery={discovery}
            channel={item}
            edit={edited}
            onUpdate={onSave}
            onCancel={onCancel}
            onRemove={onRemove}
          />

          {/*<Button onClick={() => onEdit(item.id)}>Edit</Button>*/}
          {/*<div>*/}

          {/*</div>*/}
        </CollapseContent>
      </Collapse>
    </MuiTableCell>
  </TableRow>
]

export default class Channels extends React.Component {
  static defaultProps = {
    items: [],
    emptyMessage: 'No Channels are added.',
    emptyActionText: 'Add a Channel',
    onEmptyAction: () => {}
  }

  state = { open: false, channel: null, expanded: null }

  onAdd = (id) => {
    this.setState({ open: true, channel: typeof id === 'string' ? { ...emptyChannel, channel: id } : undefined })
  }

  onSave = async ({ id, ...details }) => {
    const similar = this.props.channelsKind === 'similar'
    const { success } = id ? await this.props.onUpdate({ id, ...details, similar }) : await this.props.onCreate({ ...details })
    if (success) {
      this.onCancel()
    }
    return { success }
  }

  onRemove = async (id) => {

  }

  onEdit = (id) => {
    this.setState({ expanded: id, edited: id })
  }

  onCancel = () => this.setState({ open: false, channel: undefined, edited: null })

  onExpand = id => {
    const { expanded } = this.state
    this.setState({ expanded: expanded === id ? null : id, edited: null })
  }

  render () {
    const {
      open,
      channel,
      expanded,
      edited
    } = this.state

    const {
      thinking,
      channels,
      discovery,
      emptyMessage,
      emptyActionText,
      channelsKind,
      type,
      parentId
    } = this.props

    const similar = channelsKind === 'similar'

    return (
      <Panel
        title={ similar ? (type === 'partner' ? 'Similar Channels' : 'Competitors') : 'Channels' }
        adding
        dataCy="addChannels"
        thinking={thinking}
        onAdd={this.onAdd}
      >

        <Paper>
          {
            channels.length !== 0 && <TableWrapper>
              <MuiTable aria-labelledby="tableTitle">
                <TableBody>

                  {
                    channels.map(item => parentId ?
                      <ChannelLinkRow
                        item={item}
                        type={type}
                        discovery={discovery}
                        parentId={parentId}
                      />
                      :
                      <ChannelRow
                        client={type}
                        discovery={discovery}
                        type={type}
                        item={item}
                        similar={similar}
                        expanded={expanded === item.id}
                        edited={edited === item.id}
                        columns={[{}, {}, {}]}
                        onEdit={this.onEdit}
                        onRemove={this.props.onRemove}
                        onAddSimilar={this.onAdd}
                        onSave={this.onSave}
                        onCancel={this.onCancel}
                        onExpand={this.onExpand} />)
                  }

                </TableBody>
              </MuiTable>
            </TableWrapper>
          }
          {
            channels.length === 0 && <TableEmpty>
              { !!emptyActionText && <TableIcon /> }
              <Typography variant={"body1"}>{emptyMessage}</Typography>
              {
                !!emptyActionText && <Button mt={5} variant={"outlined"} color={"secondary"} onClick={this.onAdd}>{emptyActionText}</Button>
              }
            </TableEmpty>
          }

        </Paper>

        <ChannelDialog
          discovery={discovery}
          open={open}
          channel={channel}
          onSave={this.onSave}
          onRemove={this.onRemove}
          onCancel={this.onCancel}
        />
      </Panel>
    )
  }
}
