import React from 'react'
import styled, { withTheme } from 'styled-components'
import { Search as FeatherSearchIcon } from 'react-feather'
import { darken } from 'polished'
import { InputBase } from '@material-ui/core'
import {color} from "@material-ui/system";

const SearchIcon = styled(FeatherSearchIcon)`
color: #9E9E9E;
`

const SearchDiv = styled.div`
  border-radius: 2px;
  background-color: ${props => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  ${props => props.theme.breakpoints.up("md")} {
    //display: block;
    display: flex;
    flex-direction: row;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;
  
  > input {
    color: ${props => props.theme.header.search.color};
    padding-top: ${props => props.theme.spacing(2.5)}px;
    padding-right: ${props => props.theme.spacing(2.5)}px;
    padding-bottom: ${props => props.theme.spacing(2.5)}px;
    padding-left: ${props => props.theme.spacing(12)}px;
    width: 160px;
  }
`;

export default class Search extends React.Component {
  stopTyping = null
  onChange = ({ target: { value } }) => {
    clearTimeout(this.stopTyping)
    this.stopTyping = setTimeout(() => {
      this.props.onChange(value)
    }, 1000)
  }

  render () {
    const {
      placeholder
    } = this.props
    return (
      <SearchDiv>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <Input placeholder={placeholder} onChange={this.onChange}/>
      </SearchDiv>
    )
  }
}
