import React from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import { Visibility as VisibleIcon, VisibilityOff as HiddenIcon } from '@material-ui/icons'
import List from '../../components/List'
import Panel from '../Panel'

// brand: "CeEIsGE_h"
// channelId: "B1WrgCSPBw"
// competitorUrlsCount: 4
// country: "DE"
// createdAt: "2022-05-06T06:26:12.852Z"
// id: "HnvB9kYGBvmNhntusNmde"
// keywordsCount: 5
// name: "Lifted Website Test Sorting"
// researchDoneAt: "2022-07-29T17:28:03.152Z"
// researchStartAt: "2022-07-29T17:25:23.144Z"
// researchStatus: "researched"

const columns = [
  { id: 'createdAt', disablePadding: true, label: 'Started' },
  { id: 'name', disablePadding: true, label: 'Name' },
  { id: 'keywordsCount', disablePadding: true, label: 'Keywords' },
  { id: 'competitorsCount', disablePadding: true, label: 'Competitors' },
  { id: 'countryCode', disablePadding: true, label: 'Country' },
  { id: 'researchStatus', disablePadding: true, label: 'Status' },
  { id: 'actions', disablePadding: true, label: '', kind: 'actions' },
]

export default class BrandSearches extends React.Component {
  onToggleVisibility = item => {
    this.props.onToggleVisibility(item.id, item.hidden ? 'unhide' : 'hide')
  }

  render () {
    const { items } = this.props
    return (
      <Panel
        title="Searches"
      >
        <List
          columns={columns}
          {...this.props}
          items={items.map(item => ({ ...item, actions: () => (
              <Tooltip title={item.hidden ? 'Show search' : 'Hide search'}>
                <IconButton onClick={() => this.onToggleVisibility(item)}>
                  { item.hidden ? <HiddenIcon /> : <VisibleIcon /> }
                </IconButton>
              </Tooltip>
            ) }))}
          searchable
          emptyMessage={'No Researches matching for this Brand.'}
        />
      </Panel>
    )
  }
}
