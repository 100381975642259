import React, { useState } from 'react'
import { spacing } from '@material-ui/system'
import {
  Button as MuiButton,
  Chip,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core'
import styled from 'styled-components'
import { Delete as RemoveIcon } from '@material-ui/icons'

import ConfirmDialog from '../ConfirmDialog'
import List from '../List'
import Panel from '../Panel'
import { Asynchronous } from '../Autocomplete'

const columns = [
  { id: 'createdAt', disablePadding: true, label: 'Created' },
  { id: 'name', disablePadding: true, label: 'Name' },
  { id: 'description', disablePadding: true, label: 'Description' },
  { id: 'actions', numeric: 'right', kind: 'actions' }
]

const DialogActions = styled(MuiDialogActions)(spacing)

const Button = styled(MuiButton)(spacing)

const EcosystemsDialog = ({ open, ...rest }) => {
  const [thinking, setThinking] = useState(false)
  const [ecosystem, setEcosystem] = useState(null)

  const onChange = (value) => setEcosystem(value)

  const onCancel = () => {
    setEcosystem(null)
    rest.onClose()
  }

  const onAdd = async () => {
    setThinking(true)

    const { success } = await rest.onAdd(ecosystem)

    setThinking(false)

    if (success) {
      rest.onClose()
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      data-cy="channelDialog"
      open={open}
      onClose={onCancel}
      onBackdropClick={onCancel}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">
        Add Ecosystem to Brand
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Asynchronous
              fullWidth
              disabled={thinking}
              url={`/api/admin/ecosystems`}
              filter="filters[name]"
              placeholder={`Choose ecosystem`}
              dataField={`ecosystems`}
              dataLabelField="name"
              value={ecosystem}
              defaultValue={ecosystem}
              onChange={(e, a) => onChange(a)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions px={6} py={4}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Button
              mr={2}
              color="default"
              disabled={thinking}
              onClick={onCancel}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}
                container
                direction="row"
                justify="flex-end"
                alignItems="center">

            <Button disabled={thinking} onClick={onAdd} variant="outlined" color="primary">
              Add
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

const EcosystemMenu = ({ ecosystemId, onRemove }) => {

  return <>
    <ConfirmDialog
      title={'Remove Ecosystem from Brand'}
      confirmation
      confirmationText={'Are you sure you want to remove this ecosystem from brand?'}
      action={() => onRemove(ecosystemId)}
      confirmButtonLabel={'Remove'}
      confirmButtonProps={{
        variant: 'contained',
        color: 'primary'
      }}
    >
      <IconButton size={'small'}>
        <RemoveIcon fontSize={'small'}/>
      </IconButton>
    </ConfirmDialog>
  </>
}

export default class Ecosystems extends React.Component {
  state = { open: false }

  onOpen = () => this.setState({ open: true })

  onAdd = async (ecosystem) => {
    const { id: ecosystedId } = ecosystem
    return await this.props.onAdd(ecosystedId)
  }

  onSave = async (details) => {
    return await this.props.onCreate(details)
  }

  onRemove = async (id) => {
    return await this.props.onRemove(id)
  }

  onCancel = () => {
    this.setState({ open: false })
  }

  render () {
    const { open } = this.state
    const { items } = this.props

    return (
      <Panel
        title="Ecosystems"
        adding
        onAdd={this.onOpen}
      >
        <List
          disablePagination
          {...this.props}
          columns={columns}
          searchable={false}
          emptyMessage={'No ecosystems.'}
          items={items.map(item => ({...item, actions: () => <EcosystemMenu ecosystemId={item.id} onRemove={id => this.onRemove(id)} /> })) }
          emptyActionText={'Add Ecosystem'}
          onEmptyAction={this.onOpen}
        />

        {
          open && (
            <EcosystemsDialog
              open={open}
              onAdd={this.onAdd}
              onClose={this.onCancel}
            />
          )
        }
      </Panel>
    )
  }
}
