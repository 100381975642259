import List from './list'

export default class SaasEcosystemsStore extends List {
  url = '/api/admin/self-ecosystems'

  constructor() {
    super()
    this.order = 'desc'
    this.orderBy = 'createdAt'
    this.itemsField = 'ecosystems'
    this.getList().then()
  }
}
