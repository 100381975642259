import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent,
  DialogTitle, Grid,
  IconButton,
  Menu,
  MenuItem
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'

import List from '../../components/List'
import DashboardPage from '../../layouts/DashboardPage'
import { ChannelDialog } from '../../components/Channels'
import { Asynchronous } from '../../components/Autocomplete'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { formatNumber } from '../../utils/format'

const columns = [
  { id: 'status', disablePadding: true, label: 'Status', kind: 'status' },
  { id: 'domain', disablePadding: true, label: 'Link' },
  { id: 'createdAt', disablePadding: true, label: 'Created' },
  { id: 'kind', disablePadding: true, label: 'Kind' },
  { id: 'swGlobalRank', disablePadding: true, label: 'SimilarWeb Rank' },
  { id: 'mozDomainAuthority', disablePadding: true, label: 'Domain Authority' },
  { id: 'partnershipsOutCount', disablePadding: true, label: 'Partnerships' },
  { id: 'actions', disablePadding: true, label: '', kind: 'actions' },
]

const emptyChannel = { id: null, name: null, kind: null, link: null, verified: false, businessModels: [], errors: {} }

const DialogActions = styled(MuiDialogActions)(spacing)

const EcosystemsDialog = ({ open, channelId, ...rest }) => {
  const [thinking, setThinking] = useState(false)
  const [ecosystem, setEcosystem] = useState(null)

  const onChange = (value) => setEcosystem(value)

  const onCancel = () => {
    setEcosystem(null)
    rest.onClose()
  }

  const onAdd = async () => {
    setThinking(true)

    const { id: ecosystemId } = ecosystem

    const { success } = await rest.onAdd(ecosystemId, channelId)

    setThinking(false)

    if (success) {
      rest.onClose()
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      data-cy="channelDialog"
      open={open}
      onClose={onCancel}
      onBackdropClick={onCancel}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">
        Add Channel to Ecosystem
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Asynchronous
              fullWidth
              disabled={thinking}
              url={`/api/admin/ecosystems`}
              filter="filters[name]"
              placeholder={`Choose ecosystem`}
              dataField={`ecosystems`}
              dataLabelField="name"
              value={ecosystem}
              defaultValue={ecosystem}
              onChange={(e, a) => onChange(a)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions px={6} py={4}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Button
              mr={2}
              color="default"
              disabled={thinking}
              onClick={onCancel}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}
                container
                direction="row"
                justify="flex-end"
                alignItems="center">

            <Button disabled={thinking} onClick={onAdd} variant="outlined" color="primary">
              Add
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

const ChannelMenu = ({ channelId, onAdd }) => {
  const [open, setOpen] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)

  const onOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null)
  }

  const onOpenAddToEcosystem = () => {
    setOpen(true)
    onClose()
  }

  return <>
    <IconButton size={'small'} onClick={onOpen}>
      <MoreVert fontSize={'small'}/>
    </IconButton>
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      <MenuItem onClick={onOpenAddToEcosystem}>Add to Ecosystem</MenuItem>
      {/*<MenuItem onClick={onClose} disabled>Remove from Ecosystem</MenuItem>*/}
    </Menu>

    <EcosystemsDialog
      open={open}
      channelId={channelId}
      onAdd={onAdd}
      onClose={() => setOpen(false)}
    />
  </>
}

export default class Channels extends React.Component {
  constructor (props) {
    super(props)
    this.state = { open: false }
  }

  onAdd = (id) => {
    this.setState({ open: true, channel: typeof id === 'string' ? { ...emptyChannel, channel: id } : undefined })
  }

  onCreate = async ({ id, ...details }) => {
    return await this.props.onCreate(details)
  }

  onCancel = () => this.setState({ open: false, channel: undefined, edited: null })

  onAddToEcosystem = async (ecosystemId, channelId) => await this.props.onAddToEcosystem(ecosystemId, channelId)

  render () {
    const {
      open,
      channel
    } = this.state
    const {
      items
    } = this.props

    return (
      <DashboardPage
        kind="list"
        title="Channels"
        // noActions
        addLabel="Add Channel"
        addClick={this.onAdd}
        // addLink="/users/invite"
      >
        <List
          columns={columns}
          {...this.props}
          searchable
          items={items.map(({ id, swVisits, ...rest }) => ({ id, ...rest, swVisits: formatNumber(swVisits), actions: () => <ChannelMenu channelId={id} onAdd={this.onAddToEcosystem}/> }))}
          emptyMessage={'No Channels matching the given criteria.'}
          emptyActionText={'Add Channel'}
          onEmptyAction={this.onAdd}
        />

        <ChannelDialog
          list
          open={open}
          channel={channel}
          onSave={this.onCreate}
          onCancel={this.onCancel}
        />
      </DashboardPage>
    )
  }
}
