import React from 'react'
import styled from 'styled-components'
import { Button } from '@material-ui/core'
import TextRows from '../../components/TextRows'

const Root = styled.div`
  padding-bottom: 50px;
  height: calc(100vh - 164px);
`

export const ActionsHolder = styled('div')`
  display: flex;
  justify-content: ${props => props.right ? 'flex-end' : 'flex-start'};
  padding: ${props => props.borderTop ? '20px' : '35px 20px 20px 20px'};
  ${props => props.borderTop && 'border-top: 1px solid rgba(0, 0, 0, 0.1);'}
`

export default class HostBlocklist extends React.Component {
  constructor (props) {
    super(props)
    this.state = { hasChanges: false, list: props.list || [], errors: [], thinking: false }
  }

  onChange = (value, errors) => {
    this.setState({list: value, hasChanges: true, errors })
  }

  onSave = async () => {
    const { list } = this.state
    this.setState({ thinking: true })
    const { success } = await this.props.onSave(list)
    this.setState({ thinking: false, hasChanges: !success })
  }

  render () {

    const {
      list,
      hasChanges,
      errors,
      thinking
    } = this.state

    return <Root>
      <TextRows
        thinking={thinking}
        validation={'competitors'}
        helpText={'Type one URL per line'}
        title={'Host Blocklist URLs'}
        placeholder={'e.g. https://example.com'}
        values={list}
        errors={errors}
        onChange={this.onChange}
      />
      <ActionsHolder>
        {/*<ConfirmDialog*/}
        {/*  title={'Save Host Blocklist URLs'}*/}
        {/*  confirmation*/}
        {/*  confirmationText={'Adding this channel to blocklist will delete all Pages and Redirects. Already discovered Partnerships will be preserved.'}*/}
        {/*  action={this.onSave}*/}
        {/*  confirnButtonLabel={'Save'}*/}
        {/*  confirnButtonProps={{*/}
        {/*    variant: 'contained',*/}
        {/*    color: 'primary'*/}
        {/*  }}*/}
        {/*>*/}
          <Button
            disabled={!hasChanges || thinking || errors.length}
            variant="contained"
            color="primary"
            onClick={this.onSave}
          >
            Save
          </Button>
        {/*</ConfirmDialog>*/}
      </ActionsHolder>
    </Root>
  }
}
