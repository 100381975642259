import { action, computed, observable, toJS } from 'mobx'
import { find } from 'lodash'
import { Put } from '../utils/request'
import { formatCurrency, formatDateTime } from '../utils/format'
import { rangeDays } from '../utils/dates'
import List from './list'
import config from '../config'
import hasError from './request-message'

export default class EventsStore extends List {
  url = '/api/admin/events'

  @observable amountTotal
  @observable costTotal
  @observable profitTotal
  @observable revenueTotal

  constructor() {
    super()
    this.order = 'desc'
    this.orderBy = 'createdAt'
    this.itemsField= 'events'
    this.additionalFields = ['amountTotal', 'costTotal', 'profitTotal', 'revenueTotal']
    this.filters = {
      startAt: rangeDays(30)[0].date,
      endAt: rangeDays(30)[29].date
    }
    this.getList().then()
  }

  @action
  async setBulkStatus (ids, status, rejectReason) {
    this.thinking = true
    let events = {}
    ids.forEach(id => {
      const c = find(this.items, ['id', id])
      if (c && c.status !== 'paid') {
        events[id] = {
          status,
          ...(rejectReason && { rejectReason })
        }
      }

    })
    const { success, message } = await Put('/api/admin/events', events)
    hasError(success, message, `${ids.length} item${ids.length > 1 ? 's' : ''} successfully updated`)
    this.getList()
    this.thinking = false
    return success
  }

  @computed
  get totalEvents () {
    const {
      amountTotal,
      costTotal,
      profitTotal,
      revenueTotal
    } = this
    return {
      amount: formatCurrency((amountTotal || 0) / 100),
      cost: formatCurrency((costTotal || 0) / 100),
      profit: formatCurrency((profitTotal || 0) / 100),
      revenue: formatCurrency((revenueTotal || 0) / 100),
    }
  }

  // brand: "CeEIsGE_h"
  // brandName: "Lifted TEST"
  // createdAt: "2021-05-27T14:35:45.678Z"
  // data: {listName: "test", number: 2}
  // id: "60afae416911163a2950ff02"
  // kind: "leadsAddedToList"
  // user: "h8zu5UZz_"
  // userName: "Something New"

  @computed
  get events () {
    return toJS(this.items).map(event => {
      const createdAt = formatDateTime(event.createdAt)

      return { ...event, createdAt }
    })
  }
}
