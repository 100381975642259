import React from 'react'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import Panel from '../../components/Panel'
import List from '../../components/List'
import { formatDateTime } from '../../utils/format'
import CampaignsStore from '../../stores/campaigns'

const itemsTime = (items = []) => {
  return items.map(({ createdAt, ...other}) => {
    return { ...other, createdAt: formatDateTime(createdAt) }
  })
}

const columns = [

]

@withRouter
@observer
class ChannelPartnerships extends React.Component {
  constructor(props) {
    super(props)
    const { channelId } = props
    this.store = new CampaignsStore({ channelId }, props.link)
  }

  onChangeSort = orderByNew => e => {
    const { listProps } = this.store
    const { order, orderBy } = listProps
    let orderNew = ''
    if (orderBy === orderByNew) {
      if (order === 'desc') {
        orderNew = 'asc'
      } else {
        orderNew = 'desc'
      }
    } else {
      orderNew = 'desc'
    }

    this.store.onChangeSort(orderNew, orderByNew)
  }

  onChangePage = (e, page) => this.store.onChangePage(page)

  onChangeRowsPerPage = ({ target: { value }}) => this.store.onChangeRowsPerPage(value)

  onChangeFilters = name => object => {
    let value = null
    if (object.hasOwnProperty('target')) {
      value = object.target.value
    } else {
      value = object
    }
    this.store.onChangeFilter(name, value)
  }

  onClickRow = (e, id) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(`/campaigns/${id}`, '_blank')
    } else {
      this.props.history.push(`/campaigns/${id}`)
    }
  }

  get columns () {
    return [
      { id: 'createdAt', disablePadding: true, label: 'Created' },
      {
        id: 'outChannelLink', disablePadding: true, label: 'Partner', kind: 'link',
        link: { title: 'Partner Channel', pattern: `/channels/{id}`, keys: { id: 'outChannelId' } }
      },
      {
        id: 'inChannelLink', disablePadding: true, label: 'Brand', kind: 'link',
        link: { title: 'Brand Channel', pattern: `/channels/{id}`, keys: { id: 'inChannelId' } }
      },
      { id: 'adexes', disablePadding: true, label: 'Adex' },
      { id: 'count', disablePadding: true, label: 'Count' }
    ]
  }

  render () {
    const {
      listProps
    } = this.store

    const {
      items
    } = listProps

    return (
      <Panel
        title="Campaigns"
        thinking={listProps.loading}
      >
        <List
          {...this.props}
          {...listProps}
          searchable
          columns={this.columns}
          emptyMessage={'No Campaigns matching the given criteria.'}
          items={itemsTime(items).map(({ adexes, ...item }) => {
            return {
              ...item,
              adexes: (adexes || []).join(', ')
            }
          })}
          onChangeSort={this.onChangeSort}
          onChangeFilters={this.onChangeFilters}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          onClickRow={this.onClickRow}
        />
      </Panel>
    )
  }
}

export default ChannelPartnerships
