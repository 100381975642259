import React from 'react'
import styled, {css} from 'styled-components'
import {
  Table as MuiTable,
  TableBody,
  TableRow,
  TableCell as MuiTableCell
} from '@material-ui/core'

import Preview from './Preview'
const Table = styled(MuiTable)`
tr:last-child > td{
 border-color: transparent;
}
`
const TableCell = styled(MuiTableCell)`
  min-height: 53px;
  padding: ${props => props.padding !== 'none' && '11px 16px'};
`

export const Form = ({ name, children, onSubmit }) => (
  <form name={name} onSubmit={onSubmit} noValidate>
    <Table>
      <TableBody>
        { children }
      </TableBody>
    </Table>
  </form>
)

export const FormField = ({ name, children, padding }) => (
  <TableRow>
    <TableCell  style={{width: '30%'}}>
      <Preview>
      { name }
      </Preview>
    </TableCell>
    <TableCell style={{width: '70%'}}  padding={padding}>
      { children }
    </TableCell>
  </TableRow>
)

export const FormActions = ({ children }) => (
  <TableRow>
    <TableCell valign={"top"}>
      { children }
    </TableCell>
  </TableRow>
)
