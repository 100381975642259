import React from 'react'
import styled from 'styled-components'
import { IconButton, InputAdornment, TextField } from '@material-ui/core'
import { Trash as IconDelete, PlusCircle as IconAdd } from 'react-feather'

const Add = styled(IconAdd)`
width: 16px;
height: 16px;
color: ${props => props.theme.palette.primary.main};
`

const Delete = styled(IconDelete)`
width: 16px;
height: 16px;
color: ${props => props.theme.palette.grey[500]};
`

export default function MultiField ({ kind, disabled, label, errors, placeholder, dataCy, values, onChange }) {
  const onAdd = () => {
    if (kind === 'websites') {
      onChange([ ...values, { id: (new Date()).getTime()} ])
    } else {
      onChange({ target: { value: [ ...values, { id: (new Date()).getTime()} ] }})
    }
  }

  const onRemove = (index) => {
    if (values.length === 1) {
      onChange({ target: { value: [{ id: (new Date()).getTime()}] }})
    } else {
      if (index === 0) {
        onChange({ target: { value: [...values.splice(index + 1)] }})
      } else {
        onChange({ target: { value: [...values.splice(0, index), ...values.splice(index)] }})
      }
    }
  }

  const onChangeValue = index => ({ target: { value }}) => {
    values[index].value = value
    if (kind === 'websites') {
      onChange(values, index)
    } else {
      onChange({ target: { value: values }})
    }
  }

  const count = values.length

  return values.map((value, index) => {
    const error = errors ? errors[index] : null

    return (
      <TextField
        key={index}
        mb={2}
        fullWidth
        error={error}
        helperText={error}
        disabled={disabled}
        label={kind === 'websites' ? ' ' : label || placeholder}
        placeholder={placeholder}
        value={value.value}
        data-cy={!!dataCy && dataCy+index}
        onChange={onChangeValue(index)}
        InputProps={{
          endAdornment: disabled ? null : (
            <InputAdornment position="end">
              {
                count > 1 && <IconButton
                  color="default"
                  size={'small'}
                  onClick={() => onRemove(index)}>
                  <Delete />
                </IconButton>
              }
              {
                count - 1 === index &&
                <IconButton size={'small'} color="success" onClick={onAdd}>
                  <Add />
                </IconButton>
              }
            </InputAdornment>
          )
        }}
      />
    )
  })
}
