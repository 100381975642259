/* eslint-disable no-use-before-define */
import React from 'react'
import { Chip, TextField, CircularProgress, Select } from '@material-ui/core'
import MuiAutocomplete from '@material-ui/lab/Autocomplete'
import styled from 'styled-components'
import { Get } from '../utils/request'

const StyledOption = styled.li`
  cursor: pointer;
  display: flex;
  width: 100%;
  outline: 0;
  box-sizing: border-box;
  align-items: center;
  padding: 6px 16px;
  justify-content: space-between;
`

export const ChannelsAutocomplete = () => {}

export class Asynchronous extends React.Component {
  state = {
    open: false,
    options: [],
    loading: false,
  }

  typingTimeout = null

  onToggleOpen = async () => {
    const { open } = this.state
    this.setState({ open: !open }, async () => {
      if (!open) {
        await this.onGetData(null)
      }
    })
  }

  onSearchChange = ({ target: { value } }) => {
    clearTimeout(this.typingTimeout)
    this.typingTimeout = setTimeout(() => this.onGetData(value), 1000)
  }

  onGetData = async (value) => {
    this.setState({ loading: true })

    const filtersQuery = () => {
      let queries = ''
      const { additionalFilter, filter } = this.props
      if (additionalFilter) {
        queries = `?${additionalFilter}${value ? `&${filter}=${value}` : ''}`
      } else {
        queries = `${value ? `?${filter}=${value}` : ''}`
      }

      return queries
    }

    const url = `${this.props.url}${filtersQuery()}`
    const result = await Get(url)
    const options = result[this.props.dataField].map((i) => ({
      id: i.id,
      n: i.n,
      label: i[this.props.dataLabelField],
    }))
    this.setState({ loading: false, options })
  }

  render() {
    const { loading, options, open } = this.state
    const {
      id,
      dataCy,
      name,
      disabled,
      value,
      defaultValue,
      placeholder,
      error,
      onChange,
    } = this.props
    return (
      <MuiAutocomplete
        fullWidth
        id={id}
        name={name}
        open={open}
        onOpen={this.onToggleOpen}
        onClose={this.onToggleOpen}
        onChange={onChange}
        getOptionLabel={({ n, label }) => `${n ? `${n} - ` : ``}${label}`}
        renderOption={(option) => (
          <StyledOption>
            <div>{option.label}</div>
            <div style={{ color: 'slategrey' }}>{`${
              option.n ? `ID ${option.n}` : ''
            }`}</div>
          </StyledOption>
        )}
        options={options}
        ChipProps={{ size: 'small' }}
        loading={loading}
        disabled={disabled}
        value={value}
        // defaultValue={defaultValue}
        renderInput={(params) => (
          <TextField
            {...params}
            // label="Asynchronous"
            fullWidth
            error={!!error}
            helperText={error}
            variant='standard'
            placeholder={placeholder}
            data-cy={!!dataCy && dataCy}
            onChange={this.onSearchChange}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    )
  }
}

export default class Autocomplete extends React.Component {
  render() {
    const {
      id,
      value,
      defaultValue,
      label,
      placeholder,
      options,
      onChange,
      dataCy,
    } = this.props

    return (
      <MuiAutocomplete
        multiple
        id={id}
        options={options}
        groupBy={({ group }) => group}
        getOptionLabel={(option) => option.label}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        ChipProps={{ size: 'small' }}
        renderInput={(params) => (
          <TextField
            {...params}
            data-cy={!!dataCy && dataCy}
            variant='standard'
            label={label}
            placeholder={placeholder}
            margin='normal'
            fullWidth
          />
        )}
      />
    )
  }
}
