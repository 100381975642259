import List from './list'


export class PartnerTrackingCampaignsStore extends List {
  url = '/api/admin/tracking-campaigns'

  constructor(partner) {
    super()
    this.order = 'desc'
    this.filters = { partner }
    this.perPage = 10
    this.orderBy = 'createdAt'
    this.itemsField= 'campaigns'
    this.getList().then()
  }
}

export default class TrackingCampaignsStore extends List {
  url = '/api/admin/tracking-campaigns'

  constructor() {
    super()
    this.order = 'desc'
    this.orderBy = 'createdAt'
    this.itemsField= 'campaigns'
    this.getList().then()
  }
}
