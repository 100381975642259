import styled from 'styled-components'
import { Button } from '@material-ui/core'

export const RejectButton = styled(Button)`
  background-color: #d32f2f;
  color: #FFF;
  &:hover {
    background-color: indianred;
  }
`
