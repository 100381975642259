import List from './list'
import { action, computed, toJS } from 'mobx'
import { formatDate } from '../utils/format'
import { Post, Put } from '../utils/request'
import hasError from './request-message'

import { dataToDetails, contactToPayload } from '../utils/contacts'

export default class ContactsStore extends List {
  url = '/api/admin/contacts'

  constructor() {
    super()
    this.order = 'desc'
    this.orderBy = 'createdAt'
    this.itemsField = 'contacts'
    this.getList().then()
  }

  @action
  async uploadData(data) {
    const { success, message, created, updated } = await Post(
      `/api/admin/contacts/import`,
      { data }
    )
    hasError(
      success,
      message,
      `Created ${created} and updated ${updated} contacts`
    )
    this.getList().then()
    return { success }
  }

  @action
  async publish(contactId) {
    const { success, message } = await Put(
      `/api/admin/contacts/${contactId}/publish`,
      {}
    )
    hasError(success, message, 'Contact successfully published')
    return { success }
  }

  @action
  async createContact({ publish, ...contactDetails }) {
    const payload = {
      data: contactToPayload(contactDetails),
    }
    const { success, message, contact } = await Post(`/api/admin/contacts`, {
      contact: payload,
    })

    if (!hasError(success, message, 'Contact successfully created')) {
      if (publish) {
        await this.publish(contact.id)
      }
      this.getList().then()
    }

    return { success }
  }

  @computed
  get contacts() {
    return toJS(this.items).map((item) => {
      const { id, data, channel, createdAt, status } = item

      return {
        id,
        channel: (channel || {}).id,
        channelName: (channel || {}).domain,
        createdAt: formatDate(createdAt),
        ...dataToDetails(data),
        status,
      }
    })
  }
}
