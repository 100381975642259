import React from 'react'
import ReactDOM from 'react-dom'
import { Slider, Button, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CropperComponent  from 'react-easy-crop'
import ImgDialog from './ImageUploadPreviewDialog'
import getCroppedImg from './ImageUploadCropImage'


import  styled, {css} from 'styled-components'
import LoaderOverlay from './LoaderOverlay'

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 300px;
  user-select: none;
  ${props => props.disabled && css`
    pointer-events: none;
    cursor: no-drop;
  `}
`

const Placeholder = styled.div`
  background: ${props => props.theme.palette.grey[300]};
  font-size: 18px;
  display: flex;
  height: 100%;
  min-height: 300px;
  align-items: center;
  justify-content: center;
  font-family: ${props => props.theme.typography.fontFamily};
  color: ${props => props.theme.palette.grey[500]};
`

const Cropper = styled(CropperComponent)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`

const Controls = styled.div`
  position: relative;
  bottom: 0;
  height: 80px;
  display: flex;
  align-items: center;
`
const minZoom = 1

class App extends React.Component {
  state = {
    imageSrc: null,
      // 'https://ae01.alicdn.com/kf/HTB1AboSJFXXXXXZXpXXq6xXFXXX8/New-6-5x10ft-Studio-Photo-Backdrop-Screen-Hot-Selling-Green-Nature-Landscape-Photography-Wedding-Portrait-Background.jpg_640x640.jpg',
    crop: { x: 0, y: 0 },
    zoom: minZoom,
    aspect: 1,
    croppedAreaPixels: null,
    croppedImage: null,
    openPreview: false
  }

  onChangeHandler = async (event) => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      this.setState({ imageSrc: reader.result },
        () => this.props.onUpdate(reader.result))
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    }
  }

  onCropChange = crop => {
    this.setState({ crop })
  }

  onCropComplete = async (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels }, async () => {
      const croppedImage = await getCroppedImg(
        this.state.imageSrc,
        croppedAreaPixels
      )
      this.props.onUpdate(croppedImage)
      this.setState({ croppedImage })
    })
  }

  onZoomChange = zoom => {
    this.setState({ zoom })
  }

  showCroppedImage = async () => {
    const croppedImage = await getCroppedImg(
      this.state.imageSrc,
      this.state.croppedAreaPixels
    )
    this.props.onUpdate(croppedImage)
    this.setState({ openPreview: true })
  }

  handleClose = () => {
    this.setState({ openPreview: false })
  }

  render() {
    const { imageSrc, crop, zoom, aspect, openPreview } = this.state
    const { classes, thinking } = this.props
    return (
      <>
        <Container disabled={thinking}>
          {
            !!imageSrc
              ? <Cropper
            minZoom={minZoom}
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            restrictPosition={false}
            onCropChange={this.onCropChange}
            onCropComplete={this.onCropComplete}
            onZoomChange={this.onZoomChange}
          />
              : <Placeholder>Upload an image</Placeholder>
          }
          {!!thinking && <LoaderOverlay />}
        </Container>
        <Controls>
          <input
            accept="image/*"
            // className={classes.input}
            style={{ display: 'none' }}
            id="raised-button-file"
            onChange={this.onChangeHandler}
            type="file"
            disabled={thinking}
          />
          <label htmlFor="raised-button-file">
            <Button variant="contained" color='secondary' component="span" style={{marginRight: '2em'}} disabled={thinking}>
              Select&nbsp;image
            </Button>
          </label>

          <Typography>
            Zoom&nbsp;&nbsp;&nbsp;&nbsp;
          </Typography>
          <Slider
            disabled={thinking}
            value={zoom}
            min={0}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => this.setState({ zoom })}
          />
        </Controls>
        <ImgDialog open={openPreview} img={this.state.croppedImage} onClose={this.handleClose} />
      </>
    )
  }
}

const styles = {
  cropButton: {
    flexShrink: 0,
    marginRight: 5,
  },
}

export default withStyles(styles)(App)

