import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import { NavLink as RouterNavLink, withRouter } from 'react-router-dom'
import { darken } from 'polished'
import PerfectScrollbar from 'react-perfect-scrollbar'
import '../vendor/perfect-scrollbar.css'
import { spacing } from '@material-ui/system'
import {
  Box as MuiBox,
  Chip,
  Collapse,
  SwipeableDrawer as MuiDrawer,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { Box as BoxIcon } from 'react-feather'
import SidebarUser from './SidebarUser'
import packageJson from '../../package.json'

const NavLink = React.forwardRef((props, ref) => (
  <RouterNavLink innerRef={ref} {...props} isActive={() => props.active}/>
));

const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${props => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: ${props => props.theme.sidebar.background};
`;

const Link = styled(ListItem)`
  padding-left: ${props => props.theme.spacing(14)}px;
  padding-top: ${props => props.theme.spacing(2)}px;
  padding-bottom: ${props => props.theme.spacing(2)}px;

  span {
    color: ${props => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${props => rgba(props.theme.sidebar.color, 0.9)};
  }

  &.${props => props.active ? props.activeClassName : null} {
    background-color: ${props => darken(0.06, props.theme.sidebar.background)};

    span {
      color: ${props => props.theme.sidebar.color};
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${props => props.theme.sidebar.color};
  span {
    font-size: ${props => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const Items = styled.div`
  padding-top: ${props => props.theme.spacing(2.5)}px;
  padding-bottom: ${props => props.theme.spacing(2.5)}px;
`;

const Brand = styled(ListItem)`
  font-size: ${props => props.theme.typography.h5.fontSize};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: ${props => props.theme.sidebar.header.color};
  background-color: ${props => props.theme.sidebar.header.background};
  font-family: ${props => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${props => props.theme.spacing(6)}px;
  padding-right: ${props => props.theme.spacing(6)}px;

  ${props => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }
`;

const BrandIcon = styled(BoxIcon)`
  margin-right: ${props => props.theme.spacing(2)}px;
  color: ${props => props.theme.sidebar.header.brand.color};
`;

const Category = styled(ListItem)`
  padding-top: ${props => props.theme.spacing(3)}px;
  padding-bottom: ${props => props.theme.spacing(3)}px;
  padding-left: ${props => props.theme.spacing(6)}px;
  padding-right: ${props => props.theme.spacing(5)}px;
  font-weight: ${props => props.theme.typography.fontWeightRegular};

  svg {
    color: ${props => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${props => props.active ? props.activeClassName : null} {
    background-color: ${props => darken(0.05, props.theme.sidebar.background)};

    span {
      color: ${props => props.theme.sidebar.color};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${props => props.theme.sidebar.color};
    font-size: ${props => props.theme.typography.body1.fontSize}px;
    font-weight: ${props => props.theme.sidebar.category.fontWeight};
    padding: 0 ${props => props.theme.spacing(4)}px;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${props => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${props => rgba(props.theme.sidebar.color, 0.5)};
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${props => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 12px;
  top: 8px;
  background: ${props => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${props => props.theme.sidebar.badge.color};
    padding-left: ${props => props.theme.spacing(2)}px;
    padding-right: ${props => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const SidebarSection = styled(Typography)`
  color: ${props => props.theme.sidebar.color};
  padding: ${props => props.theme.spacing(4)}px
    ${props => props.theme.spacing(6)}px ${props => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;

function SidebarCategory({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  ...rest
}) {
  return (
    <Category {...rest} data-cy={`sidebar-link-${name}`}>
      {icon}
      <CategoryText>{name}</CategoryText>
      {isCollapsable ? (
        isOpen ? (
          <CategoryIconMore />
        ) : (
          <CategoryIconLess />
        )
      ) : null}
      {badge ? <CategoryBadge label={badge} /> : ''}
    </Category>
  );
}

function SidebarLink({ name, to, badge, ...rest }) {
  return (
    <Link
      button
      dense
      component={NavLink}
      exact
      to={to}
      activeClassName="active"
      {...rest}
    >
      <LinkText>{name}</LinkText>
      {badge ? <LinkBadge label={badge} /> : ""}
    </Link>
  );
}


class Sidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = { expanded: [], active: null }
  }

  static defaultProps = {
    routes: []
  }

  toggle = index => {
    const { expanded } = this.state
    this.setState({ expanded: index !== expanded ? index : null  })
  };

  componentDidUpdate (prevProps, prevState, snapshot) {
    const path = this.props.location.pathname
    if (this.state.path !== path) {
      this.onUpdateActiveRoute()
    }
  }

  UNSAFE_componentWillMount() {
    /* Open collapse element that matches current url */
    this.onUpdateActiveRoute()
  }

  isActiveRoute = (route, index) => {
    const path = this.props.location.pathname;
    const isActive = path.indexOf(route.path) >= 0;
    const isOpen = route.open;
    const isHome = route.containsHome && path === '/';

    if (isActive || isOpen || isHome) {
      this.setState(() => ({
        active: route.id,
        expanded: index,
        path
      }));
    }

  }

  onUpdateActiveRoute = () => {
    this.props.routes.forEach((route, index) => {
      if (!route.children) {
        this.isActiveRoute(route, index)
      } else {
        route.children.map((childRoute) => this.isActiveRoute(childRoute, index))
      }
    });
  }

  render() {
    const { classes, staticContext, routes, user, onLogout, ...other } = this.props;
    const { expanded, active } = this.state

    return (
      <Drawer variant="permanent" {...other}>
        <Brand >
          <BrandIcon/>
          <Box ml={1} aria-hidden="false" role="img" title={`Admin Panel v${packageJson.version}`}>
            Breezy
          </Box>
        </Brand>

        <Scrollbar>
          <List disablePadding>
            <Items>
              {
                routes.map((category, index) => (
                  <React.Fragment key={index}>
                    {
                      category.header ? (
                        <SidebarSection>{category.header}</SidebarSection>
                      ) : null
                    }

                    {
                      category.children ? (
                        <React.Fragment key={index}>
                          <SidebarCategory
                            isOpen={expanded !== index}
                            isCollapsable={true}
                            name={category.name}
                            icon={category.icon}
                            button={true}
                            onClick={() => this.toggle(index)}
                          />

                          <Collapse
                            in={expanded === index}
                            timeout="auto"
                            unmountOnExit
                          >
                            {
                              category.children.map((route, index) => (
                                <SidebarLink
                                  key={route.id}
                                  active={active === route.id}
                                  name={route.name}
                                  to={route.path}
                                  icon={route.icon}
                                  badge={route.badge}
                                  onClick={route.onClick}
                                />
                              ))
                            }
                          </Collapse>

                        </React.Fragment>
                      ) : (
                        <SidebarCategory
                          active={active === category.id}
                          isCollapsable={false}
                          name={category.name}
                          to={category.path}
                          activeClassName="active"
                          component={NavLink}
                          icon={category.icon}
                          exact
                          badge={category.badge}
                          onClick={category.onClick}
                        />
                      )
                    }

                  </React.Fragment>
                ))
              }
            </Items>
          </List>
        </Scrollbar>
      </Drawer>
    );
  }
}

export default withRouter(Sidebar)
