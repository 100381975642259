import React, { useState } from 'react'
import { find } from 'lodash'
import {
  Button as MuiButton,
  Dialog,
  DialogContent,
  DialogActions as MuiDialogActions,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
  Tooltip, Grid, MenuItem, Select, FormControl, InputLabel, FormHelperText
} from '@material-ui/core'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { Trash as IconDelete, PlusCircle as IconAdd } from 'react-feather'
import { Launch, Delete, FilterNone as MuiIconCopy } from '@material-ui/icons'
import Panel from '../Panel'
import List from '../List'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const Button = styled(MuiButton)(spacing)
const LaunchIcon = styled(Launch)(spacing)
const DeleteIcon = styled(Delete)`
  width: 20px;
  height: 20px;
`
const TextField = MuiTextField //styled(MuiTextField)(spacing)
const IconCopy = styled(MuiIconCopy)`
  width: 20px;
  height: 20px;
  color: ${props => props.theme.palette.secondary.main}
`
const DialogActions = styled(MuiDialogActions)(spacing)

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const APP_URL = process.env.APP_URL || 'https://dev.my.breezy.io'

const columns = [
  { id: 'email', disablePadding: true, label: 'Email' },
  { id: 'name', disablePadding: true, label: 'Name' },
  { id: 'role', disablePadding: true, label: 'Role', kind: 'actions' },
  { id: 'status', disablePadding: true, label: 'Status' },
  { id: 'link', disablePadding: true, label: 'URL', kind: 'actions' },
  { id: 'delete', disablePadding: true, label: '', kind: 'actions' }
]

const CopyLink = ({ id, brandId, fullWidth }) => {
  const [copied, setCopied] = useState(false)

  const link = `${APP_URL}/join/${brandId}/${id}/signup`

  const onCopy = () => {
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 1500)
  }

  return (
    <CopyToClipboard text={link}
                     onCopy={onCopy}>
      <Tooltip title={'Copied'} open={copied} placement={"top"}>
        <TextField value={link}
                   fullWidth={fullWidth}
                   // variant={"outlined"}
                   disabled
                   InputProps={{
                     disableUnderline: true,
                     endAdornment: <InputAdornment position="end"><Tooltip arrow placement='top' title={'Copy'}><IconButton><IconCopy /></IconButton></Tooltip></InputAdornment>,
                   }}
                   mb={3}
        />
      </Tooltip>
    </CopyToClipboard>
  )
}

const Actions = ({ id, status, userId, ...other }) => {
  return (
    <React.Fragment>
      {
        status !== 'joined' && (
          <CopyLink id={id} brandId={other.brandId} />
        )
      }
      {
        status === 'joined' && (
          <Button
            // onClick={() => { console.log(preview); window.open(preview, '_blank');}}
            href={`/users/${userId}`}
            target={'blank'}
            variant="outlined"
            color="default"
            size="small"
            endIcon={<LaunchIcon />}
          >
            User details
          </Button>
        )
      }
    </React.Fragment>
  )
}

const Role = ({ id: userId, brandAccess, disabled, onChange }) => {
  if (!brandAccess) return ''

  if (disabled) {
    return <MenuItem  value={'brandOwner'}>Owner</MenuItem>
  }

  const { role, id } = brandAccess
  const onRoleChanged = ({ target: { value }}) => {
    onChange(userId, id, value)
  }
  return <Select
    disabed={disabled}
    value={role}
    onChange={onRoleChanged}
    inputProps={{
      shrink: false
    }}
  >
    <MenuItem  value={'user'}>User</MenuItem>
    <MenuItem  value={'brandOwner'}>Owner</MenuItem>
  </Select>
}

class InviteUserDialog extends React.Component {
  constructor (props) {
    super(props)
    this.state = { email: '', role: '', thinking: false, success: false }
  }

  onChange = field => ({ target: { value } }) => {
    this.setState({ [field]: value })
  }

  onInvite = async () => {
    const { email, role } = this.state
    this.setState({ thinking: true })
    const { success, accountUser } = await this.props.onInvite({ email, role })
    this.setState({ thinking: false, success, id: accountUser.id })
  }

  render () {
    const { email, role, thinking, success } = this.state
    const { open, onClose } = this.props

    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        data-cy="dialogContactPersons"
        open={open}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          Invite New User
        </DialogTitle>

        <DialogContent>
          {
            !success && (
              <Grid container
                    spacing={2}
                    direction="row"
                    alignItems="center"
              >
                <Grid item xs={8} sm={6}>
                  <FormControl fullWidth>
                    {/*<InputLabel id="demo-simple-input-helper-label">Select User Role</InputLabel>*/}
                    <TextField
                      labelId="demo-simple-input-helper-label"
                      disabled={thinking}
                      mb={2}
                      fullWidth
                      type={'email'}
                      label={'Email'}
                      placeholder={'Input user email'}
                      value={email}
                      onChange={this.onChange('email')}
                    />
                    <FormHelperText>Input user email</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={4} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-helper-label">Select User Role</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      fullWidth
                      disabled={thinking}
                      placeholder={'Select user brand role'}
                      value={role}
                      onChange={this.onChange('role')}
                      inputProps={{placeholder: 'Select user brand role'}}
                    >
                      <MenuItem value="" disabled>
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={'brandOwner'}>Brand Owner</MenuItem>
                      <MenuItem value={'user'}>User</MenuItem>
                    </Select>

                    <FormHelperText>Chose brand user role</FormHelperText>
                  </FormControl>

                </Grid>
              </Grid>
            )
          }

          {
            success && <CopyLink fullWidth id={this.state.id} brandId={this.props.brandId} />
          }


        </DialogContent>

        <DialogActions px={7} py={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
            </Grid>

            <Grid xs={12} sm={6}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
            >
              <Button
                color="default"
                mr={2}
                disabled={thinking}
                onClick={onClose}
              >
                Cancel
              </Button>
              {
                !success && (
                  <Button
                    onClick={this.onInvite}
                    variant="outlined"
                    color="primary"
                    disabled={thinking}
                  >
                    Invite
                  </Button>
                )
              }
            </Grid>


          </Grid>
        </DialogActions>
      </Dialog>
    )
  }
}

export default class BrandUsers extends React.Component {
  constructor (props) {
    super(props)
    this.state = { invite: false }
  }

  onOpen = () => {
    this.setState({ invite: true })
  }

  onClose = () => {
    this.setState({ invite: false })
  }

  onEdit = () => {}

  onRemove = (id) => () => this.props.onRemove(id)


  render () {
    const {
      brandId,
      thinking,
      users
    } = this.props

    return (
      <Panel
        title="Users"
        adding
        thinking={thinking}
        onAdd={this.onOpen}
      >
        <List
          columns={columns}
          items={users.map((u, index) => ({
            ...u,
            role: () => <Role disabled={u.brand} brandAccess={u.brandAccess} onChange={this.props.onChangeRole} />,
            status: u.brand ? 'Brand Owner' : capitalizeFirstLetter(u.status),
            link: () => <Actions {...u} brandId={brandId} />,
            delete: () => u.brand ? null : <IconButton onClick={this.onRemove(u.id)}><DeleteIcon /></IconButton>
          }))}
          onClickRow={this.onEdit}
          emptyMessage={'No Users are invited.'}
          emptyActionText={'Invite a user'}
          onEmptyAction={this.onOpen}
          disablePagination/>

        {
          this.state.invite && <InviteUserDialog
            open
            brandId={brandId}
            onInvite={this.props.onInvite}
            onClose={this.onClose}
          />
        }

      </Panel>
    );
  }
}
