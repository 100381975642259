import React from 'react'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import Action from './Action'
import ActionStore from '../../stores/conversion'

@withRouter
@observer
class _Action extends React.Component {
  constructor(props) {
    super(props)
    const {
      match: {
        params: {
          id
        }
      }
    } = props

    this.onLoadStores(id)
  }

  onLoadStores = id => {
    this.store = new ActionStore(id)
  }

  onUpdate = async (details) => await this.store.update(details)

  render () {
    const {
      match: {
        params: {
          id
        }
      }
    } = this.props

    const {
      details,
      loading,
      thinking
    } = this.store

    return <Action
      id={id}
      details={details}
      loading={loading}
      thinking={thinking}
      onUpdate={this.onUpdate}
      onCreate={() => {}}
      onCancel={() => {}}
    />
  }
}

export default _Action
