import React from 'react'

import Helmet from 'react-helmet'

import MomentUtils from '@material-ui/pickers/adapter/moment'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { LocalizationProvider } from '@material-ui/pickers'
import { StylesProvider } from '@material-ui/styles'
import { ThemeProvider } from 'styled-components'

import { BrowserRouter as Router } from 'react-router-dom'

import maTheme from './theme'
import Routes from './Routes'

const App = ({ theme }) => {
  return (
    <React.Fragment>
      <Helmet titleTemplate='%s | Breezy' defaultTitle='Admin & Dashboard' />
      <StylesProvider injectFirst>
        <LocalizationProvider dateAdapter={MomentUtils}>
          <MuiThemeProvider theme={maTheme[theme.currentTheme]}>
            <ThemeProvider theme={maTheme[theme.currentTheme]}>
              <Router>
                <Routes />
              </Router>
            </ThemeProvider>
          </MuiThemeProvider>
        </LocalizationProvider>
      </StylesProvider>
    </React.Fragment>
  )
}

App.defaultProps = {
  theme: { currentTheme: 0 },
}

export default App
