import React from 'react'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import SimilarWebQuotas from './SimilarWebQuotas'
import SimilarWebQuotasStore from '../../stores/similarweb-quotas'
import { formatDateTime } from '../../utils/format'

@withRouter
@observer
class _SimilarWebQuotas extends React.Component {
  constructor (props) {
    super(props)
    this.store = new SimilarWebQuotasStore()
  }

  onChangeSort = orderByNew => (e) => {
    const { listProps } = this.store
    const { order, orderBy } = listProps
    let orderNew = ''
    if (orderBy === orderByNew) {
      if (order === 'desc') {
        orderNew = 'asc'
      } else {
        orderNew = 'desc'
      }
    } else {
      orderNew = 'desc'
    }

    this.store.onChangeSort(orderNew, orderByNew)
  }

  onChangePage = (e, page) => this.store.onChangePage(page)

  onChangeRowsPerPage = ({ target: { value } }) => this.store.onChangeRowsPerPage(value)

  onChangeFilters = name => (object) => {
    let value = null
    if (object.hasOwnProperty('target')) {
      value = object.target.value
    } else {
      value = object
    }
    this.store.onChangeFilter(name, value)
  }

  render () {
    const {
      listProps
    } = this.store
    const {
      items
    } = listProps

    return (
      <SimilarWebQuotas
        {...this.props}
        {...listProps}
        items={items.map(item => ({
          ...item,
          createdAt: formatDateTime(item.createdAt)
        }))}
        onChangeSort={this.onChangeSort}
        onChangeFilters={this.onChangeFilters}
        onChangePage={this.onChangePage}
        onChangeRowsPerPage={this.onChangeRowsPerPage}
        onClickRow={() => {}}
      />
    )
  }
}

export default _SimilarWebQuotas
