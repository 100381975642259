import React from 'react'
import { Button as MuiButton } from '@material-ui/core'
import styled from 'styled-components'
import DashboardPage from '../../layouts/DashboardPage'
import DetailsForm from '../../components/Details'
import SetPassword from '../../components/SetPassword'
import { spacing } from '@material-ui/system'

const Button = styled(MuiButton)(spacing)

const config = [
  { key: 'id', name: 'ID', type: 'string' },
  { key: 'firstName', name: 'First Name', editable: true, creatable: true, type: 'string' },
  { key: 'lastName', name: 'Last Name', editable: true, creatable: true, type: 'string' },
  { key: 'email', name: 'Email', editable: true, creatable: true, type: 'string' },
  { key: 'emailStatus', name: 'Email Status', type: 'string', kind: 'status' },
  { key: 'createdAt', name: 'Signed up', type: 'string' },
  // { key: 'signedAt', name: 'Last signed at', type: 'string' },
  { key: 'role', name: 'Role', editable: true, creatable: true, type: 'select',
    options: [
      { value: 'administrator', label: 'Administrator' }
    ]
  },
  // {
  //   key: 'brands', name: 'Brands', editable: false, creatable: false,
  //   links: { titleKey: 'name', pattern: `/brands/{id}`, keys: { id: 'id' } }
  // },
  { key: 'status', name: 'Status', editable: false, creatable: true, type: 'select', kind: 'status',
    options: [
      { value: 'confirmed', label: 'Confirmed' },
      { value: 'paused', label: 'Paused' },
      { value: 'deleted', label: 'Deleted' },
    ]
  }
]

export default class User extends React.Component {
  state = {
    edit: false,
    anchorEl: null
  }

  onCancel = e => {
    if (this.props.id === 'create') {
      return this.props.onCancel()
    }
  }

  onLogin = kind => {
    this.props.onLoginAs(kind)
    this.onCloseMenu()
  }

  onCloseMenu = () => this.setState({ anchorEl: null })

  render () {
    const {
      id,
      details,
      loading,
      thinking,
      onCreate,
      onUpdate,
      onCancel,
      onSetPassword
    } = this.props
    const create = id === 'invite'

    const Actions = () => {
      if (details.role !== 'administrator') {
        return <>
          <Button variant="contained" color="primary" onClick={() => this.onLogin('production')}> Login as </Button>
        </>
      }
      return null
    }

    return (
      <DashboardPage
        kind="details"
        title={"User"}
        status={id}
        noActions
        actions={Actions}
        thinking={loading}
      >
        <DetailsForm
          create={create}
          config={config}
          details={details}
          onCreate={onCreate}
          onUpdate={onUpdate}
          onCancel={onCancel}
        />
        <SetPassword onSubmit={onSetPassword} thinking={thinking} />
      </DashboardPage>
    )
  }
}
