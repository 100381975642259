import React from 'react'
import styled, {css} from 'styled-components'
import FilterGroup from './FilterGroup'
import { Search as FeatherSearchIcon, X as FeatherX } from 'react-feather'
import { darken } from 'polished'
import { Chip as MuiChip, IconButton as MuiIconButton } from '@material-ui/core'

const Chip = styled(MuiChip)`
  border-radius: 5px;
`

const SearchIcon = styled(FeatherSearchIcon)`
  color: #9E9E9E;
  width: 22px;
  height: 22px;
`
const ResetIcon = styled(FeatherX)`
  color: #9E9E9E;
  width: 22px;
  height: 22px;
`

const SearchContainer = styled.div`
  border-radius: 2px;
  padding: 0 45px;
  min-height: 63px;
  background-color: ${props => props.theme.header.background};
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  // &:hover {
  //   background-color: ${props => darken(0.05, props.theme.header.background)};
  // }
`;

const InputAdornment = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  
 ${props => props.variant === 'start' && css`
      left: 0;
  `}
 
  ${props => props.variant === 'end' && css`
      right: 0;
  `}    
`;

const SearchArea = styled.div`
  //background: aqua;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex: 1 0 auto;
  box-sizing: border-box;
`

class Search extends React.Component {
  static defaultProps = {
    filters: []
  }

  constructor (props) {
    super(props)
    this.state = { filters: [ ...this.props.filters, this.initialFilter ] }
  }

  get initialFilter () {
    return { key: null, expression: { id: 'eq', label: '=' }, value: null, id: Math.random(), edit: true }
  }

  isLastFilterComplete = () => {
    const { filters } = this.state
    const { key, expression, value } = filters[filters.length - 1]
    return !!key && !!expression && !!value
  }

  onDelete = (index) => {
    const { filters } = this.state
    if (!filters[index].key && index > 0) {
      filters.splice(index - 1, 1)
    } else {
      filters.splice(index, 1)
    }
    this.setState({ filters })
    if (!filters.length || this.isLastFilterComplete()) {
      this.setState({ filters: [ ...filters, this.initialFilter ] })
    }
    this.props.onChange(filters)
  }

  onEdit = (index) => {
    const { filters } = this.state
    filters[index].edit = true
    this.setState({ filters })
  }

  onChange = (index, key, value) => {
    const { filters } = this.state
    filters[index][key] = value
    if (key === 'value') {
      filters[index].edit = false
      this.props.onChange(filters)
    }
    this.setState({ filters })
    if (this.isLastFilterComplete()) {
      this.setState({ filters: [ ...filters, this.initialFilter ]})
    }
  }

  onReset = () => {
    this.setState({ filters: [ this.initialFilter ]})
    this.props.onChange([])
  }

  render () {
    const {
      filters
    } = this.state

    return (
      <SearchContainer>
        <InputAdornment variant={'start'}>
          <SearchIcon />
        </InputAdornment>
        <SearchArea>
          {
            filters.map((filter, index) => (
              <FilterGroup
                key={filter.id}
                index={index}
                keyOptions={this.props.keys}
                expressionOptions={this.props.expressions}
                getValuesAsync={this.props.values}
                groupBy={this.props.groupBy}
                placeholder={'Search...'}
                filter={filter}
                onChange={this.onChange}
                onDelete={this.onDelete}
                onEdit={this.onEdit}
              />
            ))
          }
        </SearchArea>
        {/* Reset all filters action component */}
        {
          !!filters[0].key && !!filters[0].key.id && <InputAdornment variant={'end'}>
            <MuiIconButton onClick={this.onReset} sizeSmall><ResetIcon /></MuiIconButton>
          </InputAdornment>
        }

      </SearchContainer>
    )
  }
}

export default Search
