import List from './list'
import { Post } from '../utils/request'
import hasError from './request-message'

export default class SignaturesStore extends List {
  url = '/api/admin/discovery-signatures'

  constructor() {
    super()
    this.order = 'desc'
    this.orderBy = 'createdAt'
    this.itemsField= 'discoverySignatures'
    this.perPage = 25
    this.getList().then()
  }

  async create ({ signatures, websites, ...details }) {
    const discoverySignature = {
      ...details,
      signatures: (signatures || []).map(({ value }) => value),
      websites: (websites || []).map(({ value }) => value),
    }
    const { success, ...rest } = await Post(this.url, { discoverySignature })
    if (success) {
      this.getList().then()
    }
    return { success, ...rest }
  }

  async publish () {
    const { success, message, ...rest } = await Post(`${this.url}/publish`, {})
    if (!hasError(success, message, 'Signatures successfully published')) {
      this.getList().then()
    }
    return { success, ...rest }
  }
}
