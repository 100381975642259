import React from 'react'
import deparam from 'can-deparam'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Post } from '../../utils/request'
import Opportunity from './Opportunity'
import OpportunityStore from '../../stores/opportunity'

const computeDetails = (details, create) => {
  if (!create) return  { details }
  const { brandId, brandName } = deparam(window.location.search)
  return { details: {...details, brandName, brand: { id: brandId, label: brandName } }, noDefaultBrand: !brandId }
}

@withRouter
@observer
class _Opportunity extends React.Component {
  constructor(props) {
    super(props)
    const {
      match: {
        params: {
          id
        }
      }
    } = props
    this.store = new OpportunityStore(id, null)
  }

  onCreate = async (details) =>  {
    const { opportunity } = await this.store.create(details)

    if (opportunity && opportunity.id) {
      const { id } = opportunity
      this.store = new OpportunityStore(id, null)
      this.props.history.push(`/opportunities/${id}`)
    }
  }

  onUpdate = async (details) => await this.store.update(details)

  onCancel = () => this.props.history.push(`/opportunities`)

  onToggleArchived = async () => await this.store.toggleArchived()


  // Tochpoints
  onCreateTouchpoint = async (details) => {
    const {
      match: {
        params: {
          id
        }
      }
    } = this.props
    return await this.store.createTouchpoint({ opportunity: id, ...details })
  }

  onUpdateTouchpoint = async (details) => {
    return await this.store.updateTouchpoint(details)
  }

  onRemoveTouchpoint = async (id) => {
    return await this.store.removeTouchpoint(id)
  }
  // Tochpoints

  // Coupons
  onCreateCoupon = async (kind, details) => await this.store.createCoupon(kind, details)
  onUpdateCoupon = async (kind, details) => await this.store.updateCoupon(kind, details)
  onRemoveCoupon = async (kind, id) => await this.store.removeCoupon(kind, id)
  // Coupons

  onCreateCampaign = async (details) => {
    const { campaign, message } = await Post('/api/admin/tracking-campaigns', { campaign: details })
    if (!message) {
      this.props.history.push(`/tracking-campaigns/${campaign.id}`)
      return null
    }
    return message
  }

  render () {
    const {
      match: {
        params: {
          id
        }
      },
      location: {
        search
      }
    } = this.props

    const {
      details,
      loading,
      thinking,
      rules,
      landings,
      trackingParameters,
      statistics,
      touchpoints,
      coupons
    } = this.store

    const touchpointsProps = {
      thinking: touchpoints.thinking,
      items: touchpoints.touchpoints,
      columns: [
        { id: 'id', disablePadding: true, label: 'ID' },
        { id: 'name', disablePadding: true, label: 'Name' },
        { id: 'kind', disablePadding: true, label: 'Kind' },
        { id: 'revenueModel', disablePadding: true, label: 'Revenue type' },
        { id: 'revenue', disablePadding: true, label: 'Revenue' },
        { id: 'costModel', disablePadding: true, label: 'Payout type' },
        { id: 'cost', disablePadding: true, label: 'Payout' },
        { id: 'lookback', disablePadding: true, label: 'Lifetime' }
      ],
      onCreate: this.onCreateTouchpoint,
      onUpdate: this.onUpdateTouchpoint,
      onRemove: this.onRemoveTouchpoint,
    }

    const couponsProps = {
      ...coupons,
      onCreate: this.onCreateCoupon,
      onUpdate: this.onUpdateCoupon,
      onRemove: this.onRemoveCoupon,
    }

    return <Opportunity
      id={id}
      {...computeDetails(details, id === 'create')}
      loading={loading}
      thinking={thinking}
      stats={statistics}
      landings={landings}
      rules={rules}
      touchpoints={touchpointsProps}
      coupons={couponsProps}
      trackingParameters={trackingParameters}

      onCreate={this.onCreate}
      onUpdate={this.onUpdate}
      onCancel={this.onCancel}
      onToggleArchived={this.onToggleArchived}
      onCreateCampaign={this.onCreateCampaign}
    />
  }
}

export default _Opportunity
