import List from './list'

export default class UsersStore extends List {
  url = '/api/admin/users'

  constructor() {
    super()
    this.order = 'desc'
    this.orderBy = 'createdAt'
    this.itemsField= 'users'
    this.filters = { role: 'all', status: 'all' }
    this.getList().then()
  }
}
