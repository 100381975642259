import List from './list'

export default class ResearchesStore extends List {
  url = '/api/admin/discovery-researches'

  constructor(filters = {}) {
    super()
    this.order = 'desc'
    this.orderBy = 'createdAt'
    this.itemsField= 'discoveryResearches'
    this.filters = filters
    this.getList().then()
  }
}
