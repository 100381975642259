import React from 'react'
import {
  Close as CloseIcon
} from '@material-ui/icons'
import {
  IconButton,
  Collapse,
  Snackbar,
  SnackbarContent
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />

class Notification extends React.Component {
  render () {
    const { open, onExited, message, onClose, variant, timeout, ...other } = this.props

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={timeout}
        onClose={onClose}
        onExited={onExited}
      >
        <Alert
          style={{ alignItems: 'inherit' }}
          severity={variant}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        >
          {message}
        </Alert>
      </Snackbar>
    )
    // return (
    //   <Snackbar
    //     anchorOrigin={{
    //       vertical: 'bottom',
    //       horizontal: 'center',
    //     }}
    //     open={open}
    //     variant="filled"
    //     autoHideDuration={timeout}
    //     onClose={onClose}
    //     onExited={onExited}
    //   >
    //     <Alert severity={variant}>
    //       <SnackbarContent
    //         aria-describedby="client-snackbar"
    //         message={<span id="client-snackbar">{message}</span>}
    //         action={[
    //           <IconButton
    //             key="close"
    //             aria-label="Close"
    //             color="inherit"
    //             onClick={onClose}
    //           >
    //             <CloseIcon />
    //           </IconButton>,
    //         ]}
    //         {...other}
    //       />
    //     </Alert>
    //   </Snackbar>
    // )
  }
}

export default Notification
