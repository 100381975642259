import React from 'react'
import { find } from 'lodash'
import is from 'is_js'
import {
  Grid,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent,
  DialogTitle,
  Button as MuiButton,
  TextField as MuiTextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Divider,
} from '@material-ui/core'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'

import { EMAIL_VALIDATE_EXPRESSION } from '../../data/email-exclusions'

const Button = styled(MuiButton)(spacing)
const DialogActions = styled(MuiDialogActions)(spacing)
const TextField = styled(MuiTextField)(spacing)

const NAMES_SOURCES = ['LinkedIn', 'Website', 'Facebook']
const PERSONAL_EMAIL_SOURCES = [
  'Apollo',
  'Hunter.io',
  'Snov.io',
  'Facebook',
  'Website',
]
const COMPANY_EMAIL_SOURCES = [
  'Apollo',
  'Hunter.io',
  'Snov.io',
  'Facebook',
  'Website',
]
const LINKED_ID_SOURCES = ['Sales Navigator']

const initialEntity = {
  id: null,
  channel: null,
  company: null,
  companyEmail: null,
  phone: null,
  linkedin: null,
  twitter: null,
  instagram: null,
}

const STRING_FIELDS = ['company']

const requiredFields = [
  { field: 'company', kind: 'text' },
  { field: 'companyEmail', kind: 'email', validateOnly: true },
  // { field: 'sourceCompanyEmails', kind: 'text' },
]

const clearString = (str) => {
  if (!str) return str

  return str
    .replace(/\s{2,}/g, ' ')
    .replace(/\t/g, ' ')
    .trim()
    .replace(/(\r\n|\n|\r)/g, '')
}

const validate = (details, fields) => {
  let errors = null

  fields.map(({ field, kind, validateOnly }) => {
    if (!details[field] && !validateOnly) {
      errors = { ...errors, [field]: 'Required field' }
    } else if (
      kind === 'email' &&
      details[field] &&
      !is.email(details[field])
    ) {
      errors = { ...errors, [field]: 'Please enter correct email' }
    } else if (kind === 'link' && !is.url(details[field])) {
      errors = { ...errors, [field]: 'Please enter correct URL' }
    }
    // else if (
    //   kind === 'email' &&
    //   field === 'companyEmail' &&
    //   EMAIL_VALIDATE_EXPRESSION.test(details[field])
    // ) {
    //   errors = { ...errors, [field]: 'Sorry, you can’t add role-based email.' }
    // }
  })

  return errors
}

const clearDetails = (details) => {
  let result = {}

  for (let key in details) {
    if (details.hasOwnProperty(key)) {
      if (STRING_FIELDS.includes(key)) {
        result[key] = clearString(details[key])
      } else {
        result[key] = details[key]
      }
    }
  }
  return result
}

const SourceSelect = ({ id, value, options, label, error, onChange }) => {
  return (
    <FormControl style={{ width: '100%' }} error={Boolean(error)}>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        autoWidth
        labelId={`${id}-label`}
        id={id}
        value={value}
        onChange={onChange}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

export class EntityModal extends React.Component {
  state = { ...initialEntity, errors: {} }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.open && this.props.open) {
      const {
        id,
        channel,
        company,
        companyEmail,
        phone,
        linkedin,
        twitter,
        instagram,
        sourceCompanyEmails,
        sourceLinkedin,
      } = this.props.entity || {}

      this.setState({
        id,
        company,
        companyEmail,
        phone,
        linkedin,
        twitter,
        instagram,
        sourceCompanyEmails,
        sourceLinkedin,
        channel,
      })
    }
  }

  onChange =
    (field) =>
    ({ target: { value } }) => {
      const { errors } = this.state
      delete errors[field]
      this.setState({ [field]: value, errors })
    }

  onRemove = () => {
    const { id } = this.state
    this.props.onRemove(id)
  }

  onSave = (publish) => {
    const {
      id,
      channel,
      company,
      companyEmail,
      phone,
      linkedin,
      twitter,
      instagram,
      sourceCompanyEmails,
      sourceLinkedin,
    } = this.state

    const errors = validate(
      {
        company,
        linkedin,
        companyEmail,
        sourceCompanyEmails,
        sourceLinkedin,
      },
      requiredFields
    )

    if (!errors) {
      const payload = {
        channel,
        id,
        ...clearDetails({
          company,
          linkedin,
          twitter,
          instagram,
          companyEmail,
          phone,
          sourceCompanyEmails,
          sourceLinkedin,
        }),
      }
      this.props.onSave(payload, publish)
    } else {
      this.setState({ errors })
    }
  }

  render() {
    const { open, entity, variant } = this.props
    const {
      channel,
      company,
      companyEmail,
      phone,
      linkedin,
      twitter,
      instagram,
      sourceCompanyEmails,
      sourceLinkedin,
      errors,
    } = this.state

    return (
      <Dialog
        fullWidth
        maxWidth='sm'
        data-cy='dialogContactContacts'
        open={open}
        aria-labelledby='max-width-dialog-title'
      >
        <DialogTitle id='max-width-dialog-title'>Entity</DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            {/* {['create', 'update'].includes(this.props.variant) && (
              <Grid item xs={12}>
                <ChannelsAutocomplete
                  error={errors['channel']}
                  value={channel}
                  placeholder={`Choose channel`}
                  onChange={(e, a) =>
                    this.onChange('channel')({ target: { value: a } })
                  }
                />
              </Grid>
            )} */}
            <Grid item xs={12}>
              <TextField
                mb={2}
                fullWidth
                label={'Company'}
                error={errors['company']}
                helperText={errors['company']}
                placeholder={'e.g. Company'}
                value={company}
                onChange={this.onChange('company')}
              />
            </Grid>

            <Grid item xs={8}>
              <TextField
                mb={2}
                fullWidth
                error={errors['linkedin']}
                helperText={errors['linkedin']}
                label={'LinkedIn'}
                data-cy='positionContactContacts'
                placeholder={'e.g. https://linkedin.com/user'}
                value={linkedin}
                onChange={this.onChange('linkedin')}
              />
            </Grid>

            <Grid item xs={4}>
              <SourceSelect
                id={'sourceLinkedin'}
                error={errors['sourceLinkedin']}
                value={sourceLinkedin}
                options={LINKED_ID_SOURCES}
                label={'LinkedIn Source'}
                onChange={this.onChange('sourceLinkedin')}
              />
            </Grid>

            <Grid item xs={8}>
              <TextField
                mb={2}
                fullWidth
                // disabled={variant === 'update'}
                label={'Generic Email'}
                data-cy='companyEmail'
                placeholder={'e.g. user@email.com'}
                value={companyEmail}
                error={errors['companyEmail']}
                helperText={errors['companyEmail']}
                onChange={this.onChange('companyEmail')}
              />
            </Grid>

            <Grid item xs={4}>
              <SourceSelect
                // disabled={variant === 'update'}
                id={'sourceCompanyEmails'}
                error={errors['sourceCompanyEmails']}
                value={sourceCompanyEmails}
                options={COMPANY_EMAIL_SOURCES}
                label={'Generic Email Source'}
                onChange={this.onChange('sourceCompanyEmails')}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <TextField
                mb={2}
                fullWidth
                label={'Phone'}
                data-cy='phoneContactContacts'
                placeholder={'Phone'}
                value={phone}
                onChange={this.onChange('phone')}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                mb={2}
                fullWidth
                label={'Instagram'}
                data-cy='positionContactContacts'
                placeholder={'e.g. https://instagram.com/user'}
                value={instagram}
                onChange={this.onChange('instagram')}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                mb={2}
                fullWidth
                label={'Twitter'}
                data-cy='positionContactContacts'
                placeholder={'e.g. https://twitter.com/user'}
                value={twitter}
                onChange={this.onChange('twitter')}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions px={7} py={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Button
                variant='outlined'
                color='default'
                mr={2}
                onClick={this.props.onClose}
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              xs={12}
              sm={6}
              container
              direction='row'
              justify='flex-end'
              alignItems='center'
            >
              <Button
                onClick={() => this.onSave()}
                variant='outlined'
                color='primary'
              >
                Save
              </Button>
              {entity && (!entity.status || entity.status === 'pending') && (
                <>
                  &nbsp;&nbsp;
                  <Button
                    onClick={() => this.onSave(true)}
                    variant='outlined'
                    color='primary'
                  >
                    Save & Publish
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }
}

export default EntityModal
