import { observable, action, computed, toJS } from 'mobx'
import { Delete, Get, Post, Put } from '../utils/request'
import hasError from './request-message'

// POST /admin/account-users { brand, email, role } - role = ‘brandOwner’, ‘user’
// GET /admin/account-users?brand=brandId - Brand users
// GET /admin/account-users/:accountUserId - Brand user details
// DELETE /admin/account-users/:accountUserId - remove user

export default class BrandUsers {
  brandId = null
  url = null
  @observable items = []
  @observable usersWithRole = []

  @observable thinking = true

  constructor (brandId) {
    this.brandId = brandId
    this.url = `/api/admin/account-users`
    this.load().then()
  }

  @action
  async load () {
    this.thinking = true

    const { errorCode, accountUsers, usersWithRole, message } = await Get(`${this.url}?brandId=${this.brandId}&filters[notStatus]=deleted`)

    if (!hasError(!errorCode, message)) {
      this.items = accountUsers
      this.usersWithRole = usersWithRole || []
    }

    this.thinking = false
  }

  @action
  async invite (details) {
    const { success, accountUser, message } = await Post(this.url, { ...details, brandId: this.brandId })
    this.load()
    this.thinking = false
    hasError(success, message, `Invite link successfully created`)
    return { success, accountUser }
  }

  @action
  async update ({ id, ...details }) {
    this.thinking = true
    const { success, message } = await Put(`${this.url}/${id}`, {
      contactPerson: { ...details }
    })
    this.load()
    this.thinking = false
    hasError(success, message, `Contact successfully updated`)
    return { success }
  }

  @action
  async updateRole(userId, accessId, role) {
    this.thinking = true
    const { success, message } = await Put(`/api/admin/brands-access/${accessId}/change-role`, { role })

    if (success) {
      // this.items = toJS(this.items).map(({ id, brandAccess, ...rest}) => {
      //   return {
      //     id,
      //     brandAccess: userId === id ? { ...brandAccess, role } : brandAccess,
      //     ...rest
      //   }
      // })
      this.load()
    }

    this.thinking = false
    hasError(success, message, `User role successfully updated`)
    return { success }
  }

  @action
  async remove (id) {
    this.thinking = true
    const { success, message } = await Delete(`${this.url}/${id}`, {})
    this.load()
    this.thinking = false
    hasError(success, message, `User successfully removed`)
    return { success }
  }

  @computed
  get users () {
    const invited = toJS(this.items).map(({ id, email, user, userId, role, status, brandAccess, ...other}) => {
      return {
        id,
        email,
        userId,
        role,
        status,
        name: status === 'joined' && user ? user.fullName : '-',
        brandAccess
      }
    })

    const brand = toJS(this.usersWithRole).map(({ id, role, user }) => {
      return {
        id: user.id,
        brand: true,
        email: user.email,
        userId: user.id,
        role,
        status: 'joined',
        name: user ? user.fullName : '-',
        brandAccess: {
          id, role, disabled: true
        }
      }
    })

    return [...brand, ...invited]
  }
}
