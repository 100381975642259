import { action, toJS } from 'mobx'
import List from './list'
import { Put } from '../utils/request'
import hasError from './request-message'

export default class CreatorsStore extends List {
  url = '/api/admin/creators'

  constructor() {
    super()
    this.order = 'desc'
    this.orderBy = 'createdAt'
    this.itemsField= 'creators'
    this.filters = { role: 'all', status: 'all' }
    this.getList().then()
  }

  @action
  async attach (channelId, creatorId) {
    const { success, message, creator } = await Put(`${this.url}/${creatorId}/attach-to-channel`, { channelId })

    if (!hasError(success, message, `Creator successfully attached`)) {
      this.items = toJS(this.items).map(c => {
        return c.id === creator.id ? creator : c
      })
    }

    return { success }
  }
}
