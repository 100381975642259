import React from 'react'
import Panel from './Panel'

import ChannelContent from './ChannelContent'

class ChannelDetails extends React.Component {
  state = { edit: false }

  onEdit = () => {
    this.setState({ edit: true })
  }

  onCancel = () => {
    this.setState({ edit: false })
  }

  onUpdate = async (details) => {
    this.setState({ thinking: true })
    const { success } = await this.props.onUpdate(details)
    this.setState({ edit: !success, thinking: false })
    return { success }
  }

  render () {
    const {
      thinking: loading,
      client,
      channel,
      channels,
      discovery
    } = this.props

    const {
      edit
    } = this.state

    return <Panel
      title="Channel Details"
      editable
      dataCy="editChannel"
      edit={edit}
      onEdit={this.onEdit}
    >
      {
        !loading &&
        <ChannelContent
          client={client}
          discovery={discovery}
          channel={channels.filter(({id}) => id === channel)[0]}
          edit={edit}
          onUpdate={this.onUpdate}
          onCancel={this.onCancel}
          onRemove={this.props.onRemove}/>
      }
    </Panel>
  }
}

export default ChannelDetails
