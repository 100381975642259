import * as language from 'numbro/languages/en-GB.js'
import { countries } from './countries'
import { decisionMakers } from './targetDecisionMaker'
import { classifications } from './classifications'

const CHANNELS = [
  { label: 'Website', value: 'website', hasLink: true },
  { label: 'YouTube', value: 'youtube', hasLink: true },
  { label: 'Facebook Ads', value: 'facebookAds' },
  { label: 'Facebook Page', value: 'facebookPage', hasLink: true },

  { label: 'Instagram Account', value: 'instagramAccount', hasLink: true },
  { label: 'Instagram Ads', value: 'instagramAds' },

  { label: 'LinkedIn', value: 'linkedIn', hasLink: true },
  { label: 'Twitter', value: 'twitter', hasLink: true },
  { label: 'Pinterest', value: 'pinterest', hasLink: true },

  { label: 'Google Ads', value: 'googleAds' },
  { label: 'Bing Ads', value: 'bingAds' },
]

const ANALYTICS_DRILL_BY = [
  // { id: 'touchpoint', label: 'Touchpoint' },
  { id: 'partner', label: 'Partners' },
  { id: 'campaign', label: 'Campaign' },
  { id: 'channelKind', label: 'Channel' },
]

const STATUSES = {
  EVENTS: [
    { label: 'Pending', value: 'pending' },
    { label: 'Approved', value: 'approved' },
    { label: 'Rejected', value: 'rejected' },
  ],
}

const COUPON_KINDS = [
  { label: 'Free Delivery', value: 'freeDelivery' },
  { label: 'Money Gift', value: 'moneyGift' },
  { label: 'Product Gift', value: 'productGift' },
  { label: 'Discount', value: 'discount' },
]

// const CLASSIFICATIONS = [
//   { label: 'Marketing', value: 'marketing' },
//   { label: 'Influencer', value: 'influencer' },
// ]

const BUSINESS_MODELS = [
  { label: 'B2B', value: 'b2b' },
  { label: 'B2C', value: 'b2c' },
  { label: 'C2C', value: 'c2c' },
  { label: 'D2C', value: 'd2c' },
  { label: 'C2B', value: 'c2b' },
]

const EMPLOYEE_COUNT = ['1-25', '26-50', '51-250', '251-500', '500+'].map(
  (value) => ({ label: value, value })
)
const AGE = [
  '8-24',
  '25-34',
  '35-44',
  '45-54',
  '55-64',
  '65-74',
  '75+',
  'All ages',
].map((value) => ({ label: value, value }))
const INCOME = [
  '0-10,000',
  '10,000-39,000',
  '40,000-84,000',
  '85,000- 149,000',
  '150,000-249,000',
  '250,000-499,000',
  '500,000+',
].map((value) => ({ label: value, value }))
const GENDER = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Other', value: 'other' },
]

let DECISION_MAKERS = []

for (let key in decisionMakers) {
  if (decisionMakers.hasOwnProperty(key)) {
    DECISION_MAKERS = [
      ...DECISION_MAKERS,
      ...decisionMakers[key].map((v) => ({ label: v, value: v, group: key })),
    ]
  }
}

const CLASSIFICATIONS = classifications.cats.map(
  ({ level1, level2, level3 }) => {
    const label = level1 || level2 || level3
    return { label, value: label }
  }
)

const LEVEL_1_CLASSIFICATIONS = ['Business', 'Content'].map((label) => ({
  label,
  value: label,
}))

const config = {
  LANGUAGE: language,
  LOCALE: 'en-GB',
  NUMBER_FORMAT: '0,0.00',
  NUMBER_CURRENCY_FORMAT: '$0,0.00',
  NUMBER_CURRENCY_SYMBOL: '£',
  DATE_FORMAT: 'YYYY-MM-DD',
  DATE_TIME_FORMAT: 'YYYY-MM-DD HH:mm',
  DATE_PERIOD_FORMAT: 'YYYY MMMM',
  GO_URL: process.env.GO_URL || 'https://dev.go.afill.co',
  GQL_LINK: process.env.GQL_LINK || 'https://nxt.api.breezy.io/graphql',
  AWS_S3_URL:
    process.env.AWS_S3_URL ||
    `https://j3deiryt8k.execute-api.eu-west-2.amazonaws.com/default/s3SignedRequestStage`,
  IMAGEKIT_URL:
    process.env.IMAGEKIT_URL || 'https://ik.imagekit.io/afill/staging',
  LOGIN_APP_URL: process.env.LOGIN_APP_URL || 'https://nxt.my.breezy.io/signin',
  APP_URL: process.env.APP_URL || 'https://nxt.my.breezy.io',
  PREVIEW: process.env.PREVIEW,
  CHANNELS,
  ANALYTICS_DRILL_BY,
  EVENT_STATUS: STATUSES.EVENTS,
  COUPON_KINDS,
  BUSINESS_MODELS,
  EMPLOYEE_COUNT,
  AGE,
  GENDER,
  INCOME,
  COUNTRIES: countries,
  DECISION_MAKERS,
  CLASSIFICATIONS,
  LEVEL_1_CLASSIFICATIONS,
}

export default config
