import { Delete, Get, Post, Put } from '../utils/request'
import { observable, action, computed, toJS } from 'mobx'
import { formatDate } from '../utils/format'
import hasError from './request-message'

class CreatorStore {
  @observable id
  @observable email
  @observable domain
  @observable channelId
  @observable contactId
  @observable inboxCount
  @observable acceptedCount
  @observable rejectedCount
  @observable status
  @observable emailStatus
  @observable createdAt

  @observable loading = true
  @observable thinking = false

  constructor (id) {
    this.id = id
    this.load().then()
  }

  @action
  async load () {
    const { errorCode, creator, /*balance,*/ message } = await Get(`/api/admin/creators/${this.id}`)

    if (errorCode) {
      return message
    }

    for (let key in creator) {
      if (creator.hasOwnProperty(key)) {
        this[key] = creator[key]
      }
    }

    this.loading = false
  }

  @action
  async attach (channelId, creatorId) {
    this.thinking = true

    const { success, message, creator } = await Put(`/api/admin/creators/${creatorId}/attach-to-channel`, { channelId })

    if (!hasError(success, message, `Creator successfully attached`)) {
      for (let key in creator) {
        if (creator.hasOwnProperty(key)) {
          this[key] = creator[key]
        }
      }
    }

    this.thinking = false

    return { success }
  }

  @computed
  get details () {
    const { id, email, domain, channelId, contactId, inboxCount, acceptedCount, rejectedCount, createdAt, status } = this
    return { id, email, domain, channelId, contactId, inboxCount, acceptedCount, rejectedCount, createdAt: formatDate(createdAt), status }
  }
}

export default CreatorStore
