import React from 'react'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Delete as RemoveIcon } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'
import Panel from '../Panel'
import List from '../List'
import ExistingOrNewModal from '../Modals/ExistingOrNewModal'
import ContactExistingModal from '../Contacts/ContactExistingModal'
import { ContactContactDialog } from '../../pages/ContactRequest/Contacts'
import ConfirmDialog from '../ConfirmDialog'

import { EntityContactsStore } from '../../stores/entity'

const columns = [
  { id: 'createdAt', disablePadding: true, label: 'Created' },
  { id: 'status', disablePadding: true, label: 'Status', kind: 'status' },
  { id: 'firstName', disablePadding: true, label: 'First name' },
  { id: 'lastName', disablePadding: true, label: 'Last Name' },
  { id: 'jobTitle', disablePadding: true, label: 'Job title' },
  { id: 'email', disablePadding: true, label: 'Email' },
  { id: 'actions', disablePadding: true, label: '', kind: 'actions' },
]

const RemoveContact = ({ contactId, onRemove }) => {
  return (
    <>
      <ConfirmDialog
        title={'Remove Contact from Entity'}
        confirmation
        confirmationText={
          'Are you sure you want to remove this contact from entity?'
        }
        action={() => onRemove(contactId)}
        confirmButtonLabel={'Remove'}
        confirmButtonProps={{
          variant: 'contained',
          color: 'primary',
        }}
      >
        <IconButton size={'small'}>
          <RemoveIcon fontSize={'small'} />
        </IconButton>
      </ConfirmDialog>
    </>
  )
}

@withRouter
@observer
class EntityContacts extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      edit: false,
      openAdd: false,
      openExisting: false,
      createOrAdd: false,
    }
    this.store = new EntityContactsStore(props.channelId)
  }

  onCreate = async (details, publish) => {
    const { success, contact } = await this.store.create(details, publish)

    if (success) {
      await this.store.attach(contact.id)
      this.setState({ openAdd: false })
      if (this.props.onCreate) {
        this.props.onCreate()
      }
    }
    return { success }
  }

  onExisting = async (contact) => {
    const { success } = await this.store.attach(contact.id)
    if (success) {
      this.setState({ openExisting: false })
      if (this.props.onCreate) {
        this.props.onCreate()
      }
    }
    return { success }
  }

  onRemove = async (id) => {
    return await this.store.detach(id)
  }

  onClickRow = (e, rowId) => {
    window.open(`/contacts/${rowId}`, '_blank')
  }
  render() {
    const { contacts } = this.store
    const { openAdd, openExisting, createOrAdd } = this.state

    return (
      <Panel
        title={'Contacts'}
        adding
        onAdd={() => this.setState({ createOrAdd: true })}
      >
        <List
          disablePagination
          columns={columns}
          items={contacts.map((c) => ({
            ...c,
            actions: () => (
              <RemoveContact
                contactId={c.id}
                onRemove={(id) => this.onRemove(id)}
              />
            ),
          }))}
          emptyMessage={'No contacts matching the given criteria.'}
          emptyActionText={'Add Contact to Entity'}
          onEmptyAction={() => this.setState({ createOrAdd: true })}
          onClickRow={this.onClickRow}
        />

        <ExistingOrNewModal
          open={createOrAdd}
          onNew={() => this.setState({ openAdd: true, createOrAdd: false })}
          onExisting={() =>
            this.setState({ openExisting: true, createOrAdd: false })
          }
          onClose={() => this.setState({ createOrAdd: false })}
        />

        <ContactContactDialog
          variant={'create'}
          open={openAdd}
          contact={{}}
          onClose={() => this.setState({ openAdd: false })}
          onCancel={() => this.setState({ openAdd: false })}
          onSave={this.onCreate}
        />

        <ContactExistingModal
          open={openExisting}
          onAttach={this.onExisting}
          onClose={() => this.setState({ openExisting: false })}
        />
      </Panel>
    )
  }
}

export default EntityContacts
