import React from 'react'
import styled from 'styled-components'
import {Link, withRouter} from 'react-router-dom'
import Helmet from 'react-helmet'
import {
  Avatar,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Input,
  InputLabel,
  Button as MuiButton,
  Paper,
  Typography
} from '@material-ui/core'
import {spacing} from '@material-ui/system'
import Validator from '../../utils/validator'


const Button = styled(MuiButton)(spacing);

const Wrapper = styled(Paper)`
  padding: ${props => props.theme.spacing(6)}px;

  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)}px;
  }
`;

const BigAvatar = styled(Avatar)`
  width: 92px;
  height: 92px;
  text-align: center;
  margin: 0 auto ${props => props.theme.spacing(5)}px;
`;

const FormErrorText = styled(FormHelperText)`
  color: #f44336;
  position: absolute;
  bottom: -18px;
`;

const schema = [
  {
    field: 'email',
    rules: 'required|valid_email',
    messages: {
      required: 'Email is required',
      valid_email: 'Please enter valid email'
    }
  },
  {
    field: 'password',
    rules: 'required',
    messages: {
      required: 'Password is required'
    }
  }
]

@withRouter
class SignIn extends React.Component {
  static defaultProps = {
    signin: console.log,
    thinking: false,
    onSuccess: console.log
  }

  state = {email: '', password: '', errors: {}, error: null}

  onChange = name => ({target: {value}}) => {
    this.setState({[name]: value})
    this.resetError(name)
    if (this.props.onChange) {
      this.props.onChange({[name]: value})
    }
  }

  resetError = name => {
    const errors = this.state.errors || {}
    errors[name] = null
    this.setState({errors, error: null})
  }

  onSubmitForm = (event) => {
    event.preventDefault()
    Validator('login-form', schema,
      errors => this.setState({errors}),
      async () => {
        const {email, password} = event.target

        const {error, message} = await this.props.signin({email: email.value, password: password.value})
        if (message) {
          switch (message) {
            case 'emailExists':
              this.setState({errors: { email: 'User with given email already exist' }, error: true})
              break;
            case 'noEmail':
              this.setState({errors: { email: 'Email not found' }, error: true})
              break;
            case 'wrongPassword':
              this.setState({errors: { password: 'Your password is not correct.' }, error: true})
              break;
            default:
              this.setState({errors: { email: message }, error: true})
          }
        }
        if (!error) {
          this.props.history.push('/users')
        }
      })
  }

  render() {
    const {
      email,
      password,
      errors
    } = this.state

    const {
      thinking
    } = this.props

    return (
      <Wrapper>
        <Helmet title="Sign In"/>
        {/*<BigAvatar alt="Lucy" src="/static/img/avatars/avatar-1.jpg" />*/}

        {/*<Typography component="h1" variant="h4" align="center" gutterBottom>*/}
        {/*  Welcome back, Lucy!*/}
        {/*</Typography>*/}

        <Typography component="h1" variant="h4" align="center">
          Sign in to your account to continue
        </Typography>
        <form
          name={'login-form'}
          onSubmit={this.onSubmitForm}
          noValidate
        >
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="email">Email Address</InputLabel>
            <Input
              label={'Email'}
              variant={'filled'}
              fieldType='clearable'
              id={'email'}
              disabled={thinking}
              value={email}
              onChange={this.onChange('email')}
              error={errors.email}
              aria-describedby="error-email"
              autoFocus
            />
            <FormErrorText id="error-email">{errors.email}</FormErrorText>
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              id={'password'}
              label='Password'
              type="password"
              fieldType='password'
              disabled={thinking}
              value={password}
              onChange={this.onChange('password')}
              error={errors.password}
              aria-describedby="error-password"
            />
            <FormErrorText id="error-password">{errors.password}</FormErrorText>
          </FormControl>
          <FormControlLabel
            control={<Checkbox value="remember" color="primary"/>}
            label="Remember me"
          />
          <Button
            type="submit"
            disabled={thinking}
            fullWidth
            variant="contained"
            color="primary"
            mb={2}
          >
            Sign in
          </Button>
        </form>
      </Wrapper>
    )
  }
}

export default SignIn;
