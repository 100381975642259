import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import styled, {css} from 'styled-components'
import MuiButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import './ImageUpload'
import ImageUpload from "./ImageUpload";
import { spacing } from '@material-ui/system'
import { uploadSingle } from '../utils/upload'
import config from '../config'
import is from 'is_js'

const Button = styled(MuiButton)(spacing)
const DialogContent = styled(MuiDialogContent)(spacing)
const DialogActions = styled(MuiDialogActions)(spacing)
const LogoContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${props => props.editable && css`
    cursor: pointer;
`}
`

const Logo = styled.div`
  width: 150px;
  height: 150px;
  background-image: url(${props => props.logo});
`


export default function LogoEditable(props) {
  const [open, setOpen] = React.useState(props.open);
  // const [editable] = React.useState(props.editable);
  const [image, setCroppedImage] = React.useState(props.image);
  const [disabled, setDisabled] = React.useState(props.thinking || props.disabled);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const handleSave = async () => {
    setDisabled(true)
    const file = await fetch(image)
      .then(res => res.blob())
      .then(blob => {
        return new File([blob], `${props.name || ''}${(new Date()).getTime()}.png`,{ type: "image/png" })
      })

    const { success, url } = await uploadSingle(file)
    setDisabled(false)
    setCroppedImage(url)
    props.onChange(url)
    setOpen(false)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setCroppedImage(null);
  };

  const editable = props.editable;

  const imageResult = () => {
    if (is.url(props.image)) {
      const splited = props.image.split('/')
      const splitedLength = splited.length
      const lastUrlParts = splited.slice(splitedLength - 2)
      return `${config.IMAGEKIT_URL}/${lastUrlParts.join('/')}?tr=h-150,w-150`
    }
    return null
  }

  return (
    <>
      <LogoContainer editable={editable} onClick={!!editable && handleClickOpen}>
        <Logo logo={imageResult() || 'https://placehold.it/150x150'} />
        {!!editable && <Typography>
          Edit logo
        </Typography>}
      </LogoContainer>
      <Dialog
        onClose={handleClose}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        aria-labelledby="Edit Logo"
        open={open}>
        <DialogTitle id="logo-edit" onClose={handleClose}>
          Update Logo
        </DialogTitle>
        <DialogContent py={0}>
          <ImageUpload onUpdate={setCroppedImage} thinking={disabled}/>
        </DialogContent>
        <DialogActions px={7} mb={2} mt={-3}>
          <Button autoFocus onClick={handleClose} color={'default'} disabled={disabled}>
            Discard
          </Button>
          <Button autoFocus onClick={handleSave} color="primary" variant={'outlined'} disabled={disabled || !image}>
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
