import React from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch, withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import AuthLayout from './layouts/Auth'
import DashboardLayout from './layouts/Dashboard'
import Page404 from './pages/NotFound/Page404'
import Notification from './components/Notification'
import { PrivateRoute, PublicRoute } from './components/Route'
import {
  authRoutes,
  sideBarTrackerRoutes,
  dashboardTrackerRoutes,
  sideBarDiscoveryRoutes,
  dashboardDiscoveryRoutes,
  roleRoutes
} from './routes/index'
import Loader from './components/Loader'



const renderRoute = ({ id, path, children, publicRoute, ...routeProps}, layout, discovery) => {
  const Component = publicRoute ? PublicRoute : PrivateRoute
  if (!children) {
    return <Route
      key={id}
      path={path}
      exact
      render={props => <Component {...routeProps} {...props} discovery={discovery} layout={layout} />}
    />
  }
  return children.map(child => {
    const { id: childId, path: childPath, ...childRouteProps } = child
    return <Route
      key={childId}
      path={childPath}
      exact
      render={props => <Component {...childRouteProps} {...props} discovery={discovery} layout={layout} />}
    />
  })
}

const roleRootRoute = role => {
  return '/brands'
}


const NotificationConnected = inject(stores => ({
  message: stores.NotificationStore.message,
  variant: stores.NotificationStore.variant,
  timeout: stores.NotificationStore.timeout,
  open: stores.NotificationStore.open,
  onExited: () => {},
  onClose: () => stores.NotificationStore.remove(),
}))(observer(Notification))

@withRouter
@inject(stores => ({
  authorized: stores.AuthorizationStore.authorized,
  user: stores.AuthorizationStore.user,
  onLogout: () => stores.AuthorizationStore.logout()
}))
@observer
class DashboardLayoutWithSideBarRoutes extends React.Component {
  onChangeApp = (event, tabIndex) => {
    let url = '/analytics'

    if (tabIndex === 1) {
      url = '/brands'
    }
    this.props.history.push({ pathname: url })
  }
  render () {
    const {
      user: {
        role
      },
      discovery
    } = this.props
    const sidebarRoutes = discovery ? sideBarDiscoveryRoutes : sideBarTrackerRoutes

    return <DashboardLayout
      routes={roleRoutes(sidebarRoutes, role)}
      {...this.props}
      tabIndex={discovery ? 1 : 0}
      onChangeApp={this.onChangeApp}
    />
  }
}

const isDiscoveryRoutes = ({ pathname }) => {
  return false //pathname.indexOf('/discovery') > -1
}

@withRouter
@inject(stores => ({
  role: (stores.AuthorizationStore.user || {}).role,
  checkAuthorization: stores.AuthorizationStore.checkAuthorization,
}))
@observer
class Routes extends React.Component {
  constructor (props) {
    super(props)
    const { location } = props
    this.state = { discovery: isDiscoveryRoutes(location) }
  }

  componentDidMount () {
    const { location } = this.props
    this.setState({ discovery: isDiscoveryRoutes(location) })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { location } = this.props
    const { location: prevLocation } = prevProps
    if (location.pathname !== prevLocation.pathname) {
      this.setState({ discovery: isDiscoveryRoutes(location) })
    }
  }

  render () {
    const {
      role,
      checkAuthorization: loading
    } = this.props
    const {
      discovery
    } = this.state

    const dashboardRoutes = discovery ? dashboardDiscoveryRoutes : dashboardTrackerRoutes
    const sidebarRoutes = discovery ? sideBarDiscoveryRoutes : sideBarTrackerRoutes

    return (
      <React.Fragment>
        <NotificationConnected />
        { loading && <Loader /> }
        {
          !loading && (
            <Switch>
              { roleRoutes(dashboardRoutes, role).map(route => renderRoute(route, DashboardLayoutWithSideBarRoutes, discovery)) }
              { roleRoutes(sidebarRoutes, role).map(route => renderRoute(route, DashboardLayoutWithSideBarRoutes, discovery)) }

              {
                authRoutes.map(route => renderRoute({
                  ...route,
                  publicRoute: true,
                  redirectToIfAuthorized: roleRootRoute(role)
                }, AuthLayout))
              }

              <Route
                path={'/'}
                exact
                render={() => <Redirect to={{ pathname: role? roleRootRoute(role) : '/signin' }} />}
              />

              <Route
                exact
                path="*"
                render={() => role ? <Page404 /> : <Redirect to={{ pathname: '/signin' }} />}
              />

            </Switch>
          )
        }
      </React.Fragment>
    )
  }
}

export default Routes;
