import React from 'react'
import List from '../../components/List'
import DashboardPage from '../../layouts/DashboardPage'
import EntityModal from '../../components/Entities/EntityModal'

const columns = [
  { id: 'createdAt', disablePadding: true, label: 'Created' },
  { id: 'status', disablePadding: true, label: 'Status', kind: 'status' },
  { id: 'company', disablePadding: true, label: 'Company' },
  { id: 'companyEmail', disablePadding: true, label: 'Email' },
]

const Entities = (props) => {
  const [thinking, setThinking] = React.useState(false)
  const [openAdd, setOpenAdd] = React.useState(false)

  const onCreate = async (details, publish) => {
    setThinking(true)
    const { success } = await props.onCreate(details, publish)
    if (success) {
      setOpenAdd(false)
    }
    setThinking(false)
    return { success }
  }

  return (
    <DashboardPage
      kind='list'
      title='Entities'
      addLabel='Add Entity'
      addClick={() => setOpenAdd(true)}
    >
      <List
        columns={columns}
        {...props}
        searchable
        emptyMessage={'No entities matching the given criteria.'}
      />

      <EntityModal
        thinking={thinking}
        variant={'create'}
        open={openAdd}
        onSave={onCreate}
        onClose={() => setOpenAdd(false)}
        entity={{}}
      />
    </DashboardPage>
  )
}

export default Entities
