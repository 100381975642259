import React from 'react'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import Channels from './Channels'
import ChannelIcon from '../../components/Icon'
import { ChannelsStore } from '../../stores/channels'
import { formatDate } from '../../utils/format'

const ChannelLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ChannelLink = ({ icon, link }) => (
  <ChannelLinkContainer>
    <ChannelIcon item={{ icon }}/> { link }
  </ChannelLinkContainer>
)

const itemsTime = (items = []) => {
  return items.map(({ createdAt, brands, link, icon, ...other}) => {
    return {
      ...other,
      link: <ChannelLink icon={icon} link={link} />,
      createdAt: formatDate(createdAt)
    }
  })
}

@withRouter
@observer
class _Channels extends React.Component {
  constructor(props) {
    super(props)
    this.store = new ChannelsStore()
  }

  onChangeSort = orderByNew => e => {
    const { listProps } = this.store
    const { order, orderBy } = listProps
    let orderNew = ''
    if (orderBy === orderByNew) {
      if (order === 'desc') {
        orderNew = 'asc'
      } else {
        orderNew = 'desc'
      }
    } else {
      orderNew = 'desc'
    }

    this.store.onChangeSort(orderNew, orderByNew)
  }

  onChangePage = (e, page) => this.store.onChangePage(page)

  onChangeRowsPerPage = ({ target: { value }}) => this.store.onChangeRowsPerPage(value)

  onChangeFilters = name => object => {
    let value = null

    const field = name === 'name' ? 'search' : name

    if (object.hasOwnProperty('target')) {
      value = object.target.value
    } else {
      value = object
    }
    this.store.onChangeFilter(field, value)
  }

  onClickRow = (e, id) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(`/channels/${id}`, '_blank')
    } else {
      this.props.history.push(`/channels/${id}`)
    }
  }

  onCreate = async (details) => {
    const { success, channel } = await this.store.create(details)

    if (success) {
      this.props.history.push(`/channels/${channel.id}`)
    }

    return { success: true }
  }

  onAddToEcosystem = async (ecosystemId, channelId) => {
    return this.store.addChannelFromEcosystem(ecosystemId, channelId)
  }

  render () {
    const {
      listProps
    } = this.store

    const {
      items
    } = listProps

    return <Channels
      {...this.props}
      {...listProps}
      items={itemsTime(items)}
      onChangeSearch={this.onChangeSearch}
      onChangeSort={this.onChangeSort}
      onChangeFilters={this.onChangeFilters}
      onChangePage={this.onChangePage}
      onChangeRowsPerPage={this.onChangeRowsPerPage}
      onClickRow={this.onClickRow}
      onCreate={this.onCreate}
      onAddToEcosystem={this.onAddToEcosystem}
    />
  }
}

export default _Channels
