import React from 'react'
import styled from 'styled-components'
import List from '../../components/List'
import DashboardPage from '../../layouts/DashboardPage'
import { Select, MenuItem } from '@material-ui/core'
import { GetBlob } from '../../utils/request'

const columns = [
  { id: 'createdAt', disablePadding: true, label: 'Created' },
  { id: 'status', disablePadding: true, label: 'Status', kind: 'status' },
  {
    id: 'brandName',
    disablePadding: true,
    label: 'Brand',
    kind: 'link',
    link: { title: 'Brand', pattern: `/brands/{id}`, keys: { id: 'brand' } },
  },
  {
    id: 'channelName',
    disablePadding: true,
    label: 'Channel',
    kind: 'link',
    link: {
      title: 'Channel',
      pattern: `/channels/{id}`,
      keys: { id: 'channel' },
    },
  },
]

const contactRequestStatusOptions = [
  { value: 'requested', label: 'Pending' },
  { value: 'found', label: 'Found' },
  { value: 'entityFound', label: 'Entity Found' },
  { value: 'unsuccessful', label: 'Rejected' },
]

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 200px;
  & p {
    display: none;
  }
  padding-right: 16px;
`

const StatusSelect = ({ selected, onSelect }) => {
  const selectedValue = selected || 'all'
  return (
    <SelectWrapper>
      <strong>Status: </strong>
      &nbsp;&nbsp;&nbsp;
      <Select
        fullWidth
        value={selectedValue}
        label='Status'
        onChange={(e) => onSelect(e.target.value)}
      >
        <MenuItem value={'all'}>All</MenuItem>
        {contactRequestStatusOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </SelectWrapper>
  )
}

export default class ContactRequests extends React.Component {
  state = { thinking: false, showUpload: false, data: null }

  handleOnDrop = (parsed) => {
    const result = parsed.map(({ data }) => data)
    result.slice(0, 1)
    this.setState({ showUpload: true, data: result })
  }

  handleOnRemoveFile = () => {
    this.setState({ showUpload: false, data: null })
  }

  onUploadCSV = async () => {
    const { data } = this.state
    this.setState({ thinking: true })

    await this.props.onUploadCSV(data)

    this.setState({ thinking: false, showUpload: false, data: null })
  }

  onExportToCSV = async () => {
    await GetBlob(`/api/admin/contact-requests/csv?filters[status]=requested`)
  }

  render() {
    const { showUpload, thinking } = this.state

    // console.log(this.props)

    return (
      <DashboardPage
        // noActions
        kind='list'
        title='Contact Requests'
        addLabel='Export to CSV'
        addClick={this.onExportToCSV}
      >
        <List
          columns={columns}
          {...this.props}
          emptyMessage={'No Partners matching the given criteria.'}
          searchable
          additional={StatusSelect}
          additionalProps={{
            selected: this.props.filters.status,
            onSelect: this.props.onChangeFilters('status'),
          }}
          onChangeFilters={this.props.onChangeFilters}
        />
      </DashboardPage>
    )
  }
}
