import React from 'react'
import DashboardPage from '../../layouts/DashboardPage'
import DetailsForm from '../../components/Details'
import { ContactContactDialog } from '../ContactRequest/Contacts'

const config = [
  { key: 'id', name: 'ID', type: 'string' },
  { key: 'firstName', name: 'First Name', type: 'string' },
  { key: 'sourceFirstName', name: 'First Name Source', type: 'string' },
  { key: 'lastName', name: 'Last Name', type: 'string' },
  { key: 'sourceLastName', name: 'Last Name Source', type: 'string' },
  { key: 'jobTitle', name: 'Job title', type: 'string' },
  { key: 'email', name: 'Email', type: 'string' },
  { key: 'sourceEmail', name: 'Email Source', type: 'string' },
  { key: 'phone', name: 'Phone', type: 'string' },
  { key: 'linkedin', name: 'LinkedIn', type: 'string' },
  { key: 'sourceLinkedin', name: 'LinkedIn Source', type: 'string' },
  { key: 'twitter', name: 'Twitter', type: 'string' },
  { key: 'instagram', name: 'Instagram', type: 'string' },
  { key: 'createdAt', name: 'Create at', type: 'string' },
  { key: 'publishedAt', name: 'Published at', type: 'string' },
  { key: 'validatedAt', name: 'Validated at', type: 'string' },
  { key: 'optedoutAt', name: 'Opted out at', type: 'string' },
  { key: 'bouncedAt', name: 'Bounced at', type: 'string' },
  { key: 'status', name: 'Status', kind: 'status' },
]

export default class User extends React.Component {
  state = {
    edit: false,
  }

  onEdit = () => this.setState({ edit: true })
  onCancel = () => this.setState({ edit: false })

  onSave = async (details, publish) => {
    const { success } = await this.props.onSave(details, publish)

    if (success) {
      this.onCancel()
    }

    return { success: true }
  }

  onRemove = async (id) => {
    const { success } = await this.props.onRemove(id)

    if (success) {
      this.onCancel()
    }

    return { success }
  }

  render() {
    const { details, loading } = this.props

    return (
      <DashboardPage
        kind='details'
        title={'Contact'}
        noActions
        thinking={loading}
      >
        {!loading && (
          <DetailsForm
            notEditable={!['pending', 'published'].includes(details.status)}
            config={config}
            details={details}
            onEdit={this.onEdit}
          />
        )}

        <ContactContactDialog
          variant={'update'}
          open={this.state.edit}
          contact={details}
          onSave={this.onSave}
          onRemove={this.onRemove}
          onCancel={this.onCancel}
        />
      </DashboardPage>
    )
  }
}
