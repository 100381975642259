import React from 'react'
import { find, findIndex } from 'lodash'
import {
  Grid,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  InputAdornment,
  MenuItem, Button as MuiButton
} from '@material-ui/core'
import Panel from './Panel'
import List from './List'
import styled from "styled-components";
import {spacing} from "@material-ui/system";
import {formatCurrency} from "../utils/format";

const Button = styled(MuiButton)(spacing)
const DialogActions = styled(MuiDialogActions)(spacing)

export class TouchpointDialog extends React.Component {
  state = {
    id: null,
    name: null,
    kind: null,
    lookback: null,
    costModel: null,
    cost: null,
    cost1: null,
    revenueModel: null,
    revenue: null,
    revenue1: null
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (!prevProps.open && this.props.open) {
      if (!this.props.touchpoint) {
        this.setState({
          id: null,
          name: null,
          kind: null,
          lookback: null,
          costModel: null,
          cost: null,
          cost1: null,
          revenueModel: null,
          revenue: null,
          revenue1: null
        })
      } else {
        this.setState({ ...this.props.touchpoint })
      }
    }
  }

  onCancel = () => this.props.onCancelEditTouchpoint()

  onSave = () => {
    const {
      id,
      name,
      kind,
      lookback,
      costModel,
      cost,
      cost1,
      revenueModel,
      revenue,
      revenue1
    } = this.state

    return this.props.onSaveTouchpoint({
      id,
      name,
      kind,
      lookback,
      costModel,
      cost,
      ...( costModel === 'H' && { cost1 }),
      revenueModel,
      revenue,
      ...( revenueModel === 'H' && { revenue1 })
    })
  }

  onRemove = () => this.props.onRemoveTouchpoint(this.state.id)

  onChange = field => ({ target: { value } }) => this.setState({ [field]: value })

  render () {
    const {
      open
    } = this.props

    const {
      id,
      name,
      kind,
      lookback,
      costModel,
      cost,
      cost1,
      revenueModel,
      revenue,
      revenue1
    } = this.state
    const {
      touchpoint,
      campaign
    } = this.props

    const size = model => model === 'H' ? 3 : 6
    const adornment = model => model === 'S' ? '%' : '£'
    const canBeRemoved = (campaign && touchpoint && touchpoint.refId) || (!campaign && touchpoint && touchpoint.id)

    return (
      <Dialog
        fullWidth
        data-cy='addTouchpointModal'
        maxWidth="sm"
        open={open}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          Define Touchpoint
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={3}>
            {/* Base info */}
            <Grid item xs={6}>
              <TextField
                fullWidth
                data-cy="nameTouchpoint"
                label="Name"
                m={2}
                value={name}
                onChange={this.onChange('name')}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                select
                fullWidth
                data-cy="kindTouchpoint"
                label="Kind"
                m={2}
                value={kind}
                onChange={this.onChange('kind')}
              >
                <MenuItem  data-cy="kindTouchpointThrough" value={'Click Through'}>Click Through</MenuItem>
                <MenuItem data-cy="kindTouchpointSale"value={'Sale'}>Sale</MenuItem>
                <MenuItem data-cy="kindTouchpointFirst"value={'First Sale'}>First Sale</MenuItem>
                <MenuItem data-cy="kindTouchpointReport" value={'Repeat Sale'}>Repeat Sale</MenuItem>
                <MenuItem data-cy="kindTouchpointAction" value={'Action'}>Action</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Lifetime"
                data-cy="lifetimeTouchpoint"
                m={2}
                inputProps={{type: 'number', min: 1}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">days</InputAdornment>,
                }}
                value={lookback}
                onChange={this.onChange('lookback')}
              />
            </Grid>

            {/* Payout info */}
            <Grid container spacing={3}>
              {/*xs={costModel === 'N' ? 12 : 6}*/}
              <Grid item alignContent={'center'} xs={6}>
                <TextField
                  data-cy="payoutTypeTouchpoint"
                  select
                  label={'Payout type'}
                  fullWidth
                  value={costModel}
                  onChange={this.onChange('costModel')}
                >
                  <MenuItem data-cy="payoutTypeTouchpointA" value={'A'}>Cost Per Action</MenuItem>
                  <MenuItem data-cy="payoutTypeTouchpointS" value={'S'}>Revenue Share</MenuItem>
                  <MenuItem data-cy="payoutTypeTouchpointH" value={'H'}>Hybrid</MenuItem>
                  <MenuItem data-cy="payoutTypeTouchpointN" value={'N'}>No Payout</MenuItem>
                </TextField>
              </Grid>
              {
                costModel !== 'N' && <Grid item xs={size(costModel)}>
                  <TextField
                    data-cy="payoutTypeTouchpointAmount"
                    fullWidth
                    inputProps={{type: 'number', step: 0.01, min: 0}}
                    label={costModel === 'H' ? 'Fixed payout' : 'Amount'}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">{adornment(costModel)}</InputAdornment>,
                    }}
                    m={2}
                    value={cost}
                    onChange={this.onChange('cost')}
                  />
                </Grid>
              }
              {
                costModel === 'H' &&
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    label="Revenue share"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">%</InputAdornment>,
                    }}
                    m={2}
                    value={cost1}
                    onChange={this.onChange('cost1')}
                  />
                </Grid>
              }
            </Grid>

            {/* Revenue info */}
            {/*xs={revenueModel === 'N' ? 12 : 6}*/}
            <Grid container spacing={3}>
              <Grid item alignContent={'center'} xs={6} >
                <TextField
                  select
                  data-cy="revenueTypeTouchpoint"
                  label={'Revenue type'}
                  fullWidth
                  value={revenueModel}
                  onChange={this.onChange('revenueModel')}
                >
                  <MenuItem data-cy="revenueTypeTouchpointA" value={'A'}>Cost Per Action</MenuItem>
                  <MenuItem data-cy="revenueTypeTouchpointS" value={'S'}>Revenue Share</MenuItem>
                  <MenuItem data-cy="revenueTypeTouchpointH" value={'H'}>Hybrid</MenuItem>
                  <MenuItem data-cy="revenueTypeTouchpointN" value={'N'}>No Revenue</MenuItem>
                </TextField>
              </Grid>
              {
                revenueModel !== 'N' && <Grid item xs={size(revenueModel)}>
                  <TextField
                    fullWidth
                    data-cy="revenueTypeTouchpointAmount"
                    label={ revenueModel === 'H' ? 'Fixed payout' :  'Amount' }
                    inputProps={{type: 'number', step: 0.01, min: 0}}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">{adornment(revenueModel)}</InputAdornment>,
                    }}
                    m={2}
                    value={revenue}
                    onChange={this.onChange('revenue')}
                  />
                </Grid>
              }
              {
                revenueModel === 'H' &&
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    label="Revenue share"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">%</InputAdornment>,
                    }}
                    m={2}
                    value={revenue1}
                    onChange={this.onChange('revenue1')}
                  />
                </Grid>
              }
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions px={6} py={4}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              {
                canBeRemoved && <Button onClick={this.onRemove} variant="outlined">
                  Archive Touchpoint
                </Button>
              }
            </Grid>
            <Grid item xs={12} sm={6}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center">
              <Button onClick={this.onCancel} color="default" mr={2}>
                Cancel
              </Button>
              <Button onClick={this.onSave} variant="outlined" color="primary">
                Save Touchpoint
              </Button>
            </Grid>
          </Grid>


        </DialogActions>
      </Dialog>
    )
  }
}

const getModel = (kind, model) => {
  if (model === 'H') {
    return `Hybrid`
  }
  return `${kind}P${model}`
}

const getType = (kind, model) => {
  if (model === 'N') {
    const no = kind === 'Cost' ? 'Payout' : kind
    return `No ${no}`
  }
  if (model === 'H') {
    return `Hybrid`
  }
  if (model === 'S') {
    return `Revenue Share`
  }
  return `${kind} Per Action`
}

const getValue = (kind, model, value, value1) => {
  if (model === 'N') return '-'
  const prefix = model === 'S' ? `${value}%` : formatCurrency(value)

  return `${prefix} ${model === 'H' ? `/ ${value1}% ` : ''}`
}

const normalizeItem = item => {
  return {
    ...item,
    cost: getValue('C', item.costModel, item.cost, item.cost1),
    revenue: getValue('R', item.revenueModel, item.revenue, item.revenue1),
    revenueModel: getType('Revenue', item.revenueModel),
    costModel: getType('Cost', item.costModel),
    lookback: `${item.lookback} days`
  }
}

const toTableData = (items = []) => {
  return items.map(normalizeItem)
}

export default class Touchpoints extends React.Component {
  constructor (props) {
    super(props)
    this.state = { open: false }
  }

  onAddTouchpoint = () => {
    this.setState({ open: true, touchpoint: undefined })
  }

  onEditTouchpoint = (e, id) => {
    this.setState({ open: true, touchpoint: find(this.props.items, ['id', id]) })
  }

  onSaveTouchpoint = async ({ id, ...details }) => {
    let result = {}
    if (!id) {
      result = await this.props.onCreate(details)
    } else {
      result = await this.props.onUpdate({ id, ...details })
    }
    this.setState({ open: !result.success })
  }

  onRemoveTouchpoint = async (id) => {
    const { success } = await this.props.onRemove(id)
    this.setState({ open: !success })
  }

  onCancelEditTouchpoint = () => {
    this.setState({ open: false })
  }

  render () {
    const {
      open,
      touchpoint
    } = this.state

    const {
      items,
      thinking,
      campaign,
      ...listProps
    } = this.props

    return (
      <Panel
        title="Touchpoints"
        dataCy="addTouchpoints"
        adding={!campaign}
        thinking={thinking}
        onAdd={this.onAddTouchpoint}
      >

        <List
          {...listProps}
          items={toTableData(this.props.items)}
          onClickRow={this.onEditTouchpoint}
          emptyMessage={'No Touchpoints are added.'}
          emptyActionText={!campaign && 'Add a Touchpoint'}
          onEmptyAction={this.onAddTouchpoint}
          disablePagination
        />

        <TouchpointDialog
          open={open}
          campaign={campaign}
          touchpoint={touchpoint}
          onSaveTouchpoint={this.onSaveTouchpoint}
          onRemoveTouchpoint={this.onRemoveTouchpoint}
          onCancelEditTouchpoint={this.onCancelEditTouchpoint}
        />

      </Panel>
    )
  }
}
