import React from 'react'
import List from '../../components/List'
import DashboardPage from '../../layouts/DashboardPage'

// {"id":"2022-05","used":97,"left":999903,"createdAt":"2022-05-05T04:52:31.114Z"}

const columns = [
  { id: 'id', disablePadding: true, label: 'ID' },
  { id: 'createdAt', disablePadding: true, label: 'Created at' },
  { id: 'batchUsed', disablePadding: true, label: 'Batch Used' },
  { id: 'batchLeft', disablePadding: true, label: 'Batch Left' },
  { id: 'restUsed', disablePadding: true, label: 'REST Used' },
  { id: 'restLeft', disablePadding: true, label: 'REST Left' }
]

export default class SimilarWebQuotas extends React.Component {
  render () {
    return (
      <DashboardPage
        noActions
        kind="list"
        title="Similarweb Quotas"
      >
        <List
          columns={columns}
          {...this.props}
          emptyMessage={'No data matching the given criteria.'}
        />
      </DashboardPage>
    )
  }
}
