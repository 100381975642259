import React from 'react'
import { find, findIndex } from 'lodash'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  Grid, TextField as MuiTextField,
  Typography,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent,
  DialogTitle,
  Button as MuiButton, Divider as MuiDivider,
  IconButton, CardContent, TableContainer, Table, TableHead, TableRow, TableCell, TableBody
} from '@material-ui/core'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import Panel from './Panel'
import List from './List'
import { Trash as IconDelete, PlusCircle as IconAdd } from 'react-feather'

const Button = styled(MuiButton)(spacing)
const DialogActions = styled(MuiDialogActions)(spacing)
const TextField = styled(MuiTextField)(spacing)
const Divider = styled(MuiDivider)(spacing)
const Add = styled(IconAdd)`
width: 16px;
height: 16px;
color: ${props => props.theme.palette.success.main};
`

const Delete = styled(IconDelete)`
width: 16px;
height: 16px;
color: ${props => props.theme.palette.grey[500]};
`

const macros = [
  'campaignId',
  'partnerId',
  'clickId',
  'country',
  'source',
  'clickSub',
  'clickSub2',
  'clickSub3',
  'clickSub4',
  'clickSub5',
  'extClickId'
]//.map(m => ({ value: m, label: m }))


const columns = [
  { id: 'index', disablePadding: true, label: '#' },
  { id: 'parameter', disablePadding: true, label: 'Parameter' },
  { id: 'value', disablePadding: true, label: 'Value' }
]

const normalizeTracking = (tracking = {}) => {
  let result = []
  let index = 1
  for (let key in tracking) {
    if (tracking.hasOwnProperty(key)) {
      result.push({
        index,
        parameter: key,
        value: tracking[key].t === 1 ? `{${tracking[key].n}}` : tracking[key].n
      })
    }
    index += 1
  }
  return result
}

const updateDialogData = data => {
  const options = macros.filter(m => {
    const index = findIndex(data, ['value', m])
    return true //index < 0
  }).map(m => ({ label: `{${m}}`, value: m }))
  const rows = data && data.length ? data.map(data => ({ ...data, id: Math.random() })) : [{ id: Math.random() }]
  return { options, rows }
}

const previewUrl = rows => {
  const queries = rows.map(r => {
    if (r.parameter && r.value) {
      return `${r.parameter}=${r.value}`
    }
    return null
  }).filter(r => !!r).join('&')
  return queries ? `Preview: ?${queries}` : null
}

const Head = () => (
  <TableHead>
    <TableRow>
      <TableCell>#</TableCell>
      <TableCell style={{paddingLeft: 5, paddingRight: 5}}>Parameter</TableCell>
      <TableCell style={{paddingLeft: 0, paddingRight: 0}}>Value</TableCell>
      <TableCell />
    </TableRow>
  </TableHead>
)

const Row = ({ index, parameter, value, error, options, onChange, onDelete, onAdd }) => (
  <TableRow>
    <TableCell style={{width: '1%', verticalAlign: "middle"}}>{ index + 1 }</TableCell>
    <TableCell style={{width: '40%', paddingLeft: 5, paddingRight: 5}}>
      <TextField
        fullWidth
        error={error}
        helperText={error}
        size={"small"}
        placeholder={"Enter parameter"}
        data-cy={"parameterTrackingParameter" + (index + 1)}
        value={parameter}
        mt={1.6}
        onChange={onChange(index, 'parameter')}
      />
    </TableCell>
    <TableCell style={{width: '40%', paddingLeft: 0, paddingRight: 0}}>
      <Autocomplete
        freeSolo
        options={options.map(o => o.label)}
        value={value}
        onChange={onChange(index, 'value')}
        renderInput={params => (
          <TextField fullWidth size={"small"}
                     data-cy={"valueTrackingParameter" + (index + 1)}
                     onBlur={onChange(index, 'value')}
                     placeholder={'Select value'}
                     InputProps={{
                       disableUnderline: true
                     }}
                     mt={1}
                     {...params}
          />
        )}/>
    </TableCell>
    <TableCell style={{width: '1%', whiteSpace: 'nowrap',  paddingLeft: 0, paddingRight: 0}}>
      {
        <IconButton
          color="default"
          data-cy={"deleteTrackingParameter" + (index + 1)}
          onClick={onDelete}
        >
          <Delete />
        </IconButton>
      }

      {
        onAdd && <IconButton
          color="success"
          data-cy={"addTrackingParameter" + (index + 1)}
          onClick={onAdd}
        >
          <Add />
        </IconButton>
      }
    </TableCell>
  </TableRow>
)

export class TrackingParametersDialog extends React.Component {
  constructor (props) {
    super(props)
    this.state = updateDialogData(props.data)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...updateDialogData(this.props.data) })
    }
  }

  onAdd = index => () => {
    const { rows } = this.state
    rows.splice(index + 1, 0, { id: Math.random() })
    this.setState({ rows })
  }

  onDelete = index => () => {
    const { rows } = this.state
    if (rows.length > 1) {
      this.setState({ rows: [ ...rows.slice(0, index), ...rows.slice(index + 1)]})
    } else {
      this.setState({ rows: [{ parameter: null, value: null }] })
    }
  }

  onSave = () => {
    this.props.onSave(this.state.rows)
  }

  onChange = (index, field) => ({ target: { value } }, value1) => {
    let rows = this.state.rows

    rows[index][field] = value1 || value

    if (field === 'parameter') {
      const i = findIndex(rows, ['parameter', rows[index][field]])
      if (i > -1 && i !== index) {
        rows[index].error = 'Parameters should unique and should not be equals'
      } else {
        rows[index].error = null
      }
    }

    this.setState({ rows })
  }

  render () {
    const {
      open
    } = this.props
    const {
      rows,
      options
    } = this.state

    return (
      <Dialog
        fullWidth
        data-cy='modalTrackingParameters'
        maxWidth="sm"
        open={open}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          Tracking Parameters
        </DialogTitle>

        <DialogContent>
          <Typography variant={"body2"} style={{color: '#707070'}} paragraph gutterBottom>
            {previewUrl(rows)}
          </Typography>


          <TableContainer>
            <Table size={'small'}>
              <Head />
              <TableBody>
                {
                  rows.map(({ parameter, value, error, id }, index) => {
                    return (
                      <Row
                        key={id}
                        index={index}
                        parameter={parameter}
                        value={value}
                        error={error}
                        options={options}
                        onAdd={rows.length - 1 === index ? this.onAdd(index) : null}
                        onDelete={this.onDelete(index)}
                        onChange={this.onChange}
                      />
                    )
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>


        </DialogContent>
        <DialogActions px={7} py={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
            </Grid>
            <Grid xs={12} sm={6}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center">
              <Button onClick={this.props.onCancel} color="default" mr={2}>
                Cancel
              </Button>
              <Button onClick={this.onSave} variant="outlined" color="primary">
                Save
              </Button>
            </Grid>
          </Grid>


        </DialogActions>
      </Dialog>
    )
  }
}

export default class TrackingParameters extends React.Component {
  static defaultProps = {
    tracking: {}
  }

  state = { edit: false }

  onEdit = () => {
    this.setState({ edit: true })
  }

  onSave = async (rows) => {

    const reg = /{(\w+)}/

    let result = {}

    rows.forEach(({ parameter, value }) => {
      if (parameter && value) {
        let v = value.match(reg)
        if (!v) {
          result[parameter] = { n: value }
        } else if (v && v[1]) {
          result[parameter] = {n: v[1], t: 1}
        }
      }
    })

    await this.props.onUpdate({ tracking: result })

    this.setState({ edit: false })
  }

  onCancel = () => {
    this.setState({ edit: false })
  }

  render () {
    const {
      edit
    } = this.state
    const {
      tracking
    } = this.props
    const data = normalizeTracking(tracking)

    return (
      <Panel
        title="Tracking Parameters"
        dataCy="editTrackingParameters"
        editable
        onEdit={this.onEdit}
      >
        <CardContent>
        <Typography variant={"body2"} style={{color: '#707070'}}>
          {previewUrl(data)}
        </Typography>
        </CardContent>
        <List
          columns={columns}
          items={data}
          emptyMessage={'No Tracking Parameters are added.'}
          emptyActionText={'Edit Tracking Parameters'}
          onEmptyAction={this.onEdit}
          disablePagination
        />

        <TrackingParametersDialog
          open={edit}
          data={data}
          onSave={this.onSave}
          onCancel={this.onCancel}
        />
      </Panel>
    )
  }
}
