import React from 'react'
import { Grid } from '@material-ui/core'
import Widget from './Widget'
import { formatCurrency } from '../utils/format'
import { Line } from 'react-chartjs-2'

const chartHeight = 167

const optionsA = {
  legend: false,
  maintainAspectRatio: false,
  layout: {
    // padding: {
    //   left: 12,
    //   right: 12,
    //   top: 0,
    //   bottom: 0
    // }
  },
  tooltips: {
    mode: 'index',
    position: 'nearest',
    intersect: false,
    callbacks: {
      label: function(tooltipItem, data) {
        let label = data.datasets[tooltipItem.datasetIndex].label || '';

        if (label) {
          label += ': ';
        }
        if (label !== 'Clicks: ' && label !== 'Conversions: ') {
          label += formatCurrency(tooltipItem.yLabel);
        }else {
          label += tooltipItem.yLabel
        }

        return label;
      }
    }
  },
  elements: {
    line: {
      fill: false,
      borderWidth: 1,
      // stepped: 'middle',
    },
    point: {
      radius: 0,
      hitRadius: 20
    }
  },
  scales: {
    xAxes: [{
      position: 'bottom',
      stacked: true,
      offset: true,
      barPercentage: 1,
      categoryPercentage: 0.2,
      // display: false,
      gridLines: {
        color: 'rgba(0,0,0,0.03)',
        drawBorder: false,
        display: false,
      },
      ticks: {
        fontColor: 'rgba(0,0,0,0.2)',
        autoSkipPadding: 20,
        fontSize: 9,
        maxRotation: 0
      }
    },
    ],
    yAxes: [{
      id: 1,
      stacked: true,
      ticks: {
        fontSize: 9,
        fontColor: 'rgba(0,0,0,0.2)',
        autoSkip: true,
        // autoSkipPadding: 100,
        padding: 5,
        min: 0,
        stepSize: 1
      },
      gridLines: {
        color: 'rgba(0,0,0,0.03)',
        drawBorder: false,
        drawTicks: true,
        zeroLineWidth: 0.2
      },
      scaleLabel: {
        display: true,
        labelString: 'Clicks',
        fontSize: 9,
        fontColor: 'rgba(0,0,0,0.4)',
      }
    },
      {
        id: 2,
        stacked: true,
        display: false,
        ticks: {
          min: 0,
          max: 0,
        }
      },
      {
        id: 3,
        stacked: true,
        position: 'right',
        scaleLabel: {
          display: true,
          labelString: 'Conversions',
          fontSize: 9,
          fontColor: 'rgba(0,0,0,0.4)',
        },
        ticks: {
          fontSize: 9,
          fontColor: 'rgba(0,0,0,0.2)',
          autoSkip: true,
          autoSkipPadding: 100,
          padding: 5,
          min: 0,
          stepSize: 1
        },
        gridLines: {
          color: 'rgba(0,0,0,0.03)',
          drawBorder: false,
          zeroLineWidth: 0,
          display: false
        },
      }
    ],
  }
}

const Chart = ({ labels, data }) => {
  const chartDatasets = [
    {
      field: 'clicks',
      label: 'Clicks',
      yAxisID: 1,
      type: 'line',
      borderColor: 'rgb(21,101,192)',
      backgroundColor: 'rgba(21,101,192,0.5)',
      data: [1865, 404, 2255, 792, 1947, 1864, 480],
    },
    {
      field: 'conversions',
      label: 'Conversions',
      yAxisID: 3,
      type: 'bar',
      borderColor: 'rgb(33,150,243)',
      backgroundColor: 'rgba(33,150,243,0.5)',
      data: [504, 21, 354, 13, 125, 234, 1],
    },
    {
      label: 'Payout',
      field: 'cost',
      yAxisID: 2,
      showLine: false,
      borderColor: 'rgb(91,193,255)',
      backgroundColor: 'rgba(91,193,255,0.5)',
      data: [190, 409, 304, 74, 267, 524, 743],
    },
    {
      field: 'profit',
      label: 'Profit',
      yAxisID: 2,
      showLine: false,
      borderColor: 'rgb(187,222,251)',
      backgroundColor: 'rgba(187,222,251,0.5)',
      data: [150, -150, 150, 150, 150, 150, 150],
    },
    {
      field: 'revenue',
      label: 'Revenue',
      yAxisID: 2,
      showLine: false,
      pointRadius: 0,
      borderColor: 'rgb(255,250,253)',
      backgroundColor: 'rgba(255,251,254, 0.5)',
      data: [190+150, 409+150, 304+150, 74+150, 267+150, 524+150, 743+150],
    }
  ]
  const datasets = chartDatasets.map(d => ({ ...d, data: data[d.field] }))
  return <Line
    options={optionsA}
    data={{
      labels: labels,
      datasets
    }}
    height={chartHeight} />
}

const tableDataTemplate = {
  profit: [
    { field: 'revenue', label: 'Revenue', value: 3456, currency: true },
    { field: 'cost', label: 'Payout', value: 3000, currency: true },
    { field: 'profit', label: 'Profit', value: 456, currency: true }
  ],
  conversions: [
    { field: 'count', label: 'Conversions', value: 3456 },
    { field: 'amount', label: 'Sales amount', value: 3000, currency: true },
  ]
}

const createTableFields = (name, data) =>
  tableDataTemplate[name].map(row => ({ ...row, value:  data[row.field] }) )

export default class StatisticsItem extends React.Component {
  render () {

    const {
      thinking,
      chart,
      profit,
      conversions
    } = this.props

    return (
      <div style={{marginBottom: 12}}>
        <Grid container spacing={3} mb={2}>
          <Grid item md={6} xs={12}>
            <Widget
              title="Clicks & Conversions (Last 7 days)"
              thinking={thinking}
              statCharts={[{ chart: <Chart {...chart} />}]}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Widget
                  thinking={thinking}
                  statCols={createTableFields('profit', profit)}
                />
              </Grid>
              <Grid item xs={12}>
                <Widget
                  thinking={thinking}
                  statCols={createTableFields('conversions', conversions)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}
