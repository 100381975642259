import React from 'react'
import {
  Grid,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent,
  DialogTitle,
  Button as MuiButton,
  TextField as MuiTextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Divider,
} from '@material-ui/core'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'

import ChannelsAutocomplete from '../../components/ChannelsAutocomplete'

const Button = styled(MuiButton)(spacing)
const DialogActions = styled(MuiDialogActions)(spacing)

export default function AttachChannelModal(props) {
  const [channel, setChannel] = React.useState(null)

  const onSelect = ({ target: { value } }) => {
    setChannel(value)
  }
  const onAttach = async () => {
    const { success } = await props.onAttach(channel)
    if (success) {
      props.onClose()
    }
    return { success }
  }

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      data-cy='dialogContactContacts'
      open={props.open}
      aria-labelledby='max-width-dialog-title'
    >
      <DialogTitle id='max-width-dialog-title'>Attach channel</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ChannelsAutocomplete
              value={channel}
              placeholder={`Choose channel`}
              onChange={(e, a) => onSelect({ target: { value: a } })}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions px={7} py={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Button
              variant='outlined'
              color='default'
              mr={2}
              onClick={props.onClose}
            >
              Cancel
            </Button>
          </Grid>
          <Grid
            xs={12}
            sm={6}
            container
            direction='row'
            justify='flex-end'
            alignItems='center'
          >
            <Button
              disabled={!channel || !channel.id}
              onClick={onAttach}
              variant='outlined'
              color='primary'
            >
              Attach
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
