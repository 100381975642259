import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import ContactRequest from './ContactRequest'
import { ContactRequestStore } from '../../stores/contact-requests'

@withRouter
@observer
class _ContactRequest extends React.Component {
  constructor(props) {
    super(props)
    const {
      match: {
        params: { id },
      },
    } = props
    this.store = new ContactRequestStore(id)
  }

  render() {
    const {
      match: {
        params: { id },
      },
    } = this.props

    const { details, loading, thinking, contacts } = this.store

    return (
      <ContactRequest
        id={id}
        details={details}
        contacts={contacts}
        loading={loading}
        thinking={thinking}
        onLoad={() => this.store.load()}
        onSave={(details, publish) => this.store.setContact(details, publish)}
        onComplete={() => this.store.complete()}
        onDeclineRequest={() => this.store.decline()}
      />
    )
  }
}

export default _ContactRequest
