import React from 'react'
import { observer } from 'mobx-react'
import Loader from '../../components/Loader'
import HostBlocklist from './HostBlocklist'
import HostBlocklistStore from '../../stores/host-blocklist'

@observer
class _HostBlocklist extends React.Component {
  constructor (props) {
    super(props)
    this.store = new HostBlocklistStore()
  }

  render () {
    const {
      loading,
      list
    } = this.store

    if (loading) {
      return <Loader />
    }

    return <HostBlocklist
      list={list}
      onSave={listItems => this.store.update(listItems)}
    />
  }
}

export default _HostBlocklist
