import React from 'react'
import List from '../../components/List'
import DashboardPage from '../../layouts/DashboardPage'
import { formatDateTime } from '../../utils/format'

const columns = [
  { id: 'status', disablePadding: true, label: 'Status', kind: 'status' },
  { id: 'name', disablePadding: true, label: 'Name' },
  { id: 'totalSpent', disablePadding: true, label: 'Total Spent' },
  { id: 'trialKind', disablePadding: true, label: 'Trial Kind' },
  { id: 'trialUntil', disablePadding: true, label: 'Trial Until' },
  { id: 'subscribedUntil', disablePadding: true, label: 'Subscribed Until' },
]

export default class Brands extends React.Component {
  render() {
    return (
      <DashboardPage
        kind='list'
        title='Brands'
        addDataCy='createBrand'
        addLabel='Create Brand'
        addLink='/brands/create'
      >
        <List
          columns={columns}
          {...this.props}
          items={this.props.items.map((item) => ({
            ...item,
            ...(item.trialUntil && {
              trialUntil: formatDateTime(item.trialUntil),
            }),
            ...(item.subscribedUntil && {
              subscribedUntil: formatDateTime(item.subscribedUntil),
            }),
          }))}
          emptyMessage={'No Brands matching the given criteria.'}
          searchable
        />
      </DashboardPage>
    )
  }
}
