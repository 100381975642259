import React from 'react'
import List from '../../components/List'
import DashboardPage from '../../layouts/DashboardPage'
import RegisterUserDialog from '../../components/RegisterUserDialog'
import { Get, Post } from '../../utils/request'

import config from '../../config'

const columns = [
  { id: 'status', disablePadding: true, label: 'Status', kind: 'status' },
  { id: 'fullName', disablePadding: true, label: 'Name' },
  { id: 'email', disablePadding: true, label: 'Email' },
  { id: 'emailStatus', disablePadding: true, label: 'Email status', kind: 'status' },
  { id: 'createdAt', disablePadding: true, label: 'Signed up' },
  // { id: 'createdAt-', disablePadding: true, label: 'Last signed at' },
  { id: 'role', disablePadding: true, label: 'Role' },
  // {
  //   id: 'brandName', disablePadding: true, label: 'Brands', kind: 'link',
  //   link: { title: 'Brand', pattern: `/brands/{id}`, keys: { id: 'brand' } }
  // }
]

const register = async (details) => Post(`/api/admin/users`, { user: details })
const getToken = async (id) => Get(`/api/admin/users/${id}/token`)

export default class Users extends React.Component {
  state = { thinking: false, open: false }

  onOpenAdd = () => this.setState({ open: true })

  onCloseAdd = () => this.setState({ open: false })

  onRegister = async (details) => await register(details)

  onSuccess = async (user) => {
    const { id } = user
    const { token } = await getToken(id)
    if (token) {
      this.props.onUpdateList()
      window.open(`${config.LOGIN_APP_URL}?token=${token}&admin=true`,'_blank')
    }
    this.onCloseAdd()
  }

  render () {
    return (
      <DashboardPage
        kind="list"
        title="Users"
        addLabel="Register User"
        addClick={this.onOpenAdd}
      >
        <List
          columns={columns}
          {...this.props}
          searchable
          emptyMessage={'No Users matching the given criteria.'}
          // emptyActionText={'Invite a new User'}
          // onEmptyAction={this.onEdit}
        />

        <RegisterUserDialog
          open={this.state.open}
          onSubmit={this.onRegister}
          onSuccess={this.onSuccess}
          onCancel={this.onCloseAdd}
        />
      </DashboardPage>
    )
  }
}
