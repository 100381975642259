import { Delete, Get } from '../utils/request'
import { observable, action, computed, toJS } from 'mobx'
import hasError from './request-message'

class SaaSEcosystemStore {
  @observable id
  @observable createdAt
  @observable name
  @observable brand = {}
  @observable channelsCount
  @observable affiliatePartnersCount
  @observable inPartnershipsCount

  @observable domains = []

  @observable loaded = false
  @observable loading = true
  @observable thinking = false

  constructor(id) {
    this.id = id
    this.load().then()
  }

  fillModel(ecosystem) {
    for (let key in ecosystem) {
      if (ecosystem.hasOwnProperty(key)) {
        this[key] = ecosystem[key]
      }
    }
  }

  @action
  async load(id) {
    this.id = id || this.id
    this.loaded = false
    this.loading = true
    const { errorCode, ecosystem, message } = await Get(
      `/api/admin/self-ecosystems/${this.id}`
    )

    if (!hasError(!errorCode, message, '')) {
      this.fillModel(ecosystem)
    }

    this.loaded = true

    setTimeout(() => {
      this.loading = false
    }, 500)
  }

  async remove() {
    return await Delete(`/api/admin/self-ecosystems/${this.id}`, {})
  }

  @computed
  get details() {
    const {
      id,
      name,
      brand,
      createdAt,
      channelsCount,
      affiliatePartnersCount,
      inPartnershipsCount,
    } = this
    return {
      id,
      name,
      brand: brand.id,
      brandName: brand.name,
      createdAt,
      channelsCount,
      affiliatePartnersCount,
      inPartnershipsCount,
    }
  }
}

export default SaaSEcosystemStore
