import React from 'react'
import { Button as MuiButton } from '@material-ui/core'
import styled from 'styled-components'
import DashboardPage from '../../layouts/DashboardPage'
import DetailsForm from '../../components/Details'
import { ChannelsDialog } from '../Creators/Creators'
import { spacing } from '@material-ui/system'

const Button = styled(MuiButton)(spacing)

const config = [
  { key: 'id', name: 'ID', type: 'string' },
  {
    key: 'domain', name: 'Channel', kind: 'link',
    link: { title: 'Channel', pattern: `/channels/{id}`, keys: { id: 'channelId' }  }
  },
  {
    key: 'email', name: 'Contact', kind: 'link',
    link: { title: 'Contact', pattern: `/contacts/{id}`, keys: { id: 'contactId' }  }
  },
  { key: 'createdAt', name: 'Created at', type: 'string' },
  { key: 'inboxCount', name: 'Inbox', type: 'string' },
  { key: 'acceptedCount', name: 'Accepted', type: 'string' },
  { key: 'rejectedCount', name: 'Rejected', type: 'string' },
  // { key: 'signedAt', name: 'Last signed at', type: 'string' },
  { key: 'status', name: 'Status', kind: 'status' }
]

export default class Creator extends React.Component {
  state = {
    edit: false,
    anchorEl: null,
    open: false
  }

  onOpenAttachToChannel = () => {
    this.setState({ open: true })
  }

  onAttachToChannel = async (channelId, creatorId) => await this.props.onAttachToChannel(channelId, creatorId)

  render () {
    const {
      id,
      details,
      loading,
      thinking
    } = this.props

    const Actions = () => {
      if (details.status === 'pending') {
        return <>
          <Button variant="contained" color="primary" onClick={this.onOpenAttachToChannel}> Attach to channel </Button>
        </>
      }
      return null
    }

    return (
      <DashboardPage
        kind="details"
        title={"User"}
        status={id}
        noActions
        actions={Actions}
        thinking={loading}
      >
        {
          !thinking && (
            <DetailsForm
              notEditable
              config={config}
              details={details}
            />
          )
        }

        <ChannelsDialog
          open={this.state.open}
          creatorId={id}
          onAttachToChannel={this.onAttachToChannel}
          onClose={() => this.setState({ open: false })}
        />
      </DashboardPage>
    )
  }
}
