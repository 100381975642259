import React from 'react'
import List from '../../components/List'
import Panel from '../Panel'

const columns = [
  { id: 'createdAt', disablePadding: true, label: 'Started' },
  { id: 'finishedAt', disablePadding: true, label: 'Finished' },
  { id: 'diff', disablePadding: true, label: 'Diff (hh:mm:ss)' },
  { id: 'researchId', disablePadding: true, label: 'Research ID' },
  { id: 'brandName', disablePadding: true, label: 'Brand Name' },
  { id: 'searchName', disablePadding: true, label: 'Search Name' },
  { id: 'cost', disablePadding: true, label: 'Cost' },
  { id: 'status', disablePadding: true, label: 'Status', kind: 'status' }
]

export default class BrandResearches extends React.Component {
  render () {
    return (
      <Panel
        title="Researches"
      >
        <List
          columns={columns}
          {...this.props}
          searchable
          emptyMessage={'No Researches matching for this Brand.'}
        />
      </Panel>
    )
  }
}
