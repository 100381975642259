import React from 'react'
import {
  Grid,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  InputAdornment,
  MenuItem, Button as MuiButton
} from '@material-ui/core'
import { spacing } from '@material-ui/system'
import styled from 'styled-components'
import { findIndex } from 'lodash'

import DashboardPage from '../../layouts/DashboardPage'
import DetailsForm from '../../components/Details'
import Touchpoints from '../../components/Touchpoints'
import TrackingParameters from '../../components/TrackingParameters'
import LandingPages from '../../components/LandingPages'
import Rules from '../../components/Rules'
import Coupons from '../../components/Coupons'
import { Asynchronous } from '../../components/Autocomplete'
import StatisticsItem from '../../components/StatisticsItem'
import { Get } from '../../utils/request'

const Button = styled(MuiButton)(spacing)
const DialogActions = styled(MuiDialogActions)(spacing)

const config = [
  { key: 'id', name: 'ID', editable: false, creatable: false, type: 'string' },
  { key: 'name', name: 'Name', editable: true, creatable: true, type: 'string', dataCy:'opportunityName' },
  { key: 'description', name: 'Description', editable: true, creatable: true, type: 'text', dataCy:'opportunityDescription' },

  {
    key: 'brand', name: 'Brand', editable: false, creatable: true, disabled: true, type: 'string' /*type: 'string'*/, dataCy:'opportunityBrand',
    link: { title: 'Brand', pattern: `/brands/{id}`, keys: { id: 'brand' } }
  },
  // {
  //   key: 'brand', name: 'Brand', editable: false, creatable: true, type: 'brands',
  //   link: { title: 'Brand', pattern: `/brands/{id}`, keys: { id: 'brand' } }
  // },
  {
    key: 'channelName', name: 'Brand Channel', creatable: true, disabled: true, type: 'string', dataCy:'opportunityBrandChannel'
  },
  { key: 'status', name: 'Status', editable: false, creatable: true, type: 'select', kind: 'status', dataCy:'opportunityStatus',
    options: [
      { value: 'active', label: 'Active' },
      { value: 'paused', label: 'Paused' },
      { value: 'deleted', label: 'Deleted' },
    ]
  }
]

const computeDetailsConfig = (details, create, noDefaultBrand) => {
  if (!create) {
    return config
  }

  let updatedConfig = [...config]
  let channelField = null
  let brandField = null

  if (details.brand && details.brand.id) {
    const channelFieldIndex = findIndex(updatedConfig, ['key', 'channelName'])
    channelField = {
      ...updatedConfig[channelFieldIndex],
      key: 'channel',
      type: 'channels',
      disabled: false,
      filter: `filters[brand]=${details.brand.id}`
    }
    updatedConfig = [
      ...updatedConfig.slice(0, channelFieldIndex),
      channelField,
      ...updatedConfig.slice(channelFieldIndex + 1)]
  }

  if (noDefaultBrand) {
    const brandFieldIndex = findIndex(updatedConfig, ['key', 'brand'])
    brandField = { ...updatedConfig[brandFieldIndex], disabled: false, type: 'brands' }
    updatedConfig = [
      ...updatedConfig.slice(0, brandFieldIndex),
      brandField,
      ...updatedConfig.slice(brandFieldIndex + 1)]
  }

  return updatedConfig
}

export class InvitePartnerDialog extends React.Component {
  state = { error: '', channels: [], channel: null }

  onChange = field => ({ target: { value } }) => {
    if (field === 'partner' && value) {
      Get(`/api/admin/channels?filters[partner]=${value.id}&all=1`).then(({ channels }) => {
        this.setState({ channels, channel: null })
      })
    }
    this.setState({ [field]: value, error: null })
  }

  onInvite = async () => {
    const { partner, channel } = this.state
    const error = await this.props.onCreate({ partner: partner.id, channel })
    this.setState({ error })
  }

  render () {
    const {
      open
    } = this.props
    const {
      error,
      partner,
      channel,
      channels
    } = this.state

    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          Invite Partner
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Asynchronous
                error={error}
                url="/api/admin/partners"
                filter="filter[name]"
                label={'Partner'}
                placeholder={'Invite Partner'}
                dataField="partners"
                dataLabelField="name"
                value={partner}
                onChange={(e, a) => this.onChange('partner')({ target: { value: a } })}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                fullWidth
                disabled={!channels.length}
                placeholder="Channel"
                m={2}
                value={channel}
                onChange={this.onChange('channel')}
              >
                {
                  channels.map(({ id, name }) => <MenuItem key={id} value={id}>{name}</MenuItem>)
                }
              </TextField>
            </Grid>
          </Grid>

        </DialogContent>

        <DialogActions px={7} py={4}>
          <Button onClick={this.props.onClose} color="default" mr={2}>
            Cancel
          </Button>
          <Button
            disabled={!partner || !channel}
            onClick={this.onInvite}
            variant="outlined"
            color="primary"
          >
            Invite
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default class Opportunity extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      edit: false,
      brand: null
    }
  }

  onCancel = e => {
    if (this.props.id === 'create') {
      return this.props.onCancel()
    }
  }

  onOpenInvitePartner = async (e) => {
    e.preventDefault()
    this.setState({ openInvitePartner: true })
  }

  onCloseInvitePartner = async (e) => {
    e.preventDefault()
    this.setState({ openInvitePartner: false })
  }

  onIntivePartner = async ({ partner, channel }) => {
    const {
      details: {
        id: opportunity,
        status,
        brand: {
          id: brand
        }
      }
    } = this.props
    return this.props.onCreateCampaign({ partner, brand, opportunity, channel, status })
  }

  onChange = (field, value, resetValueCallback) => {
    if (field === 'brand') {
      this.setState({ brand: value })
      resetValueCallback('channel')
    }
  }

  render () {
    const {
      id,
      details,
      noDefaultBrand,
      rules,
      landings,
      loading,
      thinking,
      stats,
      touchpoints,
      coupons,
      trackingParameters,
      onCreate,
      onUpdate,
      onCancel
    } = this.props
    const create = id === 'create'
    const {
      openInvitePartner
    } = this.state

    const Actions = () => {
      if (!create && details) {
        return <Button data-cy={(details.status !== 'deleted' ? 'archive' : 'unarchive') + 'Opportunity'} onClick={this.props.onToggleArchived} variant={"outlined"}> { details.status !== 'deleted' ? 'Archive' : 'Unarchive' } </Button>
      }
      return null
    }

    return (
      <DashboardPage
        kind="list"
        forceDefaultActions={!create}
        title={"Opportunity"}
        time
        status={id}
        noActions={create}
        actions={Actions}
        addLabel="Invite Partner"
        addLink="/tracking-campaigns/create"
        addClick={this.onOpenInvitePartner}
        thinking={loading}
      >
        { !create && <StatisticsItem {...stats} /> }

        <DetailsForm
          create={create}
          config={computeDetailsConfig({...details, ...(this.state.brand && { brand: this.state.brand })}, create, noDefaultBrand)}
          details={{...details, ...(create && noDefaultBrand && { brand: null } ) }}
          onCreate={onCreate}
          onUpdate={onUpdate}
          onCancel={onCancel}
          onChange={this.onChange}
        />

        { !create && <Touchpoints {...touchpoints} /> }
        { !create && <LandingPages  {...landings} onUpdate={onUpdate} /> }
        { !create && <TrackingParameters tracking={trackingParameters} onUpdate={onUpdate} /> }
        { !create && <Rules {...rules} onUpdate={r => onUpdate({ rules: r })} /> }

        { !create && <Coupons creatable editable kind={'common'} {...coupons} thinking={coupons.commonCouponsThinking} coupons={coupons.commonCoupons} /> }
        { !create && <Coupons kind={'private'} thinking={coupons.privateCouponsThinking} coupons={coupons.privateCoupons} /> }

        <InvitePartnerDialog
          open={openInvitePartner}
          details={details}
          onCreate={this.onIntivePartner}
          onClose={this.onCloseInvitePartner}
        />
      </DashboardPage>
    )
  }
}
