import { Delete, Get, Post, Put } from '../utils/request'
import { observable, action, computed, toJS } from 'mobx'
import { formatCurrency, formatDateTime } from '../utils/format'
import hasError from './request-message'
import config from '../config'

const parseSignatureError = (message) => {
  if (!message || !message.includes('already exist in')) return null
  const messages = message.split('\n')
  return messages.map(message => {
    const { 1: domain, 2: signatureName, 3: signatureId } = message.match(/(.*) already exist in "(.*)" \((.*)\)/)

    return { domain, signatureName, signatureId }
  })
}

class SignatureStore {
  @observable id
  @observable createdAt
  @observable name
  @observable description
  @observable campaignId
  @observable partnerId
  @observable signaturesCount
  @observable kind = 'adex'
  @observable affiliateProgramSignupURL

  @observable signatures = []
  @observable websites = []
  @observable ready = false
  @observable published = false

  @observable loaded = false
  @observable loading = true
  @observable thinking = false

  constructor (id) {
    this.id = id
    if (id !== 'create') {
      this.load().then()
    } else {
      this.loading = false
    }
  }

  websitesToDetails (websites) {
    return websites.map(({ value }) => value).filter(value => Boolean(value))
  }

  fillModel (discoverySignature) {
    for (let key in discoverySignature) {
      if (discoverySignature.hasOwnProperty(key)) {
        if (key === 'websites') {
          this.websites = (discoverySignature[key] || []).map(value => ({ id: (new Date()).getTime(), value }))
        } else if (key === 'signatures') {
          this.signatures = discoverySignature[key].filter(signature => Boolean(signature))
        } else {
         this[key] = discoverySignature[key]
        }
      }
    }
  }

  @action
  async load () {
    const { errorCode, discoverySignature, message } = await Get(`/api/admin/discovery-signatures/${this.id}`)

    if (!hasError(!errorCode, message, '')) {
      this.fillModel(discoverySignature)
    }

    this.loaded = true

    setTimeout(() => {
      this.loading = false
    }, 500)

  }

  async create ({ signatures, websites, ...details }) {
    this.thinking = true
    const discoverySignature = {
      ...details,
      signatures: (signatures || []).filter(value => Boolean(value)),
      websites: this.websitesToDetails(websites || [])
    }
    const { success, message, ...rest } = await Post(`/api/admin/discovery-signatures`, { discoverySignature })

    const signatureMessage = parseSignatureError(message)

    if (!hasError(success, signatureMessage ? 'Some domains already exist in other Signature' : message, 'Signature successfully created')) {
      this.fillModel(discoverySignature)
    }
    this.thinking = false

    return { success, message: signatureMessage, ...rest }
  }

  async update ({ signatures, websites, details }) {
    this.thinking = true

    const discoverySignature = {
      ...(details && { ...details }),
      ...(signatures && { signatures: (signatures || []).filter(value => Boolean(value)) }),
      ...(websites && { websites: this.websitesToDetails(websites || []) })
    }

    const { success, message, ...rest } = await Put(`/api/admin/discovery-signatures/${this.id}`, { discoverySignature })

    const signatureMessage = parseSignatureError(message)

    if (!hasError(success, signatureMessage ? 'Some domains already exist in other Signature' : message, 'Signature successfully updated')) {
      this.fillModel(discoverySignature)
    }

    this.thinking = false

    return { success, message: signatureMessage, ...rest }
  }

  async remove () {
    return await Delete(`/api/admin/discovery-signatures/${this.id}`, {})
  }

  @computed
  get signaturesList () {
    return toJS(this.signatures) || []
  }

  @computed
  get websitesList () {
    return toJS(this.websites)
  }

  @computed
  get details () {
    const {
      id, name, partnerId, campaignId, signaturesCount, ready, published, kind, affiliateProgramSignupURL, description
    } = this
    return {
      id, name, signaturesCount, partnerId, campaignId, ready, published, kind, affiliateProgramSignupURL, description
    }
  }
}

export default SignatureStore
