import React from 'react'
import DashboardPage from '../../layouts/DashboardPage'
import DetailsForm from '../../components/Details'
import EntityModal from '../../components/Entities/EntityModal'
import EntityContacts from '../../components/Entities/EntityContacts'
import EntityChannels from '../../components/Entities/EntityChannels'

const config = [
  { key: 'id', name: 'ID', type: 'string' },
  { key: 'company', name: 'Company', type: 'string' },
  { key: 'phone', name: 'Phone', type: 'string' },
  { key: 'companyEmail', name: 'Company Email', type: 'string' },
  { key: 'sourceCompanyEmail', name: 'Company Email Source', type: 'string' },
  { key: 'linkedin', name: 'LinkedIn', type: 'string' },
  { key: 'sourceLinkedin', name: 'LinkedIn Source', type: 'string' },
  { key: 'twitter', name: 'Twitter', type: 'string' },
  { key: 'instagram', name: 'Instagram', type: 'string' },
  { key: 'createdAt', name: 'Create at', type: 'string' },
  { key: 'publishedAt', name: 'Published at', type: 'string' },
  { key: 'status', name: 'Status', kind: 'status' },
]

export default function Entity(props) {
  const { id, loading, details } = props
  const [edit, setEdit] = React.useState(false)

  const onEdit = () => {
    setEdit(true)
  }

  const onSave = async (details, publish) => {
    const { success } = await props.onSave(details, publish)

    if (success) {
      setEdit(false)
    }

    return { success }
  }

  return (
    <DashboardPage kind='details' title={'Entity'} noActions thinking={loading}>
      {!loading && (
        <DetailsForm
          // notEditable={!['pending'].includes(details.status)}
          config={config}
          details={details}
          onEdit={onEdit}
        />
      )}

      <EntityContacts channelId={id} />
      <EntityChannels channelId={id} />

      <EntityModal
        open={edit}
        variant={'update'}
        entity={details}
        onSave={onSave}
        onClose={() => setEdit(false)}
      />
    </DashboardPage>
  )
}
