import React from 'react'
import { withRouter } from 'react-router-dom'
import { Grid, Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { ChevronRight as MuiChevronRight } from '@material-ui/icons'
import styled from 'styled-components'

import DashboardPage from '../../layouts/DashboardPage'
import ClientDetails from '../../components/Details'
import hasError from '../../stores/request-message'
import ChannelDetails from '../../components/ChannelDetails'
import Channels from '../../components/Channels'
import { Contacts } from '../../components/Contacts'
import { BrandUsers } from '../../components/BrandUsers'
import { Notes } from '../../components/Notes'
import { BrandResearches } from '../../components/BrandResearches'
import { BrandSearches } from '../../components/BrandSearches'
import { BrandEcosystems } from '../../components/BrandEcosystems'
import { BrandSaaSEcosystems } from '../../components/BrandSaaSEcosystems'

const ChevronRight = styled(MuiChevronRight)`
  position: relative;
  top: 5px;
  margin: 0 0.5em;
`

@withRouter
class Client extends React.Component {
  constructor(props) {
    super(props)
    const { discovery, client } = props
    this.state = { edit: false }
    this.url = `${discovery ? '/discovery' : ''}/${client}s`
  }

  //============ Client Actions ===========
  get clientCapitalizedKind() {
    const { client } = this.props
    return client.charAt(0).toUpperCase() + client.slice(1)
  }

  onCreateClient = async (details) => {
    const { client } = this.props
    const {
      success,
      message,
      [client]: model,
    } = await this.props.clientStore.create(details)

    if (
      !hasError(
        success,
        message,
        `${this.clientCapitalizedKind} successfully created`
      )
    ) {
      const { id } = model
      this.props.onUpdateStores(id)
      this.props.history.push(`${this.url}/${id}`)
    }
  }

  onUpdateClient = async (details) =>
    await this.props.clientStore.update(details)

  onCancelCreateClient = (e) => {
    const { id, history } = this.props
    if (id === 'create') {
      return history.push(this.url)
    }
  }

  onToggleArchiveClient = async () =>
    await this.props.clientStore.toggleArchive()
  //=======================================

  //============ Channels Actions =========
  onCreateChannel = async (details) => {
    // const { channel } = this.props
    // return await this.props.channelsStore.create({ channel, ...details })
  }

  onUpdateChannel = (details) => {} //this.props.channelsStore.update(details)

  onToggleArchiveChannel = async () => {
    // const { channel: channelId, channelsProps } = this.props
    // const channel = channelsProps.channels.filter(({ id }) => id === channelId)[0]
    //
    // if (channel) {
    //   return await this.props.channelsStore.toggleArchive(channel)
    // }
    return { success: false }
  }

  onRemoveSimilar = async (id) => {
    // const { channel } = this.props
    // const { success } = await this.props.channelsStore.removeSimilar(channel, id)
    // return { success }
  }
  //=======================================

  //============ Archive Actions ==========
  get toArchive() {
    const { details } = this.props
    return details.status !== 'deleted'
  }

  actions = () => {
    const { channel, client } = this.props
    const archiveAction = () =>
      !!channel ? this.onToggleArchiveChannel() : this.onToggleArchiveClient()
    return (
      <>
        {client === 'brand' && (
          <>
            <Button onClick={this.props.onAddCredits} variant={'contained'}>
              {' '}
              Add credits{' '}
            </Button>
            &nbsp;
          </>
        )}
        <Button onClick={archiveAction} variant={'outlined'}>
          {' '}
          {this.toArchive ? 'Archive' : 'Unarchive'}{' '}
        </Button>
      </>
    )
  }

  breadcrumb = () => {
    const {
      id: clientId,
      client,
      discovery,
      channel: channelId,
      channelsProps,
      details,
    } = this.props

    return details.name
    // const { id: clientId, client, discovery, channel: channelId, channelsProps, details } = this.props
    // if (!channelId) {
    //   return details.name
    // }
    // const channel = channelsProps.channels.filter(({ id }) => id === channelId)[0]
    // if (channel) {
    //   return (
    //     <>
    //       <Link to={`${discovery ? '/discovery' : ''}/${client}s/${clientId}`} style={{textDecoration: 'none', color: 'inherit'}}>
    //         {details.name}
    //       </Link>
    //       <ChevronRight />
    //       <span style={{whiteSpace: 'nowrap'}}>{channel.name}</span>
    //     </>
    //   )
    // }
    // return details.name
  }
  //=======================================

  render() {
    const {
      id,
      client,
      details,
      loading,
      channel,
      discovery,
      detailsConfig,

      channelsProps,
      similarProps,

      children,
    } = this.props

    const view = !!channel ? 'channelDetails' : 'clientDetails'
    const create = id === 'create'
    const title = client === 'brand' ? 'Brand' : 'Partner'

    return (
      <DashboardPage
        kind='details'
        title={title}
        breadcrumb={this.breadcrumb()}
        status={id}
        noActions
        thinking={loading}
        actions={this.actions}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} xl={!create ? 8 : 12}>
            {view === 'clientDetails' && (
              <ClientDetails
                logo
                create={create}
                config={detailsConfig}
                details={details}
                onCreate={this.onCreateClient}
                onUpdate={this.onUpdateClient}
                onCancel={this.onCancelCreateClient}
              />
            )}

            {/*{*/}
            {/*  view === 'channelDetails' && (*/}
            {/*    <ChannelDetails*/}
            {/*      client={client}*/}
            {/*      discovery={discovery}*/}
            {/*      channel={channel}*/}
            {/*      {...channelsProps}*/}
            {/*      onUpdate={this.onUpdateChannel}*/}
            {/*    />*/}
            {/*  )*/}
            {/*}*/}
          </Grid>
          {/*<Grid item xs={12} xl={4}>*/}
          {/*  <Contacts client={client} clientId={id} discovery={discovery} />*/}
          {/*  <Notes ownerName={details.name} client={client} clientId={id} discovery={discovery} />*/}
          {/*</Grid>*/}
        </Grid>
        <Grid container spacing={2}>
          {client === 'brand' && !create && (
            <Grid item xs={12}>
              <BrandSaaSEcosystems brandId={id} />
            </Grid>
          )}

          {client === 'brand' && !create && (
            <Grid item xs={12}>
              <BrandEcosystems brandId={id} />
            </Grid>
          )}

          {client === 'brand' && !create && (
            <Grid item xs={12}>
              <BrandUsers brandId={id} />
            </Grid>
          )}

          {client === 'brand' && (
            <Grid item xs={12}>
              <BrandSearches brandId={id} />
            </Grid>
          )}

          {client === 'brand' && (
            <Grid item xs={12}>
              <BrandResearches brandId={id} />
            </Grid>
          )}

          {/*<Grid item xs={12}>*/}
          {/*  {*/}
          {/*    view === 'clientDetails' && !create &&*/}
          {/*    <Channels*/}
          {/*      discovery={discovery}*/}
          {/*      parentId={id}*/}
          {/*      channelsKind={'channel'}*/}
          {/*      {...channelsProps}*/}
          {/*      channels={channelsProps.channels.filter(({ status }) => status !== 'deleted' )}*/}
          {/*      type={client}*/}
          {/*      onCreate={this.onCreateChannel}*/}
          {/*    />*/}
          {/*  }*/}
          {/*</Grid>*/}

          {/*<Grid item xs={12}>*/}
          {/*  {*/}
          {/*    view === 'channelDetails' && !create &&*/}
          {/*    <Channels*/}
          {/*      discovery={discovery}*/}
          {/*      channelsKind={'similar'}*/}
          {/*      {...similarProps}*/}
          {/*      channels={similarProps.channels.filter(({ parentChannel }) => parentChannel === channel)}*/}
          {/*      type={client}*/}
          {/*      onCreate={this.onCreateChannel}*/}
          {/*      onUpdate={this.onUpdateChannel}*/}
          {/*      onRemove={this.onRemoveSimilar}*/}
          {/*    />*/}
          {/*  }*/}
          {/*</Grid>*/}

          <Grid item xs={12}>
            {!create && children}
          </Grid>
        </Grid>
      </DashboardPage>
    )
  }
}

export default Client
