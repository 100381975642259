import List from './list'
import { computed } from 'mobx'

export default class CampaignsStore extends List {
  url = '/api/admin/campaigns'

  constructor(filters, link) {
    super()
    this.link = link
    this.filters = filters || {}
    this.order = '' //'''desc'
    this.orderBy = '' //'count'
    this.itemsField= 'campaigns'
    this.getList().then()
  }

  @computed
  get direction () {
    const item = this.items[0]
    if (item) {
      const { inChannelLink, outChannelLink } = item
      if (inChannelLink === this.link) {
        return 'inChannel'
      } else if (outChannelLink === this.link) {
        return 'outChannel'
      }
    }
    return 'uknown'
  }
}
