import React from 'react'
import { observer } from 'mobx-react'
import { Delete as RemoveIcon } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'
import Panel from '../Panel'
import List from '../List'
import ExistingOrNewModal from '../Modals/ExistingOrNewModal'
import EntityModal from '../Entities/EntityModal'
import EntityExistingModal from '../Entities/EntityExistingModal'
import ConfirmDialog from '../ConfirmDialog'

import EntityStore from '../../stores/entity'

const columns = [
  { id: 'createdAt', disablePadding: true, label: 'Created' },
  { id: 'status', disablePadding: true, label: 'Status', kind: 'status' },
  { id: 'company', disablePadding: true, label: 'Company' },
  { id: 'companyEmail', disablePadding: true, label: 'Company email' },
  { id: 'actions', disablePadding: true, label: '', kind: 'actions' },
]

const DetachEntity = ({ channelId, onDetach }) => {
  return (
    <>
      <ConfirmDialog
        title={'Detach entiry from channel'}
        confirmation
        confirmationText={'Detach entity from the channel?'}
        action={() => onDetach(channelId)}
        confirmButtonLabel={'Detach'}
        confirmButtonProps={{
          variant: 'contained',
          color: 'primary',
        }}
      >
        <IconButton size={'small'}>
          <RemoveIcon fontSize={'small'} />
        </IconButton>
      </ConfirmDialog>
    </>
  )
}

@observer
class ChannelEntity extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      edit: false,
      openAdd: false,
      openExisting: false,
      createOrAdd: false,
    }
    this.store = new EntityStore(props.entityId)
  }

  onCreate = async (details, publish) => {
    const { success, entity } = await this.store.create(details, publish)

    if (success) {
      await this.store.attach(this.props.channelId, entity.id)
      this.setState({ openAdd: false })
      this.props.onCreate()
    }
    return { success }
  }

  onExisting = async (entity) => {
    console.log(entity)
    const { success } = await this.store.attach(this.props.channelId, entity.id)
    if (success) {
      this.setState({ openExisting: false })
      await this.props.onCreate()
    }
    return { success }
  }

  onClickRow = (e, rowId) => {
    window.open(`/entities/${rowId}`, '_blank')
  }

  onDetach = async () => {
    const { success } = await this.store.detach(this.props.channelId)
    if (success) {
      this.props.onCreate()
    }
    return { success }
  }

  render() {
    const { details } = this.store
    const { openAdd, openExisting, thinking, createOrAdd } = this.state
    const entities = (this.props.entityId ? [details] : []).map((entity) => ({
      ...entity,
      actions: () => (
        <DetachEntity
          channelId={this.props.channelId}
          onDetach={this.onDetach}
        />
      ),
    }))
    return (
      <Panel
        title={`Entity`}
        adding={!this.props.entityId}
        onAdd={() => this.setState({ createOrAdd: true })}
      >
        {/* items={(this.props.entityId ? [details] : []).map((entity) => ({
            ...entity,
            actions: (
              <DetachEntity
                channelId={this.props.channelId}
                onDetach={this.onDetach}
              />
            ),
          }))} */}
        <List
          disablePagination
          columns={columns}
          items={entities}
          emptyMessage={'No entity attached to this channel.'}
          emptyActionText={'Add Entity to Channel'}
          onEmptyAction={() => this.setState({ createOrAdd: true })}
          onClickRow={this.onClickRow}
        />

        <ExistingOrNewModal
          open={createOrAdd}
          onNew={() => this.setState({ openAdd: true, createOrAdd: false })}
          onExisting={() =>
            this.setState({ openExisting: true, createOrAdd: false })
          }
          onClose={() => this.setState({ createOrAdd: false })}
        />

        <EntityModal
          thinking={thinking}
          variant={'create'}
          open={openAdd}
          onSave={this.onCreate}
          onClose={() => this.setState({ openAdd: false })}
          entity={{}}
        />

        <EntityExistingModal
          open={openExisting}
          onAttach={this.onExisting}
          onClose={() => this.setState({ openExisting: false })}
        />
      </Panel>
    )
  }
}

export default ChannelEntity
