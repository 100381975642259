
const checkStatus = response => response.json().then(json => ({ json, response }))
const parseJSON = ({ json, response }) => {
  return response.ok === false ? { errorCode: response.status, ...json } : json;
}

const getAuthorizedHeaders = () => {
  const token = localStorage.getItem('token')
  return {
    ...(token && { Authorization: `Bearer ${token}` })
  }
}

export const GetBlob = url => {
  return fetch(url, {
    credentials: 'include',
    headers: {
      ...getAuthorizedHeaders()
    }
  })
    .then((response) => response.blob())
    .then((blob) => {
      const _url = window.URL.createObjectURL(blob)
      window.open(_url, "_new").focus()
    }).catch((err) => {
      console.log(err)
    })
}

export const Get = url =>
  fetch(url, {
    credentials: 'include',
    headers: {
      ...getAuthorizedHeaders()
    }
  })
    .then(checkStatus)
    .then(parseJSON)

export const Put = (url, data) =>
  fetch(url,
    {
      headers: {
        'Content-Type': 'application/json',
        ...getAuthorizedHeaders()
      },
      method: 'PUT',
      body: JSON.stringify(data || {}),
      credentials: 'include'
    })
    .then(checkStatus)
    .then(parseJSON)

export const Post = (url, data) =>
  fetch(url,
    {
      headers: {
        'Content-Type': 'application/json',
        ...getAuthorizedHeaders()
      },
      method: 'POST',
      body: JSON.stringify(data || {}),
      credentials: 'include'
    })
    .then(checkStatus)
    .then(parseJSON)

export const Delete = (url, data) =>
  fetch(url,
    {
      headers: {
        'Content-Type': 'application/json',
        ...getAuthorizedHeaders()
      },
      method: 'DELETE',
      body: JSON.stringify(data || {}),
      credentials: 'include'
    })
    .then(checkStatus)
    .then(parseJSON)
