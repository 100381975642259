import React from 'react'
import Moment from 'moment'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import Signatures from './Signatures'
import SignaturesStore from '../../stores/signatures'

const itemsTime = (items = []) => {
  return items.map(({ createdAt, ready, published, ...rest}) => {
    return {
      ...rest,
      createdAt: Moment(createdAt).format(`YYYY-MM-DD`),
      ready: ready ? 'ready' : 'pending',
      published: published ? 'published' : 'pending',
    }
  })
}

const READY_STATES = ['ready', 'notReady'].map(str => ({ label: str, value: str }))
const PUBLISH_STATES = ['published', 'notPublished'].map(str => ({ label: str, value: str }))

const filteredSearchConfig = {
  keys: [
    { id: 'ready', label: 'Ready' },
    { id: 'published', label: 'Published' }
  ],
  expressions: [
    { id: 'eq', label: '=' }
  ],
  values: {
    ready: () => new Promise(resolve => resolve(READY_STATES)),
    published: () => new Promise(resolve => resolve(PUBLISH_STATES))
  }
}

@withRouter
@observer
class _Signatures extends React.Component {
  constructor(props) {
    super(props)
    this.store = new SignaturesStore()
    this.state = { filters: [] }
  }

  onChangeSort = orderByNew => e => {
    const { listProps } = this.store
    const { order, orderBy } = listProps
    let orderNew = ''
    if (orderBy === orderByNew) {
      if (order === 'desc') {
        orderNew = 'asc'
      } else {
        orderNew = 'desc'
      }
    } else {
      orderNew = 'desc'
    }

    this.store.onChangeSort(orderNew, orderByNew)
  }

  onChangePage = (e, page) => this.store.onChangePage(page)

  onChangeRowsPerPage = ({ target: { value }}) => this.store.onChangeRowsPerPage(value)

  onClickRow = (e, id) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(`/signatures/${id}`, '_blank')
    } else {
      this.props.history.push(`/signatures/${id}`)
    }
  }

  onCreate = details => this.store.create(details)

  onPublish = async () => this.store.publish()

  onChangeFilters = name => object => {
    let value = null
    if (object.hasOwnProperty('target')) {
      value = object.target.value
    } else {
      value = object
    }
    this.store.onChangeFilter(name === 'name' ? 'search' : name, value)
  }

  render () {
    const {
      listProps
    } = this.store

    const {
      items
    } = listProps

    return <Signatures
      {...this.props}
      {...listProps}
      items={itemsTime(items)}
      onChangeSort={this.onChangeSort}
      onChangePage={this.onChangePage}
      onChangeFilters={this.onChangeFilters}
      onChangeRowsPerPage={this.onChangeRowsPerPage}
      onClickRow={this.onClickRow}
      onCreate={this.onCreate}
      onPublish={this.onPublish}
    />
  }
}

export default _Signatures
