import numbro from 'numbro'
import moment from 'moment'
import config from '../config'

numbro.registerLanguage(config.LANGUAGE);
numbro.setLanguage(config.LOCALE);

export function formatNumber (number) {
  if (isNaN(number) || number === undefined || number === null) return ''
  return numbro(number).format(config.NUMBER_FORMAT)
}
export function formatCurrency (number) {
  return numbro(number).formatCurrency(config.NUMBER_CURRENCY_FORMAT)
}
export function formatDate (date) {
  return moment(date).format(config.DATE_FORMAT)
}
export function formatDateTime (date) {
  return moment(date).format(config.DATE_TIME_FORMAT)
}
