import React from 'react'
import { inject, observer } from 'mobx-react'
import { Route, Redirect } from 'react-router-dom'

@inject(stores => ({
  authorized: stores.AuthorizationStore.authorized,
  checkAuthorization: stores.AuthorizationStore.checkAuthorization
}))
@observer
class PublicRoute extends React.Component {
  render () {
    const {
      layout: Layout,
      path,
      component: Component,
      redirectToIfAuthorized,
      authorized,
      checkAuthorization
    } = this.props

    console.log('Public', authorized, redirectToIfAuthorized)

    if (authorized && redirectToIfAuthorized) {
      return <Redirect to={{ pathname: redirectToIfAuthorized }} />
    }

    return (
      <Layout thinking={checkAuthorization}>
        <Component {...this.props} />
      </Layout>
    )
  }
}

export default PublicRoute
