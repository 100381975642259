import React, { useState } from 'react'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import {
  Button,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'

import List from '../../components/List'
import DashboardPage from '../../layouts/DashboardPage'
import { Asynchronous } from '../../components/Autocomplete'
import { formatNumber } from '../../utils/format'

const columns = [
  { id: 'status', disablePadding: true, label: 'Status', kind: 'status' },
  {
    id: 'domain', disablePadding: true, label: 'Channel', kind: 'link',
    link: { title: 'Channel', pattern: `/channels/{id}`, keys: { id: 'channelId' } }
  },
  {
    id: 'email', disablePadding: true, label: 'Contact', kind: 'link',
    link: { title: 'Contact', pattern: `/contacts/{id}`, keys: { id: 'contactId' } }
  },
  { id: 'inboxCount', disablePadding: true, label: 'Inbox' },
  { id: 'acceptedCount', disablePadding: true, label: 'Accepted' },
  { id: 'rejectedCount', disablePadding: true, label: 'Rejected' },

  { id: 'createdAt', disablePadding: true, label: 'Created at' },

  { id: 'actions', disablePadding: true, label: '', kind: 'actions' },
]

const DialogActions = styled(MuiDialogActions)(spacing)

export const ChannelsDialog = ({ open, creatorId, ...rest }) => {
  const [thinking, setThinking] = useState(false)
  const [channel, setChannel] = useState(null)

  const onChange = (value) => setChannel(value)

  const onCancel = () => {
    setChannel(null)
    rest.onClose()
  }

  const onAdd = async () => {
    setThinking(true)

    const { id: channelId } = channel

    const { success } = await rest.onAttachToChannel(channelId, creatorId)

    setThinking(false)

    if (success) {
      rest.onClose()
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      data-cy="channelDialog"
      open={open}
      onClose={onCancel}
      onBackdropClick={onCancel}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">
        Attach Channel to Creator
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Asynchronous
              fullWidth
              disabled={thinking}
              url={`/api/admin/channels`}
              filter="filters[search]"
              placeholder={`Channel ecosystem`}
              dataField={`channels`}
              dataLabelField="domain"
              value={channel}
              defaultValue={channel}
              onChange={(e, a) => onChange(a)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions px={6} py={4}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Button
              mr={2}
              color="default"
              disabled={thinking}
              onClick={onCancel}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}
                container
                direction="row"
                justify="flex-end"
                alignItems="center">

            <Button disabled={thinking} onClick={onAdd} variant="outlined" color="primary">
              Add
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

const CreatorMenu = ({ creatorId, status, onAttachToChannel }) => {
  const [open, setOpen] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)

  const onOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null)
  }

  const onOpenAttachToChannel = () => {
    setOpen(true)
    onClose()
  }

  return <>
    <IconButton size={'small'} onClick={onOpen}>
      <MoreVert fontSize={'small'}/>
    </IconButton>
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      <MenuItem onClick={onOpenAttachToChannel} disabled={status === 'attached'}>Attach Channel to Creator</MenuItem>
      {/*<MenuItem onClick={onClose} disabled>Remove from Ecosystem</MenuItem>*/}
    </Menu>

    <ChannelsDialog
      open={open}
      creatorId={creatorId}
      onAttachToChannel={onAttachToChannel}
      onClose={() => setOpen(false)}
    />
  </>
}

export default class Creators extends React.Component {
  state = { thinking: false, open: false }

  onAttachToChannel = async (channelId, creatorId) => {
    return await this.props.onAttachToChannel(channelId, creatorId)
  }

  render () {
    const {
      items
    } = this.props

    return (
      <DashboardPage
        noActions
        kind="list"
        title="Creators"
      >
        <List
          columns={columns}
          {...this.props}
          items={items.map((item) => ({
            ...item,
            // inboxCount: formatNumber(item.inboxCount),
            // acceptedCount: formatNumber(item.acceptedCount),
            // rejectedCount: formatNumber(item.rejectedCount),
            actions: () => <CreatorMenu creatorId={item.id} status={item.status} onAttachToChannel={this.onAttachToChannel}/>
          }))}
          searchable
          emptyMessage={'No Creators matching the given criteria.'}
        />
      </DashboardPage>
    )
  }
}
