export const EXCLUSIONS = `abuse
accessibilty
accounting
accounts\.payable
adm
admin
admissions
ads
advertise
advisor
agency
agent
all
api
ask
attorneys
award
bills
bizdev
board
booking
bookings
buzz
callcenter
career
careers
ceo
chat
chief
comments
contact
contacts
copyright
corporate
courses
customer.services
customercare
customerservice
customerservicecenter
custserv
data
design
development
director
directory
domains
editor
editorial
emails
engineering
enquiries
enquiry
event
events
everyone
feedback
finance
foundation
frontdesk
ftp
general
global
grouplegal
groups
help
helpdesk
home
hotel
hr
human.resources
humanresources
info
information
inquiries
inquiry
instructor
insurance
internet
interoperability
invest
investor
investorrelations
jobs
lawoffice
leasing
legal
library
listing
logistics
mail
mailbox
mailroom
main
manager
marketing
mayor
media
medicalqueries
meetings
members
membership
membersupport
merchant
misc
newsroom
noc
offers
office
online
operations
organiser
organizer
pastor
pharma
photos
politics
postmaster
pr
president
prime
privacy
projects
promos
publicaffairs
publisher
quality
query
questions
radio
realestate
realtor
receptionist
redaction
register
remove
repairs
request
research
reservation
reservations
reserve
reserveroom
root
sales
schools
secretary
security
service
shopping
spam
staff
subscribe
suggest
support
survey
talent
tourism
tours
trading
training
transfer
travel
travels
unsubscribe
usa
usenet
user
username
users
uucp
verify
volunteer
web
webinfo
webmaster
website
wwinfo
www
www\-validator
team
connect
privacyofficer
press
app
iubenda
contactpress
hello
welcome
kontakt`.split('\n')

const EXPRESSION_STRING = `^[${EXCLUSIONS.join('|')}]+@`

export const EMAIL_VALIDATE_EXPRESSION = /^(abuse|accessibilty|accounting|accounts.payable|adm|admin|admissions|ads|advertise|advisor|agency|agent|all|api|ask|attorneys|award|bills|bizdev|board|booking|bookings|buzz|callcenter|career|careers|ceo|chat|chief|comments|contact|contacts|copyright|corporate|courses|customer.services|customercare|customerservice|customerservicecenter|custserv|data|design|development|director|directory|domains|editor|editorial|emails|engineering|enquiries|enquiry|event|events|everyone|feedback|finance|foundation|frontdesk|ftp|general|global|grouplegal|groups|help|helpdesk|home|hotel|hr|human.resources|humanresources|info|information|inquiries|inquiry|instructor|insurance|internet|interoperability|invest|investor|investorrelations|jobs|lawoffice|leasing|legal|library|listing|logistics|mail|mailbox|mailroom|main|manager|marketing|mayor|media|medicalqueries|meetings|members|membership|membersupport|merchant|misc|newsroom|noc|offers|office|online|operations|organiser|organizer|pastor|pharma|photos|politics|postmaster|pr|president|prime|privacy|projects|promos|publicaffairs|publisher|quality|query|questions|radio|realestate|realtor|receptionist|redaction|register|remove|repairs|request|research|reservation|reservations|reserve|reserveroom|root|sales|schools|secretary|security|service|shopping|spam|staff|subscribe|suggest|support|survey|talent|tourism|tours|trading|training|transfer|travel|travels|unsubscribe|usa|usenet|user|username|users|uucp|verify|volunteer|web|webinfo|webmaster|website|wwinfo|www|www\-validator|team|connect|privacyofficer|press|app|iubenda|contactpress|hello|welcome|kontakt)+@/g


