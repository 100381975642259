import React from 'react'
import {
  TextField,
  Input,
  CircularProgress
} from '@material-ui/core'
import MuiAutocomplete from '@material-ui/lab/Autocomplete'
import styled from 'styled-components'

const Option = styled.li`
    cursor: pointer;
    display: flex;
    width: 100%;
    outline: 0;
    box-sizing: border-box;
    align-items: center;
    padding: 6px 16px;
    justify-content: space-between;
`

export class Asynchronous extends React.Component {
  static defaultProps = {
    onGetData: () => new Promise(resolve => resolve([])),
    onRemoveValue: () => {}
  }

  state = {
    open: false,
    options: [],
    loading: false
  }

  typingTimeout = null

  componentDidMount () {

  }

  onToggleOpen = async () => {
    const { open } = this.state
    this.setState({ open: !open }, async () => {
      if (!open) {
        this.setState({ loading: true })
        const options = await this.props.onGetData(null)
        this.setState({ loading: false, options })
      }
    })
  }

  onSearchChange = ({ target: { value } }) => {
    clearTimeout(this.typingTimeout)
    this.typingTimeout = setTimeout(async () => {
      this.setState({ loading: true })
      const options = await this.props.onGetData(value)
      this.setState({ loading: false, options })
    }, 1000)
  }

  render () {
    const { loading, options, open } = this.state
    const {
      id,
      name,
      disabled,
      multiple,
      defaultValue,
      placeholder,
      error,
      onChange,
      groupBy
    } = this.props

    return (
      <MuiAutocomplete
        id={id}
        name={name}
        open={open}
        multiple={multiple}
        openOnFocus
        onOpen={this.onToggleOpen}
        onClose={this.onToggleOpen}
        onChange={onChange}
        groupBy={groupBy ? (option) => option[groupBy] : null}
        getOptionLabel={({ label }) => label}
        renderOption={(option) => <Option>
          <div>{option.label}</div>
          <div style={{ color: 'slategrey' }}>{`${option.n ? `ID ${option.n}` : ''}`}</div>
        </Option>}
        options={options}
        ChipProps={{size: "small"}}
        closeIcon={null}
        popupIcon={null}
        loading={loading}
        disabled={disabled}
        defaultValue={defaultValue}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            error={!!error}
            helperText={error}
            variant="standard"
            placeholder={placeholder}
            onChange={this.onSearchChange}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              autoFocus: true,
              onKeyDown: (ev) => {
                if (!ev.target.value && 'Backspace' === ev.key) {
                  this.props.onRemoveValue()
                }
              },
              onBlur: () => this.setState({ open: false }, this.props.onBlur),
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    )
  }
}

export default class Autocomplete extends React.Component {
  static defaultProps = {
    onRemoveValue: () => {}
  }

  render () {
    const {
      id,
      value,
      defaultValue,
      multiple,
      placeholder,
      options,
      onChange
    } = this.props

    return <MuiAutocomplete
      fullWidth
      multiple={multiple}
      id={id}
      options={options}
      getOptionLabel={option => option.label}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      ChipProps={{size: "small"}}
      closeIcon={null}
      popupIcon={null}
      renderInput={params =>
        <Input
          {...params}
          {...params.InputProps}
          onKeyDown={(ev) => {
            if (!ev.target.value && 'Backspace' === ev.key) {
              this.props.onRemoveValue()
            }
          }}
          autoFocus
          disableUnderline
          fullWidth
          placeholder={placeholder}
        />}
    />
  }
}

