import React from 'react'
import styled from 'styled-components'
import List from '../../components/List'
import { Asynchronous } from '../../components/Autocomplete'

import DashboardPage from '../../layouts/DashboardPage'

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 200px;
  & p {
    display: none;
  }
  padding-right: 16px;
`

const columns = [
  { id: 'createdAt', disablePadding: true, label: 'Created at', nowrap: true },

  { id: 'name', disablePadding: true, label: 'Name', nowrap: true },
  {
    id: 'channelsCount',
    disablePadding: true,
    label: 'Brands',
    numeric: true,
  },
  {
    id: 'affiliatePartnersCount',
    disablePadding: true,
    label: 'Creators',
    numeric: true,
  },
  {
    id: 'inPartnershipsCount',
    disablePadding: true,
    label: 'Partnerships',
    numeric: true,
  },
  {
    id: 'brandName',
    disablePadding: true,
    label: 'Brand',
    kind: 'link',
    link: { title: 'Brand', pattern: `/brands/{id}`, keys: { id: 'brand' } },
  },
]

const BrandSelect = (props) => {
  const [selected, setSelect] = React.useState(null)

  const onSelect = (value) => {
    setSelect(value)

    props.onSelect(value ? value.id : null)
  }

  return (
    <SelectWrapper>
      <Asynchronous
        fullWidth
        url='/api/admin/brands'
        filter='filters[name]'
        placeholder={'Select brand'}
        dataField='brands'
        dataLabelField='name'
        value={selected}
        defaultValue={selected}
        onChange={(e, a) => onSelect(a)}
      />
    </SelectWrapper>
  )
}

export default class SaasEcosystems extends React.Component {
  render() {
    return (
      <DashboardPage kind='list' title='SaaS Ecosystems' noActions>
        <List
          {...this.props}
          columns={columns}
          searchable
          emptyMessage={'No actions matching the given criteria.'}
          additional={BrandSelect}
          additionalProps={{
            onSelect: this.props.onChangeFilters('brandId'),
          }}
          onChangeFilters={this.props.onChangeFilters}
        />
      </DashboardPage>
    )
  }
}
