import React from 'react'
import DashboardPage from '../../layouts/DashboardPage'
import DetailsForm from '../../components/Details'
import globalConfig from '../../config'
import ConfirmDialog from '../../components/ConfirmDialog'
import { RejectButton } from '../../components/Buttons'
import { Button } from '@material-ui/core'

const config = [
  { key: 'id', name: 'ID', type: 'string' },
  { key: 'status', name: 'Status', editable: true, creatable: false, type: 'select', options: globalConfig.EVENT_STATUS, kind: 'status' },
  {
    key: 'brandName', name: 'Brand', type: 'brands',
    link: { title: 'Brand', pattern: `/brands/{id}`, keys: { id: 'brand' } }
  },
  {
    key: 'opportunityName', name: 'Opportunity', type: 'opportunities',
    link: { title: 'Opportunity', pattern: `/opportunities/{id}`, keys: { id: 'opportunity' } }
  },
  { key: 'touchpointName', name: 'Touchpoint', type: 'string' },
  {
    key: 'partnerName', name: 'Partner', type: 'partners',
    link: { title: 'Partner', pattern: `/partners/{id}`, keys: { id: 'partner' } }
  },
  { key: 'channelName', name: 'Channel', type: 'string' },
  { key: 'payout', name: 'Payout', type: 'string' },
  { key: 'revenue', name: 'Revenue', type: 'string' },
  { key: 'amount', name: 'Amount', type: 'string' },
  { key: 'couponCode', name: 'Coupon Code', type: 'string' },
]

const EventsAction = (props) => {
  const { status, onClick } = props

  if (status === 'paid') {
    return null
  }

  return <React.Fragment>
    {
      status !== 'rejected' &&
      <ConfirmDialog
        title={'Reject reason'}
        action={(reason) => onClick('rejected', reason)}
        confirnButtonLabel={'Reject'}
        confirnButtonProps={{
          variant: 'contained',
          color: 'primary'
        }}
      >
        <RejectButton
          variant="contained"
          color="primary"
        >
          Reject
        </RejectButton>
      </ConfirmDialog>
    }
    { status === 'pending' && <span>&nbsp;</span> }
    {
      status !== 'approved' &&
      <Button
        variant="contained"
        color="secondary"
        onClick={() => onClick('approved')}
      >
        Approve
      </Button>
    }

  </React.Fragment>
}

export default class Event extends React.Component {
  state = {
    edit: false
  }

  onCancel = e => {
    if (this.props.id === 'create') {
      return this.props.onCancel()
    }
  }

  render () {
    const {
      id,
      details,
      loading,
      thinking,
      onCreate,
      onUpdate,
      onCancel
    } = this.props

    return (
      <DashboardPage
        kind="details"
        title={"Event"}
        status={id}
        actions={() => <EventsAction status={details.status} onClick={(status, rejectReason) => onUpdate({ status, ...(rejectReason && { rejectReason })})}/>}
        thinking={loading}
      >
        <DetailsForm
          notEditable
          config={config}
          details={details}
          // onCreate={onCreate}
          onUpdate={onUpdate}
          onCancel={onCancel}
        />

      </DashboardPage>
    )
  }
}
