import List from './list'
import { action } from 'mobx'
import { Delete } from '../utils/request'
import hasError from './request-message'

export default class BrandSaaSEcosystemsStore extends List {
  brandId
  url = '/api/admin/self-ecosystems'

  constructor(brandId) {
    super()
    this.brandId = brandId
    this.url = `${this.url}`
    this.order = 'desc'
    this.orderBy = 'createdAt'
    this.itemsField = 'ecosystems'
    this.perPage = 25
    this.filters = { brandId: this.brandId }
    this.getList().then()
  }

  @action
  async remove(ecosystemId) {
    const { success, message } = await Delete(
      `/api/admin/self-ecosystems/${ecosystemId}`,
      {}
    )
    if (
      !hasError(success, message, 'Signatures successfully removed from brand')
    ) {
      this.getList().then()
    }

    return { success }
  }
}
