import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import Creator from './Creator'
import CreatorStore from '../../stores/creator'
import config from '../../config'

@withRouter
@observer
class _Creator extends React.Component {
  constructor(props) {
    super(props)
    const {
      match: {
        params: {
          id
        }
      }
    } = props
    this.store = new CreatorStore(id)
  }


  onAttachToChannel = async (channelId, creatorId) => await this.store.attach(channelId, creatorId)

  render () {
    const {
      match: {
        params: {
          id
        }
      }
    } = this.props

    const {
      details,
      loading,
      thinking
    } = this.store

    return <Creator
      id={id}
      details={details}
      loading={loading}
      thinking={thinking}
      onAttachToChannel={this.onAttachToChannel}
    />
  }
}

export default _Creator
