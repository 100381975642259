import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import BrandSearches from './BrandSearches'
import { formatDateTime } from '../../utils/format'
import config from '../../config'

import SearchesStore from '../../stores/searches'

const getDiff = (start, finish) => {
  const diff = moment(finish).diff(moment(start))
  return moment(diff).utc().format('HH:mm:ss')
}

const itemsTime = (items = []) => {
  return items.map(({ createdAt, finishedAt, costs, ...other}) => {
    return {
      ...other,
      createdAt: formatDateTime(createdAt),
      finishedAt: other.status !== 'done' ? '' : formatDateTime(finishedAt),
      cost: `${config.NUMBER_CURRENCY_SYMBOL}${((costs || {}).total || 0).toFixed(4)}`,
      diff: other.status !== 'done' ? null : getDiff(createdAt, finishedAt)
    }
  })
}

@withRouter
@observer
class _BrandSearches extends React.Component {
  constructor(props) {
    super(props)
    const { brandId } = props
    this.store = new SearchesStore({ brandId })
  }

  onChangeSort = orderByNew => e => {
    const { listProps } = this.store
    const { order, orderBy } = listProps
    let orderNew = ''
    if (orderBy === orderByNew) {
      if (order === 'desc') {
        orderNew = 'asc'
      } else {
        orderNew = 'desc'
      }
    } else {
      orderNew = 'desc'
    }

    this.store.onChangeSort(orderNew, orderByNew)
  }

  onChangePage = (e, page) => this.store.onChangePage(page)

  onChangeRowsPerPage = ({ target: { value }}) => this.store.onChangeRowsPerPage(value)

  onChangeFilters = name => object => {
    let value = null
    if (object.hasOwnProperty('target')) {
      value = object.target.value
    } else {
      value = object
    }
    this.store.onChangeFilter(name, value)
  }

  onToggleVisibility = (id, kind) => {
    this.store.toggleVisibility(id, kind)
  }

  render () {
    const {
      listProps
    } = this.store

    const {
      items
    } = listProps

    return <BrandSearches
      {...this.props}
      {...listProps}
      items={itemsTime(items)}
      onChangeSort={this.onChangeSort}
      onChangeFilters={this.onChangeFilters}
      onChangePage={this.onChangePage}
      onChangeRowsPerPage={this.onChangeRowsPerPage}
      onToggleVisibility={this.onToggleVisibility}
    />
  }
}

export default _BrandSearches
