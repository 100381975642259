import React from 'react'
import { observer } from 'mobx-react'
import Contacts from './Contacts'
import ContactsStore from '../../stores/contact-persons'


@observer
class _Contacts extends React.Component {
  constructor (props) {
    super(props)
    const { client, clientId, discovery } = props
    this.store = new ContactsStore(client, clientId, discovery)
  }

  onCreate = details => this.store.create(details)
  onUpdate = details => this.store.update(details)
  onRemove = id => this.store.remove(id)

  render () {
    const {
      persons,
      thinking
    } = this.store
    return <Contacts
      persons={persons}
      thinking={thinking}
      onCreate={this.onCreate}
      onUpdate={this.onUpdate}
      onRemove={this.onRemove}
    />
  }
}

export default _Contacts
