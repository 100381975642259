import List from './list'

export default class BrandsStore extends List {
  url = '/api/admin/brands'

  constructor() {
    super()
    this.order = 'desc'
    this.orderBy = 'createdAt'
    this.itemsField= 'brands'
    this.getList().then()
  }
}
