import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions as MuiDialogActions,
  FormControl,
  FormLabel,
  Grid,
  Button as MuiButton, InputLabel, Input, FormHelperText
} from '@material-ui/core'
import { spacing } from '@material-ui/system'
import Validator from '../utils/validator'

const FormErrorText = styled(FormHelperText)`
  color: #f44336;
  position: absolute;
  bottom: -18px;
`;

const Button = styled(MuiButton)(spacing)
const DialogActions = styled(MuiDialogActions)(spacing)

const schema = [
  {
    field: 'email',
    rules: 'required|valid_email',
    messages: {
      required: 'Email is required',
      valid_email: 'Please enter valid email'
    }
  },
  {
    field: 'password',
    rules: 'required',
    messages: {
      required: 'Password is required'
    }
  }
]

export default function (props) {
  const [details, setDetails] = useState({})
  const [error, setError] = useState(null)
  const [errors, setErrors] = useState({})
  const [thinking, setThinking] = useState(false)

  const onResetError = name => {
    delete errors[name]
    setErrors(errors)
  }

  const onChange = name => ({target: {value}}) => {
    setDetails({...details, [name]: value })
    onResetError(name)
  }

  const onSubmit = (event) => {
    setThinking(true)
    event.preventDefault()
    Validator('register-user-form', schema,
      validationErrors => {
        setErrors(validationErrors)
        setThinking(false)
      },
      async () => {
        const { password, email } = event.target

        const { error, message, user } = await props.onSubmit({ email: email.value, password: password.value })

        setThinking(false)

        if (error || message) {
          setError(message)
        } else {
          props.onSuccess(user)
        }
      })
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      data-cy="channelDialog"
      open={props.open}
      onClose={props.onCancel}
      onBackdropClick={props.onCancel}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">
        Register User
      </DialogTitle>
      <form
        name={'register-user-form'}
        onSubmit={onSubmit}
        noValidate
      >
        <DialogContent>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email">Email</InputLabel>
                <Input
                  label={'Email'}
                  variant={'filled'}
                  fieldType='clearable'
                  id={'email'}
                  disabled={thinking}
                  value={details.email}
                  onChange={onChange('email')}
                  error={errors.email || error}
                  aria-describedby="error-email"
                  autoFocus
                />
                <FormErrorText id="error-email">{errors.email || error}</FormErrorText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input
                  label={'Password'}
                  variant={'filled'}
                  fieldType='clearable'
                  id={'password'}
                  disabled={thinking}
                  value={details.password}
                  onChange={onChange('password')}
                  error={errors.password}
                  aria-describedby="error-password"
                />
                <FormErrorText id="error-password">{errors.password}</FormErrorText>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions px={6} py={4}>
          <Grid container>
            <Grid item xs={12} sm={6} />
            <Grid item xs={12} sm={6}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center">
              <Button
                mr={2}
                color="default"
                disabled={thinking}
                onClick={props.onCancel}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                disabled={thinking}
              >
                Register
              </Button>
            </Grid>
          </Grid>
        </DialogActions>

      </form>
    </Dialog>
  )
}
