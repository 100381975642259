import React from 'react'
import {
  Grid,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent,
  DialogTitle,
  Button as MuiButton,
  TextField as MuiTextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Divider,
} from '@material-ui/core'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'

const Button = styled(MuiButton)(spacing)
const DialogActions = styled(MuiDialogActions)(spacing)

export default function ExistingOrNewModal({
  open,
  onNew,
  onExisting,
  onClose,
}) {
  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      data-cy='dialogContactContacts'
      open={open}
      aria-labelledby='max-width-dialog-title'
    >
      <DialogTitle id='max-width-dialog-title'>
        Create New or Add Existing
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item alignContent='center' xs={6}>
            <Button
              fullWidth
              variant='outlined'
              color='secondary'
              onClick={onNew}
            >
              Add New
            </Button>
          </Grid>
          <Grid item alignContent='center' xs={6}>
            <Button
              fullWidth
              variant='outlined'
              color='primary'
              onClick={onExisting}
            >
              Add existing
            </Button>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button color='secondary' onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
