import { observable, action, computed, toJS } from 'mobx'
import { find, isEmpty } from 'lodash'
import moment from 'moment'
import { Delete, Get, Post, Put } from '../utils/request'
import { rangeDays } from '../utils/dates'
import { formatDate } from '../utils/format'
import Notification from './notification'
import hasError from './request-message'

import config from '../config'

const numberFields = ['revenue', 'revenue1', 'cost', 'cost1', 'lookback']
const moneyFields = ['cost', 'revenue']

const convertToCents = (details) => {
  let result = { ...details }
  for (let key in result) {
    if (result.hasOwnProperty(key) && numberFields.indexOf(key) >= 0) {
      result[key] = parseFloat(result[key])
      if (moneyFields.indexOf(key) >= 0) {
        const toDollarsCoefficient = (key === 'cost' && details.costModel === 'S') || (key === 'revenue' && details.revenueModel === 'S') ? 1 : 100
        result[key] = parseFloat(result[key]) * toDollarsCoefficient
      }
    }
  }
  return result
}

const convertToDollars = (details) => {
  let result = { ...details }
  for (let key in result) {
    if (result.hasOwnProperty(key) && numberFields.indexOf(key) >= 0) {
      result[key] = parseFloat(result[key])
      if (moneyFields.indexOf(key) >= 0) {
        const toDollarsCoefficient = (key === 'cost' && details.costModel === 'S') || (key === 'revenue' && details.revenueModel === 'S') ? 1 : 100
        result[key] = parseFloat(result[key]) / toDollarsCoefficient
      }
    }
  }
  return result
}

class OpportunityCampaignStore {
  model = 'opportunity'
  api = '/api/admin/opportunities'

  @observable id
  @observable n
  @observable name
  @observable brand
  @observable brandName
  @observable brandChannel
  @observable brandChannelKind
  @observable brandChannelName
  @observable partner
  @observable partnerName
  @observable channel
  @observable channelName
  @observable opportunity
  @observable opportunityName
  @observable createdAt
  @observable status

  @observable privateCoupons = []
  @observable privateCouponsThinking = true
  @observable commonCoupons = []
  @observable commonCouponsThinking = true

  @observable _rules = []
  @observable opportunityRules = []

  @observable urls = []
  @observable opportunityUrls = []

  @observable _touchpoints = []
  @observable opportunityTouchpoints = []

  @observable tracking = {}

  @observable tableStatistics = {}
  @observable chartStatistics = {}
  @observable clicksStatistics = {}

  @observable loading = true
  @observable loadingStatistics = true
  @observable loadingTouchpoints = true
  @observable loadingOpportunity = true
  @observable thinking = false

  fillModelFields (model) {
    for (let key in model) {
      if (model.hasOwnProperty(key)) {
        if (key === 'rules') {
          this['_rules'] = model[key]
        } else {
          this[key] = model[key]
        }
      }
    }
  }

  constructor (id, kind) {
    if (kind === 'campaign') {
      this.model = 'campaign'
      this.api = '/api/admin/tracking-campaigns'
    }

    if (id !== 'create') {
      this.id = id
      this.load().then()
    } else {
      this.loading = false
    }
  }

  @action
  async load () {
    const { [this.model]: model, message, errorCode } = await Get(`${this.api}/${this.id}`)

    this.fillModelFields(model)

    this.loading = false

    this.getStatistic()
    this.getOpportunity()
    this.getTouchpoints(model.opportunity)

    this.getCoupons('private')

    if (this.model !== 'campaign') {
      this.getCoupons('common')
    }

    return !hasError(!errorCode, message)
  }

  @action
  async create (details) {
    const { brand, channel, ...other } = details

    let info = { ...other, brand: brand.id, channel: channel.id }
    if (this.model === 'campaign') {
      const { partner, opportunity } = details
      info = { ...info,  partner: partner.id, opportunity: opportunity.id }
    }

    const { [this.model]: model, success, message } = await Post(this.api, { [this.model]: info } )

    if (!hasError(success, message, `${this.model === 'campaign' ? 'Campaign' : 'Opportunity'} successfully created`)) {
      return { [this.model]: model, success }
    }

    return { success }
  }

  @action
  async update (details) {
    const { [this.model]: model, message, success } = await Put(`${this.api}/${this.id}`, { [this.model]: details })

    this.fillModelFields(model)

    this.getStatistic().then()
    this.getOpportunity().then()

    if (!hasError(success, message, `${this.model === 'campaign' ? 'Campaign' : 'Opportunity'} successfully updated`)) {
      return { [this.model]: model, success }
    }

    return { success, message }
  }

  @action
  async toggleArchived () {
    const toArchive = this.status !== 'deleted'
    let result = {}
    if (toArchive) {
      result = await Delete(`${this.api}/${this.id}`, {})
    } else {
      result = await Put(`${this.api}/${this.id}`, { [this.model]: { status: 'active' } })
    }
    const { message, success } = result

    if (!hasError(success, message, `${this.model === 'campaign' ? 'Campaign' : 'Opportunity'} successfully ${toArchive ? 'archived' : 'unarchived'}`)) {
      this.status = toArchive ? 'deleted' : 'active'
    }

    return { success }
  }

  @action
  async getOpportunity () {
    this.loadingOpportunity = true
    if (this.model === 'campaign') {
      const { opportunity, message } = await Get(`/api/admin/opportunities/${this.opportunity}`)
      if (!message) {
        this.opportunityRules = opportunity.rules
        this.opportunityUrls = opportunity.urls
      }
    }
    this.loadingOpportunity = false
  }

  @action
  async getStatistic () {
    const id = this.id
    const lastDay = rangeDays(7)[0].date
    const { allConversions } = await Get(`/api/stats/common?${this.model}=${id}`)
    const perDay = await Get(`/api/stats/common?breakBy=day&startAt=${lastDay}&${this.model}=${id}`)
    const clicks = await Get(`/api/stats/clicks?startAt=${lastDay}&${this.model}=${id}`)

    this.tableStatistics = allConversions
    this.chartStatistics = perDay
    this.clicksStatistics = clicks

    this.loadingStatistics = false

    return { perDay, clicks, allConversions }
  }

  @action
  async getTouchpoints () {
    this.loadingTouchpoints = true
    const url = `/api/admin/touchpoints`
    const excludeCampaigns = this.model !== 'campaign' ? '&filters[excludeCampaigns]=true' : ''
    const { touchpoints } = await Get(`${url}?filters[${this.model}]=${this.id}&filters[notStatus]=deleted&all=1${excludeCampaigns}`)
    this._touchpoints = touchpoints.map(convertToDollars)
    if (this.model === 'campaign') {
      const { touchpoints: opportunityTouchpoints } = await Get(`${url}?filters[opportunity]=${this.opportunity}&filters[notStatus]=deleted&all=1&filters[excludeCampaigns]=true`)
      this.opportunityTouchpoints = opportunityTouchpoints.map(convertToDollars)
    }
    this.loadingTouchpoints = false
  }

  @action
  async createTouchpoint (details) {
    const { success, message } = await Post(`/api/admin/touchpoints`, { touchpoint: convertToCents(details) })

    this.getTouchpoints()

    return { success: !hasError(success, message, `Touchpoint successfully created`) }
  }

  @action
  async updateTouchpoint ({ id, ...details }) {
    let result = {}

    if (this.model !== 'campaign') {
      result = await Put(`/api/admin/touchpoints/${id}`, { touchpoint: convertToCents(details) })
    } else {
      const { touchpoints } = this.touchpoints
      const touchpoint = find(touchpoints, ['id', id])
      if (touchpoint.refId) {
        result = await Put(`/api/admin/touchpoints/${id}`, { touchpoint: convertToCents(details) })
      } else {
        const { partner, id: campaign, opportunity, } = this
        const toCreateDetails = {
          ...convertToCents(details),
          partner,
          campaign,
          opportunity,
          refId: id
        }
        result = await Post(`/api/admin/touchpoints`, { touchpoint: toCreateDetails })
      }
    }

    const { success, message } = result

    await this.getTouchpoints()

    return { success: !hasError(success, message, `Touchpoint successfully updated`) }
  }

  @action
  async removeTouchpoint (id) {
    const { success, message } = await Delete(`/api/admin/touchpoints/${id}`, {})
    this.getTouchpoints()

    return { success: !hasError(success, message, `Touchpoint successfully archived`) }
  }

  @action
  async getCoupons (kind) {
    let queries = ``
    this[`${kind}CouponsThinking`] = true
    if (this.model === 'campaign') {
      queries = `?filters[campaign]=${this.id}`
    } else {
      queries = `?filters[opportunity]=${this.id}&filters[${kind === 'common' ? 'commonOnly' : 'personalOnly'}]=1`
    }
    const { coupons } = await Get(`/api/admin/coupons${queries}`)
    this[`${kind}Coupons`] = coupons.map(coupon => {
      const kindName = (find(config.COUPON_KINDS, ['value', coupon.kind]) || {}).label
      const channelName = (find(config.CHANNELS, ['value', coupon.channelKind]) || {}).label
      return {
        ...coupon,
        startsOn: formatDate(coupon.startsOn),
        expiresOn: formatDate(coupon.expiresOn),
        kindName,
        channelName
      }
    })
    this[`${kind}CouponsThinking`] = false
  }

  @action
  async createCoupon (kind, details) {
    let coupon = {}
    const dates = { startsOn: moment(details.startsOn).format('YYYY-MM-DD'), expiresOn: moment(details.expiresOn).format('YYYY-MM-DD')}
    if (this.model === 'campaign') {
      coupon = { ...details, campaign: this.id, opportunity: this.opportunity, ...dates }
    } else {
      coupon = { ...details, opportunity: this.id, ...dates }
    }
    const { success, message } = await Post(`/api/admin/coupons`, { coupon })
    this.getCoupons(kind)

    return { success: !hasError(success, message, `Coupon successfully created`), message }
  }

  @action
  async updateCoupon (kind, details) {
    const { id, ...otherDetails } = details
    const dates = { startsOn: moment(details.startsOn).format('YYYY-MM-DD'), expiresOn: moment(details.expiresOn).format('YYYY-MM-DD')}
    const { success, message } = await Put(`/api/admin/coupons/${id}`, { coupon: {...otherDetails, ...dates} })
    this.getCoupons(kind)
    return { success: !hasError(success, message, `Coupon successfully updated`), message }
  }

  @action
  async removeCoupon (kind, id) {
    const { success, message } = await Delete(`/api/admin/coupons/${id}`, {})
    this.getCoupons(kind)
    return { success: !hasError(success, message, `Coupon successfully archived`) }
  }

  @computed
  get details () {
    const {
      id,
      n,
      name,
      description,
      brand,
      brandName,
      brandChannel,
      brandChannelKind,
      brandChannelName,
      partner,
      partnerName,
      channelName,
      opportunity,
      opportunityName,
      createdAt,
      status
    } = this
    return {
      id,
      n,
      name,
      description,
      channelName,
      brandName,
      brandChannel,
      brandChannelKind,
      brandChannelName,
      brand: { id: brand, label: brandName },
      partner: { id: partner, label: partnerName },
      opportunity: { id: opportunity, label: opportunityName },
      createdAt,
      status
    }
  }

  @computed
  get landings () {
    const urls = toJS(this.urls)
    const opportunityUrls = toJS(this.opportunityUrls)
    return {
      urls: urls.length ? urls : opportunityUrls,
      thinking: this.loadingOpportunity
    }
  }

  @computed
  get rules () {
    const _rules = toJS(this._rules)
    const opportunityRules = toJS(this.opportunityRules)
    return {
      rules: !!_rules.length ? _rules : opportunityRules.map(r => ({ ...r, opportunity: true })),
      thinking: this.loadingOpportunity
    }
  }

  @computed
  get touchpoints () {
    let touchpoints = toJS(this._touchpoints)

    if (this.model === 'campaign') {
      const oTouchpoints = toJS(this.opportunityTouchpoints)
      touchpoints = [...touchpoints, ...oTouchpoints.filter(touchpoint => {
        const campaignTouchpoint = find(touchpoints, ['refId', touchpoint.id])
        return !campaignTouchpoint
      })]
    }

    return {
      touchpoints: touchpoints,
      thinking: this.loadingTouchpoints
    }
  }

  @computed
  get trackingParameters () {
    return toJS(this.tracking)
  }

  @computed
  get statistics () {
    const ts = this.tableStatistics

    const rangedDays = rangeDays(7)
    const labels = rangedDays.map(({ dayOfWeek }) => dayOfWeek)
    const days = rangedDays.map(({ date }) => date)

    const chartFields = ['clicks', 'amount', 'revenue', 'profit', 'count', 'cost']
    let chartData = {
      clicks: [],
      amount: [],
      revenue: [],
      profit: [],
      cost: [],
      conversions: [],
      count: []
    }

    days.forEach(day => {
      const dayData = ((toJS(this.chartStatistics)[day] || {}).allConversions || {})
      const clicksDayData = (toJS(this.clicksStatistics)[day] || 0)



      chartFields.forEach(field => {
        if (field === 'clicks') {
          chartData[field].push(clicksDayData)
        } else {
          if (field === 'count') {
            chartData['conversions'].push(dayData[field] || 0)
          } else {
            chartData[field].push((dayData[field] || 0) / 100)
          }
        }
      })
    })

    return {
      thinking: this.loadingStatistics,
      showMocks: isEmpty(toJS(this.clicksStatistics)) && isEmpty(toJS(this.chartStatistics)),
      chart: {
        labels,
        data: chartData
      },
      profit: {
        revenue: ts.revenue / 100,
        cost: ts.cost / 100,
        profit: ts.profit / 100
      },
      conversions: {
        count: ts.count,
        amount: ts.amount / 100
      }
    }
  }

  @computed
  get coupons () {
    const privateCoupons = toJS(this.privateCoupons)
    const commonCoupons = toJS(this.commonCoupons)
    return {
      commonCouponsThinking: this.commonCouponsThinking,
      privateCouponsThinking: this.privateCouponsThinking,
      commonCoupons,
      privateCoupons
    }
  }
}

export default OpportunityCampaignStore
