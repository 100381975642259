import React from 'react'
import Helmet from 'react-helmet'
import moment from 'moment'
import { Link as RouterLink } from 'react-router-dom'
import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Grid,
  IconButton,
  Typography,
  Hidden,
  Fab,
  CircularProgress as MuiCircularProgress
} from '@material-ui/core'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { AddCircleOutline , Block, Delete, Add } from '@material-ui/icons'
import Divider from '../components/Divider'
import LoaderOverlay from '../components/LoaderOverlay'

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing)
const Button = styled(MuiButton)(spacing)
const CircularProgress = styled(MuiCircularProgress)(spacing)
const Date = styled(Typography)`
padding-left: 3em;
white-space: nowrap;
@media (max-width: 700px){
padding-left: 0;
display: block;
}
`
const AddIcon = styled(AddCircleOutline)`
padding-right: 0.2em
`
const AddMobile = styled(Fab)`
position: fixed;
bottom: 5em;
right: 1em;
z-index: 100;
`

export default class DashboardPage extends React.Component {
  static defaultProps = {
    addClick: () => {}
  }

  render () {
    const {
      title,
      breadcrumb,
      time,
      kind,
      status,
      forceDefaultActions,
      thinking,
      addLabel,
      addDataCy,
      addLink,
      addTarget,
      addClick,
      noAddIcon,
      noActions,
      children,
      actions: Actions
    } = this.props

    return (
      <React.Fragment>
        <Helmet title={title} />
        <Grid container alignItems="center">
          <Grid item xs={12} md={6} style={{minHeight: 36}}>
            <Typography variant="h3" gutterBottom display="inline">
              { breadcrumb || title }
            </Typography>
            {
              time &&
              <Date variant="body1" gutterBottom display="inline">
                { moment().utc().format('dddd, D MMMM YYYY hh:mm') }&nbsp;GMT
              </Date>
            }
          </Grid>

          <Grid item xs md/>

          <Grid item style={{ display: 'inline-flex' }}>
            {
              !!Actions && <><Actions />&nbsp;&nbsp;</>
            }
            {
              kind === 'list' && ((!noActions && !Actions) || forceDefaultActions) &&
              <>
                <Hidden smDown implementation="css">
                <Button
                  component={addTarget ? 'a' : RouterLink}
                  data-cy={!!addDataCy && addDataCy}
                  mr={2}
                  variant="contained"
                  color="primary"
                  target={addTarget}
                  href={addTarget ? addLink : null}
                  to={{ pathname: addLink }}
                  onClick={addClick}
                >
                  { !noAddIcon && <AddIcon /> }
                  { addLabel }
                </Button>
                </Hidden>
                <Hidden  mdUp implementation="js">
                  <AddMobile color="primary" href={addLink}  onClick={addClick}>
                    <Add />
                  </AddMobile>
                </Hidden>
              </>
            }
            {
              kind === 'details' && !noActions && !Actions &&
              <React.Fragment>
                <IconButton aria-label="delete">
                  <Delete fontSize="small" />
                </IconButton>
                <IconButton aria-label="delete">
                  <Block fontSize="small" />
                </IconButton>

              </React.Fragment>
            }
          </Grid>
        </Grid>

        <Divider my={6} />

        { thinking && <LoaderOverlay /> }
        { !thinking && children }

      </React.Fragment>
    )
  }
}
