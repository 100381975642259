import React from 'react'
import Panel from './Panel'
import Validator from '../utils/validator'
import { FormControl, FormHelperText, Input, InputLabel, Button, Paper } from '@material-ui/core'
import styled from 'styled-components'

const FormErrorText = styled(FormHelperText)`
  color: #f44336;
  position: absolute;
  bottom: -18px;
`;

const Wrapper = styled(Paper)`
  padding: ${props => props.theme.spacing(6)}px;

  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)}px;
  }
`;

const schema = [
  {
    field: 'password',
    rules: 'required',
    messages: {
      required: 'Password is required'
    }
  }
]

export default class SetPassword extends React.Component {
  state = { password: '', errors: {}, error: null }

  onChange = name => ({target: {value}}) => {
    this.setState({[name]: value})
    this.resetError(name)
    if (this.props.onChange) {
      this.props.onChange({[name]: value})
    }
  }

  resetError = name => {
    const errors = this.state.errors || {}
    errors[name] = null
    this.setState({errors, error: null})
  }

  onSubmit = (event) => {
    event.preventDefault()
    Validator('set-password-form', schema,
      errors => this.setState({errors}),
      async () => {
        const { password } = event.target

        const { error, message } = await this.props.onSubmit({ password: password.value })

        if (error || message) {
          this.setState({ error: message })
        } else {
          this.setState({ password: null })
        }
      })
  }

  render () {
    const {
      password,
      errors
    } = this.state

    const {
      thinking
    } = this.props

    return (
      <Panel
        title="Update user password"
        thinking={thinking}
      >
        <Wrapper>
          <form
            name={'set-password-form'}
            onSubmit={this.onSubmit}
            noValidate
          >
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                label={'Password'}
                variant={'filled'}
                fieldType='clearable'
                id={'password'}
                disabled={thinking}
                value={password}
                onChange={this.onChange('password')}
                error={errors.password}
                aria-describedby="error-password"
                autoFocus
              />
              <FormErrorText id="error-password">{errors.password}</FormErrorText>
            </FormControl>

            <Button
              type="submit"
              disabled={thinking}
              variant="contained"
              color="primary"
              mb={2}
            >
              Set
            </Button>
          </form>
        </Wrapper>
      </Panel>
    )
  }
}
