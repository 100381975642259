import React from 'react'
import {
  Card as MuiCard,
  CardContent,
  CardActions,
  Button,
  TextField,
  Typography,
  IconButton,
  Menu,
  MenuItem
} from '@material-ui/core'
import moment from 'moment'
import { AttachFile, MoreVert, Close as MuiRemove } from '@material-ui/icons'
import { File as MuiFile } from 'react-feather'
import styled from 'styled-components'
import { AnimatedList } from 'react-animated-list'
import { find } from 'lodash'
import Loader from '../LoaderOverlay'
import Panel from '../Panel'
import Link from '../Link'
import { uploadMulti, getSignedRequestMulti } from '../../utils/upload'

const NoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 15px;
  position: inherit;
`
const Card = styled(MuiCard)`
background: #EAFAFF;
border-radius: 3px;
box-sizing: border-box;
margin: 1em;
`
const NoteActions = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 0;
  justify-content: space-between;
`
const NoteHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: -10px;
`
const More = styled(MoreVert)`
width: 15px;
height: 15px;
color: ${props=>props.theme.palette.grey[700]};
`
const File = styled(MuiFile)`
width: 14px;
height: 14px;
position: relative;
color: ${props=>props.theme.palette.grey[700]};
margin-right: 0.3em;
flex: 0 0 auto;
`
const Remove = styled(MuiRemove)`
width: 14px;
height: 14px;
position: relative;
color: ${props=>props.theme.palette.grey[700]};
flex: 0 0 auto;
`


const FileList = styled.ul`
list-style: none;
padding: 0;
margin: 0 0 15px;
`
const FileListItem = styled.li`
display: flex;
margin: 1px 0;
padding: 5px;
background: rgba(0,0,0,0.04);
border-radius: 3px;
align-items: center;
&:hover {
background: rgba(0,0,0,0.08);
}
`


const FileListText = styled.div`
font-family: ${props => props.theme.typography.fontFamily};
font-size: 14px;
flex: 1 1 auto;
display: flex;
align-items: center;
width: 90%;
position: relative;
& a {
  white-space: nowrap;
  color: ${props => props.edit ? '' : props.theme.palette.primary.main};
  text-decoration: none;
      overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: 100%;
}
}
`





export class FilesUpload extends React.Component {
  constructor (props) {
    super(props)
  }

  fileInputRef = null

  get getFolder () {
    const { attachments } = this.props
    if (attachments && attachments.length) {
      const splited = attachments[0].split('/')
      return splited[splited.length - 2]
    }
    return null
  }

  getFileName = url => {
    if (url) {
      const splited = url.split('/')
      return splited[splited.length - 1]
    }
    return null
  }

  onFilesAdd = async (event) => {
    const files = event.target.files
    const filesBatch = []
    const newAttachments = []

    for (let i = 0; i < files.length; i++) {
      const { name: fileName, type: fileType } = files[i]
      filesBatch.push({ fileName, fileType })
    }

    const result = await getSignedRequestMulti(filesBatch, this.getFolder)

    if (result.success) {
      const toUpload = result.files.map(({ url, signedRequest }) => {
        const file = find(files, ['name', this.getFileName(url)])
        if (file) {
          newAttachments.push(url)
          return {
            url, signedRequest, file
          }
        }
      })
      this.props.onChange([...this.props.attachments, ...newAttachments], [...this.props.toUpload, ...toUpload])
      this.fileInputRef.value = null
    }
  }

  render () {
    const {
      id,
      edit,
      disabled,
    } = this.props

    return (
      edit && <>
        <input
          ref={node => this.fileInputRef = node}
          style={{ display: 'none' }}
          id={`upload-note-file-button-${id}`}
          onChange={this.onFilesAdd}
          type="file"
          disabled={false}
          multiple
        />
        <label style={{margin: 0}} htmlFor={`upload-note-file-button-${id}`}>
          <Button
            component="span"
            disabled={disabled}
            style={{fontSize: '0.8em'}}
            color="default"
            size="small"
            startIcon={<AttachFile />}
          > { 'Attach Files' } </Button>
        </label>
      </>
    )
  }
}

export class FileAttachments extends React.Component {
  onRemoveAttachment = attachment => {
    const attachments = this.props.attachments.filter(a => a !== attachment)
    const toUpload = this.props.toUpload.filter(({ url }) => url !== attachment)
    this.props.onChange(attachments, toUpload)
  }

  render () {
    const {
      edit,
      disabled,
      attachments
    } = this.props

    return <FileList>
      {
        attachments.map(attachment => (
          <FileListItem
          >
            <File />
            <FileListText edit={edit}>
              <a href={edit || disabled ? null : attachment} target={'_new'}>
                { attachment.replace(/^.*[\\\/]/, '') }
              </a>
            </FileListText>
            {
              (edit && !disabled) && <IconButton size='small' onClick={() => this.onRemoveAttachment(attachment)}>
                <Remove />
              </IconButton>
            }
          </FileListItem>
        ))
      }
    </FileList>
  }
}

export class AddNote extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      note: props.note || '',
      attachments: props.attachments || [],
      thinking: props.thinking || false,
      toUpload: []
    }
  }

  onChange = field => ({ target: { value } }) => {
    this.setState({ [field]: value })
  }

  onAttachmentsChange = (attachments, toUpload) => {
    this.setState({ attachments, toUpload })
  }

  onCreate = async () => {
    this.setState({ thinking: true })

    await uploadMulti(this.state.toUpload)

    const { success } = await this.props.onCreate({ text: this.state.note, attachments: this.state.attachments })

    if (success) {
      this.setState({ note: '', attachments: [], toUpload: [] })
    }
    this.setState({ thinking: false })
  }

  render () {
    const { ownerName } = this.props
    const {
      note,
      attachments,
      thinking
    } = this.state


    return (
      <NoteContainer>
        { thinking && <Loader /> }
        <Typography component="span" variant="body2" color="textPrimary">
          { `Add note about ${ownerName}` }
        </Typography>
        <TextField
          multiline
          rows={4}
          value={note}
          disabled={thinking}
          variant="outlined"
          onChange={this.onChange('note')}
        />
        <NoteActions>

          <FilesUpload
            id={'create-note'}
            edit
            disabled={thinking}
            attachments={attachments}
            toUpload={this.state.toUpload}
            onChange={this.onAttachmentsChange}/>

          <Button
            variant="outlined"
            color="default"
            size="small"
            disabled={thinking || !note || note.length < 3}
            onClick={this.onCreate}
          > { 'Add this note' } </Button>
        </NoteActions>
        {
          !!attachments && !!attachments.length && (
            <FileAttachments
              attachments={attachments}
              toUpload={this.state.toUpload}
              edit
              disabled={thinking}
              onChange={this.onAttachmentsChange}
            />
          )
        }
      </NoteContainer>
    )
  }
}

class Note extends React.Component {
  constructor (props) {
    super(props)
    const { text, attachments } = props
    this.state = { edit: false, text, attachments: attachments || [], toUpload: [], open: false, anchor: null, fileName: null, file: null }
  }

  onOpenMenu = ({ target: anchor }) => {
    this.setState({ open: true, anchor })
  }

  onCloseMenu = () => {
    this.setState({ open: false, anchor: null })
  }

  onEdit = () => {
    this.setState({ edit: true }, this.onCloseMenu)
  }

  onCancelEdit = () => {
    const { text, attachments } = this.props
    this.setState({ edit: false, text, attachments, toUpload: [] })
  }

  onChange = field => ({ target: { value } }) => {
    this.setState({ [field]: value })
  }

  onAttachmentsChange = (attachments, toUpload) => {
    this.setState({ attachments, toUpload })
  }

  onSaveNote = async () => {
    this.setState({ thinking: true })
    const { id } = this.props
    const { toUpload, attachments, text } = this.state
    await uploadMulti(toUpload)
    const { success } = await this.props.onUpdate({ id, text, attachments })
    this.setState({ edit: !success, thinking: false })
  }

  onRemoveNote = async () => {
    const { id } = this.props
    await this.props.onRemove(id)
  }

  render () {
    const {
      open,
      anchor,
      edit,
      text,
      attachments,
      toUpload,
      thinking
    } = this.state
    const { id, createdAt, author, authorName } = this.props

    return (
      <Card>
        <CardContent style={{padding:' 16px 16px 4px'}}>
          <NoteHeader>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Typography gutterBottom variant={'caption'} style={{fontWeight: 'bold', marginRight: '-0.5em'}}>
                { moment(createdAt).format('dddd, DD MMM') }
              </Typography>
              &nbsp;&nbsp;&nbsp;
              <Typography gutterBottom variant="caption" color="textSecondary">
                <Link value={`by ${authorName}`} title={'author'} link={`/users/${author}`}/>
              </Typography>
            </div>
            <IconButton style={{position: 'relative', marginTop: -5, marginRight: -14}} onClick={this.onOpenMenu}>
              <More/>
            </IconButton>
          </NoteHeader>

          {
            edit ? (
              <TextField
                style={{ width: '100%', marginBottom: 10, background: 'white' }}
                autoFocus
                disabled={thinking}
                multiline
                rows={4}
                value={text}
                variant="outlined"
                onChange={this.onChange('text')}
              />
            ) : (
              <Typography variant="body2" component="p" dangerouslySetInnerHTML={{__html: (text || '').replace(/\n/g, '<br/>')}}/>
            )
          }
        </CardContent>
        {
          edit && (
            <CardActions style={{padding: `0 1${edit ? 2 : 0}px 10px`, marginTop: -8, justifyContent: edit ? 'space-between' : 'flex-start'}}>

              <FilesUpload
                id={id}
                edit={edit}
                disabled={thinking}
                attachments={attachments}
                toUpload={toUpload}
                onChange={this.onAttachmentsChange}
              />
              {
                edit &&  <div style={{flex: '0 1 0', display: 'flex'}}>
                  <Button
                    variant="text"
                    color="default"
                    size="small"
                    disabled={thinking}
                    onClick={this.onCancelEdit}
                  > { 'Cancel' } </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    disabled={thinking}
                    onClick={this.onSaveNote}
                  > { 'Save' } </Button>
                  &nbsp;
                </div>
              }
            </CardActions>
          )
        }
        {
          !!attachments && !!attachments.length && (
            <div style={{margin: '0 15px'}}>
              <FileAttachments
                edit={edit}
                disabled={thinking}
                attachments={attachments}
                toUpload={toUpload}
                onChange={this.onAttachmentsChange}
              />
            </div>
          )
        }
        <Menu
          anchorEl={anchor}
          keepMounted
          open={open}
          disabled={thinking}
          onClose={this.onCloseMenu}
        >
          <MenuItem onClick={this.onEdit}>Edit</MenuItem>
          <MenuItem onClick={this.onRemoveNote}>Delete</MenuItem>
        </Menu>
      </Card>
    )
  }
}

export default class Notes extends React.Component {
  render () {
    const {
      owner,
      thinking,
      ownerName,
      notes,
      onCreate,
      onUpdate,
      onRemove
    } = this.props

    return (
      <Panel
        title="Notes"
        thinking={thinking}
      >
        <AddNote ownerName={ownerName} onCreate={onCreate}/>

        <AnimatedList animation="grow" initialAnimationDuration={300}>
          {
            notes.map(note => <Note key={note.id} {...note} onRemove={onRemove} onUpdate={onUpdate}/>)
          }
        </AnimatedList>
      </Panel>
    )
  }
}
