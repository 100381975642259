import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import User from './User'
import UserStore from '../../stores/user'
import config from '../../config'

@withRouter
@observer
class _User extends React.Component {
  constructor(props) {
    super(props)
    const {
      match: {
        params: {
          id
        }
      }
    } = props
    this.store = new UserStore(id)
  }

  onUpdate = async (details) => await this.store.update(details)
  onSetPassword = async (details) => await this.store.setPassword(details)

  onLoginAs = async (kind) => {
    const { token } = await this.store.getToken()
    if (token) {
      const url = kind === 'staging' ? 'https://staging.my.breezy.io' : config.LOGIN_APP_URL
      window.open(`${url}?token=${token}&admin=true`,'_blank')
    }
  }

  render () {
    const {
      match: {
        params: {
          id
        }
      }
    } = this.props

    const {
      details,
      loading,
      thinking
    } = this.store

    return <User
      id={id}
      details={details}
      loading={loading}
      thinking={thinking}
      onUpdate={this.onUpdate}
      onSetPassword={this.onSetPassword}
      onLoginAs={this.onLoginAs}
    />
  }
}

export default _User
