import React from 'react'
import DashboardPage from '../../layouts/DashboardPage'
import DetailsForm from '../../components/Details'
import SetPassword from '../../components/SetPassword'

const config = [
  { key: 'id', name: 'ID', type: 'string' },
  { key: 'brand', name: 'Brand ID', editable: true, creatable: true, type: 'string' },
  {
    key: 'brandName', name: 'Brand Name', editable: true, creatable: true, type: 'string',
    link: { title: 'Brand', pattern: `/brands/{id}`, keys: { id: 'brand' } }
  },
  { key: 'searchId', name: 'Search ID', editable: true, creatable: true, type: 'string' },
  { key: 'searchName', name: 'Search Name', editable: true, creatable: true, type: 'string' },
  { key: 'researchId', name: 'Research ID', editable: true, creatable: true, type: 'string' },
  { key: 'createdAt', name: 'Started', type: 'string' },
  { key: 'finishedAt', name: 'Finished', type: 'string' },
  { key: 'swUsedQuota', name: 'Used Quota', type: 'string' },
  { key: 'cost', name: 'Cost', type: 'string' },
  { key: 'status', name: 'Status', type: 'string', kind: 'status' },

  // { key: 'holder', name: '', type: 'string' },
  // { key: 'holderCounters', name: <strong>Counters</strong>, type: 'string' },
  // // { key: 'alexa', name: 'alexa', type: 'number' },
  // { key: 'mozLinks', name: 'mozLinks', type: 'number' },
  // { key: 'mozMetrics', name: 'mozMetrics', type: 'number' },
  // { key: 'zenserp', name: 'zenserp', type: 'number' },
  //
  //
  // { key: 'holder', name: '', type: 'string' },
  // { key: 'hostScoreWeightingsHolder', name: <strong>Host Score Weightings</strong>, type: 'string' },
  // { key: 'hostQuality', name: 'hostQuality', type: 'string' },
  // { key: 'hostQuantity', name: 'hostQuantity', type: 'string' },
  // { key: 'ubiquitousHost', name: 'ubiquitousHost', type: 'string' },
  //
  //
  // { key: 'holder', name: '', type: 'string' },
  // { key: 'hostScoreWeightingsHolder', name: <strong>URL Score Weightings</strong>, type: 'string' },
  // { key: 'competitorResearch', name: 'competitorResearch', type: 'string' },
  // { key: 'keywordResearch', name: 'keywordResearch', type: 'string' },
  // { key: 'ubiquitousUrl', name: 'ubiquitousUrl', type: 'string' },
  // { key: 'urlKeywords', name: 'urlKeywords', type: 'string' },



]

export default class Research extends React.Component {
  state = {
    edit: false
  }

  onCancel = e => {
    if (this.props.id === 'create') {
      return this.props.onCancel()
    }
  }

  render () {
    const {
      id,
      details,
      loading,
      onCreate,
      onUpdate,
      onCancel
    } = this.props

    return (
      <DashboardPage
        kind="details"
        title={"Research"}
        status={id}
        noActions
        thinking={loading}
      >
        <DetailsForm
          notEditable
          config={config}
          details={details}
          onCreate={onCreate}
          onUpdate={onUpdate}
          onCancel={onCancel}
        />

      </DashboardPage>
    )
  }
}
