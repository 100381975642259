import React from 'react'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import Brands from './Brands'
import BrandsStore from '../../stores/brands'
import config from '../../config'

@withRouter
@observer
class _Brands extends React.Component {
  constructor(props) {
    super(props)
    this.store = new BrandsStore()
  }

  onChangeSort = orderByNew => e => {
    const { listProps } = this.store
    const { order, orderBy } = listProps
    let orderNew = ''
    if (orderBy === orderByNew) {
      if (order === 'desc') {
        orderNew = 'asc'
      } else {
        orderNew = 'desc'
      }
    } else {
      orderNew = 'desc'
    }

    this.store.onChangeSort(orderNew, orderByNew)
  }

  onChangePage = (e, page) => this.store.onChangePage(page)

  onChangeRowsPerPage = ({ target: { value }}) => this.store.onChangeRowsPerPage(value)

  onChangeFilters = name => object => {
    let value = null
    if (object.hasOwnProperty('target')) {
      value = object.target.value
    } else {
      value = object
    }
    this.store.onChangeFilter(name, value)
  }

  onClickRow = (e, id) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(`/brands/${id}`, '_blank')
    } else {
      this.props.history.push(`/brands/${id}`)
    }
  }

  render () {
    const {
      listProps
    } = this.store

    return <Brands
      {...this.props}
      {...listProps}
      items={listProps.items.map(({ totalSpent, ...rest }) => ({
        ...rest,
        totalSpent: `${config.NUMBER_CURRENCY_SYMBOL}${(totalSpent || 0).toFixed(4)}`
      }))}
      onChangeSort={this.onChangeSort}
      onChangeFilters={this.onChangeFilters}
      onChangePage={this.onChangePage}
      onChangeRowsPerPage={this.onChangeRowsPerPage}
      onClickRow={this.onClickRow}
    />
  }
}

export default _Brands
