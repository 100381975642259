import React from 'react'
import styled from 'styled-components'
import {Link as RouterLink} from 'react-router-dom'
import MuiTableCell from '@material-ui/core/TableCell'

const TableCell = styled(MuiTableCell)`
  position: relative;
`
const Link = styled(RouterLink)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const TableCellLink = ({children, to, ...other}) => <TableCell {...other}>
  <Link to={to} />
  {children}
</TableCell>

export default TableCellLink