import config from '../config'

const SIGNED_REQUEST_URL = config.AWS_S3_URL

const generateUniqueFolder = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8)
    return `${v.toString(16)}`
  });
}

const getSignedRequest = async (file) => {
  return fetch(`${SIGNED_REQUEST_URL}?domain=${window.location.origin}&fileName=${generateUniqueFolder()}/${file.name}&fileType=${file.type}`)
    .then(response => {
      if (!response.ok) {
        throw new Error(`${response.status}: ${response.statusText}`);
      }
      return response.json();
    });
}

const uploadFile = async (file, signedRequest) => {
  const options = {
    method: 'PUT',
    body: file,
    'x-amz-acl': 'public-read',
    'Content-Type': file.type
  };
  return fetch(signedRequest, options)
    .then(response => {
      let success = true
      if (!response.ok) {
        success = false;
        throw new Error(`${response.status}: ${response.statusText}`);
      }
      return  { success };
    });
}

const uploadToS3 = async (file) => {
  return getSignedRequest(file)
    .then(json => uploadFile(file, json.signedRequest, json.url))
    .then(url => {
      return url;
    })
    .catch(err => {
      console.error(err);
      return null;
    });
}

export const upload = async (file) => await uploadToS3(file)

const mocked = {"success":true,"files":[
  {"signedRequest":"https://test-assets-afill-eu.s3.eu-west-2.amazonaws.com/07b42781-1ce5-4bc9-b6e0-b754b36f0b94/2019-12-31%2018-54-47.png?Content-Type=image%2Fpng&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA3YH7EARIYICS5HFP%2F20200609%2Feu-west-2%2Fs3%2Faws4_request&X-Amz-Date=20200609T104235Z&X-Amz-Expires=500&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEJv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCWV1LXdlc3QtMiJIMEYCIQDkEQ1inPvKwzxvM%2Brc0UpKZoBwlxjt%2FG4GdKN3KzTX%2BwIhALiY7Z9e6HIjNZyXGAiMXy1J6edN5Q9n9kJiuwx8b67BKtMBCBQQARoMODA3OTg4ODg4NjU3IgxxZXSf7WOWOU0mXL4qsAEsMEi7%2FTeAijlC8bZtfhIwcYwutwwKqqpM56jRgSwGmYh8VwGotZHJCKZ1xUlPa7Wvia4ffoq64QeDP8UTgfkdD4yu1DBhwI3gvC0VpOCqTFwEaj9pD3dnz1Guv7haN5agzuoC1xMbocqfZcYfxZD36ND4PdXcSAcP%2BjLLISmIRyFPbqunyqG%2BQmWiGhrqrT6Hi7MOfY5pLgKS8uMSmQdGvZfYUD1qpxcX7N3LFgSqbjDUzv32BTrfASSQdoopmX%2BL2ecy3NZ7wy%2FXQUISeNZqoyTzYyTYYJ%2BTyC4ewin5ikCpLPDpEHkAW%2Fg6WBrylPO2%2F%2BklZOaM1HvVkGzr7JlP45hssY5pgnJ0b4DE%2Bii%2BstAzw2K%2FpNsMGmThVi4OAeHyZYHGZVMcRMZstRjeJALMN%2BpCbDORPG2bVq9%2BCkU3vw8YQxxO8%2BMlA%2F4yGRo13UFsOB5SSZT4QEV9srN8jkRcrtuPJhPd%2FeoLdY%2B3I%2BmMxSFZCKiPfBsstKh94tIHE1n%2F2LatqN2oGmOOlUvz4wdSCDAEYuHVwnA%3D&X-Amz-Signature=c63ac31c1fbfc2df060b3e74a947f83ac4859c121f3c6e4a5dea708737e835f7&X-Amz-SignedHeaders=host%3Bx-amz-acl&x-amz-acl=public-read",
    "url":"https://test-assets-afill-eu.s3.amazonaws.com/07b42781-1ce5-4bc9-b6e0-b754b36f0b94/2019-12-31 18-54-47.png"},
    {"signedRequest":"https://test-assets-afill-eu.s3.eu-west-2.amazonaws.com/07b42781-1ce5-4bc9-b6e0-b754b36f0b94/13605803.jpg?Content-Type=image%2Fjpeg&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA3YH7EARIYICS5HFP%2F20200609%2Feu-west-2%2Fs3%2Faws4_request&X-Amz-Date=20200609T104235Z&X-Amz-Expires=500&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEJv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCWV1LXdlc3QtMiJIMEYCIQDkEQ1inPvKwzxvM%2Brc0UpKZoBwlxjt%2FG4GdKN3KzTX%2BwIhALiY7Z9e6HIjNZyXGAiMXy1J6edN5Q9n9kJiuwx8b67BKtMBCBQQARoMODA3OTg4ODg4NjU3IgxxZXSf7WOWOU0mXL4qsAEsMEi7%2FTeAijlC8bZtfhIwcYwutwwKqqpM56jRgSwGmYh8VwGotZHJCKZ1xUlPa7Wvia4ffoq64QeDP8UTgfkdD4yu1DBhwI3gvC0VpOCqTFwEaj9pD3dnz1Guv7haN5agzuoC1xMbocqfZcYfxZD36ND4PdXcSAcP%2BjLLISmIRyFPbqunyqG%2BQmWiGhrqrT6Hi7MOfY5pLgKS8uMSmQdGvZfYUD1qpxcX7N3LFgSqbjDUzv32BTrfASSQdoopmX%2BL2ecy3NZ7wy%2FXQUISeNZqoyTzYyTYYJ%2BTyC4ewin5ikCpLPDpEHkAW%2Fg6WBrylPO2%2F%2BklZOaM1HvVkGzr7JlP45hssY5pgnJ0b4DE%2Bii%2BstAzw2K%2FpNsMGmThVi4OAeHyZYHGZVMcRMZstRjeJALMN%2BpCbDORPG2bVq9%2BCkU3vw8YQxxO8%2BMlA%2F4yGRo13UFsOB5SSZT4QEV9srN8jkRcrtuPJhPd%2FeoLdY%2B3I%2BmMxSFZCKiPfBsstKh94tIHE1n%2F2LatqN2oGmOOlUvz4wdSCDAEYuHVwnA%3D&X-Amz-Signature=52b1329bbc5f670267b291c7255dce679cfb63d40ff7b8254009533ccf341fb6&X-Amz-SignedHeaders=host%3Bx-amz-acl&x-amz-acl=public-read",
      "url":"https://test-assets-afill-eu.s3.amazonaws.com/07b42781-1ce5-4bc9-b6e0-b754b36f0b94/13605803.jpg"},
    {"signedRequest":"https://test-assets-afill-eu.s3.eu-west-2.amazonaws.com/07b42781-1ce5-4bc9-b6e0-b754b36f0b94/13605870.jpg?Content-Type=image%2Fjpeg&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA3YH7EARIYICS5HFP%2F20200609%2Feu-west-2%2Fs3%2Faws4_request&X-Amz-Date=20200609T104235Z&X-Amz-Expires=500&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEJv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCWV1LXdlc3QtMiJIMEYCIQDkEQ1inPvKwzxvM%2Brc0UpKZoBwlxjt%2FG4GdKN3KzTX%2BwIhALiY7Z9e6HIjNZyXGAiMXy1J6edN5Q9n9kJiuwx8b67BKtMBCBQQARoMODA3OTg4ODg4NjU3IgxxZXSf7WOWOU0mXL4qsAEsMEi7%2FTeAijlC8bZtfhIwcYwutwwKqqpM56jRgSwGmYh8VwGotZHJCKZ1xUlPa7Wvia4ffoq64QeDP8UTgfkdD4yu1DBhwI3gvC0VpOCqTFwEaj9pD3dnz1Guv7haN5agzuoC1xMbocqfZcYfxZD36ND4PdXcSAcP%2BjLLISmIRyFPbqunyqG%2BQmWiGhrqrT6Hi7MOfY5pLgKS8uMSmQdGvZfYUD1qpxcX7N3LFgSqbjDUzv32BTrfASSQdoopmX%2BL2ecy3NZ7wy%2FXQUISeNZqoyTzYyTYYJ%2BTyC4ewin5ikCpLPDpEHkAW%2Fg6WBrylPO2%2F%2BklZOaM1HvVkGzr7JlP45hssY5pgnJ0b4DE%2Bii%2BstAzw2K%2FpNsMGmThVi4OAeHyZYHGZVMcRMZstRjeJALMN%2BpCbDORPG2bVq9%2BCkU3vw8YQxxO8%2BMlA%2F4yGRo13UFsOB5SSZT4QEV9srN8jkRcrtuPJhPd%2FeoLdY%2B3I%2BmMxSFZCKiPfBsstKh94tIHE1n%2F2LatqN2oGmOOlUvz4wdSCDAEYuHVwnA%3D&X-Amz-Signature=e5372663d3524420729208732992542c777b21353d6d6c8bf8817059845a7fa5&X-Amz-SignedHeaders=host%3Bx-amz-acl&x-amz-acl=public-read",
    "url":"https://test-assets-afill-eu.s3.amazonaws.com/07b42781-1ce5-4bc9-b6e0-b754b36f0b94/13605870.jpg"}]}

export const getSignedRequestMulti = async (files, existingFolder) => {
  const folder = existingFolder || generateUniqueFolder()
  return fetch(SIGNED_REQUEST_URL,
    {
      method: 'POST',
      body: JSON.stringify({ files: files.map(({ fileName, fileType }) => ({ fileName: `${folder}/${fileName}`, fileType })) }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`${response.status}: ${response.statusText}`);
      }
      return response.json();
    })
}

export const uploadSingle = async (file) => {
  const { name: fileName, type: fileType } = file
  const { success: signedSuccess, files } = await getSignedRequestMulti([{ fileName, fileType }])
  if (signedSuccess) {
    const { success } = await uploadFile(file, files[0].signedRequest)
    return { success, url: files[0].url }
  }
  return { success: false, url: null }
}

const AllPromises = (files) => {
  // const promises = filesBatchResult.map(({ url, signedRequest}) => new Promise(async (resolve) => resolve(await uploadSingle()))
}

export const getFilesUrlToUpload = getSignedRequestMulti

export const uploadMulti = (files) => new Promise (resolve => {
  const promises = files.map(f => new Promise(async (r) => {
    const result = await uploadFile(f.file, f.signedRequest)
    return resolve(result)
  }))

  Promise.all(promises).then(resolve)
})


