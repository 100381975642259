import React from 'react'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import Campaigns from './Campaigns'
import CampaignsStore from '../../stores/campaigns'
import config from '../../config'
import { formatDateTime } from '../../utils/format'

const itemsTime = (items = []) => {
  return items.map(({ createdAt, ...other}) => {
    return { ...other, createdAt: formatDateTime(createdAt) }
  })
}

@withRouter
@observer
class _Campaigns extends React.Component {
  constructor(props) {
    super(props)
    this.store = new CampaignsStore()
  }

  onChangeSort = orderByNew => e => {
    const { listProps } = this.store
    const { order, orderBy } = listProps
    let orderNew = ''
    if (orderBy === orderByNew) {
      if (order === 'desc') {
        orderNew = 'asc'
      } else {
        orderNew = 'desc'
      }
    } else {
      orderNew = 'desc'
    }

    this.store.onChangeSort(orderNew, orderByNew)
  }

  onChangePage = (e, page) => this.store.onChangePage(page)

  onChangeRowsPerPage = ({ target: { value }}) => this.store.onChangeRowsPerPage(value)

  onChangeFilters = name => object => {
    let value = null
    if (object.hasOwnProperty('target')) {
      value = object.target.value
    } else {
      value = object
    }

    this.store.onChangeFilter(name === 'name' ? 'search' : name, value)
  }

  onClickRow = (e, id) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(`/campaigns/${id}`, '_blank')
    } else {
      this.props.history.push(`/campaigns/${id}`)
    }
  }

  render () {
    const {
      listProps
    } = this.store

    const {
      items
    } = listProps

    return <Campaigns
      {...this.props}
      {...listProps}
      items={itemsTime(items).map(({ adexes, ...item }) => {
        return {
          adexes: (adexes || []).join(', '),
          ...item
        }
      })}
      onChangeSort={this.onChangeSort}
      onChangeFilters={this.onChangeFilters}
      onChangePage={this.onChangePage}
      onChangeRowsPerPage={this.onChangeRowsPerPage}
      onClickRow={this.onClickRow}
    />
  }
}

export default _Campaigns
