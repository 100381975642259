import { observable, action, computed, toJS } from 'mobx'
import { Delete, Get, Post, Put } from '../utils/request'
import hasError from './request-message'

export default class Notes {
  model = null
  modelId = null
  url = null
  @observable items = []

  @observable thinking = true

  constructor (model, modelId, discovery) {
    this.model = model
    this.modelId = modelId
    this.url = `/api/admin/${discovery ? 'discovery/' : ''}notes`
    this.load().then()
  }

  @action
  async load () {
    this.thinking = true

    const { errorCode, notes, message } = await Get(`${this.url}?filters[${this.model}]=${this.modelId}`)

    if (!hasError(!errorCode, message)) {
      this.items = notes
    }

    this.thinking = false
  }

  @action
  async create (details) {
    this.thinking = true
    const { success, message } = await Post(`${this.url}`, {
      note: {...details, [this.model]: this.modelId }
    })
    this.load()
    this.thinking = false
    return { success: !hasError(success, message, `Note successfully created`) }
  }

  @action
  async update ({ id, ...details }) {
    this.thinking = true
    const { success, message } = await Put(`${this.url}/${id}`, {
      note: details
    })
    this.load()
    this.thinking = false
    return { success: !hasError(success, message, `Note successfully updated`) }
  }

  @action
  async remove (id) {
    this.thinking = true
    const { success, message } = await Delete(`${this.url}/${id}`, {})
    this.load()
    this.thinking = false
    return { success: !hasError(success, message, `Note successfully removed`) }
  }

  @computed
  get notes () {
    return toJS(this.items)
  }
}
