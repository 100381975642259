import React from 'react'
import { Button } from '@material-ui/core'
import List from '../../components/List'
import DashboardPage from '../../layouts/DashboardPage'
import ConfirmDialog from '../../components/ConfirmDialog'

const columns = [
  { id: 'createdAt', disablePadding: true, label: 'Live Since' },
  { id: 'ready', disablePadding: true, label: 'Ready', kind: 'status' },
  { id: 'published', disablePadding: true, label: 'Published', kind: 'status' },
  { id: 'name', disablePadding: true, label: 'Adex' },
  { id: 'signaturesCount', disablePadding: true, label: 'Signatures' },
  { id: 'kind', disablePadding: true, label: 'Kind' }
]

export default class Signatures extends React.Component {
  state = { open: false }

  onOpen = () => this.setState({ open: true })

  onSave = async (details) => {
    return await this.props.onCreate(details)
  }

  onCancel = () => {
    this.setState({ open: false })
  }

  onShowDeletedOnly = () => {
    const { filters } = this.props
    this.props.onChangeFilters('deletedOnly')(!filters.hasOwnProperty('deletedOnly'))
  }

  render () {
    const { filters } = this.props

    return (
      <DashboardPage
        forceDefaultActions
        kind="list"
        title="Signatures"
        addLabel="Create Signature"
        addLink="/signatures/create"
        actions={() => {
          return <>
            <Button
              mr={2}
              variant={filters.hasOwnProperty('deletedOnly') ? 'contained' : 'outlined'}
              color={filters.hasOwnProperty('deletedOnly') ? 'primary' : 'default'}
              onClick={this.onShowDeletedOnly}
            >
              Show Deleted Only
            </Button>
            &nbsp;&nbsp;
            <ConfirmDialog
              title={'Publish Signatures'}
              confirmation
              confirmationText={'Are you sure you want to publish all signatures?'}
              action={this.props.onPublish}
              confirmationButtonPrimary
              confirmButtonLabel={'Publish'}
              confirmButtonProps={{
                variant: 'contained',
                color: 'primary'
              }}
            >
              <Button variant={'outlined'} color="default" mr={2}>Publish</Button>
            </ConfirmDialog>
          </>
        }}
      >
        <List
          columns={columns}
          {...this.props}
          searchable
          emptyMessage={'No signatures matching the given criteria.'}
        />
      </DashboardPage>
    )
  }
}
