import React from 'react'
import {
  Grid,
  Button as MuiButton,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  MenuItem
} from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import { spacing } from '@material-ui/system'
import styled from 'styled-components'
import { RejectButton } from './Buttons'

const DialogActions = styled(MuiDialogActions)(spacing)
const Button = styled(MuiButton)(spacing)

export default class ConfirmDialog extends React.Component {
  constructor (props) {
    super(props)
    this.state = { open: props.open || false, reason: '', thinking: false, progress: 0 }
  }

  static defaultProps = {
    title: 'Confirm action',
    quickAnswers: [
      'Payment error',
      'Out of stock',
      'Refund',
      'Fraud',
      'Can\'t contact buyer',
      'Duplicate'
    ],
    confirmButtonLabel: 'Confirm',
    confirmButtonProps: {},
    action: () => {}
  }

  onChange = name => ({ target: { value }}) => { this.setState({ reason: value })}

  onClick = () => {
    const { open } = this.state
    this.setState({ open: !open})
  }

  onConfirm = () => {

    this.setState({ thinking: true })

    if (!this.props.progress) {
      this.props.action(this.state.reason)
      this.setState({ open: false })
    } else {
      this.props.action(this.state.reason)
      const interval = setInterval(() => {
        const { progress } = this.state
        if (progress >= 99) {
          clearInterval(interval)
          this.setState({ open: false })
        } else {
          this.setState({ progress: progress + 1})
        }
      }, 600)

    }

  }

  render () {
    const { open, reason, thinking } = this.state
    const {
      title,
      children,
      confirmation,
      confirmationText,
      confirmButtonLabel,
      confirmButtonProps,
      confirmationButtonPrimary,
      quickAnswers
    } = this.props

    return (
      <React.Fragment>
        { children && React.cloneElement(children, { onClick: this.onClick }) }
        <Dialog
          fullWidth
          maxWidth="sm"
          open={open}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">
            {title}
          </DialogTitle>

          <DialogContent>
            {
              !!confirmationText && (
                <>
                  <Grid item xs={12}>
                    {confirmationText}
                  </Grid>
                  {
                    this.props.progress && this.state.progress > 0 && (
                      <Grid item xs={12}>
                        <br/>
                        <LinearProgress variant="determinate" value={this.state.progress}/>
                      </Grid>
                    )
                  }
                </>
              )
            }
            {
              !confirmation && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      select
                      fullWidth
                      label="Quick answer"
                      m={2}
                      value={reason}
                      onChange={this.onChange('reason')}
                    >
                      {
                        quickAnswers.map(a => <MenuItem key={a} value={a}>{a}</MenuItem>)
                      }
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Terms"
                      m={2}
                      multiline
                      rows="4"
                      rowsMax="4"
                      value={reason}
                      onChange={this.onChange('reason')}
                    />
                  </Grid>
                </>
              )
            }
          </DialogContent>
          <DialogActions px={6} py={4}>
            <Grid container>
              <Grid item xs={12} sm={6}></Grid>
              <Grid xs={12} sm={6}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                <Button
                  onClick={this.onClick}
                  color="default"
                  disabled={thinking}
                  mr={2}>
                  Cancel
                </Button>
                {
                  confirmationButtonPrimary && (
                    <Button
                      onClick={this.onConfirm}
                      color="default"
                      mr={2}
                      {...confirmButtonProps}
                      disabled={thinking}
                    >
                      { confirmButtonLabel }
                    </Button>
                  )
                }
                {
                  !confirmationButtonPrimary && (
                    <RejectButton
                      onClick={this.onConfirm}
                      color="default"
                      mr={2}
                      {...confirmButtonProps}
                      disabled={thinking}
                    >
                      { confirmButtonLabel }
                    </RejectButton>
                  )
                }
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}
