import List from './list'
import { toJS } from 'mobx'
import { Get, Post, Put } from '../utils/request'
import hasError from './request-message'

const timeout = (time) => new Promise(resolve => setTimeout(resolve, time))

export default class SearchesStore extends List {
  url = '/api/admin/searches'

  constructor(filters = {}) {
    super()
    this.order = 'desc'
    this.orderBy = 'createdAt'
    this.itemsField= 'searches'
    this.filters = filters
    this.getList().then()
  }

  async waitForCopyFinished (copyActionId) {
    const { status } = await Get(`/api/admin/searches/copy-status?copyActionId=${copyActionId}`)

    if (status === 'done') {
      hasError(true, null, `Search successfully copied to the brand`)
      return { success: true }
    }

    await timeout(20 * 1000)

    return await this.waitForCopyFinished(copyActionId)
  }

  async copy (brand, search) {
    const { id: searchId } = search
    const { id: brandId, name: brandName } = brand

    const { success, message, copyActionId } = await Post(`/api/admin/searches/${searchId}/copy`, { brandId })

    if (!hasError(success, message, `We started copying search to the brand with name ${brandName}`)) {
      return await this.waitForCopyFinished(copyActionId)
    }

    return { success }
  }

  async toggleVisibility (id, kind) {
    this.items = toJS(this.items).map(({ hidden, ...rest }) => {
      if (rest.id === id) {
        return {
          ...rest,
          ...(!hidden && { hidden: true })
        }
      }
      return { hidden, ...rest }
    })

    const { success, message } = await Put(`/api/admin/searches/${id}/${kind}`)

    if (!hasError(success, message, `Search successfully marked as ${kind === 'hide' ? 'hidden' : 'visible'}`)) {

    }
  }
}
