import React from 'react'
import { Chip as MuiChip } from '@material-ui/core'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { red, green, orange } from '@material-ui/core/colors'

const Chip = styled(MuiChip)(spacing)

const GREEN = { backgroundColor: green[500], color: '#fff' }
const ORANGE = { backgroundColor: orange[500], color: '#fff' }
const RED = { backgroundColor: red[500], color: '#fff' }

export default class Status extends React.Component {
  render() {
    switch (this.props.value) {
      case 'confirmed':
        return <Chip label='Confirmed' color='secondary' size='small' />
      case 'verified':
        return <Chip label='Verified' color='secondary' size='small' />
      case 'active':
        return <Chip label='Active' color='secondary' size='small' />
      case 'approved':
        return <Chip label='Approved' color='secondary' size='small' />
      case 'done':
        return <Chip label='Done' color='secondary' size='small' />
      case 'ready':
        return <Chip label='Ready' color='secondary' size='small' />

      case 'unconfirmed':
        return <Chip label='Unconfirmed' color='default' size='small' />
      case 'requested':
        return <Chip label='Pending' color='default' size='small' />
      case 'pending':
        return <Chip label='Pending' color='default' size='small' />
      case 'paused':
        return <Chip label='Paused' color='default' size='small' />
      case 'progress':
        return <Chip label='Processing' color='default' size='small' />
      case 'processing':
        return <Chip label='Processing' color='default' size='small' />
      case 'processed':
        return <Chip label='Importing' color='default' size='small' />

      case 'validating':
        return <Chip label='Validating' style={ORANGE} size='small' />
      case 'found':
        return <Chip label='Found' style={GREEN} size='small' />
      case 'entityFound':
        return <Chip label='Entity Found' style={GREEN} size='small' />
      case 'published':
        return <Chip label='Published' style={GREEN} size='small' />
      case 'researched':
        return <Chip label='Researched' style={GREEN} size='small' />
      case 'attached':
        return <Chip label='Attached' style={GREEN} size='small' />

      case 'bounced':
        return <Chip label='Bounced' style={RED} size='small' />
      case 'optedout':
        return <Chip label='Optedout' style={RED} size='small' />
      case 'unsuccessful':
        return <Chip label='Rejected' style={RED} size='small' />
      case 'rejected':
        return <Chip label='Rejected' style={RED} size='small' />
      case 'deleted':
        return <Chip label='Archived' style={RED} size='small' />

      default:
        return null
    }
  }
}
