import React from 'react'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import Contacts from './Contacts'
import ContactsStore from '../../stores/contacts'

@withRouter
@observer
class _Contacts extends React.Component {
  constructor(props) {
    super(props)
    this.store = new ContactsStore()
  }

  onChangeSort = (orderByNew) => (e) => {
    const { listProps } = this.store
    const { order, orderBy } = listProps
    let orderNew = ''
    if (orderBy === orderByNew) {
      if (order === 'desc') {
        orderNew = 'asc'
      } else {
        orderNew = 'desc'
      }
    } else {
      orderNew = 'desc'
    }

    this.store.onChangeSort(orderNew, orderByNew)
  }

  onChangePage = (e, page) => this.store.onChangePage(page)

  onChangeRowsPerPage = ({ target: { value } }) =>
    this.store.onChangeRowsPerPage(value)

  onChangeFilters = (name) => (object) => {
    let value = null

    const field = name === 'name' ? 'search' : name

    if (object.hasOwnProperty('target')) {
      value = object.target.value
    } else {
      value = object
    }
    this.store.onChangeFilter(field, value)
  }

  onClickRow = (e, id) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(`/contacts/${id}`, '_blank')
    } else {
      this.props.history.push(`/contacts/${id}`)
    }
  }

  onCreate = async (details) => {
    console.log('create', details)
    return await this.store.createContact(details)
  }

  render() {
    const { listProps, contacts } = this.store

    return (
      <Contacts
        {...this.props}
        {...listProps}
        items={contacts}
        onChangeSort={this.onChangeSort}
        onChangeFilters={this.onChangeFilters}
        onChangePage={this.onChangePage}
        onChangeRowsPerPage={this.onChangeRowsPerPage}
        onClickRow={this.onClickRow}
        onCreate={this.onCreate}
      />
    )
  }
}

export default _Contacts
