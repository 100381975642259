import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import Research from './Research'
import ResearchStore from '../../stores/research'

@withRouter
@observer
class _Research extends React.Component {
  constructor(props) {
    super(props)
    const {
      match: {
        params: {
          id
        }
      }
    } = props
    this.store = new ResearchStore(id)
  }

  onUpdate = async (details) => await this.store.update(details)
  onSetPassword = async (details) => await this.store.setPassword(details)

  render () {
    const {
      match: {
        params: {
          id
        }
      }
    } = this.props

    const {
      details,
      loading,
      thinking
    } = this.store

    return <Research
      id={id}
      details={details}
      loading={loading}
      thinking={thinking}
      onUpdate={this.onUpdate}
      onSetPassword={this.onSetPassword}
    />
  }
}

export default _Research
