import React from 'react'
import { observer } from 'mobx-react'
import Notes from './Notes'
import NotesStore from '../../stores/notes'


@observer
class _Notes extends React.Component {
  constructor (props) {
    super(props)
    const { client, clientId, discovery } = props
    this.store = new NotesStore(client, clientId, discovery)
  }

  onCreate = details => this.store.create(details)
  onUpdate = details => this.store.update(details)
  onRemove = id => this.store.remove(id)

  render () {
    const {
      notes,
      thinking
    } = this.store
    return <Notes
      notes={notes}
      thinking={thinking}
      onCreate={this.onCreate}
      onUpdate={this.onUpdate}
      onRemove={this.onRemove}
      {...this.props}
    />
  }
}

export default _Notes
