import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import Ecosystem from './Ecosystem'
import EcosystemStore from '../../stores/ecosystem'

@withRouter
@observer
class _Ecosystem extends React.Component {
  constructor(props) {
    super(props)
    const {
      match: {
        params: {
          id
        }
      }
    } = props
    this.store = new EcosystemStore(id)
  }

  onUpdate = async (details) => await this.store.update(details)

  onCreate = async (details) => {
    const { success, ecosystem } = await this.store.create(details)
    if (success) {
      this.props.history.push(`/ecosystems/${ecosystem.id}`)
      this.store.load(ecosystem.id)
    }
  }

  onClickRow = (e, id) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(`/ecosystems/${id}`, '_blank')
    } else {
      this.props.history.push(`/ecosystems/${id}`)
    }
  }

  onRemove = async () => {
    const { success } = await this.store.remove()
    if (success) {
      this.props.history.push(`/ecosystems`)
    }
  }

  render () {
    const {
      match: {
        params: {
          id
        }
      }
    } = this.props

    const {
      details,
      domainsList,
      loaded,
      loading,
      thinking
    } = this.store

    return <Ecosystem
      id={id}
      create={id === 'create'}
      details={details}
      domains={domainsList}
      loaded={loaded}
      loading={loading}
      thinking={thinking}
      onCreate={this.onCreate}
      onUpdate={this.onUpdate}
      onRemove={this.onRemove}
    />
  }
}

export default _Ecosystem
