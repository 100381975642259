import List from './list'
import { action } from 'mobx'
import { Post } from '../utils/request'
import hasError from './request-message'

const ECOSYSTEMS_UPDATE_TIMEOUT = 60 * 1000

const timeout = (success) => new Promise(resolve => setTimeout(() => {
  return resolve({ success })
}, ECOSYSTEMS_UPDATE_TIMEOUT))

export default class EcosystemsStore extends List {
  url = '/api/admin/ecosystems'

  constructor() {
    super()
    // this.order = 'desc'
    // this.orderBy = 'createdAt'
    this.itemsField= 'ecosystems'
    this.perPage = 25
    this.getList().then()
  }

  @action
  async updateEcosystems () {
    const { success, message } = await Post(`${this.url}/refresh`)
    await timeout(success)

    hasError(success, message, `Ecosystem successfully updated`)

    this.getList().then()
  }
}
