import React from 'react'
import styled from 'styled-components'
import {Instagram, Facebook, YouTube, Pinterest, LinkedIn, Twitter, Public} from '@material-ui/icons'
import {IconBing, IconGoogle} from './Icons'
import ChannelIcon from './Icon'
// import {Instagram, Facebook, } from 'react-feather'

const Root = styled.div`
display: inline-flex;
align-items: center;
`
const Name = styled.div`
  display: flex;
  align-items: center;
font-weight: bold;
`
const Icon = styled.div`
line-height: 0;
svg {
font-size: 1.8em;
  //width: 1em;
  //height: 1em;
   fill: ${props => props.theme.palette.grey[700]}
}
`
const Wrapper = styled.div`
margin-left: 1em;
`
const URL = styled.div`
`

const ChannelKind = ({name, kind, url, icon: channelIcon }) => {
  const icon = {
    'instagramAccount': <Instagram />,
    'instagramAds': <Instagram />,
    'facebookPage': <Facebook />,
    'facebookAds': <Facebook />,
    'youtube': <YouTube />,
    'twitter': <Twitter />,
    'pinterest': <Pinterest />,
    'linkedIn': <LinkedIn />,
    'bingAds': <IconBing />,
    'googleAds': <IconGoogle />
  }
  return (
    <Root>
      <Icon>{icon[kind] || <Public />}</Icon>
      <Wrapper>
        <Name><ChannelIcon item={{ icon: channelIcon }} />  {name}</Name>
        <URL>{url}</URL>
      </Wrapper>
    </Root>
  )
}

export default ChannelKind
