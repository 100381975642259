import List from './list'
import { action, computed, observable, toJS } from 'mobx'
import { formatDate } from '../utils/format'
import { Get, Post, Put } from '../utils/request'
import hasError from './request-message'

import { dataToDetails, contactToPayload } from '../utils/contacts'

export default class ContactRequestsStore extends List {
  url = '/api/admin/contact-requests'

  constructor() {
    super()
    this.order = 'desc'
    this.orderBy = 'createdAt'
    this.itemsField = 'contactRequests'
    this.getList().then()
  }

  @action
  async uploadData(data) {
    const { success, message, created, updated } = await Post(
      `/api/admin/contact/import`,
      { data }
    )
    hasError(
      success,
      message,
      `Created ${created} and updated ${updated} contacts`
    )
    this.getList().then()
    return { success }
  }

  @computed
  get requests() {
    return toJS(this.items).map((item) => {
      const { createdAt } = item
      const brand = item.brand || {}
      const channel = item.channel || {}

      return {
        ...item,
        brand: brand.id,
        brandName: brand.name,
        channel: channel.id,
        channelName: channel.domain,
        createdAt: formatDate(createdAt),
      }
    })
  }
}

export class ContactRequestStore {
  @observable id

  @observable brand = {}
  @observable channel = {}
  @observable createdAt
  @observable status

  @observable contactList = []

  @observable loading = true
  @observable thinking = false

  constructor(id) {
    this.id = id
    this.load().then()
  }

  updateFields(contactRequest) {
    const { brand, channel, status, createdAt } = contactRequest
    this.brand = brand
    this.channel = channel
    this.status = status
    this.createdAt = createdAt
  }

  @action
  async load() {
    this.loading = true
    const { errorCode, message, contactRequest } = await Get(
      `/api/admin/contact-requests/${this.id}`
    )

    if (hasError(!errorCode, message)) {
      return message
    } else {
      this.updateFields(contactRequest)
      await this.loadContacts()
    }
    this.loading = false
  }

  @action
  async loadContacts() {
    const { id: brandId } = toJS(this.brand)
    const { id: channelId } = toJS(this.channel)
    const { errorCode, message, contacts } = await Get(
      `/api/admin/contacts?filters[channelId]=${channelId}&filters[brandId]=${brandId}`
    )

    if (!hasError(!errorCode, message, '')) {
      this.contactList = contacts
    }
  }

  @action
  async complete() {
    const { success, message, contactRequest } = await Put(
      `/api/admin//contact-requests/${this.id}/has-entity-only`,
      {}
    )
    if (hasError(success, message)) {
      return message
    } else {
      this.updateFields(contactRequest)
    }
  }

  @action
  async decline() {
    const { success, message, contactRequest } = await Put(
      `/api/admin/contact-requests/${this.id}/decline`,
      {}
    )
    if (!hasError(success, message, 'Contacts request successfully declined')) {
      this.status = 'unsuccessful'
    }
    return { success }
  }

  @action
  async setContact({ id, ...contact }, publish) {
    const data = contactToPayload(contact)
    if (!id) {
      return await this.createContact({ data }, publish)
    }
    return await this.updateContact(id, { data }, publish)
  }

  @action
  async publishContact(contactId) {
    const { success, message } = await Put(
      `/api/admin/contacts/${contactId}/publish`,
      {}
    )

    if (!hasError(success, message, 'Contact successfully published')) {
      await this.load()
      // this.contactList = toJS(this.contactList).map(c => {
      //   return contactId === c.id ? contact : c
      // })
    }
    return { success }
  }

  @action
  async createContact(details, publish) {
    console.log()

    const { success, message, contact } = await Post(`/api/admin/contacts`, {
      contact: {
        ...details,
        channelId: this.channel.id,
      },
    })
    if (!hasError(success, message, 'Contact successfully created')) {
      this.contactList = [contact, ...toJS(this.contactList)]
      if (publish) {
        return await this.publishContact(contact.id)
      }
    }

    return { success }
  }

  @action
  async updateContact(id, details, publish) {
    const { success, message, contact } = await Put(
      `/api/admin/contacts/${id}`,
      {
        contact: {
          ...details,
          // channel: this.channel.id,
        },
      }
    )
    if (!hasError(success, message, 'Contact successfully updated')) {
      this.contactList = toJS(this.contactList).map((c) => {
        return contact.id === c.id ? contact : c
      })

      if (publish) {
        return await this.publishContact(contact.id)
      }
    }

    return { success }
  }

  @computed
  get details() {
    if (!this.loading) {
      const { id, createdAt, brand, channel, status } = this

      return {
        id,
        status,
        createdAt,
        brand: brand.id,
        brandName: brand.name,
        channel: channel.id,
        channelName: channel.domain,
        entityId: channel.entityId,
      }
    }

    return {}
  }

  @computed
  get contacts() {
    return toJS(this.contactList).map((contact) => {
      const { id, data, status } = contact
      return {
        id,
        ...dataToDetails(data),
        status,
      }
    })
  }
}
